body.shopoverviewpage {
    section#content {
        h1 {
            margin-top: 0;
            font-size: 48px;
            line-height: 40px;
            font-weight: 700;
            color: $colorBlack;

            @include media-breakpoint-up(md) {
                font-size: 56px;
                line-height: 48px;
            }
        }
    }

    section#hero {
        padding-top: 80px;
        // padding-bottom: 0;
        margin-bottom: 30px;
        
        @include media-breakpoint-up(md) {
            margin-bottom: 70px;
            padding-top: 80px;
            padding-bottom: 120px;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .slick-slide{
            height:auto;
        }

        .background {
            &:before {
                height: 30px;
                
                @include media-breakpoint-up(md) {
                    height: 100px;
                }
            }

            &:after {
                height: 30px;
                
                @include media-breakpoint-up(md) {
                    height: 100px;
                }
            }
        }

        .background .layer.back {
            background: linear-gradient(to bottom,
                    #{$colorJava} 0,
                    #{$colorCyan} 100%);
        }

        .background .layer.front {
            opacity: 0;
        }

        h2 {
            color: $colorBlack;
            margin-top: 0;
            margin-bottom: 0;

            font-size: 48px;
            line-height: 40px;
            font-weight: 700;

            em {
                font-style: normal;
                color: $colorYellow;
            }
        }

        p {
            width: 100%;
            color: $colorWhite;
            margin-bottom: 30px;
            margin-top: 0;

            font-size: 24px;
            line-height: 32px;

            @include media-breakpoint-up(md) {
                margin-bottom: 20px;
            }
        }

        a.button {
            padding-right: 60px;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
        }

        svg {
            position: absolute;
        }

        img {
            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }

            .ie &, .edge & {
                border-radius: 31% 69% 56% 44% / 57% 58% 42% 43%;
                overflow: hidden;
            }
        }

        .image {
            position: relative;
            margin-bottom: 10px;
            clip-path: url(#hero-mask);
            margin-left: 50px;
            margin-right: 50px;

            img {
                position: relative;
                // height: 100%;
                width: 100%;
                max-height: 480px;
                object-fit: cover;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                display: block;
                z-index: 100;
                mix-blend-mode: soft-light;

                background: linear-gradient(to bottom,
                    $colorBlue 0,
                    #A620B0 50%,
                    $colorPurple 100%
                );
                opacity: .5;


                .ie &, .edge & {
                    display: none;
                }
            }
        }

        .productSliderContainer {
            // display: flex;
            // align-items: center;
            position: relative;

            .previousContainer, .nextContainer {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                display: inline-block;

                a {
                    display: inline-block;
                    background: none;
                    border: none;
                    width: 16px + 10px;
                    height: 28px + 10px;
                    padding: 5px;
                    cursor: pointer;

                    @include media-breakpoint-up(md) {
                        width: 20px + 20px;
                        height: 36px + 20px;
                        padding: 10px;
                    }

                    svg.sprite {
                        display: inline-block;
                        width: 16px;
                        max-height: 28px;
                        @include transition(transform);

                        @include media-breakpoint-up(md) {
                            width: 20px;
                            max-height: 36px;
                        }

                        use {
                            stroke: $colorWhite;
                        }
                    }
                }
            }

            .previousContainer {
                left: 0;

                @include media-breakpoint-up(md) {
                    left: -10px;
                }

                a {
                    &:hover {
                        svg.sprite {
                            transform: translateX(-5px);
                        }
                    }
                }
            }

            .nextContainer {
                right: 0;

                @include media-breakpoint-up(md) {
                    right: -10px;
                }

                a {
                    &:hover {
                        svg.sprite {
                            transform: translateX(5px) scaleX(-1);
                        }
                    }
                }
            }

            .textColumn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                h2 {
                    margin-bottom: 38px;
                    font-size: 48px;
                    line-height: 40px;
                    font-weight: 700;
                }

                .priceContainer {
                    margin-bottom: 19px;

                    .original {
                        display: inline-block;
                        color: $colorRed;
                        font-size: 24px;
                        line-height: 32px;
                        margin-right: 16px;
                        text-decoration: line-through;
                    }

                    .current {
                        font-size: 40px;
                        line-height: 32px;
                        font-weight: 600;
                        color: $colorBlack;

                    }
                }

                a.button {
                    padding-right: 150px;
                    margin-bottom: 36px;
                }

                .sub {
                    display: inline-block;

                    @include media-breakpoint-up(md) {
                        margin-bottom: 60px;
                    }
                }
            }

            .dotsContainer {
                text-align: center;

                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                }

                ul {
                    display: inline-flex;

                    li {
                        margin: 0 8px;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        background: $colorGreyLight;
                        @include transition(background);
                        cursor: pointer;

                        button {
                            display: none;
                        }

                        &.slick-active {
                            background: $colorGreyLighter;
                        }
                    }
                }
            }
        }
    }

    section#hero {

        + .container {
            margin-bottom: 112px;
        }
    }

    p.intro {
        font-size: 24px;
        line-height: 32px;
        margin: 0;
    }

    section#content section.categories {
        margin-bottom: 64px;

        h2 {
            text-align: center;
            color: $colorRed;
            font-size: 36px;
            line-height: 32px;
            margin-top: 0;
            margin-bottom: 32px;
            
            @include media-breakpoint-up(md) {
                font-size: 48px;
                line-height: 48px;
                margin-bottom: 64px;
            }
        }

        ul {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;

            margin-top: -24px;
            margin-left: -10px; // Half of gutter
            margin-right: -10px;

            @include media-breakpoint-up(xl) {
                margin-left: -20px;
                margin-right: -20px;
            }

            li {
                flex: 0 0 auto;
                flex-basis: 50%;
                max-width: 50%;
                padding-left: 10px;
                padding-right: 10px;
                margin-top: 24px;

                @include media-breakpoint-up(sm) {
                    flex-basis: 33.33%;
                }
                
                @include media-breakpoint-up(md) {
                    flex-basis: 25%;
                }
                
                @include media-breakpoint-up(lg) {
                    flex-basis: 20%;
                }

                @include media-breakpoint-up(xl) {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                a {
                    // position: relative;
                    display: block;
                    color: $colorGreyLighter;
                    text-decoration: none;
                    @include transition(color);
                    
                    @include active {
                        color: $colorBlack;
                    }
                }

                .image {

                    position: relative;
                    height: 0;
                    padding-bottom: 100%;
                    margin-bottom: 24px;
                    border-radius: 50%;
                    overflow: hidden;
                    width: 100%;
                    
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                span {
                    display: block;
                    text-align: center;
                }
            }
        }
    }

    section#content {
        .new {
            padding-top: 65px;
            padding-bottom: 75px;
            position: relative;
    
            margin-left: -15px;
            margin-right: -15px;
            margin-bottom: 128px;
    
            @include media-breakpoint-up(md) {
                margin-left: -75px;
                margin-right: -75px;
                padding-top: 115px;
                padding-bottom: 95px;
            }
    
            @include media-breakpoint-up(lg) {
                margin-left: -50px;
                margin-right: -50px;
                padding-bottom: 156px;
            }
    
            @include media-breakpoint-up(lg) {
                padding-bottom: 128px;
                padding-bottom: 175px;
            }
    
            .background {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
    
                background: #F8F8F4;
    
                &:before, &:after {
                    position: absolute;
                    content: '';
                    width: 100%;
                    left: 0;
                    background-size: 100% 100%;
                    height: 14px;
    
                    @include media-breakpoint-up(md) {
                        height: 29px;
                    }
    
                    @include media-breakpoint-up(lg) {
                        height: 39px;
                    }
    
                    @include media-breakpoint-up(xl) {
                        height: 55px;
                    }
                }
    
                &:before {
                    background-image: url('/img/stories_curve_2.svg');
                    top: 0;
                    
                }
    
                &:after {
                    background-image: url('/img/stories_curve_1.svg');
                    bottom: 0;
                }
            }

            h2 {
                color: $colorRed;
                font-size: 36px;
                line-height: 32px;
            }

            article {
                .image {
                    width: 174px;
                    max-height: 174px;

                    margin-bottom: 16px;

                    margin-left: auto;
                    margin-right: auto;

                    @include media-breakpoint-up(md) {
                        margin-left: 0;
                        margin-right: 0;
                    }

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                .title {
                    height: 40px;
                    font-weight: 400;
                    // margin-bottom: 5px;
                }

                .price + .subtitle {
                    font-size: 16px;
                }
            }
        }
    }

    .USPs {
        margin-bottom: 96px;

        h2 {
            color: $colorRed;
        }
    }

    .landingFooter {
        margin-bottom: 96px;
        img {
            padding: 0 50px;
            border-radius: 50%;
        }

        p {
            text-align: center;
        }
    }

    section.howItWorks {
        position: relative;

        @include media-breakpoint-up(md) {
            margin-bottom: 96px;
        }

        h2 {
            color: $colorRed;
            font-size: 32px;
            line-height: 32px;
            font-weight: 500;
        }

        .container .row {
            .col, [class*="col-"] {
                margin-bottom: 20px;

                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                }

                h3 {
                    font-size: 24px;
                    line-height: 32px;
                }

                img {
                    display: block;
                    margin: 0 auto 10px;

                    @include media-breakpoint-up(md) {
                        margin-bottom: 20px;
                    }
                }

                p {
                    font-size: 18px;
                    line-height: 32px;
                    margin: 0;
                    text-align: center;
                }
            }
        }
    }

    section#content {
        section.howItWorks {
            h2 {
                margin-bottom: 10px;
            }
        }

        section.howItWorks {
            .container .row {
                .col, [class*="col-"] {
                    h3 {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}