body.shopcheckoutaddresspage {
    section#content {
        h1 {
            color: $colorBlack;
            margin-top: 0;
            margin-bottom: 43px;
            font-size: 32px;
            line-height: 32px;
        }
    }

    .progressRow {
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
            margin-bottom: 62px;
        }

        .progressStep {
            position: relative;
            display: block;
            margin-bottom: 10px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }

            span {
                display: inline-block;
                font-size: 18px;
                line-height: 21px;
                margin-bottom: 16px;
                color: $colorGrey;
            }

            &:before, &:after {
                position: absolute;
                height: 1px;
                width: 100%;
                bottom: 0;
                content: '';
                display: block;
                transform-origin: left;
            }

            &:before {
                background: $colorGreyLight;
            }

            &:after {
                background: $colorYellow;
                transform: scaleX(0);
            }

            &.active {
                span {
                    color: $colorBlack;
                    font-weight: 700;
                }

                &:after {
                    @include animation(activeProgressStep);
                }
            }

            &.finished {
                span {
                    color: $colorBlack;
                }

                &:after {
                    transform: none;
                }
            }
        }
    }

    form {
        margin: 0;
        
        legend {
            font-size: 24px;
            line-height: 32px;
            font-weight: bold;
            margin-bottom: 38px;
        }

        .input {
            align-items: center;

            label {
                color: $colorGrey;
                cursor: pointer;
            }

            input {
                padding: 5px 20px;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid $colorGreyLight;
                padding-left: 10px;
                font-size: 18px;
                background: transparent;
                color: $colorBlack;
                line-height: 30px;

                &:focus {
                    border-bottom-color: $colorGrey;
                }
            }
        }

        .field-validation-error {
            color: $colorRed;
            line-height: 21px;
            display: inline-block;
            margin-top: 5px;
        }

        .simpleRow {
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .emailRow {
            margin-bottom: 67px;
        }
    }

    .billingColumn, .shippingColumn {
        margin-bottom: 40px;

        @include media-breakpoint-up(md) {
            margin-bottom: 67px;
        }
    }

    .shippingColumn {
        .simpleRow.check {
            position: absolute;
            top: 38px;
        }

        .simpleRow:not(.check) {
            display: flex;
            @include animation(appear);
        }

        .simpleRow.check.checked {
            ~ .simpleRow {
                display: none;
            }
        }
    }


    section#content {
        .accountRow {
            span.subtitle {
                display: inline-block;
                font-style: normal;
                font-size: 18px;
                line-height: 21px;
                margin-bottom: 32px;
            }

            
        }
    }

    .accountRow {
        margin-bottom: 40px;

        @include media-breakpoint-up(md) {
            margin-bottom: 100px;
        }

        legend {
            margin-bottom: 14px;
        }

        .simpleRow:not(.check) {
            display: none;
            @include animation(appear);
        }

        .simpleRow.check.checked {
            ~ .simpleRow {
                display: flex;
            }
        }
    }

    .stepsRow {
        padding-top: 32px;
        border-top: 1px solid $colorGreyLight;
        align-items: center;
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
            margin-bottom: 100px;
        }
    }

    a.back {
        text-decoration: none;
        color: $colorGreyLighter;
        display: inline-flex;
        align-items: center;
        min-height: 1px;
        padding: 5px 0;

        @include underline_closed($colorGreyLighter, 1px);

        svg.sprite {
            margin-right: 10px;
            @include transition(transform);
        }

        @include active {
            svg.sprite {
                transform: translateX(-5px);
            }
        }
    }

    .stepForwardColumn {
        .button {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-weight: 600;

            margin-bottom: 32px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
    }
}