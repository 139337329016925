
@import './settings/variables';

/**
* Bootstrap
**/
$grid-columns: 12;
$grid-gutter-width: 20px;

$grid-gutter-widths: (
  xl: 40px
);

$grid-breakpoints: (
  xs: 0,
  sm: 375px,
  md: 768px,
  lg: 1024px,
  xl: 1300px
);

$container-max-widths: (
    sm: 768px,
    md: 1138px,
    lg: 1263px,
    xl: 1300px
);

@import 'bootstrap/scss/bootstrap-grid';

@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
  @include media-breakpoint-up($grid-breakpoint) {
    $grid-gutter-half-width: $grid-gutter-width / 2;
    .row:not(.no-gutters) {
      margin-right: -$grid-gutter-half-width;
      margin-left: -$grid-gutter-half-width;
    }
    .row:not(.no-gutters) > .col,
    .row:not(.no-gutters) > [class*="col-"] {
      padding-right: $grid-gutter-half-width;
      padding-left: $grid-gutter-half-width;
    }
  }
}

@import 'simplebar/dist/simplebar';
.simplebar-scrollbar {
  width: 2px;
}
.simplebar-scrollbar:before {
  background: $colorBlue;
  background: transparentize($colorGrey, .5);
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

@import 'tippy.js/dist/tippy';

.tippy-tooltip {
  background: $colorWhite;
  box-shadow: 0 0 15px rgba(0,0,0,.4);
  color: $colorBlack;
  border-radius: 8px;
}

.tippy-tooltip[data-placement^=top] > .tippy-arrow {
  border-top-color: $colorWhite;
}

@import 'slick-carousel/slick/slick.scss';
@import 'select2/src/scss/core';
@import 'selectric/public/selectric';

// @import 'lity/dist/lity';