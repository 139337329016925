body.courselandingpage {
    section#hero {
        padding-top: 100px;
        padding-bottom: 100px;
        margin-bottom: 70px;

        @include media-breakpoint-up(md) {
            padding-top: 200px;
            padding-bottom: 200px;
            margin-bottom: 70px;
        }

        .background {
            &:before {
                height: 50px;

                @include media-breakpoint-up(md) {
                    height: 110px;
                }
            }

            &:after {
                height: 50px;

                @include media-breakpoint-up(md) {
                    height: 100px;
                }
            }

            .layer.back {
                background: linear-gradient(to bottom, $colorBlue 0, $colorPurple2 50%, $colorPurple 100%);
                background: linear-gradient(to bottom, $colorWhite 0, $colorBlue 1%, $colorBlue 50%, #00E5FF 99%, $colorWhite 100%);
            }
        }

        h1 {
            color: $colorWhite;
            margin-top: 0;
            margin-bottom: 20px;
            font-size: 56px;
            line-height: 48px;
            font-weight: 600;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }

        p {
            width: 100%;
            color: $colorWhite;
            margin-bottom: 28px;
            margin-top: 0;
            font-size: 24px;
            line-height: 32px;

            @include media-breakpoint-up(md) {
                margin-bottom: 20px;
            }
        }

        svg {
            position: absolute;
        }

        img {
            clip-path: url(#home-mask);

            .ie &,
            .edge & {
                border-radius: 31% 69% 56% 44% / 57% 58% 42% 43%;
                overflow: hidden;
            }
        }
    }

    .simpleHero {
        margin-bottom: 64px + 64px + 15px;
        font-size: 24px;
        line-height: 40px;

        p {
            margin-top: 0;
        }

        a.button {
            font-size: 22px;
            line-height: 32px;
            padding-right: 60px;
        }
    }

    section.USPs {
        margin-bottom: 50px;

        @include media-breakpoint-up(md) {
            margin-bottom: 100px;
        }

        h2 {
            color: $colorRed;
        }
    }

    section.trainers {
        margin-bottom: 90px;

        h2 {
            font-size: 48px;
            line-height: 48px;
            font-weight: 700;
            color: $colorRed;
            text-align: center;
        }

        ~ .container {
            margin-bottom: 50px;

            @include media-breakpoint-up(md) {
                margin-bottom: 100px;
            }
        }

        div.trainer {
            div.image {
                img {
                    border-radius: 50%;
                    border: 1px solid $colorGreyLight;
                    display: block;
                    width: 100%;
                }
            }

            span.name {
                font-size: 24px;
                line-height: 24px;
                color: $colorGreyLighter;
                text-align: center;
                display: block;
                margin-top: 24px;
            }
        }
    }

    section#content {

        h2 {
            margin-top: 0;
        }

        .umb-grid {
            .row {
                justify-content: center;
            }
        }

        section.highlighted {
            margin-bottom: 60px;

            h2 {
                font-size: 48px;
                line-height: 48px;
                font-weight: 700;
                color: $colorRed;
            }

            article {
                span.title {
                    margin-top: 5px;
                    font-size: 24px;
                    line-height: 24px;
                    font-weight: 400;
                }

                &.hasImage > a {
                    margin-bottom: 10px;
                }
            }
        }

        article {
            .image {
                justify-content: start;

                img {
                    width: 132px;
                    height: 132px;
                    border-radius: 50%;
                    border: 1px solid $colorGreyLight;

                    @include media-breakpoint-up(md) {
                        width: 214px;
                        height: 214px;
                    }

                    @include media-breakpoint-up(xl) {
                        width: 170px;
                        height: 170px;
                    }
                }
            }
        }
    }
}
