.filterContainer {
    position: fixed;
    // border: 1px solid $colorGrey;
    padding: 0 20px 30px;
    background: $colorWhite;
    border-radius: 10px;
    z-index: 120;
    top: 60px;
    left: 30px;
    max-height: calc(var(--fullHeight) - 90px);
    overflow-y: auto;
    width: calc(100vw - 60px);
    box-shadow: 0 0 0 200vh rgba(0, 0, 0, .25);
    @include transition(opacity transform);

    @include media-breakpoint-up(md) {
        position: relative;
        border: none;
        padding: 0;
        top: auto;
        left: auto;
        height: auto;
        max-height: none;
        width: auto;
        box-shadow: none;
        transition: none;
        z-index: 1;
        border-radius: 0;
    }

    &.mobileHidden {
        transform: scale(.5);
        opacity: 0;
        pointer-events: none;

        * {
            pointer-events: none !important;
        }

        @include media-breakpoint-up(md) {
            transform: none;
            opacity: 1;
            pointer-events: all;

            * {
                pointer-events: all !important;
            }
        }
    }

    .filterContainerHeader {
        position: sticky;
        top: 0;
        padding: 20px 0;
        background: $colorWhite;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-up(md) {
            display: none;
        }

        .filterContainerTitle {
            font-size: 26px;
            line-height: 32px;
            font-weight: 400;
            color: $colorPurple;
        }

        .filterContainerClose {
            cursor: pointer;
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg.icon {
                @include transition(transform);
            }

            &:hover {
                svg.icon {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .filterContainerContent {
        height: 100%;
        max-height: calc(var(--fullHeight) - 165px); // Magic number! (it's a combination of padding from all the other stuff)
        overflow-y: auto;

        @include media-breakpoint-up(md) {
            height: initial;
            overflow-y: initial;
            max-height: none;
        }

        a.button.c2a {
            margin-top: 5px;
            margin-bottom: 30px;
            width: 100%;
            color: $colorWhite;
        }

        .clearFilters {
            text-align: center;
        }
    }

    .hiddenFilters {
        display: none;
    }

    a.showAll {
        position: relative;
        display: inline-block;
        margin-left: 35px;
        text-decoration: none;
        color: $colorPurple;

        &:after {
            content: '';
            display: block;
            background: $colorPurple;
            height: 1px;
            width: 100%;
            bottom: 0;
            left: 0;

            transform: scaleX(0);
            @include transition(transform);
        }

        @include active {
            &:after {
                transform: none;
            }
        }
    }
}

.filterInput {
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        margin-bottom: 60px;
    }

    .info {
        margin-bottom: 10px;
        font-weight: 600;

        @include media-breakpoint-up(md) {
            margin-bottom: 20px;
        }

        .name {}

        .expandIcon {
            display: inline-block;
            position: absolute;
            transform: translateX(10px) scale(.8) rotate(-90deg);
            @include transition(transform);

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    .selection {
        display: none;
    }

    .filterName {
        font-weight: 600;
        color: $colorPurple;
        background: $colorWhite;
        position: sticky;
        top: 0;
        cursor: pointer;
        z-index: 2;
        display: inline-flex;
        width: 100%;
        margin-bottom: 5px;

        @include media-breakpoint-up(md) {
            position: static;
            cursor: default;
            display: inline-block;
            margin-bottom: 10px;
            width: auto;
        }

        svg.sprite {
            position: relative;
            top: 5px;
            flex-shrink: 0;

            @include transition(transform);

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        .count {
            display: none;
            font-weight: 400;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    &.expanded {
        .info {
            .name {}

            .expandIcon {
                transform: translateX(10px) scale(.8) rotate(-90deg) scaleX(-1);
            }
        }

        .filterName {
            svg.icon {
                transform: scaleY(-1);
            }
        }
    }

    &.filtered {
        .filterName {
            font-weight: 700;

            @include media-breakpoint-up(md) {
                font-weight: 600;
            }

            .count {
                display: inline;

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }
    }

    ul {
        position: absolute;
        opacity: 0;
        transform-origin: top left;
        transform: translateY(-5px) scale(.95);
        z-index: -10;
        pointer-events: none;
        height: 0;
        overflow-y: hidden;

        @include media-breakpoint-up(md) {
            position: static;
            opacity: 1;
            transform: none;
            z-index: 1;
            pointer-events: all;
            overflow-y: auto;
            height: auto;

            section.content & {
                margin-bottom: 15px;
            }
        }

        li {
            margin-bottom: 16px;

            &.hidden {
                @include media-breakpoint-up(md) {
                    * {
                        pointer-events: none !important;
                    }
                }
            }

            section.content & {
                margin-left: 30px;
                margin-bottom: 13px;

                &:last-child {
                    margin-bottom: 0;
                }

                @include media-breakpoint-up(md) {
                    margin-left: 0;
                }
            }

            &:not(.hidden) {
                @include media-breakpoint-up(md) {
                    position: static;
                    z-index: 1;
                    pointer-events: all;
                    opacity: 1;
                    transform-origin: top left;
                    transform: none;

                    @include transition(transform opacity);
                }
            }

            &.hidden {
                @include media-breakpoint-up(md) {
                    position: absolute;
                    z-index: -10;
                    pointer-events: none !important;
                    opacity: 0;
                    transform: translateY(-5px) scale(.95);
                }
            }

            &:before {
                display: none;
            }

            a {
                line-height: 24px;
                color: $colorBlack;
                text-decoration: none;

                @include active(){
                    text-decoration: underline;
                }
            }

            input {
                display: none;

                +label {
                    position: relative;
                    padding-left: 35px;
                    cursor: pointer;
                    display: block;
                    line-height: 24px;
                    color: $colorGreyLighter;

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 3px;
                        width: 18px;
                        height: 18px;
                        border-radius: 5px;
                    }

                    &:before {
                        opacity: 0;
                        background-image: url('/img/icon_check_green.svg');
                        background-position: center 30%;
                        background-repeat: no-repeat;
                        transform: scale(.4);
                        @include transition(opacity transform);
                    }

                    &:after {
                        border: 1px solid $colorGreyLighter;
                        @include transition(border-color);
                    }

                    @include active {
                        &:after {
                            border-color: $colorBlack;
                        }
                    }
                }

                &:checked {

                    +label {
                        &:before {
                            opacity: 1;
                            transform: none;
                        }

                        &:after {
                            border-color: $colorGreen;
                        }
                    }
                }
            }
        }
    }

    &.expanded {
        ul {
            opacity: 1;
            position: relative;
            transform: none;
            z-index: 1;
            overflow-y: auto;
            height: auto;
            pointer-events: all;

            @include transition(transform);
        }
    }

    a.showAll {
        display: none;
        color: $colorBlue;

        svg.icon {
            @include transition(transform);
        }

        &:hover {
            color: $colorPurple;

            svg.icon {
                transform: rotate(90deg);

                use {
                    fill: $colorPurple;
                }
            }
        }

        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }
}