.blob {
    border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%;
    overflow: hidden;
    // animation: 
    //     blob-landing-transform 20s ease-in-out infinite both alternate,
    //     // blob-landing-moving-1 40s ease-in-out infinite both;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &[class*="1"] {
        border-radius: 31% 69% 56% 44% / 57% 58% 42% 43%;
    }
}