body.stribersoverviewpage { 
    section#content {
        .highlightedStriber {
            padding-bottom: 85px;
            margin-bottom: 75px;
            border-bottom: 2px solid $colorGreyLight;

            .imageContainer {
                svg.svg {
                    position: absolute;
                    height: 0;
                    width: 0;
                }
        
                img {
                    clip-path: url(#striber-mask);
        
                    .ie &, .edge & {
                        border-radius: 31% 69% 56% 44% / 57% 58% 42% 43%;
                        overflow: hidden;
                    }
                }
            }

            .infoContainer {
                h2 {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    color: $colorRed;
                }

                .journalInfo {
                    display: flex;
                    align-items: center;

                    .likes {
                        margin-right: 20px;

                        .icon {
                            display: inline-flex;
                            align-items: center;
                            margin-right: 0;

                            svg.sprite {
                                width: 19px;
                                max-height: 16px;
                                margin-right: 3px;
                            }

                            span {
                                position: relative;
                                color: $colorBlack;
                                font-size: 16px;
                                top: 0;
                            }
                        }
                    }

                    .image {
                        width: 56px;
                        height: 56px;
                        
                        img {
                            width: 56px;
                            height: 56px;
                        }
                    }
                }

                p {
                    font-size: 18px;
                    line-height: 32px;
                    margin-bottom: 9px;

                    + a {
                        color: $colorGreyLighter;
                        font-size: 16px;
                        line-height: 16px;
                        
                        text-decoration: none;

                        @include transition(color);
                        @include underline_closed($colorPurple);

                        @include active {
                            color: $colorPurple;
                        }
                    }
                }
            }
        }

        h1 {
            margin-top: 0;
            color: $colorBlack;
        }
    }

    .overviewHeaderContainer {
        margin-bottom: 50px;

        h1 {
            padding-bottom: 20px;

            @include media-breakpoint-up(md) {
                padding-bottom: 0;
            }
        }
    }

    .openFiltersRow {
        margin-bottom: 30px;

        a.button.openFilters {
            width: 100%;
            text-align: center;
            display: inline-block;
        }
    }

    .sortOptions {
        display: flex;
        align-items: center;
        text-align: right;
        justify-content: flex-end;
        flex-wrap: wrap;    
        
        margin-bottom: 20px;

        * {
            white-space: nowrap;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }

        > span {
            margin-right: 8px;
        }

        .sortOption {
            margin-right: 12px;


            &:last-child {
                margin-right: 0;
            }

            input {
                display: none;
            }

            label {
                color: $colorGreyLighter;
                border-bottom: 1px solid transparent;
                padding-bottom: 5px;
                cursor: pointer;
                line-height: 30px;

                @include transition(border-color);
            }

            input:checked + label {
                border-bottom-color: $colorGreyLighter;
                cursor: default;
            }
        }
    }

    .stribersContainer {
        ul.targetsContainer {
            display: flex;
            flex-wrap: wrap;
            margin-left: -15px;
            margin-right: -15px;
            margin-top: -40px;
            margin-bottom: 70px;

            > li {
                flex: 0 0 auto;
                flex-basis: 100%;
                max-width: 100%;
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 40px;
                margin-left: 0;

                @include animation(appear);

                @include media-breakpoint-up(sm) {
                    flex-basis: 50%;
                    max-width: 50%;
                }
        
                @include media-breakpoint-up(md) {
                    flex-basis: 33.33%;
                    max-width: 33.33%;
                }
        
                @include media-breakpoint-up(lg) {
                    // flex-basis: 25%;
                    // max-width: 25%;
                }
            }
        }
    }

    .pagerContainer {
        margin-bottom: 70px;
    }
}