.shareContainer {
    display: inline-block;
    display: inline-flex;
    align-items: center;

    span {
        margin-right: 10px;
        font-size: .9em;

        display: none;

        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }

    a {
        margin-right: 16px;
        
        &:last-child {
            margin-right: 0;
        }

        svg.sprite {
            use {
                @include transition(fill stroke);
            }
        }

        @include active {
            svg.sprite {
                use {
                    fill: $colorBlue;
                    stroke: $colorBlue;
                }
            }
        }
    }
}