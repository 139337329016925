.popup {
    position: fixed;
    top: 0;
    height: 100vh;
    height: var(--fullHeight);

    left: 0;
    width: 100%;

    background: $colorWhite;
    z-index: 100;

    padding: 32px 35px 50px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    overflow: hidden;
    transform: scale(0);
    opacity: 0;
    $function: cubic-bezier(0.61, 0.1, 0.38, 0.97);
    transition:
        transform 600ms $animationFunction,
        opacity 0ms linear 600ms,
        clip-path 1s $animationFunction;

    clip-path: circle(0 at 50% 50%);

    .ie & {
        transition:
            transform 600ms $animationFunction,
            opacity 600ms $animationFunction;
    }

    @include media-breakpoint-up(md) {
        top: 30px;
        height: 100vh;
        height: calc(100vh - 60px);
        height: var(--fullHeight);
        height: calc(var(--fullHeight) - 60px);

        left: 30px;
        width: calc(100% - 60px);

        padding: 45px 70px 70px;
    }

    &.active {
        transform: none;
        opacity: 1;
        pointer-events: all;
        transition:
            transform 600ms $function,
            opacity 0ms linear 0ms,
            clip-path 1s $function;

        .ie & {
            transition:
                transform 600ms $animationFunction,
                opacity 600ms $animationFunction;
        }

        clip-path: circle(110% at 50% 50%);
    }

    .header {
        min-height: 45px;
        margin-bottom: 70px;
        width: 100%;

        @include media-breakpoint-up(md) {
            min-height: 54px;
        }

        .container {
            height: 100%;

            .row {
                height: 100%;

                >* {
                    height: 100%;
                    display: inline-flex;
                    align-content: center;
                }
            }
        }

        nav {
            height: 100%;

            ul {
                display: inline-flex;
                flex-wrap: wrap;
                height: 100%;

                li {
                    display: inline-block;
                    height: 45px;

                    @include media-breakpoint-up(md) {
                        height: 54px;
                    }

                    &:not(:last-child) {
                        margin-right: 8px;

                        @include media-breakpoint-up(md) {
                            margin-right: 24px;
                        }
                    }

                    button {
                        height: 100%;

                        background: transparent;
                        color: $colorPurple;
                        border-radius: 54px;
                        padding: 0 25px 0 24px;
                        border: none;
                        font-size: 18px;
                        line-height: 32px;
                        font-weight: 600;
                        font-family: $fontTitle;
                        height: 45px;

                        @include transition(color background);

                        @include media-breakpoint-up(md) {
                            font-size: 24px;
                            line-height: 32px;
                            padding: 0 70px 0 24px;
                            height: 54px;
                        }
                    }

                    &:nth-child(1) {
                        button {
                            color: $colorBlue;

                            &[aria-selected="true"] {
                                background: $colorBlue;
                                color: $colorWhite;
                            }
                        }
                    }

                    &:nth-child(2) {
                        button {
                            color: $colorPurple;

                            &[aria-selected="true"] {
                                background: $colorPurple;
                                color: $colorWhite;
                            }
                        }
                    }

                    &:nth-child(3) {
                        button {
                            color: $colorRed;

                            &[aria-selected="true"] {
                                background: $colorRed;
                                color: $colorWhite;
                            }
                        }
                    }

                    &:nth-child(4) {
                        button {
                            color: $colorGreen;

                            &[aria-selected="true"] {
                                background: $colorGreen;
                                color: $colorWhite;
                            }
                        }
                    }

                    &:nth-child(5) {
                        button {
                            color: $colorYellow;

                            &[aria-selected="true"] {
                                background: $colorYellow;
                                color: $colorWhite;
                            }
                        }
                    }
                }
            }
        }

        a.close {
            cursor: pointer;
            margin-left: auto;
            @include transition(background);
            border-radius: 50%;
            position: absolute;
            top: 0;
            height: 56px;
            width: 56px;
            right: -5px;

            @include media-breakpoint-up(md) {
                top: auto;
                right: 0;
            }

            @include active {
                background: $colorGreyLight;
            }
        }
    }

    .content {
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        flex: 1;

        p {
            font-weight: 300;
        }

        h2 {
            color: $colorBlue;
            font-size: 48px;
            line-height: 50px;
            font-weight: 700;
            margin-bottom: 100px;

            @include media-breakpoint-up(md) {
                margin-bottom: 70px;
            }
        }

        a {
            color: $colorBlue;
        }

        .cssColumns {
            @include media-breakpoint-up(md) {
                columns: 2;
                column-gap: 20px;
            }

            @include media-breakpoint-up(lg) {
                columns: 2;
            }

            p {
                margin-top: 0;
            }
        }

        .imageColumn {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
			align-content: flex-start;
            margin-top: 70px;

            img {
                flex: 1 1 50%;
                width: 50%;
                max-width: 50%;
                padding: 10px;

                @include media-breakpoint-up(md) {}
            }

            &.single {
                img {
                    flex: 1 1 100%;
                    width: 100%;
                    max-width: 100%;
                    border-radius: 50%;
                    padding: 0;
                }
            }
        }

        .tabContent {
            &:nth-child(1) {
                h2 {
                    color: $colorBlue;
                }
            }

            &:nth-child(2) {
                h2 {
                    color: $colorPurple;
                }
            }

            &:nth-child(3) {
                h2 {
                    color: $colorRed;
                }
            }

            &:nth-child(4) {
                h2 {
                    color: $colorGreen;
                }
            }

            &:nth-child(5) {
                h2 {
                    color: $colorYellow;
                }
            }
        }
    }
}