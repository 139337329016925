body.jointhestribelandingpage,
body.investlandingpage {
    section#hero {
        padding-top: 100px;
        padding-bottom: 100px;
        margin-bottom: 70px;

        @include media-breakpoint-up(md) {
            padding-top: 200px;
            padding-bottom: 200px;
            margin-bottom: 70px;
        }

        .background {
            &:before {
                height: 50px;
                transform:scaleX(-1);
                
                @include media-breakpoint-up(md) {
                    height: 110px;
                }
            }

            &:after {
                height: 50px;

                @include media-breakpoint-up(md) {
                    height: 100px;
                }
            }

            .layer.back {
                background: linear-gradient(to bottom,
                        $colorRed 0,
                        $colorRed 50%,
                        $colorYellow 100%);
            }
        }

        h1 {
            color: $colorWhite;
            margin-top: 0;
            margin-bottom: 20px;

            font-size: 56px;
            line-height: 48px;
            font-weight: 600;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }

        p {
            width: 100%;
            color: $colorWhite;
            margin-bottom: 28px;
            margin-top: 0;

            font-size: 24px;
            line-height: 32px;

            @include media-breakpoint-up(md) {
                margin-bottom: 20px;
            }
        }

        .c2as {
            margin-bottom: 28px;
            color: $colorWhite;
            font-size: 32px;
            line-height: 37px;
            font-weight: 900;
            display: block;

            a {
                color: $colorWhite;
                text-decoration: none;
                position: relative;
                display: inline;
                color: $colorYellow;

                @include media-breakpoint-up(md) {
                    color: inherit;
                }

                @include media-breakpoint-up(md) {
                    display: inline-block;
                }

                &:after {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 2px;
                    width: 100%;
                    background: $colorWhite;
                    @include transition(background);

                    @include media-breakpoint-up(md) {
                        content: '';
                    }
                }

                @include active {
                    &:after {
                        background: $colorYellow;
                    }
                }
            }

            a.button {
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: auto;
                }

                &:first-child:not(:last-child) {
                    margin-bottom: 10px;

                    @include media-breakpoint-up(lg) {
                        margin-bottom: 0;
                        margin-right: 20px;
                    }
                }

                &:last-child:not(:first-child) {
                    margin-top: 10px;

                    @include media-breakpoint-up(lg) {
                        margin-top: 0;
                        margin-left: 20px;
                    }
                }
            }
        }

        .c2as+span {
            font-size: 16px;
            line-height: 32px;
            color: $colorWhite;

            a {
                position: relative;
                color: $colorWhite;
                text-decoration: none;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 1px;
                    width: 100%;
                    background: $colorWhite;
                    @include transition(background);
                }

                @include active {
                    &:after {
                        background: $colorYellow;
                    }
                }
            }
        }

        a.button {
            padding-right: 60px;
            font-weight: 600;

            &.clear {
                color: $colorWhite;

                @include transition(color border-color background);

                @include active {
                    background: $colorBlue;
                    border-color: $colorBlue;
                }
            }
        }

        svg {
            position: absolute;
        }

        img {
            clip-path: url(#home-mask);

            .ie &,
            .edge & {
                border-radius: 31% 69% 56% 44% / 57% 58% 42% 43%;
                overflow: hidden;
            }
        }
    }

    section.alternating {
        margin-bottom: 60px;

        @include media-breakpoint-up(md) {
            margin-bottom: 100px;
        }
    }

    section.quote {
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
            margin-bottom: 65px;
        }
    }

    section.USPs {
        margin-bottom: 50px;

        @include media-breakpoint-up(md) {
            margin-bottom: 100px;
        }

        h2 {
            color: $colorRed;
        }
    }

    section.partners {
        margin-bottom: 90px;

        h2 {
            font-size: 22px;
            line-height: 32px;
            font-weight: 500;
            color: $colorGreyLighter;
        }

        ~.container {
            margin-bottom: 50px;

            @include media-breakpoint-up(md) {
                margin-bottom: 100px;
            }
        }
    }

    section#content {

        h2 {
            margin-top: 0;
        }

        .umb-grid {
            .row {
                justify-content: center;
            }
        }
    }
}