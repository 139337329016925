body.homepage {
    section#hero {
        padding-top: 80px;
        // padding-bottom: 0;
        margin-bottom: 30px;
        
        @include media-breakpoint-up(md) {
            margin-bottom: 70px;
            padding-top: 80px;
            padding-bottom: 120px;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .background {
            &:before {
                height: 30px;
                
                @include media-breakpoint-up(md) {
                    height: 100px;
                }
            }

            &:after {
                height: 30px;
                
                @include media-breakpoint-up(md) {
                    height: 100px;
                }
            }
        }

        .background .layer.back {
            background: linear-gradient(to bottom,
                    #{$colorBlue} 0,
                    #A620B0 50%,
                    #{$colorPurple} 100%);
        }

        .background .layer.front {
            opacity: 0;
        }

        h2 {
            color: $colorWhite;
            margin-top: 0;
            margin-bottom: 17px;

            font-size: 48px;
            line-height: 48px;
            font-weight: 600;
            
            @include media-breakpoint-up(md) {
                font-size: 62px;
                line-height: 62px;
            }

            em {
                font-style: normal;
                color: $colorYellow;
            }
        }

        p {
            width: 100%;
            color: $colorWhite;
            margin-bottom: 32px;
            margin-top: 0;

            font-size: 24px;
            line-height: 32px;

            @include media-breakpoint-up(md) {
                margin-bottom: 20px;
            }
        }

        a.button {
            padding-right: 60px;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
        }

        svg {
            position: absolute;
        }

        img {
            // clip-path: url(#home-mask);

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }

            .ie &, .edge & {
                border-radius: 31% 69% 56% 44% / 57% 58% 42% 43%;
                overflow: hidden;
            }
        }

        .image {
            position: relative;
            margin-bottom: 10px;
            clip-path: url(#home-mask);

            img {
                position: relative;
                // height: 100%;
                width: 100%;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                display: block;
                z-index: 100;
                mix-blend-mode: soft-light;

                background: linear-gradient(to bottom,
                    $colorBlue 0,
                    #A620B0 50%,
                    $colorPurple 100%
                );
                opacity: .5;


                .ie &, .edge & {
                    display: none;
                }
            }
        }
    }

    section.aboutStribe {
        margin-bottom: 50px;
        
        @include media-breakpoint-up(md) {
            margin-bottom: 100px;
        }

        h2 {
            color: $colorBlack;
            font-size: 48px;
            line-height: 40px;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 30px;

            ~ div {
                text-align: center;

                @include media-breakpoint-up(md) {
                    text-align: left;
                }
            }
        }

        a.button {
            padding-right: 60px;
            font-weight: 600;

            @include media-breakpoint-up(md) {
                margin-right: 30px;
            }
        }

        a.join {
            display: inline-block;
            position: relative;
            color: $colorRed;
            text-decoration: none;
            font-weight: 800;
            text-decoration: none;
            @include underline($colorRed, 2px);
            margin-top: 20px;

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }

            @include transition(color);

            @include active {
                color: $colorPurple;

                &:after {
                    background: $colorPurple;
                    transform: scaleX(0);
                }
            }
        }
    }

    section.ebookPromo {
        margin-bottom: 60px;

        @include media-breakpoint-up(md) {
            margin-bottom: 150px;
        }
    }

    section.highlightedProducts {
        position: relative;
        width: calc(100% + 35px);
        margin-left: -20px;
        background: #F8F8F4;
        padding-top: 60px;
        padding-bottom: 40px;
        padding-left: 20px;
        padding-right: 20px;
        
        @include media-breakpoint-up(md) {
            padding-top: 125px;
            padding-bottom: 80px;
            width: calc(100% + 100px);
            margin-left: -50px;
        }

        h2 {
            color: $colorRed;
            padding-bottom: 20px;

            @include media-breakpoint-up(md) {
                padding-bottom: 0;
            }
        }

        article {
            > a {
                flex: 0 0 auto;
            }
        }

        ul.highlightedProductsList {
            margin-bottom: 70px;

            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            margin-left: -10px;
            margin-right: -10px;

            li {
                flex: 1 1 100%;
                max-width: 100%;
                padding-left: 10px;
                padding-right: 10px;
                @include animation(appear);

                @include media-breakpoint-up(md) {
                    flex-basis: 25%;
                    max-width: 25%;
                }

                &.paged, &.filtered {
                    display: none;
                }
            }
        }

        .pager {
            .controlBlock {
                position: relative !important;
            }
        }

        &:before {
            display: block;
            z-index: 1;
            position: absolute;
            content: '';
            width: 100%;
            left: 0;
            background-size: 100% 100%;
        }

        &:before {
            background-image: url('/img/highlighted_curve_1.svg');
            top: 0;
            height: 30px;
            transform: translateY(-100%) translateY(1px);

            @include media-breakpoint-up(md) {
                height: 120px;
            }
        }

        .filterInput {
            .info {
                display: none;
            }
            
            .selection {
                display: none;
            }

            ul {
                display: inline-flex;
                flex-wrap: wrap;

                position: relative;
                opacity: 1;
                transform: none;
                z-index: 1;
                pointer-events: all;
                height: auto;
                overflow-y: auto;

                li {
                    width: 50%;
                    padding: 0 10px;

                    @include media-breakpoint-up(md) {
                        margin-right: 40px;
                        width: auto;
                        padding: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                label {
                    padding: 10px;
                    margin: 0;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background: $colorWhite;
                    background: transparent;
                    width: 100%;
                    height: 50px;
                    border-radius: 30px;
                    overflow: hidden;
                    border: 1px solid $colorGreyLight;

                    @include media-breakpoint-up(md) {
                        width: 175px;
                        padding: 10px 30px;
                        justify-content: flex-start;
                    }

                    &:after {
                        display: none;
                    }

                    &:before, &:after {
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: $colorGreyLight;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        transform: scale(0);
                        z-index: -1;
                        border-radius: 200px;
                    }
                    
                    &:after {
                        background: $colorWhite;
                        opacity: 1;
                        transform: none;
                        z-index: -2;
                        border: none;
                    }
                }

                input:checked + label {
                    &:before {
                        transform: none;
                        opacity: 1;
                    }
                }
            }
        }
    }

    section.stories {
        padding-top: 32px;

        @include media-breakpoint-up(md) {
            padding-top: 75px;
        }

        h2 {
            font-size: 36px;
            line-height: 32px;
        }

        .storiesList {
            margin-bottom: 30px;

            @include media-breakpoint-up(md) {
                margin-bottom: 50px;
            }
        }
    }

    section.USPs {
        padding-top: 80px;
        
        @include media-breakpoint-up(md) {
            padding-top: 150px;
        }

        h2 {
            color: $colorBlue;
        }

        .USPFooter {
            span {
                color: $colorRed;
            }
        }
    }

    section.newsletter {
        padding-top: 105px;
        padding-bottom: 120px;

        @include media-breakpoint-up(md) {
            padding-top: 164px;
            padding-bottom: 164px;
        }

        h2 {
            color: $colorGreen;
        }
    }

    section.parallaxBackground {
        margin-bottom: -20px; // Negates bottom margin from section#content to place it against the footer
    }

    section#content {
        h1 {
            color: $colorBlack;
            font-size: 48px;
            line-height: 40px;
            margin-bottom: 20px;
        }

        h2 {
            margin-top: 0;
        }

        section.highlightedProducts {
            h2 {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        section.stories {
            h2 {
                margin-top: 0;
                margin-bottom: 75px;
            }
        }

        article.hasImage {
            flex-direction: row;

            .image {
                width: auto;
                margin-right: 20px;
            }

            @include media-breakpoint-up(md) {
                flex-direction: column;

                .image {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }
}