section#content article {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    /* border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 20px; */

    &.hasImage {
        // @include media-breakpoint-up(md) {
        //     flex-direction: row;
        // }
    }

    .image {
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 20px;
        max-width: 100%;
        text-align: center;
        position: relative;
        
        @include media-breakpoint-up(md) {
            margin-bottom: 5px;
            // margin-right: 20px;
            width: 100%;
        }

        img {
            width: 132px;
            height: 132px;
            border-radius: 50%;
            border: 1px solid $colorGreyLight;

            @include media-breakpoint-up(md) {
                width: 214px;
                height: 214px;
            }

            @include media-breakpoint-up(xl) {
                width: 281px;
                height: 281px;
            }
        }

        .discount {
            position: absolute;
            bottom: 0;
            right: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 67px;
            height: 67px;
            border-radius: 50%;
            font-size: 18px;
            color: $colorWhite;
            background: $colorRed;
            font-weight: 300;

            @include media-breakpoint-up(md) {
                right: 0;
            }
        }
    }

    .contents {
        display: flex;
        flex-direction: column;
        font-weight: 300;
    }    

    h2 {
        margin-bottom: 20px;
        display: inline-block;
    }

    .title {
        font-size: 20px;
        margin-bottom: 5px;
        display: inline-block;
        font-weight: 600;

        @include media-breakpoint-up(md) {
            font-weight: 700;
        }
    }

    .subtitle {
        font-style: normal !important;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 5px;
        display: inline-block;
        color: $colorGreyLighter !important;

        @include media-breakpoint-up(md) {
            font-size: 16px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 18px;
            line-height: 24px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .title + .subtitle {
        margin-bottom: 25px;
    }

    > a, .contents > a {
        display: inline-block;
        margin-bottom: 20px;
        text-decoration: none;
        color: inherit;

        h2 {
            margin-bottom: 0;
        }

        &.cart {
            text-decoration: underline;
            color: $colorGreen;

            text-decoration: none;
            
            span {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 1px;
                    width: 100%;
                    pointer-events: none;
                    background: $colorGreen;

                    @include transition(transform);
                }
            }
    
            svg {
                margin-left: 5px;

                use {
                    fill: $colorGreen;
                }
            }

            @include active {
                span {
                    &:after {
                        transform: scale(0);
                    }
                }
            }
        }
    }

    address {
        position: relative;
        top: -15px;
    }

    .price {
        margin-bottom: 5px;
        display: inline-block;
        .current {
            font-size: 18px;
            color: $colorGrey;
            display: inline-block;
        }

        .original {
            font-size: 14px;
            color: $colorRed;
            text-decoration: line-through;
            display: inline-block;
        }
    }

    .content {
        p:first-child {
            margin-top: 0;
        }
    }

    .actions {

        a {
            text-decoration: none;
            font-weight: bold;
            color: #555555;
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

// Styling for when an article is in a list
.list, .pagedList {
    article {
        height: 100%;

        .content {
            margin-top: auto;
        }

        /* .actions {
            margin-top: auto;
        } */
    }
}