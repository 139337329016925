@keyframes appear {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes submenuAppear {
	0% {
		opacity: 0;
		transform: translate(-50%, -20px) scale(.75);
	}

	100% {
		opacity: 1;
		transform: translate(-50%, 20px);
	}
}

@keyframes appearFromRight {
	0% {
		opacity: 0;
		transform: translateX(20px);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes appearFromLeft {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes popupGif {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes wiggle {
	0% {
		transform: rotate(0);
	}

	25% {
		transform: rotate(-12deg);
	}

	50% {
		transform: rotate(10deg);
	}

	75% {
		transform: rotate(-4deg);
	}

	100% {
		transform: rotate(0);
	}
}

@keyframes blob-landing-transform {
	100% {
		border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%;
	}

	20% {
		border-radius: 37% 63% 51% 49% / 37% 65% 35% 63%;
	}

	40% {
		border-radius: 36% 64% 64% 36% / 64% 48% 52% 36%;
	}

	60% {
		border-radius: 37% 63% 51% 49% / 30% 30% 70% 70%;
	}

	80% {
		border-radius: 40% 60% 42% 58% / 41% 51% 49% 59%;
	}
}

@keyframes blob-landing-moving-1 {
	100% {
		transform: none;
	}

	50% {
		transform: rotateY(10deg) scale(1.2);
	}
}

@keyframes blob-landing-moving-2 {
	100% {
		transform: none;
	}

	50% {
		transform: rotate(-200deg) scale(1.2);
	}
}

@keyframes liked {
	0% {
		transform: scale(1);
	}

	33.33% {
		transform: scale(1.1);
	}

	66.66% {
		transform: scale(.95);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes quickscanReverseOut {
	0% {
        opacity: 1;
        transform: none;
    }

    100% {
        opacity: 0;
        transform: translateX(20%);
    }
}

@keyframes quickscanOut {
    0% {
        opacity: 1;
        transform: none;
    }

    100% {
        opacity: 0;
        transform: translateX(-20%);
    }
}

@keyframes quickscanReverseIn {
    0% {
        opacity: 0;
        transform: translateX(-20%);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes quickscanIn {
    0% {
        opacity: 0;
        transform: translateX(20%);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes quickscanLoadingAppear {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes toastAppear {
	0% {
		transform: translateY(-100%);
	}

	100% {
		transform: none;
	}
}

@keyframes activeProgressStep {
	0% {
		transform: scaleX(0);
	}

	100% {
		transform: scaleX(1);
	}
}