@mixin transition(
    $property: all,
    $duration: $animationDuration,
    $timingFunction: $animationFunction
) {
    @if (type-of($property) == list) {
        transition: all $duration $timingFunction;
        transition-property: append($property, (), 'comma');
    } @else {
        transition: $property $duration $timingFunction;
    }
}

@mixin animation(
	$name,
	$duration: $animationDuration,
	$timingFunction: $animationFunction
) {
	animation: {
    direction: normal;
    duration: $duration;
    fill-mode: forwards;   
    name: $name;
    timing-function: $timingFunction;
  }
}

@mixin hardStopGradient($direction: to bottom, $colorList: ()) {
    $stopsList: ();
    
    @for $i from 1 through length($colorList) {
      @if ($i == 1) {
        // First loop, include the 0 start with the first color
        $stopsList: append($stopsList, nth($colorList, 1) 0%, 'comma');
      }
      
      @if ($i != length($colorList)) {
        // Append the first color, plus the next color right after
        // Only do this if this isn't the last loop
        $stopsList: append($stopsList, nth($colorList, $i) (100% / length($colorList) * $i));
        $stopsList: append($stopsList, nth($colorList, ($i + 1)) (100% / length($colorList) * $i));
      } @else {
        // Last loop, include the 100 end with the last color
        $stopsList: append($stopsList, nth($colorList, length($colorList)) 100%, 'comma');
      }
    }
    background: linear-gradient($direction, $stopsList);
}

@mixin underline($color, $height: 1px) {
  position: relative;
  text-decoration: none;
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $color;
    height: $height;
    width: 100%;

    @include transition(transform);
  }

  @include active {
    &:after {
      transform: scaleX(0);
    }
  }
}

@mixin underline_closed($color, $height: 1px) {
  position: relative;
  text-decoration: none;
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $color;
    height: $height;
    width: 100%;
    transform: scaleX(0);

    @include transition(transform);
  }

  @include active {
    &:after {
      transform: none;
    }
  }
}

@mixin active {
  &:hover,
  &:focus {
    @content;
  }
}