section.newsletter {
    max-width: 100%;
    overflow: hidden;
    h2 {
        font-size: 18px;
        line-height: 32px;
        font-weight: 800;

        @include media-breakpoint-up(md) {
            text-align: right;
        }
    }

    .content {
        span {
            font-size: 32px;
            line-height: 32px;

            display: block;
            margin-bottom: 10px;
            font-weight: 800;
            position: relative;
            z-index: 1;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
                text-align: right;
            }
        }

        p {
            margin-top: 0;
            margin-bottom: 45px;

            @include media-breakpoint-up(md) {
                margin-bottom: 25px;
            }
        }

        a.button {
            font-size: 22px;
            font-weight: 600;
            padding-right: 60px;
        }

        .col:last-child, [class*="col-"]:last-child {
            position: relative;

            > * {
                position: relative;
            }

            img.bg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -60%) scale(1.2);
            }
        }
    }
}

section#content section.newsletter {
    h2 {
        margin-top: 0;
        margin-bottom: 0;
    }
}