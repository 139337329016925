body.investlandingpage {
    section#hero {
        .background {
            &:before {
                height: 40px;

                @include media-breakpoint-up(md) {
                    height: 80px;
                }
            }

            .layer.back {
                background: linear-gradient(to bottom,
                    $colorRed 0,
                    $colorPurple 50%,
                    $colorBlue 100%);
            }
        }

        h1 {
            padding-bottom: 20px;

            font-size: 48px;
            line-height: 40px;

            @include media-breakpoint-up(xl) {
                padding-bottom: 0;

                font-size: 56px;
                line-height: 48px;
            }
        }
    }

    section.partners ~ .container {
        h3 {
            font-weight: 500;
        }
    }

    section#content {
        section.partners ~ .container {
            h3 {
                margin-top: 0;
            }
        }

        .umb-grid {
            .row {
                justify-content: center;
            }
        }
    }

    section.parallaxBackground {
        overflow: visible;

        .background {
            overflow: hidden;
        }
    }
}