a.button,
button.button {
    display: inline-flex;
    padding: 10px 30px;
    flex-direction: row;
    border-radius: 50px;
    align-items: center;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    text-decoration: none;
    border: none;

    @include transition(background color);

    &.narrow {
        padding: 5px 30px;

        span {
            font-weight: 300;
        }
    }

    span {
        font-size: 22px;
        line-height: 28px;
        font-family: $fontDefault;
        font-weight: 700;
    }

    svg use {
        @include transition(fill stroke);
    }

    > *:not(:last-child) {
        margin-right: 20px;
    }
    // Color variations
    &.yellow-blue {
        background: $colorYellow;
        color: $colorBlue;

        svg use {
            fill: $colorBlue;
        }

        @include active {
            background: $colorBlue;
            color: $colorYellow;

            svg use {
                fill: $colorYellow;
            }
        }
    }

    &.blue-white {
        background: $colorBlue;
        color: $colorWhite;

        svg use {
            fill: $colorWhite;
        }

        @include active {
            background: #efefef;
            color: $colorBlue;

            svg use {
                fill: $colorBlue;
            }
        }
    }

    &.white-red {
        background: $colorWhite;
        color: $colorRed;

        svg use {
            fill: $colorRed;
        }

        @include active {
            background: $colorRed;
            color: $colorWhite;

            svg use {
                fill: $colorWhite;
            }
        }
    }

    &.red-white {
        background: $colorRed;
        color: $colorWhite;

        svg use {
            fill: $colorBlack;
        }

        @include active {
            background: $colorGreyLight;
            color: $colorRed;

            svg use {
                fill: $colorWhite;
            }
        }
    }

    &.grey-black {
        background: $colorGreyLight;
        color: $colorBlack;

        svg use {
            fill: $colorBlack;
        }

        @include active {
            background: $colorBlack;
            color: $colorGreyLight;

            svg use {
                fill: $colorGreyLight;
            }
        }
    }

    &.clear {
        border: 1px solid $colorGreyLight;
        background: $colorWhite;

        &.buybutton:hover {
            span {
                color: $colorPurple;
            }
        }
    }

    &.green {
        background: $colorGreen;
        color: $colorWhite;

        @include active {
            background: $colorGreyLight;
            color: $colorGreen;
        }
    }

    &.purple {
        background: $colorPurple;
        color: $colorWhite;

        @include active {
            background: $colorGreyLight;
            color: $colorPurple;
        }
    }
}

#hero {

    a.button,
    button.button {
        &.clear {
            background: transparent;
        }
    }
}

div.firebaseEnabled {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span.field-validation-error {
        color: $colorRed;
        margin-top: 5px;
        display: none;

        &.visible {
            display: block;
        }
    }
}

a.button-google {
    border-radius: 2px;
    background-color: #4285f4;
    border: 1px solid #4285f4;
    text-decoration: none;
    display: flex;
    align-items: center;
    box-shadow: 0 0 5px -5px #4285f4;
    width: 240px;
    @include transition(box-shadow);

    @include active() {
        box-shadow: 0 0 5px -1px #4285f4;
    }

    svg {
        width: 46px;
        height: 46px;
        background: $colorWhite;
    }

    span {
        font-size: 14px;
        color: $colorWhite;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        margin: 0 auto;
    }
}
.academy-button {
    margin-top:70px;
}
