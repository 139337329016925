section.quote {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    overflow: hidden;

    // With the hero being in the content section, we need to break out of it to go fullwidth
    margin-left: -15px;
    margin-right: -15px;

    @include media-breakpoint-up(md) {
        margin-left: -75px;
        margin-right: -75px;

        padding-top: 100px;
        padding-bottom: 90px;
    }

    @include media-breakpoint-up(lg) {
        margin-left: -50px;
        margin-right: -50px;

        padding-bottom: 75px;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 130px;
        padding-bottom: 100px;
    }

    blockquote {
        text-align: left;
        margin-bottom: 15px;

        padding-left: 15px;
        padding-right: 15px;
        font-weight: 500;
    }

    span.quotee {
        color: $colorBlue;

        padding-left: 15px;
        padding-right: 15px;
    }

    .background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        .layer {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            background: $colorYellow;
        }

        &:before, &:after {
            display: block;
            z-index: 1;
            position: absolute;
            content: '';
            width: 100%;
            left: 0;
            background-size: 100% 100%;
        }

        &:before {
            background-image: url('/img/hero_curve_1.svg');
            top: 0;
            transform: scaleX(-1);
            height: 40px;

            @include media-breakpoint-up(md) {
                height: 60px;
            }
        }

        &:after {
            background-image: url('/img/hero_curve_1.svg');
            bottom: 0;
            height: 50px;
            z-index: 0;
            transform: rotate(180deg) scaleX(-1);
            
            @include media-breakpoint-up(md) {
                height: 80px;
            }
        }
    }
}   