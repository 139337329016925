.project {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @include media-breakpoint-up(sm) {
        display: block;
    }

    .image {
        height: 86px;
        margin-bottom: 10px;
        margin-right: 15px;

        flex: 0 0 86px;

        @include media-breakpoint-up(sm) {
            height: 175px;
            margin-right: 0;
        }

        a {
            text-decoration: none;
        }

        img {
            border-radius: 100px;
            height: 86px;
            width: 86px;
            
            @include media-breakpoint-up(sm) {
                height: 175px;
                width: 175px;
            }
        }
    }

    .contents {
        .author {
            font-size: 16px;
            color: $colorGreyLighter;
            display: block;
            margin-bottom: 5px;
        }

        .title, > a .title {
            font-size: 24px;
            line-height: 24px;
            font-weight: normal;

            display: block;
            margin-bottom: 10px;
        }

        > a {
            text-decoration: none;

            .title {
                color: $colorBlack;

                @include transition(color);
            }
            
            .link{
                color: $colorGreen;
                text-decoration: underline;
            }

            @include active {
                .title {
                    color: $colorPurple;
                }

                .link{
                    color:$colorJava;
                }
            }
        }

        .info {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 15px;
        }
    }
}