header .headerNavigation {
    display: flex;
    justify-content: flex-start;

    align-items: center;
}

header nav:not(.landing)#headerMenu {
    $function: cubic-bezier(0.61, 0.1, 0.38, 0.97);
    display: flex;
    flex-direction: column;
    align-self: center;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: -1;
    background: $colorBlue;
    height: 100vh;
    height: var(--fullHeight);
    overflow-y: auto;
    padding: ($sizeMenuHeightMobile + 20px) 20px 20px 0;
    // transform: scale(.9);
    transform-origin: top right;
    // opacity: 0;
    pointer-events: none;
    clip-path: circle(0 at calc(100% - 50px) 50px);
    @include transition(transform opacity clip-path);

    .ie & {
        opacity: 0;

        @include media-breakpoint-up(md) {
            opacity: 1;
        }
    }

    transition: transform 600ms $function, opacity 400ms $animationFunction 200ms, clip-path 600ms $function;

    @include media-breakpoint-up(md) {
        position: relative;
        opacity: 1;
        width: auto;
        left: auto;
        top: auto;
        transform: none;
        background: transparent;
        height: auto;
        padding-top: 20px;
        z-index: 1;
        pointer-events: auto;
        clip-path: none;
        width: 100%;
        overflow: visible;
        flex-direction: row;
    }

    ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        max-height: 100%;
        overflow-y: auto;
        padding: 0 20px;

        @include media-breakpoint-up(md) {
            display: inline-block;
            padding: 0;
            overflow-y: initial;
        }

        li {
            position: relative;
            display: inline-block;
            list-style: none;
            margin: 0;
            padding: 0;

            a,
            button {
                display: inline-block;
                padding: 10px 20px 14px 15px;
                margin: 5px 0;
                text-decoration: none;
                color: $colorWhite;
                background: transparent;
                font-size: 24px;
                font-family: $fontDefault;
                font-weight: 500;

                svg.sprite {
                    use {
                        fill: $colorBlack;
                    }
                }

                ~ .submenu {
                    display: none;
                    @include animation(appearFromLeft);

                    @include media-breakpoint-up(md) {
                        position: absolute;
                        top: 100%;
                        max-height: initial;
                        left: 50%;
                        transform: translateY(20px) translateX(-50%);

                        @include animation(submenuAppear);
                        background: $colorWhite;
                        padding: 20px 10px;
                        border-radius: 32px;
                        // align-items: center;
                        // flex-direction: row;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, .26);

                        &:before {
                            position: absolute;
                            content: '';
                            top: 0;
                            left: 50%;
                            height: 20px;
                            width: 20px;
                            border-radius: 5px;
                            transform: translate(-50%, -50%) rotate(45deg);
                            background: $colorWhite;
                            z-index: -1;
                        }
                        // margin: -10px;
                        // li {
                        //     margin: 10px;
                        //     a, button {
                        //         // word-wrap:
                        //     }
                        // }
                    }
                }

                &.subMenuPage {
                    padding-right: 0 !important;
                }

                &.subMenuLink {
                    padding-left: 0 !important;

                    svg.sprite {
                        use {
                            fill: $colorRed;
                        }
                    }
                }


                &.hasSubmenu {
                    svg.sprite {
                        @include transition(transform);
                    }
                }

                &.submenuActive {
                    svg.sprite {
                        transform: scaleY(-1);
                    }

                    ~ .submenu {
                        display: block;
                    }
                }

                &.shop {
                    border-radius: 50px;
                    background: $colorWhite;
                    color: $colorGreen;
                }

                &.join {
                    @include media-breakpoint-up(md) {
                        color: $colorRed;

                        svg.sprite {
                            use {
                                fill: $colorRed;
                            }
                        }

                        &:after {
                            width: calc(100% - 10px);
                        }
                    }
                }

                @include transition(color);

                @include media-breakpoint-up(md) {
                    margin: 0;
                    padding: 10px;
                    color: $colorBlack;
                    font-size: 18px;
                    line-height: 32px;
                    position: relative;
                    padding: 6px 20px;
                    font-weight: 300;

                    &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        bottom: 0;
                        left: 20px;
                        width: 100%;
                        width: calc(100% - 40px);
                        height: 2px;
                        background: $colorBlue;
                        transform: scaleX(0);
                        opacity: 0;

                        @include transition(transform opacity);
                    }



                    &.shop:after {
                        background: $colorGreen;
                    }

                    &.join:after {
                        background: $colorRed;
                    }

                    @include active {
                        color: $colorBlue;

                        &:after {
                            opacity: 1;
                            transform: none;
                        }

                        &.shop {
                            color: $colorGreen;
                        }

                        &.join {
                            color: $colorRed;
                        }
                    }

                    &.active {
                        color: $colorBlue;

                        &:after {
                            opacity: 1;
                            transform: none;
                        }

                        &.shop {
                            color: $colorGreen;
                        }

                        &.join {
                            color: $colorRed;
                        }
                    }
                }
            }

            ul.sub {
                display: flex;
                margin: -5px;

                li {
                    margin: 5px;
                }
            }
        }

        &.meta {
            position: relative;
            margin-top: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                width: calc(100% - 50px);
                height: 1px;
                background: $colorWhite;
            }

            @include media-breakpoint-up(md) {
                margin-top: -50px;
                position: absolute;
                right: 0;

                &:before {
                    display: none;
                }
            }

            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }

            form {
                margin: 0;

                a {
                    display: inline-block;
                    text-decoration: none;

                    .icon {
                        max-height: 50px;
                        max-width: 50px;
                        border-radius: 50%;
                        vertical-align: middle;

                        @include media-breakpoint-up(md) {
                            // margin-right: -20px;
                        }
                    }
                }
            }

            li {
                a {
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 32px;

                    &:after {
                        display: none;
                    }
                }
            }
        }

        &.main {
            li {
                a {
                    @include media-breakpoint-up(md) {
                        padding: 6px 15px;
                    }
                }
            }
        }
    }
}

header.mobileMenuActive nav#headerMenu {
    $function: cubic-bezier(0.61, 0.1, 0.38, 0.97);
    pointer-events: all;
    transform: none;
    opacity: 1;
    clip-path: circle(131vmax at calc(100% - 50px) 50px);

    .ie & {
        opacity: 1;
    }

    ul li a svg.sprite {
        use {
            fill: $colorWhite;
        }
    }
}

header .menuButton {
    display: inline-flex;
    cursor: pointer;
    width: 50px;
    height: 50px;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    // margin-left: auto;
    margin-left: 10px;

    >div {
        position: absolute;
        width: 24px;
        height: 2px;
        background: $colorBlack;
        @include transition(opacity transform background);
        border-radius: 4px;

        &.ham {
            transform: translateY(-10px);
            transition-delay: 240ms;
        }

        &.bur {
            transition-delay: 200ms;
        }

        &.ger {
            transform: translateY(10px);
            transition-delay: 280ms;
        }
    }
}

header.mobileMenuActive .menuButton {

    >div {
        background: $colorWhite;

        &.ham {
            transform: rotate(180 + 45deg);
            transition-delay: 240ms;
        }

        &.bur {
            opacity: 0;
            transform: translateX(5px) scaleY(0);
            transition-delay: 0;
        }

        &.ger {
            transform: rotate(180 + -45deg);
            transition-delay: 280ms;
        }
    }
}

header nav#metaMenu {
    margin-left: auto;

    @include media-breakpoint-up(md) {
        top: -50px;
        position: relative;
    }

    @include media-breakpoint-up(lg) {
        top: 0;
    }

    ul {
        display: inline-flex;

        li {
            a {
                display: inline-flex;
                cursor: pointer;
                width: 50px;
                height: 50px;
                position: relative;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                margin-left: 10px;

                svg {
                    use {
                        fill: $colorBlack;
                        @include transition(fill);
                        transition-delay: 200ms;
                    }
                }
            }

            &:first-child {
                a {
                    margin-left: 0;
                }
            }

            .headerCart {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                svg.sprite {
                    use {}
                }

                span {
                    position: absolute;
                    transform: translateY(3px);
                    color: $colorBlack;
                }

                &.hasItems {
                    span {
                        color: $colorWhite;
                        transform: translateY(3px) translateX(-1px);
                    }
                }
            }
        }
    }
}

header.mobileMenuActive nav#metaMenu {
    ul {
        li {
            a {
                svg {
                    use {
                        fill: $colorWhite;

                        @include media-breakpoint-up(md) {
                            fill: $colorBlack;
                        }
                    }
                }
            }
        }
    }
}

aside {
    position: relative;

    label[for="input_showMenuMobile"] {
        display: block;
        padding: 12px 45px 12px 16px;
        color: $colorBlack;
        text-decoration: none;
        width: 100%;
        background: $colorWhite;
        border: 1px solid $colorGreyLight;
        border-bottom-color: $colorGreyLight;
        border-radius: 24px;
        cursor: pointer;

        @include media-breakpoint-up(md) {
            display: none;
        }

        &:after {
            content: '\25bc';
            display: inline-block;
            position: absolute;
            right: 24px;
            color: $colorGreyLighter;
            transform: scale(0.6) translateY(2px);
        }
    }

    input#input_showMenuMobile {
        display: none;

        &:checked {
            &~label[for="input_showMenuMobile"] {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-color: transparent;
            }
        }
    }

    .sideMenu {
        display: inline-flex;
        flex-direction: column;

        transform-origin: top;
        transform: scaleY(0.9);
        pointer-events: none;
        opacity: 0;
        border: 1px solid $colorGreyLight;
        border-top: none;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        overflow: hidden;

        position: absolute;
        left: 0;
        width: 100%;
        z-index: 10;
        @include transition(transform opacity);
        background: $colorWhite;

        @include media-breakpoint-up(md) {
            transform: none;
            pointer-events: all;
            opacity: 1;

            position: static;
            width: auto;

            border: none;
            border-radius: 0;
            background: transparent;
        }

        li {
            display: inline-block;

            @include media-breakpoint-up(md) {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            a {
                display: inline-block;
                padding: 12px 45px 12px 16px;
                border: none;
                color: $colorBlack;
                text-decoration: none;
                width: 100%;
                background: $colorWhite;

                @include transition(border-color);

                &.active {
                    background: #F8F8F4;
                }

                @include active {
                    border-color: $colorGreyLighter;
                    color: $colorBlack;
                }

                @include media-breakpoint-up(md) {
                    border: 1px solid $colorGreyLight;
                    border-radius: 50px;
                }
            }
        }
    }

    input#input_showMenuMobile:checked {
        &~.sideMenu {
            pointer-events: all;
            transform: none;
            opacity: 1;
        }
    }
}