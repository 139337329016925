ul.pagedList, ol.pagedList {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -20px;
    margin-bottom: 20px;

    li {
        flex: 0 0 auto;
        flex-basis: 100%;
        max-width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 20px;
        margin-left: 0;

        &:before {
            display: none;
        }
    }

    &:not(.fullWidth) {
        li {
            @include media-breakpoint-up(sm) {
                flex-basis: 50%;
                max-width: 50%;
            }
    
            @include media-breakpoint-up(md) {
                flex-basis: 33.33%;
                max-width: 33.33%;
            }
    
            @include media-breakpoint-up(lg) {
                // flex-basis: 25%;
                // max-width: 25%;
            }
        }
    }
}

nav.pager {
    width: 100%;

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        justify-content: center;
        
        li {
            list-style: none;
            margin: 0;
            padding: 0;

            a {
                display: inline-block;
                border: 1px solid #d8d8d8;
                border-radius: 4px;
                padding: 5px 10px;
                text-decoration: none;
                font-weight: bold;
                color: #555555;
                margin: 0 2.5px;

                &:not([href]) {
                    color: #868686;
                }
            }
        }
    }
}