#breadcrumb {
    padding: 10px 0;

    ul {
        display: inline-block;

        li {
            list-style: none;
            display: inline-block;

            &:not(:last-child) {
                &:after {
                    content: '>';
                }
            }

            a {
                text-decoration: none;
            }
        }
    }
}