.tabs {
    .tabContents {
        .tabContent {
            @include animation(appear, 600ms);

            display: none;

            &.active {
                display: block;
            }

            &.right {
                > div {
                    @include animation(appearFromRight, 600ms);
                }
            }

            &.left {
                > div {
                    @include animation(appearFromLeft, 600ms);
                }
            }
        }
    }
}