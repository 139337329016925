body.striberdetailpage, body.mystriberpreview {
    .striberIntro {
        margin-top: 65px;
        margin-bottom: 32px;
        z-index: 2;
        position: relative;

        @include media-breakpoint-up(md) {
            margin-top: 45px;
            margin-bottom: 40px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: -130px;
        }

        svg.svg {
            position: absolute;
            height: 0;
            width: 0;
        }

        img {
            clip-path: url(#striber-mask);

            .ie &, .edge & {
                border-radius: 31% 69% 56% 44% / 57% 58% 42% 43%;
                overflow: hidden;
            }
        }

        h1 {
            color: $colorRed;
            margin-top: 0;
            font-size: 48px;
            line-height: 48px;
        }

        .subtitle {
            display: inline-block;
            color: $colorGreyLighter;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 28px;
            font-style: normal !important;


            @include media-breakpoint-up(md) {
                font-size: 18px;
                margin-bottom: 26px;
            }
        }

        .actions {

            li {
                &:not(&:last-child) {
                    margin-bottom: 8px;
                }
            }

            a {
                &.button {
                    padding-right: 60px;
                }

                &:not(.button) {
                    color: $colorBlue;
                    font-size: 18px;

                    svg.sprite {
                        width: 15px;
                        margin-right: 8.5px;
                        vertical-align: middle;

                        use {
                            fill: $colorBlue;
                        }
                    }
                }
            }
        }
    }

    section#hero {
        padding-bottom: 50px;

        @include media-breakpoint-up(md) {
            padding-top: 120px;
            padding-bottom: 100px;
        }

        .background {
            &:before {
                height: 40px;

                @include media-breakpoint-up(md) {
                    height: 100px;
                }
            }

            &:after {
                height: 40px;

                @include media-breakpoint-up(md) {
                    height: 50px;
                }

                @include media-breakpoint-up(lg) {
                    height: 100px;
                }
            }
        }

        .background .layer.back {
            background: linear-gradient(to bottom,
                    #FFFFFF 0,
                    $colorBlue 1%,
                    $colorBlue 50%,
                    #00E5FF 99%,
                    #FFFFFF 100%);
        }

        p {
            width: 100%;
            color: $colorWhite;
            margin-bottom: 30px;
            margin-top: 0;

            font-size: 24px;
            line-height: 32px;

            @include media-breakpoint-up(md) {
                text-align: right;
                margin-bottom: 0;
                padding: 65px 0;
            }
        }

        img {
            border-radius: 49% 51% 51% 49% / 65% 64% 36% 35%;
        }
    }

    .umb-grid {
        margin-bottom: 50px;

        @include media-breakpoint-up(md) {
            margin-bottom: 70px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 65px;
        }
    }

    section#content section.stories h2 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    section.stories {
        padding-top: 65px;
        padding-bottom: 75px;
        position: relative;

        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 65px;

        @include media-breakpoint-up(md) {
            margin-left: -75px;
            margin-right: -75px;
            padding-top: 115px;
            padding-bottom: 95px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: -50px;
            margin-right: -50px;
            padding-bottom: 156px;
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: 128px;
            padding-bottom: 175px;
        }

        .background {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            background: #F8F8F4;

            &:before, &:after {
                position: absolute;
                content: '';
                width: 100%;
                left: 0;
                background-size: 100% 100%;
                height: 14px;

                @include media-breakpoint-up(md) {
                    height: 29px;
                }

                @include media-breakpoint-up(lg) {
                    height: 39px;
                }

                @include media-breakpoint-up(xl) {
                    height: 55px;
                }
            }

            &:before {
                background-image: url('/img/stories_curve_2.svg');
                top: 0;
                
            }

            &:after {
                background-image: url('/img/stories_curve_1.svg');
                bottom: 0;
            }
        }

        h2 {
            color: $colorRed;
        }
    }

    section#content section.products h2 {
        margin: 5px auto 20px;
    }

    section.products {
        margin-bottom: 130px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 160px;
        }

        h2 {
            color: $colorRed;
            font-size: 36px;
            line-height: 42px;
        }

        > div:nth-of-type(1) > .row {
            margin-bottom: 35px;
        }

        > div:nth-of-type(2) > .row {
            margin-bottom: 35px;

            > div {
                display: flex;
                align-items: center;

            }
        }
        

        a.readmore {
            display: inline-flex;
            align-items: center;
            color: $colorGreyLighter;
            text-decoration: none;
            margin-left: auto;
            margin-right: auto;

            svg {
                margin-left: 5px;
                @include transition(transform);
            }

            @include active {
                svg {
                    transform: translateX(4px);
                }
            }
        }

        ul.productsList {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;

            li {
                margin: 10px;

                @include media-breakpoint-up(md) {
                    max-width: 33%;
                    flex-basis: 33%;
                }

                @include media-breakpoint-up(lg) {
                    max-width: 25%;
                    flex-basis: 25%;
                }

                @include media-breakpoint-up(xl) {
                    margin: 10px 20px;
                }
            }
        }
    }
}