@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

:root {
  --fullHeight: 100vh;
}

/**
* Bootstrap
**/
/*!
 * Bootstrap Grid v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 375px) {
  .container-sm, .container {
    max-width: 768px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 1138px;
  }
}
@media (min-width: 1024px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1263px;
  }
}
@media (min-width: 1300px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1300px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 375px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1300px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 375px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1024px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1300px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 375px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1024px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1300px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 375px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1300px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1300px) {
  .row:not(.no-gutters) {
    margin-right: -20px;
    margin-left: -20px;
  }
  .row:not(.no-gutters) > .col,
  .row:not(.no-gutters) > [class*=col-] {
    padding-right: 20px;
    padding-left: 20px;
  }
}
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-scrollbar {
  width: 2px;
}

.simplebar-scrollbar:before {
  background: #651FFF;
  background: rgba(112, 112, 112, 0.5);
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

.tippy-tooltip[data-animation=fade][data-state=hidden] {
  opacity: 0;
}

.tippy-iOS {
  cursor: pointer !important;
  -webkit-tap-highlight-color: transparent;
}

.tippy-popper {
  pointer-events: none;
  max-width: calc(100vw - 10px);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: transform;
}

.tippy-tooltip {
  position: relative;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  background-color: #333;
  transition-property: visibility, opacity, transform;
  outline: 0;
}

.tippy-tooltip[data-placement^=top] > .tippy-arrow {
  border-width: 8px 8px 0;
  border-top-color: #333;
  margin: 0 3px;
  transform-origin: 50% 0;
  bottom: -7px;
}

.tippy-tooltip[data-placement^=bottom] > .tippy-arrow {
  border-width: 0 8px 8px;
  border-bottom-color: #333;
  margin: 0 3px;
  transform-origin: 50% 7px;
  top: -7px;
}

.tippy-tooltip[data-placement^=left] > .tippy-arrow {
  border-width: 8px 0 8px 8px;
  border-left-color: #333;
  margin: 3px 0;
  transform-origin: 0 50%;
  right: -7px;
}

.tippy-tooltip[data-placement^=right] > .tippy-arrow {
  border-width: 8px 8px 8px 0;
  border-right-color: #333;
  margin: 3px 0;
  transform-origin: 7px 50%;
  left: -7px;
}

.tippy-tooltip[data-interactive][data-state=visible] {
  pointer-events: auto;
}

.tippy-tooltip[data-inertia][data-state=visible] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}

.tippy-arrow {
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-content {
  padding: 5px 9px;
}

.tippy-tooltip {
  background: #FFFFFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  color: #232320;
  border-radius: 8px;
}

.tippy-tooltip[data-placement^=top] > .tippy-arrow {
  border-top-color: #FFFFFF;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}
.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-search--inline {
  float: left;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #dee2e6;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #dee2e6;
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  left: 1px;
  right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  cursor: text;
  outline: 0;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #dee2e6;
  outline: 0;
}
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}
.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}
.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}
.select2-container--classic .select2-dropdown--below {
  border-top: none;
}
.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}
.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

/*======================================
  Selectric v1.13.0
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 1px solid #DDD;
  border-radius: 0px;
  background: #F8F8F8;
  position: relative;
  overflow: hidden;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 0 10px;
  font-size: 12px;
  line-height: 38px;
  color: #444;
  height: 38px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  line-height: 38px;
  background-color: #F8f8f8;
  color: #BBB;
  text-align: center;
  font: 0/0 a;
  *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial;
}

.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #BBB;
  border-bottom: none;
}

.selectric-focus .selectric {
  border-color: #aaaaaa;
}

.selectric-hover .selectric {
  border-color: #c4c4c4;
}

.selectric-hover .selectric .button {
  color: #a2a2a2;
}

.selectric-hover .selectric .button:after {
  border-top-color: #a2a2a2;
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric {
  border-color: #c4c4c4;
}

.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F8F8F8;
  border: 1px solid #c4c4c4;
  z-index: -1;
  box-shadow: 0 0 10px -6px;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items li {
  display: block;
  padding: 10px;
  color: #666;
  cursor: pointer;
}

.selectric-items li.selected {
  background: #E0E0E0;
  color: #444;
}

.selectric-items li.highlighted {
  background: #D0D0D0;
  color: #444;
}

.selectric-items li:hover {
  background: #D5D5D5;
  color: #444;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}

:root {
  --fullHeight: 100vh;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes submenuAppear {
  0% {
    opacity: 0;
    transform: translate(-50%, -20px) scale(0.75);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 20px);
  }
}
@keyframes appearFromRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes appearFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes popupGif {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes wiggle {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-12deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes blob-landing-transform {
  100% {
    border-radius: 33% 67% 70% 30%/30% 30% 70% 70%;
  }
  20% {
    border-radius: 37% 63% 51% 49%/37% 65% 35% 63%;
  }
  40% {
    border-radius: 36% 64% 64% 36%/64% 48% 52% 36%;
  }
  60% {
    border-radius: 37% 63% 51% 49%/30% 30% 70% 70%;
  }
  80% {
    border-radius: 40% 60% 42% 58%/41% 51% 49% 59%;
  }
}
@keyframes blob-landing-moving-1 {
  100% {
    transform: none;
  }
  50% {
    transform: rotateY(10deg) scale(1.2);
  }
}
@keyframes blob-landing-moving-2 {
  100% {
    transform: none;
  }
  50% {
    transform: rotate(-200deg) scale(1.2);
  }
}
@keyframes liked {
  0% {
    transform: scale(1);
  }
  33.33% {
    transform: scale(1.1);
  }
  66.66% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes quickscanReverseOut {
  0% {
    opacity: 1;
    transform: none;
  }
  100% {
    opacity: 0;
    transform: translateX(20%);
  }
}
@keyframes quickscanOut {
  0% {
    opacity: 1;
    transform: none;
  }
  100% {
    opacity: 0;
    transform: translateX(-20%);
  }
}
@keyframes quickscanReverseIn {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes quickscanIn {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes quickscanLoadingAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes toastAppear {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: none;
  }
}
@keyframes activeProgressStep {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
svg.sprite.logo {
  width: 120px;
  max-height: 50px;
}
svg.sprite.logo use {
  fill: #232320;
}
svg.sprite.logo.white use {
  fill: #FFFFFF;
}
svg.sprite[class*=share_] {
  width: 48px;
  max-height: 48px;
}
svg.sprite[class*=share_] use {
  fill: #FFFFFF;
  stroke: #83847B;
}
svg.sprite.icon_avatar {
  width: 50px;
  height: 50px;
}
svg.sprite.icon_facebook {
  width: 14px;
  max-height: 25px;
}
svg.sprite.icon_facebook use {
  fill: #FFFFFF;
}
svg.sprite.icon_instagram {
  width: 32px;
  max-height: 32px;
}
svg.sprite.icon_instagram use {
  fill: #FFFFFF;
}
svg.sprite.icon_linkedin {
  width: 24px;
  max-height: 24px;
}
svg.sprite.icon_linkedin use {
  fill: #FFFFFF;
}
svg.sprite.icon_twitter {
  width: 28px;
  max-height: 23px;
}
svg.sprite.icon_twitter use {
  fill: #FFFFFF;
}
svg.sprite.icon_play {
  width: 43px;
  max-height: 43px;
}
svg.sprite.icon_play use {
  fill: #FFFFFF;
  transform: translateX(3px);
}
svg.sprite.icon_bell {
  width: 27px;
  max-height: 34px;
}
svg.sprite.icon_bell use {
  fill: #FFFFFF;
}
svg.sprite.icon_send {
  width: 32px;
  max-height: 32px;
}
svg.sprite.icon_send use {
  fill: #FFFFFF;
}
svg.sprite.icon_close {
  width: 56px;
  max-height: 56px;
  display: block;
}
svg.sprite.icon_close use {
  fill: #83847B;
}
svg.sprite.icon_search {
  width: 24px;
  max-height: 24px;
}
svg.sprite.icon_search use {
  fill: #232320;
}
svg.sprite.icon_shop {
  width: 26px;
  max-height: 32px;
}
svg.sprite.icon_shop use {
  fill: #232320;
}
svg.sprite.icon_shop_filled {
  width: 28px;
  max-height: 36px;
}
svg.sprite.icon_shop_filled use {
  fill: #232320;
}
svg.sprite.icon_shop_small {
  width: 15px;
  max-height: 18px;
}
svg.sprite.icon_shop_small use {
  fill: #232320;
}
svg.sprite.icon_heart_small {
  width: 19px;
  max-height: 16px;
}
svg.sprite.icon_heart_small use {
  fill: #232320;
}
svg.sprite.icon_stories_small {
  width: 16px;
  max-height: 18px;
}
svg.sprite.icon_stories_small use {
  fill: #232320;
}
svg.sprite.icon_project_small {
  width: 12px;
  max-height: 18px;
}
svg.sprite.icon_project_small use {
  fill: #232320;
}
svg.sprite.icon_chevron_right {
  width: 9px;
  max-height: 15px;
}
svg.sprite.icon_chevron_right use {
  stroke: #83847B;
}
svg.sprite.icon_chevron_left {
  width: 11px;
  max-height: 19px;
}
svg.sprite.icon_chevron_left use {
  stroke: #83847B;
}
svg.sprite.icon_chevron_left.right {
  transform: scaleX(-1);
}
svg.sprite.icon_like {
  width: 36px;
  max-height: 31px;
}
svg.sprite.icon_like use {
  fill: #FFFFFF;
  stroke: #83847B;
}
svg.sprite.icon_arrow_left {
  width: 32px;
  max-height: 10px;
}
svg.sprite.icon_arrow_left use {
  fill: #83847B;
  stroke: #83847B;
}
svg.sprite.icon_edit {
  width: 12px;
  max-height: 12px;
}
svg.sprite.icon_edit use {
  fill: #83847B;
}
svg.sprite.icon_stop {
  width: 12px;
  max-height: 12px;
}
svg.sprite.icon_stop use {
  fill: #83847B;
}
svg.sprite.icon_error, svg.sprite.icon_success {
  width: 24px;
  max-height: 24px;
}
svg.sprite.icon_delete {
  width: 28px;
  max-height: 32px;
}
svg.sprite.icon_delete use {
  fill: #E1E1D2;
}
svg.sprite.icon_rating {
  width: 29px;
  max-height: 25px;
}
svg.sprite.icon_angle_down {
  width: 14px;
  max-height: 10.5px;
}
svg.sprite[class*=SDG_] {
  max-height: 30px;
}
svg.sprite[class*=SDG_] use {
  fill: #232320;
}
svg.sprite.SDG_1 {
  width: 36px;
}
svg.sprite.SDG_2 {
  width: 35px;
}
svg.sprite.SDG_3 {
  width: 42px;
}
svg.sprite.SDG_4 {
  width: 37px;
}
svg.sprite.SDG_5 {
  width: 22px;
}
svg.sprite.SDG_6 {
  width: 22px;
}
svg.sprite.SDG_7 {
  width: 30px;
}
svg.sprite.SDG_8 {
  width: 29px;
}
svg.sprite.SDG_9 {
  width: 30px;
}
svg.sprite.SDG_10 {
  width: 29px;
}
svg.sprite.SDG_11 {
  width: 40px;
}
svg.sprite.SDG_12 {
  width: 32px;
}
svg.sprite.SDG_13 {
  width: 47px;
}
svg.sprite.SDG_14 {
  width: 35px;
}
svg.sprite.SDG_15 {
  width: 31px;
}
svg.sprite.SDG_16 {
  width: 30px;
}
svg.sprite.SDG_17 {
  width: 31px;
}

header:not(.landing) {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  height: 97px;
  background: #FFFFFF;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  header:not(.landing) {
    height: 160px;
  }
}
header:not(.landing).hidden {
  transform: translateY(-100%);
}
header:not(.landing).hidden.mobileMenuActive {
  transform: none;
}
@media (min-width: 768px) {
  header:not(.landing).hidden.mobileMenuActive {
    transform: translateY(-100%);
  }
}
header:not(.landing) .container {
  height: 97px;
  height: 100%;
}
@media (min-width: 768px) {
  header:not(.landing) .container {
    height: 160px;
    height: 100%;
  }
}
header:not(.landing) .container .row {
  height: 100%;
}
@media (min-width: 768px) {
  header:not(.landing) .container .row {
    height: 100%;
  }
}
header:not(.landing) .container .row .col,
header:not(.landing) .container .row [class*=col-] {
  max-height: 100%;
}
header:not(.landing) .logoColumn {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}
header:not(.landing) .logoColumn .headerLogo {
  align-self: center;
  width: 120px;
}
@media (min-width: 768px) {
  header:not(.landing) .logoColumn .headerLogo {
    width: 100px;
  }
}
@media (min-width: 1024px) {
  header:not(.landing) .logoColumn .headerLogo {
    width: 120px;
  }
}
@media (min-width: 1300px) {
  header:not(.landing) .logoColumn .headerLogo {
    width: 100%;
  }
}
header:not(.landing) .logoColumn .headerLogo svg {
  width: 100%;
  max-height: 97px;
}
header:not(.landing) .logoColumn .headerLogo svg use {
  transition: fill 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-delay: 200ms;
}
header:not(.landing) .logoColumn a {
  display: inline-block;
}
header:not(.landing) .logoColumn img {
  max-height: 77px;
}
@media (min-width: 768px) {
  header:not(.landing) .logoColumn img {
    max-height: 140px;
  }
}

header:not(.landing).mobileMenuActive .logoColumn .headerLogo svg use {
  fill: #FFFFFF;
}
@media (min-width: 768px) {
  header:not(.landing).mobileMenuActive .logoColumn .headerLogo svg use {
    fill: #232320;
  }
}

header .headerNavigation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

header nav:not(.landing)#headerMenu {
  display: flex;
  flex-direction: column;
  align-self: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: -1;
  background: #651FFF;
  height: 100vh;
  height: var(--fullHeight);
  overflow-y: auto;
  padding: 117px 20px 20px 0;
  transform-origin: top right;
  pointer-events: none;
  clip-path: circle(0 at calc(100% - 50px) 50px);
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: transform, opacity, clip-path;
  transition: transform 600ms cubic-bezier(0.61, 0.1, 0.38, 0.97), opacity 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9) 200ms, clip-path 600ms cubic-bezier(0.61, 0.1, 0.38, 0.97);
}
.ie header nav:not(.landing)#headerMenu {
  opacity: 0;
}
@media (min-width: 768px) {
  .ie header nav:not(.landing)#headerMenu {
    opacity: 1;
  }
}
@media (min-width: 768px) {
  header nav:not(.landing)#headerMenu {
    position: relative;
    opacity: 1;
    width: auto;
    left: auto;
    top: auto;
    transform: none;
    background: transparent;
    height: auto;
    padding-top: 20px;
    z-index: 1;
    pointer-events: auto;
    clip-path: none;
    width: 100%;
    overflow: visible;
    flex-direction: row;
  }
}
header nav:not(.landing)#headerMenu ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  max-height: 100%;
  overflow-y: auto;
  padding: 0 20px;
}
@media (min-width: 768px) {
  header nav:not(.landing)#headerMenu ul {
    display: inline-block;
    padding: 0;
    overflow-y: initial;
  }
}
header nav:not(.landing)#headerMenu ul li {
  position: relative;
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}
header nav:not(.landing)#headerMenu ul li a,
header nav:not(.landing)#headerMenu ul li button {
  display: inline-block;
  padding: 10px 20px 14px 15px;
  margin: 5px 0;
  text-decoration: none;
  color: #FFFFFF;
  background: transparent;
  font-size: 24px;
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
header nav:not(.landing)#headerMenu ul li a svg.sprite use,
header nav:not(.landing)#headerMenu ul li button svg.sprite use {
  fill: #232320;
}
header nav:not(.landing)#headerMenu ul li a ~ .submenu,
header nav:not(.landing)#headerMenu ul li button ~ .submenu {
  display: none;
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: appearFromLeft;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  header nav:not(.landing)#headerMenu ul li a ~ .submenu,
  header nav:not(.landing)#headerMenu ul li button ~ .submenu {
    position: absolute;
    top: 100%;
    max-height: initial;
    left: 50%;
    transform: translateY(20px) translateX(-50%);
    animation-direction: normal;
    animation-duration: 400ms;
    animation-fill-mode: forwards;
    animation-name: submenuAppear;
    animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
    background: #FFFFFF;
    padding: 20px 10px;
    border-radius: 32px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
  }
  header nav:not(.landing)#headerMenu ul li a ~ .submenu:before,
  header nav:not(.landing)#headerMenu ul li button ~ .submenu:before {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #FFFFFF;
    z-index: -1;
  }
}
header nav:not(.landing)#headerMenu ul li a.subMenuPage,
header nav:not(.landing)#headerMenu ul li button.subMenuPage {
  padding-right: 0 !important;
}
header nav:not(.landing)#headerMenu ul li a.subMenuLink,
header nav:not(.landing)#headerMenu ul li button.subMenuLink {
  padding-left: 0 !important;
}
header nav:not(.landing)#headerMenu ul li a.subMenuLink svg.sprite use,
header nav:not(.landing)#headerMenu ul li button.subMenuLink svg.sprite use {
  fill: #E72264;
}
header nav:not(.landing)#headerMenu ul li a.hasSubmenu svg.sprite,
header nav:not(.landing)#headerMenu ul li button.hasSubmenu svg.sprite {
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
header nav:not(.landing)#headerMenu ul li a.submenuActive svg.sprite,
header nav:not(.landing)#headerMenu ul li button.submenuActive svg.sprite {
  transform: scaleY(-1);
}
header nav:not(.landing)#headerMenu ul li a.submenuActive ~ .submenu,
header nav:not(.landing)#headerMenu ul li button.submenuActive ~ .submenu {
  display: block;
}
header nav:not(.landing)#headerMenu ul li a.shop,
header nav:not(.landing)#headerMenu ul li button.shop {
  border-radius: 50px;
  background: #FFFFFF;
  color: #12C700;
}
@media (min-width: 768px) {
  header nav:not(.landing)#headerMenu ul li a.join,
  header nav:not(.landing)#headerMenu ul li button.join {
    color: #E72264;
  }
  header nav:not(.landing)#headerMenu ul li a.join svg.sprite use,
  header nav:not(.landing)#headerMenu ul li button.join svg.sprite use {
    fill: #E72264;
  }
  header nav:not(.landing)#headerMenu ul li a.join:after,
  header nav:not(.landing)#headerMenu ul li button.join:after {
    width: calc(100% - 10px);
  }
}
@media (min-width: 768px) {
  header nav:not(.landing)#headerMenu ul li a,
  header nav:not(.landing)#headerMenu ul li button {
    margin: 0;
    padding: 10px;
    color: #232320;
    font-size: 18px;
    line-height: 32px;
    position: relative;
    padding: 6px 20px;
    font-weight: 300;
  }
  header nav:not(.landing)#headerMenu ul li a:after,
  header nav:not(.landing)#headerMenu ul li button:after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 20px;
    width: 100%;
    width: calc(100% - 40px);
    height: 2px;
    background: #651FFF;
    transform: scaleX(0);
    opacity: 0;
    transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
    transition-property: transform, opacity;
  }
  header nav:not(.landing)#headerMenu ul li a.shop:after,
  header nav:not(.landing)#headerMenu ul li button.shop:after {
    background: #12C700;
  }
  header nav:not(.landing)#headerMenu ul li a.join:after,
  header nav:not(.landing)#headerMenu ul li button.join:after {
    background: #E72264;
  }
  header nav:not(.landing)#headerMenu ul li a:hover, header nav:not(.landing)#headerMenu ul li a:focus,
  header nav:not(.landing)#headerMenu ul li button:hover,
  header nav:not(.landing)#headerMenu ul li button:focus {
    color: #651FFF;
  }
  header nav:not(.landing)#headerMenu ul li a:hover:after, header nav:not(.landing)#headerMenu ul li a:focus:after,
  header nav:not(.landing)#headerMenu ul li button:hover:after,
  header nav:not(.landing)#headerMenu ul li button:focus:after {
    opacity: 1;
    transform: none;
  }
  header nav:not(.landing)#headerMenu ul li a:hover.shop, header nav:not(.landing)#headerMenu ul li a:focus.shop,
  header nav:not(.landing)#headerMenu ul li button:hover.shop,
  header nav:not(.landing)#headerMenu ul li button:focus.shop {
    color: #12C700;
  }
  header nav:not(.landing)#headerMenu ul li a:hover.join, header nav:not(.landing)#headerMenu ul li a:focus.join,
  header nav:not(.landing)#headerMenu ul li button:hover.join,
  header nav:not(.landing)#headerMenu ul li button:focus.join {
    color: #E72264;
  }
  header nav:not(.landing)#headerMenu ul li a.active,
  header nav:not(.landing)#headerMenu ul li button.active {
    color: #651FFF;
  }
  header nav:not(.landing)#headerMenu ul li a.active:after,
  header nav:not(.landing)#headerMenu ul li button.active:after {
    opacity: 1;
    transform: none;
  }
  header nav:not(.landing)#headerMenu ul li a.active.shop,
  header nav:not(.landing)#headerMenu ul li button.active.shop {
    color: #12C700;
  }
  header nav:not(.landing)#headerMenu ul li a.active.join,
  header nav:not(.landing)#headerMenu ul li button.active.join {
    color: #E72264;
  }
}
header nav:not(.landing)#headerMenu ul li ul.sub {
  display: flex;
  margin: -5px;
}
header nav:not(.landing)#headerMenu ul li ul.sub li {
  margin: 5px;
}
header nav:not(.landing)#headerMenu ul.meta {
  position: relative;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
header nav:not(.landing)#headerMenu ul.meta:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: calc(100% - 50px);
  height: 1px;
  background: #FFFFFF;
}
@media (min-width: 768px) {
  header nav:not(.landing)#headerMenu ul.meta {
    margin-top: -50px;
    position: absolute;
    right: 0;
  }
  header nav:not(.landing)#headerMenu ul.meta:before {
    display: none;
  }
}
@media (min-width: 1024px) {
  header nav:not(.landing)#headerMenu ul.meta {
    margin-top: 0;
  }
}
header nav:not(.landing)#headerMenu ul.meta form {
  margin: 0;
}
header nav:not(.landing)#headerMenu ul.meta form a {
  display: inline-block;
  text-decoration: none;
}
header nav:not(.landing)#headerMenu ul.meta form a .icon {
  max-height: 50px;
  max-width: 50px;
  border-radius: 50%;
  vertical-align: middle;
}
header nav:not(.landing)#headerMenu ul.meta li a {
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
}
header nav:not(.landing)#headerMenu ul.meta li a:after {
  display: none;
}
@media (min-width: 768px) {
  header nav:not(.landing)#headerMenu ul.main li a {
    padding: 6px 15px;
  }
}

header.mobileMenuActive nav#headerMenu {
  pointer-events: all;
  transform: none;
  opacity: 1;
  clip-path: circle(131vmax at calc(100% - 50px) 50px);
}
.ie header.mobileMenuActive nav#headerMenu {
  opacity: 1;
}
header.mobileMenuActive nav#headerMenu ul li a svg.sprite use {
  fill: #FFFFFF;
}

header .menuButton {
  display: inline-flex;
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: 10px;
}
header .menuButton > div {
  position: absolute;
  width: 24px;
  height: 2px;
  background: #232320;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: opacity, transform, background;
  border-radius: 4px;
}
header .menuButton > div.ham {
  transform: translateY(-10px);
  transition-delay: 240ms;
}
header .menuButton > div.bur {
  transition-delay: 200ms;
}
header .menuButton > div.ger {
  transform: translateY(10px);
  transition-delay: 280ms;
}

header.mobileMenuActive .menuButton > div {
  background: #FFFFFF;
}
header.mobileMenuActive .menuButton > div.ham {
  transform: rotate(225deg);
  transition-delay: 240ms;
}
header.mobileMenuActive .menuButton > div.bur {
  opacity: 0;
  transform: translateX(5px) scaleY(0);
  transition-delay: 0;
}
header.mobileMenuActive .menuButton > div.ger {
  transform: rotate(135deg);
  transition-delay: 280ms;
}

header nav#metaMenu {
  margin-left: auto;
}
@media (min-width: 768px) {
  header nav#metaMenu {
    top: -50px;
    position: relative;
  }
}
@media (min-width: 1024px) {
  header nav#metaMenu {
    top: 0;
  }
}
header nav#metaMenu ul {
  display: inline-flex;
}
header nav#metaMenu ul li a {
  display: inline-flex;
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: 10px;
}
header nav#metaMenu ul li a svg use {
  fill: #232320;
  transition: fill 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-delay: 200ms;
}
header nav#metaMenu ul li:first-child a {
  margin-left: 0;
}
header nav#metaMenu ul li .headerCart {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
header nav#metaMenu ul li .headerCart span {
  position: absolute;
  transform: translateY(3px);
  color: #232320;
}
header nav#metaMenu ul li .headerCart.hasItems span {
  color: #FFFFFF;
  transform: translateY(3px) translateX(-1px);
}

header.mobileMenuActive nav#metaMenu ul li a svg use {
  fill: #FFFFFF;
}
@media (min-width: 768px) {
  header.mobileMenuActive nav#metaMenu ul li a svg use {
    fill: #232320;
  }
}

aside {
  position: relative;
}
aside label[for=input_showMenuMobile] {
  display: block;
  padding: 12px 45px 12px 16px;
  color: #232320;
  text-decoration: none;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E1E1D2;
  border-bottom-color: #E1E1D2;
  border-radius: 24px;
  cursor: pointer;
}
@media (min-width: 768px) {
  aside label[for=input_showMenuMobile] {
    display: none;
  }
}
aside label[for=input_showMenuMobile]:after {
  content: "▼";
  display: inline-block;
  position: absolute;
  right: 24px;
  color: #83847B;
  transform: scale(0.6) translateY(2px);
}
aside input#input_showMenuMobile {
  display: none;
}
aside input#input_showMenuMobile:checked ~ label[for=input_showMenuMobile] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: transparent;
}
aside .sideMenu {
  display: inline-flex;
  flex-direction: column;
  transform-origin: top;
  transform: scaleY(0.9);
  pointer-events: none;
  opacity: 0;
  border: 1px solid #E1E1D2;
  border-top: none;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: transform, opacity;
  background: #FFFFFF;
}
@media (min-width: 768px) {
  aside .sideMenu {
    transform: none;
    pointer-events: all;
    opacity: 1;
    position: static;
    width: auto;
    border: none;
    border-radius: 0;
    background: transparent;
  }
}
aside .sideMenu li {
  display: inline-block;
}
@media (min-width: 768px) {
  aside .sideMenu li {
    margin-bottom: 16px;
  }
  aside .sideMenu li:last-child {
    margin-bottom: 0;
  }
}
aside .sideMenu li a {
  display: inline-block;
  padding: 12px 45px 12px 16px;
  border: none;
  color: #232320;
  text-decoration: none;
  width: 100%;
  background: #FFFFFF;
  transition: border-color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
aside .sideMenu li a.active {
  background: #F8F8F4;
}
aside .sideMenu li a:hover, aside .sideMenu li a:focus {
  border-color: #83847B;
  color: #232320;
}
@media (min-width: 768px) {
  aside .sideMenu li a {
    border: 1px solid #E1E1D2;
    border-radius: 50px;
  }
}
aside input#input_showMenuMobile:checked ~ .sideMenu {
  pointer-events: all;
  transform: none;
  opacity: 1;
}

section#hero {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
  margin-left: -15px;
  margin-right: -15px;
}
section#hero > .container {
  padding-left: 25px;
  padding-right: 25px;
}
@media (min-width: 768px) {
  section#hero {
    margin-left: -75px;
    margin-right: -75px;
    padding-top: 75px;
    padding-bottom: 90px;
  }
}
@media (min-width: 1024px) {
  section#hero {
    margin-left: -50px;
    margin-right: -50px;
    padding-bottom: 75px;
  }
}
@media (min-width: 1300px) {
  section#hero {
    padding-top: 180px;
    padding-bottom: 40px;
  }
}
section#hero .background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
section#hero .background .layer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
section#hero .background .layer.back {
  background: #83847B;
}
section#hero .background .layer.front {
  background: url("/img/bg_hero_shapes.svg");
  background-attachment: fixed;
  background-size: 200%;
  opacity: 0;
  top: -10%;
  left: -10%;
  height: 120%;
  width: 120%;
}
@media (min-width: 768px) {
  section#hero .background .layer.front {
    background-size: 150%;
  }
}
@media (min-width: 1024px) {
  section#hero .background .layer.front {
    background-size: 100%;
  }
}
.ie section#hero .background .layer.front {
  background-attachment: scroll;
}
section#hero .background:before, section#hero .background:after {
  display: block;
  z-index: 1;
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  background-size: 100% 100%;
}
section#hero .background:before {
  background-image: url("/img/hero_curve_1.svg");
  top: -1.5px;
  height: 143px;
}
section#hero .background:after {
  background-image: url("/img/hero_curve_2.svg");
  bottom: -1.5px;
  height: 100px;
  z-index: 0;
}
section#hero .background.reverse:before {
  background-image: url("/img/hero_curve_2.svg");
  top: 0;
  height: 100px;
  transform: rotate(180deg) scaleX(-1);
}
section#hero .background.reverse:after {
  background-image: url("/img/hero_curve_1.svg");
  bottom: 0;
  height: 134px;
  transform: rotate(180deg) scaleX(-1);
}
section#hero .background.reverseX:before {
  transform: scaleX(-1);
}
section#hero .background.reverseX:after {
  transform: scaleX(-1);
}

#breadcrumb {
  padding: 10px 0;
}
#breadcrumb ul {
  display: inline-block;
}
#breadcrumb ul li {
  list-style: none;
  display: inline-block;
}
#breadcrumb ul li:not(:last-child):after {
  content: ">";
}
#breadcrumb ul li a {
  text-decoration: none;
}

form {
  margin: 50px 0;
}
form *::placeholder {
  color: rgba(0, 0, 0, 0.7);
}
form *:placeholder {
  color: rgba(0, 0, 0, 0.7);
}
form input {
  width: 100%;
}
form input[type=button], form input[type=checkbox], form input[type=image], form input[type=radio], form input[type=reset], form input[type=submit] {
  width: auto;
}
form textarea,
form select {
  width: 100%;
}
form input[type=text],
form input[type=number],
form input[type=password],
form input[type=file],
form textarea {
  border: 1px solid #9b9b9b;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 5px 20px;
  font-size: 16px;
  line-height: 30px;
  font-family: "Public Sans", sans-serif;
  transition: border-color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
form input[type=text]:focus,
form input[type=number]:focus,
form input[type=password]:focus,
form input[type=file]:focus,
form textarea:focus {
  border-color: #000;
}
form button,
form input[type=submit] {
  border: none;
  background: #bbbbbb;
}
form.searchForm {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: stretch;
}
form.searchForm label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #bbbbbb;
}
form.searchForm input {
  flex-grow: 1;
}
form .inputRow {
  width: 100%;
  margin-bottom: 10px;
}
form .inputRow:last-child {
  margin-bottom: 0;
}
form .inputRow label {
  display: inline-block;
  text-align: left;
  margin-bottom: 0;
  padding-left: 10px;
  font-size: 18px;
  margin-bottom: -5px;
  transform: translateY(31px);
  cursor: text;
  transform-origin: left;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: color, transform;
}
form .inputRow label.entered, form .inputRow label.active {
  transform: scale(0.9);
  color: #12C700;
  cursor: default;
}
form .inputRow input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #E1E1D2;
  padding-left: 10px;
  font-size: 18px;
  background: transparent;
  color: #707070;
}
form .inputRow .field-validation-valid {
  display: none;
}
form .inputRow .field-validation-error {
  display: block;
  padding: 10px;
  color: #E72264;
  font-size: 0.9em;
}
form .validation-summary-errors ul li {
  color: #E72264;
  padding: 10px;
  font-size: 0.9em;
}
form .simpleRow {
  margin-bottom: 20px;
  position: relative;
}
form .simpleRow input[type=checkbox],
form .simpleRow input[type=radio] {
  display: none;
}
form .simpleRow input[type=checkbox] + label,
form .simpleRow input[type=checkbox] + input[type=hidden] + label,
form .simpleRow input[type=radio] + label,
form .simpleRow input[type=radio] + input[type=hidden] + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: block;
  line-height: 24px;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
form .simpleRow input[type=checkbox] + label:before, form .simpleRow input[type=checkbox] + label:after,
form .simpleRow input[type=checkbox] + input[type=hidden] + label:before,
form .simpleRow input[type=checkbox] + input[type=hidden] + label:after,
form .simpleRow input[type=radio] + label:before,
form .simpleRow input[type=radio] + label:after,
form .simpleRow input[type=radio] + input[type=hidden] + label:before,
form .simpleRow input[type=radio] + input[type=hidden] + label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border-radius: 5px;
}
form .simpleRow input[type=checkbox] + label:before,
form .simpleRow input[type=checkbox] + input[type=hidden] + label:before,
form .simpleRow input[type=radio] + label:before,
form .simpleRow input[type=radio] + input[type=hidden] + label:before {
  opacity: 0;
  background-image: url("/img/icon_check_green.svg");
  background-position: center 30%;
  background-repeat: no-repeat;
  transform: scale(0.4);
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: opacity, transform;
}
form .simpleRow input[type=checkbox] + label:after,
form .simpleRow input[type=checkbox] + input[type=hidden] + label:after,
form .simpleRow input[type=radio] + label:after,
form .simpleRow input[type=radio] + input[type=hidden] + label:after {
  border: 1px solid #83847B;
  transition: border-color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
form .simpleRow input[type=checkbox] + label:hover:after, form .simpleRow input[type=checkbox] + label:focus:after,
form .simpleRow input[type=checkbox] + input[type=hidden] + label:hover:after,
form .simpleRow input[type=checkbox] + input[type=hidden] + label:focus:after,
form .simpleRow input[type=radio] + label:hover:after,
form .simpleRow input[type=radio] + label:focus:after,
form .simpleRow input[type=radio] + input[type=hidden] + label:hover:after,
form .simpleRow input[type=radio] + input[type=hidden] + label:focus:after {
  border-color: #232320;
}
form .simpleRow input[type=checkbox]:checked + label,
form .simpleRow input[type=checkbox]:checked + input[type=hidden] + label,
form .simpleRow input[type=radio]:checked + label,
form .simpleRow input[type=radio]:checked + input[type=hidden] + label {
  color: #12C700;
}
form .simpleRow input[type=checkbox]:checked + label:before,
form .simpleRow input[type=checkbox]:checked + input[type=hidden] + label:before,
form .simpleRow input[type=radio]:checked + label:before,
form .simpleRow input[type=radio]:checked + input[type=hidden] + label:before {
  opacity: 1;
  transform: none;
}
form .simpleRow input[type=checkbox]:checked + label:after,
form .simpleRow input[type=checkbox]:checked + input[type=hidden] + label:after,
form .simpleRow input[type=radio]:checked + label:after,
form .simpleRow input[type=radio]:checked + input[type=hidden] + label:after {
  border-color: #12C700;
}
form .simpleRow input[type=radio] + label:before, form .simpleRow input[type=radio] + label:after,
form .simpleRow input[type=radio] + input[type=hidden] + label:before,
form .simpleRow input[type=radio] + input[type=hidden] + label:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.orColumn {
  display: flex;
  align-items: center;
  position: relative;
}
.orColumn:before {
  content: "";
  display: block;
  z-index: -1;
  position: absolute;
  background: #E1E1D2;
  top: 50%;
  width: 100%;
  left: 0;
  height: 1px;
}
@media (min-width: 768px) {
  .orColumn:before {
    left: 50%;
    height: 100%;
    width: 1px;
    top: 0;
  }
}
.orColumn span {
  display: inline-block;
  text-align: center;
  background: #FFFFFF;
  padding: 5px 8px 7px 8px;
  margin: 0 auto;
}

.select2.select2-container {
  height: 40px;
  width: 100% !important;
}
.select2.select2-container .select2-selection--single {
  height: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #E1E1D2;
}
.select2.select2-container .select2-selection--single span {
  height: 100%;
  line-height: 40px;
}
.select2.select2-container .select2-selection--multiple {
  border: 1px solid #E1E1D2;
}
.select2.select2-container .select2-selection--multiple .select2-selection__choice {
  height: 32px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background-color: #F8F8F4;
  border: 1px solid #E1E1D2;
  font-size: 16px;
  margin-bottom: 3px;
}
.select2.select2-container .select2-selection--multiple .select2-selection__choice span {
  margin-left: 5px;
}
.select2.select2-container--focus .select2-selection--single, .select2.select2-container--open .select2-selection--single, .select2:active .select2-selection--single {
  border-bottom-color: #707070;
}

.select2-dropdown {
  border-radius: 16px;
  overflow: hidden;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: none;
  border-bottom: 1px solid #E1E1D2;
  line-height: 30px;
}

.select2-results__option {
  line-height: 30px;
  padding: 5px 6px;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #E1E1D2;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: #FFFFFF;
  background-color: #651FFF;
}

a.button,
button.button {
  display: inline-flex;
  padding: 10px 30px;
  flex-direction: row;
  border-radius: 50px;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  text-decoration: none;
  border: none;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: background, color;
}
a.button.narrow,
button.button.narrow {
  padding: 5px 30px;
}
a.button.narrow span,
button.button.narrow span {
  font-weight: 300;
}
a.button span,
button.button span {
  font-size: 22px;
  line-height: 28px;
  font-family: "Public Sans", sans-serif;
  font-weight: 700;
}
a.button svg use,
button.button svg use {
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: fill, stroke;
}
a.button > *:not(:last-child),
button.button > *:not(:last-child) {
  margin-right: 20px;
}
a.button.yellow-blue,
button.button.yellow-blue {
  background: #FFC400;
  color: #651FFF;
}
a.button.yellow-blue svg use,
button.button.yellow-blue svg use {
  fill: #651FFF;
}
a.button.yellow-blue:hover, a.button.yellow-blue:focus,
button.button.yellow-blue:hover,
button.button.yellow-blue:focus {
  background: #651FFF;
  color: #FFC400;
}
a.button.yellow-blue:hover svg use, a.button.yellow-blue:focus svg use,
button.button.yellow-blue:hover svg use,
button.button.yellow-blue:focus svg use {
  fill: #FFC400;
}
a.button.blue-white,
button.button.blue-white {
  background: #651FFF;
  color: #FFFFFF;
}
a.button.blue-white svg use,
button.button.blue-white svg use {
  fill: #FFFFFF;
}
a.button.blue-white:hover, a.button.blue-white:focus,
button.button.blue-white:hover,
button.button.blue-white:focus {
  background: #efefef;
  color: #651FFF;
}
a.button.blue-white:hover svg use, a.button.blue-white:focus svg use,
button.button.blue-white:hover svg use,
button.button.blue-white:focus svg use {
  fill: #651FFF;
}
a.button.white-red,
button.button.white-red {
  background: #FFFFFF;
  color: #E72264;
}
a.button.white-red svg use,
button.button.white-red svg use {
  fill: #E72264;
}
a.button.white-red:hover, a.button.white-red:focus,
button.button.white-red:hover,
button.button.white-red:focus {
  background: #E72264;
  color: #FFFFFF;
}
a.button.white-red:hover svg use, a.button.white-red:focus svg use,
button.button.white-red:hover svg use,
button.button.white-red:focus svg use {
  fill: #FFFFFF;
}
a.button.red-white,
button.button.red-white {
  background: #E72264;
  color: #FFFFFF;
}
a.button.red-white svg use,
button.button.red-white svg use {
  fill: #232320;
}
a.button.red-white:hover, a.button.red-white:focus,
button.button.red-white:hover,
button.button.red-white:focus {
  background: #E1E1D2;
  color: #E72264;
}
a.button.red-white:hover svg use, a.button.red-white:focus svg use,
button.button.red-white:hover svg use,
button.button.red-white:focus svg use {
  fill: #FFFFFF;
}
a.button.grey-black,
button.button.grey-black {
  background: #E1E1D2;
  color: #232320;
}
a.button.grey-black svg use,
button.button.grey-black svg use {
  fill: #232320;
}
a.button.grey-black:hover, a.button.grey-black:focus,
button.button.grey-black:hover,
button.button.grey-black:focus {
  background: #232320;
  color: #E1E1D2;
}
a.button.grey-black:hover svg use, a.button.grey-black:focus svg use,
button.button.grey-black:hover svg use,
button.button.grey-black:focus svg use {
  fill: #E1E1D2;
}
a.button.clear,
button.button.clear {
  border: 1px solid #E1E1D2;
  background: #FFFFFF;
}
a.button.clear.buybutton:hover span,
button.button.clear.buybutton:hover span {
  color: #AA00FF;
}
a.button.green,
button.button.green {
  background: #12C700;
  color: #FFFFFF;
}
a.button.green:hover, a.button.green:focus,
button.button.green:hover,
button.button.green:focus {
  background: #E1E1D2;
  color: #12C700;
}
a.button.purple,
button.button.purple {
  background: #AA00FF;
  color: #FFFFFF;
}
a.button.purple:hover, a.button.purple:focus,
button.button.purple:hover,
button.button.purple:focus {
  background: #E1E1D2;
  color: #AA00FF;
}

#hero a.button.clear,
#hero button.button.clear {
  background: transparent;
}

div.firebaseEnabled {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
div.firebaseEnabled span.field-validation-error {
  color: #E72264;
  margin-top: 5px;
  display: none;
}
div.firebaseEnabled span.field-validation-error.visible {
  display: block;
}

a.button-google {
  border-radius: 2px;
  background-color: #4285f4;
  border: 1px solid #4285f4;
  text-decoration: none;
  display: flex;
  align-items: center;
  box-shadow: 0 0 5px -5px #4285f4;
  width: 240px;
  transition: box-shadow 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
a.button-google:hover, a.button-google:focus {
  box-shadow: 0 0 5px -1px #4285f4;
}
a.button-google svg {
  width: 46px;
  height: 46px;
  background: #FFFFFF;
}
a.button-google span {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin: 0 auto;
}

.academy-button {
  margin-top: 70px;
}

.blob {
  border-radius: 33% 67% 70% 30%/30% 30% 70% 70%;
  overflow: hidden;
}
.blob img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blob[class*="1"] {
  border-radius: 31% 69% 56% 44%/57% 58% 42% 43%;
}

section#content article {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  /* border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 20px; */
}
section#content article .image {
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 20px;
  max-width: 100%;
  text-align: center;
  position: relative;
}
@media (min-width: 768px) {
  section#content article .image {
    margin-bottom: 5px;
    width: 100%;
  }
}
section#content article .image img {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  border: 1px solid #E1E1D2;
}
@media (min-width: 768px) {
  section#content article .image img {
    width: 214px;
    height: 214px;
  }
}
@media (min-width: 1300px) {
  section#content article .image img {
    width: 281px;
    height: 281px;
  }
}
section#content article .image .discount {
  position: absolute;
  bottom: 0;
  right: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 67px;
  height: 67px;
  border-radius: 50%;
  font-size: 18px;
  color: #FFFFFF;
  background: #E72264;
  font-weight: 300;
}
@media (min-width: 768px) {
  section#content article .image .discount {
    right: 0;
  }
}
section#content article .contents {
  display: flex;
  flex-direction: column;
  font-weight: 300;
}
section#content article h2 {
  margin-bottom: 20px;
  display: inline-block;
}
section#content article .title {
  font-size: 20px;
  margin-bottom: 5px;
  display: inline-block;
  font-weight: 600;
}
@media (min-width: 768px) {
  section#content article .title {
    font-weight: 700;
  }
}
section#content article .subtitle {
  font-style: normal !important;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 5px;
  display: inline-block;
  color: #83847B !important;
}
@media (min-width: 768px) {
  section#content article .subtitle {
    font-size: 16px;
  }
}
@media (min-width: 1024px) {
  section#content article .subtitle {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (min-width: 1300px) {
  section#content article .subtitle {
    font-size: 18px;
    line-height: 24px;
  }
}
section#content article .title + .subtitle {
  margin-bottom: 25px;
}
section#content article > a, section#content article .contents > a {
  display: inline-block;
  margin-bottom: 20px;
  text-decoration: none;
  color: inherit;
}
section#content article > a h2, section#content article .contents > a h2 {
  margin-bottom: 0;
}
section#content article > a.cart, section#content article .contents > a.cart {
  text-decoration: underline;
  color: #12C700;
  text-decoration: none;
}
section#content article > a.cart span, section#content article .contents > a.cart span {
  position: relative;
}
section#content article > a.cart span:after, section#content article .contents > a.cart span:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  pointer-events: none;
  background: #12C700;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
section#content article > a.cart svg, section#content article .contents > a.cart svg {
  margin-left: 5px;
}
section#content article > a.cart svg use, section#content article .contents > a.cart svg use {
  fill: #12C700;
}
section#content article > a.cart:hover span:after, section#content article > a.cart:focus span:after, section#content article .contents > a.cart:hover span:after, section#content article .contents > a.cart:focus span:after {
  transform: scale(0);
}
section#content article address {
  position: relative;
  top: -15px;
}
section#content article .price {
  margin-bottom: 5px;
  display: inline-block;
}
section#content article .price .current {
  font-size: 18px;
  color: #707070;
  display: inline-block;
}
section#content article .price .original {
  font-size: 14px;
  color: #E72264;
  text-decoration: line-through;
  display: inline-block;
}
section#content article .content p:first-child {
  margin-top: 0;
}
section#content article .actions a {
  text-decoration: none;
  font-weight: bold;
  color: #555555;
}
section#content article > *:last-child {
  margin-bottom: 0;
}

.list article, .pagedList article {
  height: 100%;
  /* .actions {
      margin-top: auto;
  } */
}
.list article .content, .pagedList article .content {
  margin-top: auto;
}

.journal {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media (min-width: 375px) {
  .journal {
    display: block;
  }
}
.journal .image {
  height: 86px;
  margin-bottom: 10px;
  margin-right: 15px;
  flex: 0 0 86px;
}
@media (min-width: 375px) {
  .journal .image {
    height: 175px;
    margin-right: 0;
  }
}
.journal .image a {
  text-decoration: none;
}
.journal .image img {
  border-radius: 100px;
  height: 86px;
  width: 86px;
}
@media (min-width: 375px) {
  .journal .image img {
    height: 175px;
    width: 175px;
  }
}
.journal .image .icon {
  height: 33px;
  width: 33px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 43px;
  left: 43px;
  background-color: #E72264;
  border-radius: 50%;
  opacity: 0.75;
  transform: translate(-50%, -50%);
}
.journal .image .icon svg.sprite {
  width: 25px;
  max-height: 25px;
}
@media (min-width: 375px) {
  .journal .image .icon {
    height: 67px;
    width: 67px;
    top: 87.5px;
    left: 87.5px;
  }
  .journal .image .icon svg.sprite {
    width: 50px;
    max-height: 50px;
  }
}
.journal .contents .author {
  font-size: 16px;
  color: #83847B;
  display: block;
  margin-bottom: 5px;
}
.journal .contents .title, .journal .contents > a .title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 800;
  display: block;
  margin-bottom: 10px;
}
.journal .contents > a {
  text-decoration: none;
}
.journal .contents > a .title {
  color: #232320;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.journal .contents > a:hover .title, .journal .contents > a:focus .title {
  color: #AA00FF;
}
.journal .contents .info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.journal .contents .info .journalLikes {
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
}
.journal .contents .info .journalLikes svg.sprite {
  width: 19px;
  max-height: 16px;
  margin-right: 8px;
}
.journal .contents .info .journalLikes svg.sprite use {
  stroke: #12C700;
  fill: #12C700;
}
.journal .contents .info .journalLikes span {
  font-size: 16px;
}
.journal .contents .info address {
  font-size: 16px;
  opacity: 1;
  color: #83847B;
  font-weight: 400;
}

.striber {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media (min-width: 375px) {
  .striber {
    display: block;
  }
}
.striber .image {
  margin-bottom: 10px;
  margin-right: 15px;
  flex: 0 0 86px;
  max-width: 86px;
}
@media (min-width: 375px) {
  .striber .image {
    max-width: 175px;
    margin-right: 0;
  }
}
.striber .image a {
  text-decoration: none;
  display: block;
  position: relative;
  height: 0;
  padding-bottom: 100%;
}
.striber .image img {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.striber .image .icon {
  height: 33px;
  width: 33px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 43px;
  left: 43px;
  background-color: #E72264;
  border-radius: 50%;
  opacity: 0.75;
  transform: translate(-50%, -50%);
}
.striber .image .icon svg.sprite {
  width: 25px;
  max-height: 25px;
}
@media (min-width: 375px) {
  .striber .image .icon {
    height: 67px;
    width: 67px;
    top: 87.5px;
    left: 87.5px;
  }
  .striber .image .icon svg.sprite {
    width: 50px;
    max-height: 50px;
  }
}
.striber .contents .author {
  font-size: 16px;
  color: #83847B;
  display: block;
  margin-bottom: 5px;
}
.striber .contents .title, .striber .contents > a .title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 800;
  display: block;
  margin-bottom: 10px;
}
.striber .contents > a {
  text-decoration: none;
}
.striber .contents > a .title {
  color: #232320;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.striber .contents > a:hover .title, .striber .contents > a:focus .title {
  color: #AA00FF;
}
.striber .contents .info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.striber .contents .info .journalLikes {
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
}
.striber .contents .info .journalLikes svg.sprite {
  width: 19px;
  max-height: 16px;
  margin-right: 8px;
}
.striber .contents .info .journalLikes svg.sprite use {
  stroke: #12C700;
  fill: #12C700;
}
.striber .contents .info .journalLikes span {
  font-size: 16px;
}
.striber .contents .info address {
  font-size: 16px;
  opacity: 1;
  color: #83847B;
  font-weight: 400;
}

.project {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media (min-width: 375px) {
  .project {
    display: block;
  }
}
.project .image {
  height: 86px;
  margin-bottom: 10px;
  margin-right: 15px;
  flex: 0 0 86px;
}
@media (min-width: 375px) {
  .project .image {
    height: 175px;
    margin-right: 0;
  }
}
.project .image a {
  text-decoration: none;
}
.project .image img {
  border-radius: 100px;
  height: 86px;
  width: 86px;
}
@media (min-width: 375px) {
  .project .image img {
    height: 175px;
    width: 175px;
  }
}
.project .contents .author {
  font-size: 16px;
  color: #83847B;
  display: block;
  margin-bottom: 5px;
}
.project .contents .title, .project .contents > a .title {
  font-size: 24px;
  line-height: 24px;
  font-weight: normal;
  display: block;
  margin-bottom: 10px;
}
.project .contents > a {
  text-decoration: none;
}
.project .contents > a .title {
  color: #232320;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.project .contents > a .link {
  color: #12C700;
  text-decoration: underline;
}
.project .contents > a:hover .title, .project .contents > a:focus .title {
  color: #AA00FF;
}
.project .contents > a:hover .link, .project .contents > a:focus .link {
  color: #1DE9B6;
}
.project .contents .info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.course {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media (min-width: 375px) {
  .course {
    display: block;
  }
}
.course .image {
  height: 86px;
  margin-bottom: 10px;
  margin-right: 15px;
  flex: 0 0 86px;
}
@media (min-width: 375px) {
  .course .image {
    height: 175px;
    margin-right: 0;
  }
}
.course .image a {
  text-decoration: none;
}
.course .image img {
  border-radius: 100px;
  height: 86px;
  width: 86px;
}
@media (min-width: 375px) {
  .course .image img {
    height: 175px;
    width: 175px;
  }
}
.course .contents .author {
  font-size: 16px;
  color: #83847B;
  display: block;
  margin-bottom: 5px;
}
.course .contents .title, .course .contents > a .title {
  font-size: 24px;
  line-height: 24px;
  font-weight: normal;
  display: block;
  margin-bottom: 10px;
}
.course .contents .price {
  margin-bottom: 5px;
  display: inline-block;
}
.course .contents .price .current {
  font-size: 18px;
  color: #707070;
}
.course .contents .price .original {
  font-size: 14px;
  color: #E72264;
  text-decoration: line-through;
}
.course .contents > a {
  text-decoration: none;
}
.course .contents > a .title {
  color: #232320;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.course .contents > a .link {
  color: #12C700;
  text-decoration: underline;
}
.course .contents > a:hover .title, .course .contents > a:focus .title {
  color: #AA00FF;
}
.course .contents > a:hover .link, .course .contents > a:focus .link {
  color: #1DE9B6;
}
.course .contents .info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

ul.pagedList, ol.pagedList {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -20px;
  margin-bottom: 20px;
}
ul.pagedList li, ol.pagedList li {
  flex: 0 0 auto;
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  margin-left: 0;
}
ul.pagedList li:before, ol.pagedList li:before {
  display: none;
}
@media (min-width: 375px) {
  ul.pagedList:not(.fullWidth) li, ol.pagedList:not(.fullWidth) li {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  ul.pagedList:not(.fullWidth) li, ol.pagedList:not(.fullWidth) li {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
}
nav.pager {
  width: 100%;
}
nav.pager ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  justify-content: center;
}
nav.pager ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav.pager ul li a {
  display: inline-block;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 5px 10px;
  text-decoration: none;
  font-weight: bold;
  color: #555555;
  margin: 0 2.5px;
}
nav.pager ul li a:not([href]) {
  color: #868686;
}

.popup {
  position: fixed;
  top: 0;
  height: 100vh;
  height: var(--fullHeight);
  left: 0;
  width: 100%;
  background: #FFFFFF;
  z-index: 100;
  padding: 32px 35px 50px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  overflow: hidden;
  transform: scale(0);
  opacity: 0;
  transition: transform 600ms cubic-bezier(0.05, 0.93, 0.53, 0.9), opacity 0ms linear 600ms, clip-path 1s cubic-bezier(0.05, 0.93, 0.53, 0.9);
  clip-path: circle(0 at 50% 50%);
}
.ie .popup {
  transition: transform 600ms cubic-bezier(0.05, 0.93, 0.53, 0.9), opacity 600ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  .popup {
    top: 30px;
    height: 100vh;
    height: calc(100vh - 60px);
    height: var(--fullHeight);
    height: calc(var(--fullHeight) - 60px);
    left: 30px;
    width: calc(100% - 60px);
    padding: 45px 70px 70px;
  }
}
.popup.active {
  transform: none;
  opacity: 1;
  pointer-events: all;
  transition: transform 600ms cubic-bezier(0.61, 0.1, 0.38, 0.97), opacity 0ms linear 0ms, clip-path 1s cubic-bezier(0.61, 0.1, 0.38, 0.97);
  clip-path: circle(110% at 50% 50%);
}
.ie .popup.active {
  transition: transform 600ms cubic-bezier(0.05, 0.93, 0.53, 0.9), opacity 600ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.popup .header {
  min-height: 45px;
  margin-bottom: 70px;
  width: 100%;
}
@media (min-width: 768px) {
  .popup .header {
    min-height: 54px;
  }
}
.popup .header .container {
  height: 100%;
}
.popup .header .container .row {
  height: 100%;
}
.popup .header .container .row > * {
  height: 100%;
  display: inline-flex;
  align-content: center;
}
.popup .header nav {
  height: 100%;
}
.popup .header nav ul {
  display: inline-flex;
  flex-wrap: wrap;
  height: 100%;
}
.popup .header nav ul li {
  display: inline-block;
  height: 45px;
}
@media (min-width: 768px) {
  .popup .header nav ul li {
    height: 54px;
  }
}
.popup .header nav ul li:not(:last-child) {
  margin-right: 8px;
}
@media (min-width: 768px) {
  .popup .header nav ul li:not(:last-child) {
    margin-right: 24px;
  }
}
.popup .header nav ul li button {
  height: 100%;
  background: transparent;
  color: #AA00FF;
  border-radius: 54px;
  padding: 0 25px 0 24px;
  border: none;
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  font-family: "Public Sans", sans-serif;
  height: 45px;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: color, background;
}
@media (min-width: 768px) {
  .popup .header nav ul li button {
    font-size: 24px;
    line-height: 32px;
    padding: 0 70px 0 24px;
    height: 54px;
  }
}
.popup .header nav ul li:nth-child(1) button {
  color: #651FFF;
}
.popup .header nav ul li:nth-child(1) button[aria-selected=true] {
  background: #651FFF;
  color: #FFFFFF;
}
.popup .header nav ul li:nth-child(2) button {
  color: #AA00FF;
}
.popup .header nav ul li:nth-child(2) button[aria-selected=true] {
  background: #AA00FF;
  color: #FFFFFF;
}
.popup .header nav ul li:nth-child(3) button {
  color: #E72264;
}
.popup .header nav ul li:nth-child(3) button[aria-selected=true] {
  background: #E72264;
  color: #FFFFFF;
}
.popup .header nav ul li:nth-child(4) button {
  color: #12C700;
}
.popup .header nav ul li:nth-child(4) button[aria-selected=true] {
  background: #12C700;
  color: #FFFFFF;
}
.popup .header nav ul li:nth-child(5) button {
  color: #FFC400;
}
.popup .header nav ul li:nth-child(5) button[aria-selected=true] {
  background: #FFC400;
  color: #FFFFFF;
}
.popup .header a.close {
  cursor: pointer;
  margin-left: auto;
  transition: background 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  border-radius: 50%;
  position: absolute;
  top: 0;
  height: 56px;
  width: 56px;
  right: -5px;
}
@media (min-width: 768px) {
  .popup .header a.close {
    top: auto;
    right: 0;
  }
}
.popup .header a.close:hover, .popup .header a.close:focus {
  background: #E1E1D2;
}
.popup .content {
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}
.popup .content p {
  font-weight: 300;
}
.popup .content h2 {
  color: #651FFF;
  font-size: 48px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 100px;
}
@media (min-width: 768px) {
  .popup .content h2 {
    margin-bottom: 70px;
  }
}
.popup .content a {
  color: #651FFF;
}
@media (min-width: 768px) {
  .popup .content .cssColumns {
    columns: 2;
    column-gap: 20px;
  }
}
@media (min-width: 1024px) {
  .popup .content .cssColumns {
    columns: 2;
  }
}
.popup .content .cssColumns p {
  margin-top: 0;
}
.popup .content .imageColumn {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 70px;
}
.popup .content .imageColumn img {
  flex: 1 1 50%;
  width: 50%;
  max-width: 50%;
  padding: 10px;
}
.popup .content .imageColumn.single img {
  flex: 1 1 100%;
  width: 100%;
  max-width: 100%;
  border-radius: 50%;
  padding: 0;
}
.popup .content .tabContent:nth-child(1) h2 {
  color: #651FFF;
}
.popup .content .tabContent:nth-child(2) h2 {
  color: #AA00FF;
}
.popup .content .tabContent:nth-child(3) h2 {
  color: #E72264;
}
.popup .content .tabContent:nth-child(4) h2 {
  color: #12C700;
}
.popup .content .tabContent:nth-child(5) h2 {
  color: #FFC400;
}

ul.accordeon, ol.accordeon {
  border-top: 2px solid #E1E1D2;
}
ul.accordeon li, ol.accordeon li {
  border-bottom: 2px solid #E1E1D2;
  padding-top: 3px;
  padding-bottom: 3px;
}
ul.accordeon .header, ol.accordeon .header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
ul.accordeon .header .headerContent, ol.accordeon .header .headerContent {
  flex: 1 1 auto;
}
ul.accordeon .header .headerContent h3, ol.accordeon .header .headerContent h3 {
  font-size: 24px;
  line-height: 32px;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
ul.accordeon .header .headerCloseButton, ol.accordeon .header .headerCloseButton {
  flex: 0 0 auto;
}
ul.accordeon .header .headerCloseButton .close, ol.accordeon .header .headerCloseButton .close {
  position: relative;
  height: 45px;
  width: 45px;
  transform: translateX(10px);
}
ul.accordeon .header .headerCloseButton .close:before, ul.accordeon .header .headerCloseButton .close:after, ol.accordeon .header .headerCloseButton .close:before, ol.accordeon .header .headerCloseButton .close:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 17px;
  background: #232320;
  transform: translateX(-50%) translateY(-50%);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
ul.accordeon .header .headerCloseButton .close:after, ol.accordeon .header .headerCloseButton .close:after {
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transition-delay: 80ms;
}
ul.accordeon li.active .header .headerContent h3, ol.accordeon li.active .header .headerContent h3 {
  color: #E72264;
}
ul.accordeon li.active .header .headerCloseButton .close:before, ol.accordeon li.active .header .headerCloseButton .close:before {
  transform: translateX(-50%) translateY(-50%) scaleY(0);
}
ul.accordeon li.active .header .headerCloseButton .close:after, ol.accordeon li.active .header .headerCloseButton .close:after {
  transform: translateX(-50%) translateY(-50%) rotate(-90deg) scaleY(0.8);
}
ul.accordeon .content, ol.accordeon .content {
  overflow-y: hidden;
  font-size: 18px;
  line-height: 32px;
  transition: height 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
ul.accordeon .content p:first-child, ol.accordeon .content p:first-child {
  margin-top: 10px;
}
ul.accordeon .content p:last-child, ol.accordeon .content p:last-child {
  margin-bottom: 10px;
}
ul.accordeon .content .actions > a, ul.accordeon .content .actions > div, ul.accordeon .content .actions > span, ol.accordeon .content .actions > a, ol.accordeon .content .actions > div, ol.accordeon .content .actions > span {
  display: flex;
  align-items: center;
  color: #AA00FF;
  text-decoration: none;
}
ul.accordeon .content .actions > a svg.sprite, ul.accordeon .content .actions > div svg.sprite, ul.accordeon .content .actions > span svg.sprite, ol.accordeon .content .actions > a svg.sprite, ol.accordeon .content .actions > div svg.sprite, ol.accordeon .content .actions > span svg.sprite {
  flex: 0 0 auto;
  width: 20px;
  margin-right: 10px;
}

section#content ul.accordeon .header .headerContent h3, section#content ol.accordeon .header .headerContent h3 {
  margin-top: 0;
  margin-bottom: 0;
}
section#content .umb-grid ul.accordeon, section#content .umb-grid ol.accordeon {
  padding-left: 0;
  margin: 0;
}
section#content .umb-grid ul.accordeon li, section#content .umb-grid ol.accordeon li {
  margin: 0;
}
section#content .umb-grid ul.accordeon li:before, section#content .umb-grid ol.accordeon li:before {
  content: none;
}

.tabs .tabContents .tabContent {
  animation-direction: normal;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-name: appear;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
  display: none;
}
.tabs .tabContents .tabContent.active {
  display: block;
}
.tabs .tabContents .tabContent.right > div {
  animation-direction: normal;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-name: appearFromRight;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.tabs .tabContents .tabContent.left > div {
  animation-direction: normal;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-name: appearFromLeft;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}

.toast {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 60px;
  z-index: 1000;
  background: #c1c1bd;
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: toastAppear;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  animation-fill-mode: none;
}
.toast.disappearing {
  transform: translateY(-100%);
}
.toast.success {
  color: #0E9201;
  background: #D0F2CD;
}
.toast.success a {
  color: #0d6605;
}
.toast.error {
  color: #8E113B;
  background: #FCCDDD;
}
.toast.error svg.sprite use {
  fill: #8e113b;
}
.toast.error a {
  color: #640b28;
}
.toast.warning {
  color: #513e00;
  background: #FFC400;
}
.toast.warning svg.sprite use {
  fill: #513e00;
}
.toast.warning a {
  color: #42350a;
}
.toast .container {
  height: 100%;
}
.toast .container .row {
  height: 100%;
  align-items: center;
}
.toast .container .row .content {
  padding: 10px 0;
  display: inline-flex;
  align-items: center;
  min-height: 1px;
}
.toast .container .row .content svg.sprite {
  flex: 0 0 auto;
  margin-right: 10px;
}
.toast .container .row .content span {
  font-size: 18px;
  line-height: 21px;
}

.shareContainer {
  display: inline-block;
  display: inline-flex;
  align-items: center;
}
.shareContainer span {
  margin-right: 10px;
  font-size: 0.9em;
  display: none;
}
@media (min-width: 768px) {
  .shareContainer span {
    display: inline-block;
  }
}
.shareContainer a {
  margin-right: 16px;
}
.shareContainer a:last-child {
  margin-right: 0;
}
.shareContainer a svg.sprite use {
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: fill, stroke;
}
.shareContainer a:hover svg.sprite use, .shareContainer a:focus svg.sprite use {
  fill: #651FFF;
  stroke: #651FFF;
}

.pagedContainer li {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: appear;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.pagedContainer li.paged {
  display: none;
}

.pager {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #83847B;
}
.pager > a.next {
  display: none;
  color: #232320;
  padding: 12px 100px;
}
.pager > a.next:hover, .pager > a.next:focus {
  color: #651FFF;
}
@media (min-width: 768px) {
  .pager > a.next {
    display: inline-block;
  }
}
.pager .controlBlock {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .pager .controlBlock {
    position: absolute;
    right: 0;
  }
}
.pager .controls {
  display: inline-flex;
  align-items: center;
}
.pager .controls .previous, .pager .controls .next, .pager .controls .current {
  height: 48px;
  width: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.pager .controls .previous svg.sprite use, .pager .controls .next svg.sprite use, .pager .controls .current svg.sprite use {
  transition: stroke 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.pager .controls .previous, .pager .controls .next {
  background: transparent;
  border: none;
  transition: opacity 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.pager .controls .previous:hover svg.sprite use, .pager .controls .previous:focus svg.sprite use, .pager .controls .next:hover svg.sprite use, .pager .controls .next:focus svg.sprite use {
  stroke: #651FFF;
}
.pager .controls .current {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #E1E1D2;
  border-radius: 50%;
}
.pager .controls ul.pages {
  display: inline-flex;
  align-items: center;
}
.pager .controls ul.pages li {
  padding: 0 8px;
}
.pager .controls ul.pages li .page {
  height: 48px;
  width: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #E1E1D2;
  border-radius: 50%;
  cursor: pointer;
}
.pager .controls ul.pages li.active .page {
  color: #E72264;
}
.pager [disabled] {
  opacity: 0.4;
  pointer-events: none;
  user-select: none;
}

section.USPs h2 {
  font-size: 36px;
  line-height: 36px;
  font-weight: 500;
}
@media (min-width: 768px) {
  section.USPs h2 {
    text-align: center;
    font-size: 48px;
    line-height: 48px;
    font-weight: 800;
  }
}
section.USPs .USPRow .usp {
  margin-bottom: 40px;
}
section.USPs .USPRow .usp:last-child {
  margin-bottom: 0;
}
section.USPs .USPRow .usp h3 {
  font-size: 32px;
  line-height: 32px;
}
@media (min-width: 768px) {
  section.USPs .USPRow .usp h3 {
    text-align: right;
  }
}

section#content section.USPs h2 {
  margin-top: 0;
  margin-bottom: 40px;
  font-weight: 800;
}
@media (min-width: 768px) {
  section#content section.USPs h2 {
    margin-bottom: 45px;
  }
}
section#content section.USPs .USPRow {
  margin-bottom: 70px;
}
section#content section.USPs .USPRow .usp h3 {
  margin-top: 0;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  section#content section.USPs .USPRow .usp h3 {
    margin-bottom: 0;
  }
}
section#content section.USPs .USPRow .usp p {
  margin-top: 0;
  margin-bottom: 0;
}
section#content section.USPs .USPFooter span {
  display: block;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
  font-weight: 800;
}
@media (min-width: 768px) {
  section#content section.USPs .USPFooter span {
    text-align: right;
    margin-bottom: 0;
  }
}
section#content section.USPs .USPFooter a.button {
  font-size: 22px;
  font-weight: 600;
  padding-right: 60px;
}

section.quote {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
  margin-left: -15px;
  margin-right: -15px;
}
@media (min-width: 768px) {
  section.quote {
    margin-left: -75px;
    margin-right: -75px;
    padding-top: 100px;
    padding-bottom: 90px;
  }
}
@media (min-width: 1024px) {
  section.quote {
    margin-left: -50px;
    margin-right: -50px;
    padding-bottom: 75px;
  }
}
@media (min-width: 1300px) {
  section.quote {
    padding-top: 130px;
    padding-bottom: 100px;
  }
}
section.quote blockquote {
  text-align: left;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
}
section.quote span.quotee {
  color: #651FFF;
  padding-left: 15px;
  padding-right: 15px;
}
section.quote .background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
section.quote .background .layer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #FFC400;
}
section.quote .background:before, section.quote .background:after {
  display: block;
  z-index: 1;
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  background-size: 100% 100%;
}
section.quote .background:before {
  background-image: url("/img/hero_curve_1.svg");
  top: 0;
  transform: scaleX(-1);
  height: 40px;
}
@media (min-width: 768px) {
  section.quote .background:before {
    height: 60px;
  }
}
section.quote .background:after {
  background-image: url("/img/hero_curve_1.svg");
  bottom: 0;
  height: 50px;
  z-index: 0;
  transform: rotate(180deg) scaleX(-1);
}
@media (min-width: 768px) {
  section.quote .background:after {
    height: 80px;
  }
}

.sliderControls {
  display: flex;
  justify-content: center;
}
.sliderControls .prev button, .sliderControls .next button {
  background: none;
  border: none;
  height: 48px;
  width: 48px;
}
.sliderControls ul.slick-dots {
  display: inline-flex;
  align-items: center;
}
.sliderControls ul.slick-dots li {
  margin: 0 8px;
}
.sliderControls ul.slick-dots li:first-child {
  margin-left: 0;
}
.sliderControls ul.slick-dots li:last-child {
  margin-right: 0;
}
.sliderControls ul.slick-dots li button {
  color: #232320;
  border: 1px solid #E1E1D2;
  background: #FFFFFF;
  height: 48px;
  width: 48px;
  border-radius: 50px;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: color, border-color;
}
.sliderControls ul.slick-dots li button:hover, .sliderControls ul.slick-dots li button:focus {
  border-color: #83847B;
}
.sliderControls ul.slick-dots li.slick-active button {
  color: #E72264;
  border-color: #83847B;
}

.ebookPromo .promo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  border: 1px solid #E1E1D2;
  border-radius: 32px;
  padding: 32px 40px;
}
@media (min-width: 768px) {
  .ebookPromo .promo {
    flex-direction: row;
  }
}
.ebookPromo .promo .content {
  flex: 1 1 auto;
  order: 2;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .ebookPromo .promo .content {
    margin-top: 0;
    order: 1;
  }
}
.ebookPromo .promo .content h2, .ebookPromo .promo .content .title {
  font-size: 36px;
  line-height: 42px;
  font-weight: 900;
}
.ebookPromo .promo .content h2 em, .ebookPromo .promo .content .title em {
  font-style: normal;
  color: #E72264;
}
.ebookPromo .promo .content p {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 30px;
}
.ebookPromo .promo .content .actions {
  font-size: 22px;
  font-weight: 900;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .ebookPromo .promo .content .actions {
    flex-direction: row;
    justify-content: flex-start;
  }
}
.ebookPromo .promo .content .actions .button:not(.secondary) {
  padding-right: 57px;
}
.ebookPromo .promo .content .actions .secondary {
  margin-top: 20px;
  color: #E72264;
  font-weight: 900;
}
@media (min-width: 768px) {
  .ebookPromo .promo .content .actions .secondary {
    margin-top: 0;
    margin-left: 20px;
  }
}
.ebookPromo .promo .image {
  order: 1;
  flex: 1 1 auto;
  min-width: 200px;
}
@media (min-width: 768px) {
  .ebookPromo .promo .image {
    order: 2;
    margin-left: 40px;
  }
}

section#content .ebookPromo .promo .content h2, section#content .ebookPromo .promo .content .title {
  margin-top: 0;
  margin-bottom: 23px;
}

section.subscriptions {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  section.subscriptions {
    margin-bottom: 64px;
  }
}
section.subscriptions h2 {
  color: #E72264;
  text-align: center;
}
section.subscriptions p.intro {
  width: 100%;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  section.subscriptions p.intro {
    margin-bottom: 64px;
  }
}
section.subscriptions .subscriptionsRow > .col, section.subscriptions .subscriptionsRow > [class*=col-] {
  margin-bottom: 32px;
}
@media (min-width: 1024px) {
  section.subscriptions .subscriptionsRow > .col, section.subscriptions .subscriptionsRow > [class*=col-] {
    margin-bottom: 0;
  }
}
section.subscriptions .subscription {
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-bottom: 45px;
  border: 1px solid #707070;
  border-radius: 32px;
}
@media (min-width: 768px) {
  section.subscriptions .subscription {
    height: 100%;
  }
}
section.subscriptions .subscription .title {
  width: 100%;
  text-align: center;
  font-size: 32px;
  line-height: 37px;
  margin-bottom: 32px;
  font-weight: 900;
}
section.subscriptions .subscription .USPs {
  margin-bottom: 18px;
}
section.subscriptions .subscription .USPs li {
  font-size: 18px;
  line-height: 24px;
  padding-left: 37px;
  position: relative;
  margin-bottom: 12px;
}
section.subscriptions .subscription .USPs li:last-child {
  margin-bottom: 0;
}
section.subscriptions .subscription .USPs li:before {
  height: 17px;
  width: 17px;
  display: block;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: url("/img/icon_check_green.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
section.subscriptions .subscription .price {
  width: 100%;
  text-align: center;
  margin-top: auto;
  margin-bottom: 24px;
}
section.subscriptions .subscription .price span {
  display: block;
  font-size: 36px;
  line-height: 32px;
}
section.subscriptions .subscription .price span.VAT {
  color: #707070;
  font-size: 18px;
}
section.subscriptions .subscription .actions {
  text-align: center;
  padding: 0 37px;
}
section.subscriptions .subscription .actions .button {
  color: #FFFFFF;
  display: inline-block;
  width: 100%;
  text-align: center;
  background: #83847B;
  font-size: 22px;
  font-weight: 400;
}
section.subscriptions .subscription.free .title {
  color: #12C700;
}
section.subscriptions .subscription.free .actions .button {
  background: #12C700;
}
section.subscriptions .subscription.free .actions .button:hover, section.subscriptions .subscription.free .actions .button:focus {
  background: #E1E1D2;
  color: #12C700;
}
section.subscriptions .subscription.community .title {
  color: #E72264;
}
section.subscriptions .subscription.community .actions .button {
  background: #E72264;
}
section.subscriptions .subscription.community .actions .button:hover, section.subscriptions .subscription.community .actions .button:focus {
  background: #E1E1D2;
  color: #E72264;
}
section.subscriptions .subscription.academy .title {
  color: #651FFF;
}
section.subscriptions .subscription.academy .actions .button {
  background: #651FFF;
}
section.subscriptions .subscription.academy .actions .button:hover, section.subscriptions .subscription.academy .actions .button:focus {
  background: #E1E1D2;
  color: #651FFF;
}

section#content section.subscriptions h2 {
  margin-bottom: 20px;
}

section.stories h2 {
  color: #E72264;
}
section.stories > div:nth-of-type(2) > .row {
  margin-bottom: 35px;
}
section.stories > div:nth-of-type(2) > .row > div {
  display: flex;
  align-items: center;
}
section.stories a.readmore {
  display: inline-flex;
  align-items: center;
  color: #83847B;
  text-decoration: none;
  margin: 0 auto;
}
section.stories a.readmore svg {
  margin-left: 5px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
section.stories a.readmore:hover svg, section.stories a.readmore:focus svg {
  transform: translateX(4px);
}
section.stories .storiesList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -32px;
}
@media (min-width: 1024px) {
  section.stories .storiesList {
    margin-left: -10px;
    margin-right: -10px;
  }
}
section.stories .storiesList li {
  flex-basis: 100%;
  width: 100%;
  max-width: 100%;
}
@media (min-width: 1024px) {
  section.stories .storiesList li {
    flex-basis: calc(50% - 20px);
    width: calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
}
section.stories .storiesList li:not(:last-child) {
  margin-top: 32px;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  section.stories .storiesList li:not(:last-child) {
    margin-top: 32px;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  section.stories .storiesList li:last-child {
    margin-top: 32px;
  }
}
section.stories .storiesList .embeddedStory {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media (min-width: 768px) {
  section.stories .storiesList .embeddedStory {
    flex-direction: row;
  }
}
section.stories .storiesList .embeddedStory a.image {
  width: 132px;
  height: 132px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  section.stories .storiesList .embeddedStory a.image {
    margin-bottom: 0;
    margin-right: 40px;
    width: 136px;
    height: 136px;
  }
}
@media (min-width: 1300px) {
  section.stories .storiesList .embeddedStory a.image {
    width: 174px;
    height: 174px;
  }
}
section.stories .storiesList .embeddedStory a.image img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
section.stories .storiesList .embeddedStory a.image svg {
  display: none;
}
section.stories .storiesList .embeddedStory > div {
  display: inline-block;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  section.stories .storiesList .embeddedStory > div {
    align-items: flex-start;
  }
}
section.stories .storiesList .embeddedStory a.text {
  display: inline-block;
  text-decoration: none;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 10px;
  color: #232320;
}
@media (min-width: 768px) {
  section.stories .storiesList .embeddedStory a.text {
    font-size: 24px;
    line-height: 24px;
  }
}
section.stories .storiesList .embeddedStory div.like {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  section.stories .storiesList .embeddedStory div.like {
    display: inline-flex;
    justify-content: flex-start;
  }
}
section.stories .storiesList .embeddedStory div.like svg {
  margin-right: 5px;
}
section.stories .storiesList .embeddedStory div.like svg use {
  fill: #12C700;
}

section.newsletter {
  max-width: 100%;
  overflow: hidden;
}
section.newsletter h2 {
  font-size: 18px;
  line-height: 32px;
  font-weight: 800;
}
@media (min-width: 768px) {
  section.newsletter h2 {
    text-align: right;
  }
}
section.newsletter .content span {
  font-size: 32px;
  line-height: 32px;
  display: block;
  margin-bottom: 10px;
  font-weight: 800;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  section.newsletter .content span {
    margin-bottom: 0;
    text-align: right;
  }
}
section.newsletter .content p {
  margin-top: 0;
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  section.newsletter .content p {
    margin-bottom: 25px;
  }
}
section.newsletter .content a.button {
  font-size: 22px;
  font-weight: 600;
  padding-right: 60px;
}
section.newsletter .content .col:last-child, section.newsletter .content [class*=col-]:last-child {
  position: relative;
}
section.newsletter .content .col:last-child > *, section.newsletter .content [class*=col-]:last-child > * {
  position: relative;
}
section.newsletter .content .col:last-child img.bg, section.newsletter .content [class*=col-]:last-child img.bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%) scale(1.2);
}

section#content section.newsletter h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.parallaxImageSet {
  position: relative;
  padding-bottom: 70%;
}
.parallaxImageSet .layer {
  position: absolute;
  background-size: contain;
}
section.alternating .block {
  margin-bottom: 70px;
}
@media (min-width: 768px) {
  section.alternating .block {
    margin-bottom: 90px;
  }
}
section.alternating .block:last-child {
  margin-bottom: 0;
}
section.alternating .block > .col:first-child:not(:last-child), section.alternating .block > [class*=col-]:first-child:not(:last-child) {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  section.alternating .block > .col:first-child:not(:last-child), section.alternating .block > [class*=col-]:first-child:not(:last-child) {
    margin-bottom: 0;
  }
}
section.alternating h2 {
  font-size: 48px;
  line-height: 40px;
}
section.alternating p {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 32px;
}
section.alternating p a {
  color: #651FFF;
  position: relative;
  text-decoration: none;
}
section.alternating p a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #651FFF;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
section.alternating p a:hover:after, section.alternating p a:focus:after {
  transform: none;
}
section.alternating p + a {
  color: #E72264;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  position: relative;
  text-decoration: none;
}
section.alternating p + a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #E72264;
  height: 1px;
  width: 100%;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
section.alternating p + a:hover:after, section.alternating p + a:focus:after {
  transform: scaleX(0);
}

section#content section.alternatingBlocks h2 {
  margin-top: 0;
  margin-bottom: 24px;
}

.filterContainer {
  position: fixed;
  padding: 0 20px 30px;
  background: #FFFFFF;
  border-radius: 10px;
  z-index: 120;
  top: 60px;
  left: 30px;
  max-height: calc(var(--fullHeight) - 90px);
  overflow-y: auto;
  width: calc(100vw - 60px);
  box-shadow: 0 0 0 200vh rgba(0, 0, 0, 0.25);
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: opacity, transform;
}
@media (min-width: 768px) {
  .filterContainer {
    position: relative;
    border: none;
    padding: 0;
    top: auto;
    left: auto;
    height: auto;
    max-height: none;
    width: auto;
    box-shadow: none;
    transition: none;
    z-index: 1;
    border-radius: 0;
  }
}
.filterContainer.mobileHidden {
  transform: scale(0.5);
  opacity: 0;
  pointer-events: none;
}
.filterContainer.mobileHidden * {
  pointer-events: none !important;
}
@media (min-width: 768px) {
  .filterContainer.mobileHidden {
    transform: none;
    opacity: 1;
    pointer-events: all;
  }
  .filterContainer.mobileHidden * {
    pointer-events: all !important;
  }
}
.filterContainer .filterContainerHeader {
  position: sticky;
  top: 0;
  padding: 20px 0;
  background: #FFFFFF;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .filterContainer .filterContainerHeader {
    display: none;
  }
}
.filterContainer .filterContainerHeader .filterContainerTitle {
  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
  color: #AA00FF;
}
.filterContainer .filterContainerHeader .filterContainerClose {
  cursor: pointer;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filterContainer .filterContainerHeader .filterContainerClose svg.icon {
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.filterContainer .filterContainerHeader .filterContainerClose:hover svg.icon {
  transform: rotate(90deg);
}
.filterContainer .filterContainerContent {
  height: 100%;
  max-height: calc(var(--fullHeight) - 165px);
  overflow-y: auto;
}
@media (min-width: 768px) {
  .filterContainer .filterContainerContent {
    height: initial;
    overflow-y: initial;
    max-height: none;
  }
}
.filterContainer .filterContainerContent a.button.c2a {
  margin-top: 5px;
  margin-bottom: 30px;
  width: 100%;
  color: #FFFFFF;
}
.filterContainer .filterContainerContent .clearFilters {
  text-align: center;
}
.filterContainer .hiddenFilters {
  display: none;
}
.filterContainer a.showAll {
  position: relative;
  display: inline-block;
  margin-left: 35px;
  text-decoration: none;
  color: #AA00FF;
}
.filterContainer a.showAll:after {
  content: "";
  display: block;
  background: #AA00FF;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.filterContainer a.showAll:hover:after, .filterContainer a.showAll:focus:after {
  transform: none;
}

.filterInput {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .filterInput {
    margin-bottom: 60px;
  }
}
.filterInput .info {
  margin-bottom: 10px;
  font-weight: 600;
}
@media (min-width: 768px) {
  .filterInput .info {
    margin-bottom: 20px;
  }
}
.filterInput .info .expandIcon {
  display: inline-block;
  position: absolute;
  transform: translateX(10px) scale(0.8) rotate(-90deg);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  .filterInput .info .expandIcon {
    display: none;
  }
}
.filterInput .selection {
  display: none;
}
.filterInput .filterName {
  font-weight: 600;
  color: #AA00FF;
  background: #FFFFFF;
  position: sticky;
  top: 0;
  cursor: pointer;
  z-index: 2;
  display: inline-flex;
  width: 100%;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .filterInput .filterName {
    position: static;
    cursor: default;
    display: inline-block;
    margin-bottom: 10px;
    width: auto;
  }
}
.filterInput .filterName svg.sprite {
  position: relative;
  top: 5px;
  flex-shrink: 0;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  .filterInput .filterName svg.sprite {
    display: none;
  }
}
.filterInput .filterName .count {
  display: none;
  font-weight: 400;
}
@media (min-width: 768px) {
  .filterInput .filterName .count {
    display: none;
  }
}
.filterInput.expanded .info .expandIcon {
  transform: translateX(10px) scale(0.8) rotate(-90deg) scaleX(-1);
}
.filterInput.expanded .filterName svg.icon {
  transform: scaleY(-1);
}
.filterInput.filtered .filterName {
  font-weight: 700;
}
@media (min-width: 768px) {
  .filterInput.filtered .filterName {
    font-weight: 600;
  }
}
.filterInput.filtered .filterName .count {
  display: inline;
}
@media (min-width: 768px) {
  .filterInput.filtered .filterName .count {
    display: none;
  }
}
.filterInput ul {
  position: absolute;
  opacity: 0;
  transform-origin: top left;
  transform: translateY(-5px) scale(0.95);
  z-index: -10;
  pointer-events: none;
  height: 0;
  overflow-y: hidden;
}
@media (min-width: 768px) {
  .filterInput ul {
    position: static;
    opacity: 1;
    transform: none;
    z-index: 1;
    pointer-events: all;
    overflow-y: auto;
    height: auto;
  }
  section.content .filterInput ul {
    margin-bottom: 15px;
  }
}
.filterInput ul li {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .filterInput ul li.hidden * {
    pointer-events: none !important;
  }
}
section.content .filterInput ul li {
  margin-left: 30px;
  margin-bottom: 13px;
}
section.content .filterInput ul li:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  section.content .filterInput ul li {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .filterInput ul li:not(.hidden) {
    position: static;
    z-index: 1;
    pointer-events: all;
    opacity: 1;
    transform-origin: top left;
    transform: none;
    transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
    transition-property: transform, opacity;
  }
}
@media (min-width: 768px) {
  .filterInput ul li.hidden {
    position: absolute;
    z-index: -10;
    pointer-events: none !important;
    opacity: 0;
    transform: translateY(-5px) scale(0.95);
  }
}
.filterInput ul li:before {
  display: none;
}
.filterInput ul li a {
  line-height: 24px;
  color: #232320;
  text-decoration: none;
}
.filterInput ul li a:hover, .filterInput ul li a:focus {
  text-decoration: underline;
}
.filterInput ul li input {
  display: none;
}
.filterInput ul li input + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: block;
  line-height: 24px;
  color: #83847B;
}
.filterInput ul li input + label:before, .filterInput ul li input + label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border-radius: 5px;
}
.filterInput ul li input + label:before {
  opacity: 0;
  background-image: url("/img/icon_check_green.svg");
  background-position: center 30%;
  background-repeat: no-repeat;
  transform: scale(0.4);
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: opacity, transform;
}
.filterInput ul li input + label:after {
  border: 1px solid #83847B;
  transition: border-color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.filterInput ul li input + label:hover:after, .filterInput ul li input + label:focus:after {
  border-color: #232320;
}
.filterInput ul li input:checked + label:before {
  opacity: 1;
  transform: none;
}
.filterInput ul li input:checked + label:after {
  border-color: #12C700;
}
.filterInput.expanded ul {
  opacity: 1;
  position: relative;
  transform: none;
  z-index: 1;
  overflow-y: auto;
  height: auto;
  pointer-events: all;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.filterInput a.showAll {
  display: none;
  color: #651FFF;
}
.filterInput a.showAll svg.icon {
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.filterInput a.showAll:hover {
  color: #AA00FF;
}
.filterInput a.showAll:hover svg.icon {
  transform: rotate(90deg);
}
.filterInput a.showAll:hover svg.icon use {
  fill: #AA00FF;
}
@media (min-width: 768px) {
  .filterInput a.showAll {
    display: inline-block;
  }
}

.striberQuickView {
  display: inline-flex;
}
.striberQuickView .image {
  margin-right: 8px;
  height: 67px;
  width: 67px;
}
@media (min-width: 768px) {
  .striberQuickView .image {
    margin-right: 16px;
  }
}
.striberQuickView .image img {
  border-radius: 100px;
}
.striberQuickView .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #83847B;
}
@media (min-width: 768px) {
  .striberQuickView .content {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .striberQuickView .content.center {
    justify-content: center;
  }
}
.striberQuickView .content .name {
  display: block;
  margin-top: 5px;
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 18px;
}
@media (min-width: 768px) {
  .striberQuickView .content .name {
    display: flex;
  }
}
.striberQuickView .content ul.activity {
  display: none;
}
@media (min-width: 768px) {
  .striberQuickView .content ul.activity {
    display: flex;
  }
}
.striberQuickView .content ul.activity li {
  margin-right: 16px;
  display: inline-flex;
  align-items: center;
}
.striberQuickView .content ul.activity li:last-child {
  margin-right: 0;
}
.striberQuickView .content ul.activity li svg.sprite {
  margin-right: 6px;
}
.striberQuickView .content ul.activity li svg.sprite use {
  fill: #83847B;
}
.likes[data-like] {
  display: inline-flex;
  align-self: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.likes[data-like] .icon {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
}
@media (min-width: 768px) {
  .likes[data-like] .icon {
    display: block;
  }
}
.likes[data-like] .icon svg.sprite {
  flex: 0 0 auto;
  width: 21px;
  max-height: 18px;
  margin-right: 4px;
}
@media (min-width: 768px) {
  .likes[data-like] .icon svg.sprite {
    margin-right: 0;
    width: 51px;
    max-height: 43px;
  }
}
.likes[data-like] .icon svg.sprite use {
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: fill, stroke;
}
.likes[data-like] .icon span {
  position: relative;
  left: 0;
  top: -2px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #232320;
  transition: opacity 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  .likes[data-like] .icon span {
    position: absolute;
    color: #83847B;
  }
}
.likes[data-like] > span {
  font-size: 0.9em;
  line-height: 24px;
  margin-right: 10px;
  display: none;
}
@media (min-width: 768px) {
  .likes[data-like] > span {
    display: inline;
  }
}
.likes[data-like]:not(.liked) .icon:hover svg.sprite use, .likes[data-like]:not(.liked) .icon:focus svg.sprite use {
  stroke: #12C700;
}
.likes[data-like]:not(.liked) .icon:hover span, .likes[data-like]:not(.liked) .icon:focus span {
  color: #12C700;
}
.likes[data-like].liked .icon {
  pointer-events: none;
  cursor: default;
}
.likes[data-like].liked .icon svg.sprite {
  animation-direction: normal;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-name: liked;
  animation-timing-function: ease-out;
}
.likes[data-like].liked .icon svg.sprite use {
  fill: #12C700;
  stroke: #FFFFFF;
}
@media (min-width: 768px) {
  .likes[data-like].liked .icon span {
    color: #FFFFFF;
    opacity: 0.8;
  }
}
.likes[data-like].alreadyLiked * {
  animation: none !important;
  transition: none !important;
}

footer {
  background: #FFFFFF;
  min-height: 200px;
  border-top: 1px solid #E1E1D2;
  padding-bottom: 40px;
}
footer .row.footerLinks {
  margin-top: 45px;
  margin-bottom: 45px;
}
footer .row.footerLinks > div:not(:last-child) {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  footer .row.footerLinks > div:not(:last-child) {
    margin-bottom: 0;
  }
}
footer .row.footerLinks nav h2, footer .row.footerLinks nav a {
  font-size: 18px;
  line-height: 20px;
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
}
footer .row.footerLinks nav h2 {
  color: #E72264;
  font-weight: 800;
}
footer .row.footerLinks nav a {
  color: #83847B;
  text-decoration: none;
  font-weight: 300;
  position: relative;
}
footer .row.footerLinks nav a:after {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #E72264;
  transform: scaleX(0);
  opacity: 0;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: transform, opacity;
}
footer .row.footerLinks nav a:hover, footer .row.footerLinks nav a:focus {
  color: #E72264;
}
footer .row.footerLinks nav a:hover:after, footer .row.footerLinks nav a:focus:after {
  opacity: 1;
  transform: none;
}
footer .row.footerLinks nav ul li {
  margin-bottom: 16px;
}
footer ul.socialMediaList {
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: -8px;
  justify-content: center;
}
footer ul.socialMediaList li {
  display: inline-block;
  margin-top: 8px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  footer ul.socialMediaList li {
    margin-left: 8px;
    margin-right: 8px;
  }
  footer ul.socialMediaList li:first-child {
    margin-left: 0;
  }
  footer ul.socialMediaList li:last-child {
    margin-right: 0;
  }
}
footer ul.socialMediaList li a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 56px;
  background: #651FFF;
  transition: background 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
footer ul.socialMediaList li a svg use {
  transition: fill 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
footer ul.socialMediaList li a:hover, footer ul.socialMediaList li a:focus {
  background: #FFFFFF;
}
footer ul.socialMediaList li a:hover:after, footer ul.socialMediaList li a:focus:after {
  opacity: 1;
  transform: none;
}
footer ul.socialMediaList li a:hover svg.sprite use, footer ul.socialMediaList li a:focus svg.sprite use {
  fill: #651FFF;
}
@media (min-width: 768px) {
  footer ul.socialMediaList li a {
    width: 56px;
    height: 56px;
  }
}

section#prefooter {
  display: none;
  background: rgba(0, 0, 0, 0.1);
  /* EXPERIMENTAL */
  /* margin-top: auto; */
}

#cookieBar {
  position: fixed;
  background: rgba(35, 35, 32, 0.8);
  right: 0;
  bottom: 0;
  left: 0;
  color: #FFFFFF;
  padding: 20px 0 30px 0;
  transition: transform 800ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transform: translateY(100%);
  width: 100%;
  z-index: 9999;
}
#cookieBar.open {
  transform: translateY(0);
}
#cookieBar .inner {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1023.98px) {
  #cookieBar .inner {
    flex-direction: column;
  }
}
#cookieBar .inner span.title {
  display: block;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  flex-basis: 100%;
  color: #FFFFFF;
  margin-bottom: 10px;
}
#cookieBar .inner .text {
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-right: 50px;
  font-size: 16px;
  line-height: 22px;
}
@media (min-width: 768px) {
  #cookieBar .inner .text {
    display: flex;
  }
}
#cookieBar .inner .text.mobile {
  display: flex;
  margin-right: 0;
}
@media (min-width: 768px) {
  #cookieBar .inner .text.mobile {
    display: none;
  }
}
#cookieBar .inner .text p {
  margin: 0;
}
#cookieBar .inner .text a {
  color: #ffffff;
  text-decoration: none;
}
#cookieBar .inner .text a:hover {
  text-decoration: none;
}
#cookieBar .inner .buttons {
  margin-top: 20px;
}
@media (min-width: 768px) {
  #cookieBar .inner .buttons {
    margin-top: 0;
  }
}
#cookieBar .inner .buttons a.button, #cookieBar .inner .buttons button {
  cursor: pointer;
  width: 100%;
  max-width: 150px;
  text-align: center;
  font-weight: 600;
  padding: 7px 15px;
  margin: 5px 5px 0 0;
  font-size: 0.9em;
  border-radius: 30px;
  justify-content: center;
  color: #232320;
  text-decoration: none;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  #cookieBar .inner .buttons a.button, #cookieBar .inner .buttons button {
    margin: 5px 0;
    max-width: none;
    display: block;
  }
}
@media (min-width: 1024px) {
  #cookieBar .inner .buttons a.button, #cookieBar .inner .buttons button {
    font-size: 0.9em;
  }
}
#cookieBar .inner .buttons a.button.agreeCookies, #cookieBar .inner .buttons button.agreeCookies {
  background-color: #00E5FF;
}
#cookieBar .inner .buttons a.button.agreeCookies:hover, #cookieBar .inner .buttons a.button.agreeCookies:focus, #cookieBar .inner .buttons button.agreeCookies:hover, #cookieBar .inner .buttons button.agreeCookies:focus {
  background-color: #232320;
  color: #00E5FF;
}
#cookieBar .inner .buttons a.button.goConfigure, #cookieBar .inner .buttons button.goConfigure {
  background-color: #00E5FF;
}
#cookieBar .inner .buttons a.button.goConfigure:hover, #cookieBar .inner .buttons a.button.goConfigure:focus, #cookieBar .inner .buttons button.goConfigure:hover, #cookieBar .inner .buttons button.goConfigure:focus {
  background-color: #232320;
  color: #00E5FF;
}

.videoCookieMessage {
  background: #F5F5F5;
  text-align: center;
  border: 1px solid #CECECE;
  display: table;
  height: 100%;
  margin: -1px;
  width: 100%;
}
.videoCookieMessage .contentHolder {
  display: table-cell;
  vertical-align: middle;
}
.videoCookieMessage .contentHolder .inner {
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
  width: 100%;
}
@media (min-width: 768px) {
  .videoCookieMessage .contentHolder .inner {
    padding: 20px;
  }
}
.videoCookieMessage svg {
  display: none;
  height: 60px;
  margin-bottom: 13px;
  vertical-align: top;
  width: 60px;
}
@media (min-width: 768px) {
  .videoCookieMessage svg {
    display: inline-block;
  }
}
.videoCookieMessage span.title {
  color: #4A4A4A;
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  padding: 0 20px;
}
.videoCookieMessage p {
  color: #4A4A4A;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 14px;
  padding: 0 20px;
}
.videoCookieMessage p a {
  border-bottom: 0;
  color: #3388C2;
  padding-bottom: 0;
  text-decoration: none;
}
.videoCookieMessage p a:hover, .videoCookieMessage p a:active, .videoCookieMessage p a:focus {
  border-bottom: 0;
  text-decoration: none;
}

#cookieConfigure .checkboxButton {
  margin-top: 30px;
  margin-bottom: 15px;
  margin-left: 30px;
  display: inline-block;
  position: relative;
}
@media (max-width: 1023.98px) {
  #cookieConfigure .checkboxButton {
    margin-left: 0px;
  }
}
#cookieConfigure .checkboxButton label {
  display: block;
  margin-bottom: 10px;
}
#cookieConfigure .checkboxButton label:before, #cookieConfigure .checkboxButton label:after {
  content: "";
  height: 20px;
  width: 20px;
  border-radius: 4px;
  display: inline-block;
  position: absolute;
  left: -35px;
  top: -3px;
}
#cookieConfigure .checkboxButton label:before {
  border: 3px solid white;
  border-radius: 6px;
  background: #ff1990;
  transform: scale(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
#cookieConfigure .checkboxButton label:after {
  border: 2px solid #333333;
}
#cookieConfigure .checkboxButton label span {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 13px;
}
#cookieConfigure .checkboxButton label span a {
  color: #4a8dc0;
  font-weight: 700;
}
#cookieConfigure .checkboxButton input {
  display: none;
}
#cookieConfigure .checkboxButton input:checked {
  background-color: red;
}
#cookieConfigure .checkboxButton input:checked ~ label:before {
  transform: none;
}
#cookieConfigure .checkboxButton input:disabled ~ label:before, #cookieConfigure .checkboxButton input:disabled ~ label:after {
  opacity: 0.5;
}
#cookieConfigure button.configure {
  background-color: green;
  border-radius: 25px;
  padding: 0 25px;
  border: none;
  height: 50px;
}
#cookieConfigure button.configure span {
  color: white;
}
#cookieConfigure .onSaveComplete {
  transition: opacity 200ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  margin-left: 20px;
  display: inline-block;
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 1023.98px) {
  #cookieConfigure .onSaveComplete {
    margin-left: 0px;
  }
}
#cookieConfigure .onSaveComplete.success {
  opacity: 1;
}

body.landingpage {
  background: #871FD5;
  background: linear-gradient(to bottom, #651FFF 0, #A620B0 50%, #AA00FF 100%);
  background-repeat: no-repeat;
  background-position: fixed;
  background-size: contain;
  min-height: 100vh;
  min-height: var(--fullHeight);
  height: 200px;
  max-height: 100vh;
  max-height: var(--fullHeight);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.landingpage header {
  position: relative;
  top: 34px;
  height: 0;
  overflow-y: visible;
  width: 100%;
  transition: filter 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-delay: 600ms;
}
@media (min-width: 768px) {
  body.landingpage header {
    top: 67px;
  }
}
body.landingpage header.blurred {
  pointer-events: none;
  filter: blur(10px);
}
body.landingpage header a.headerLogo img {
  width: 117px;
}
@media (min-width: 768px) {
  body.landingpage header a.headerLogo img {
    width: 175px;
  }
}
body.landingpage header .headerNavigation {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
body.landingpage header .headerNavigation nav ul,
body.landingpage header .headerNavigation nav li {
  display: inline-block;
}
body.landingpage header .headerNavigation nav li {
  margin-right: 32px;
}
@media (min-width: 768px) {
  body.landingpage header .headerNavigation nav li {
    margin-right: 52px;
  }
}
body.landingpage header .headerNavigation nav li:last-child {
  margin-right: 0;
}
body.landingpage header .headerNavigation nav a {
  text-decoration: none;
  color: #FFFFFF;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  position: relative;
  padding: 10px 0;
}
@media (min-width: 768px) {
  body.landingpage header .headerNavigation nav a {
    padding: 10px 5px;
  }
}
body.landingpage header .headerNavigation nav a:after {
  position: absolute;
  width: 100%;
  height: 5px;
  content: "";
  display: inline-block;
  background: #FFC400;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: left;
  border-radius: 500px;
  opacity: 0;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: transform, opacity;
}
body.landingpage header .headerNavigation nav a:hover:after, body.landingpage header .headerNavigation nav a:focus:after {
  transform: none;
  opacity: 1;
}
body.landingpage h1 {
  color: #FFC400;
}
body.landingpage section#background {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100vh;
  height: var(--fullHeight);
  overflow: hidden;
  z-index: -1;
  pointer-events: none;
  transition: filter 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-delay: 600ms;
}
body.landingpage section#background.blurred {
  filter: blur(10px);
}
body.landingpage section#background.noanimate div.row.parallaxSceneMouse div[class*=col-] {
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: transform, 200ms;
  transform: none !important;
}
body.landingpage section#background.noanimate div.blob {
  animation-play-state: paused;
}
body.landingpage section#background div.blob {
  width: 507px;
  height: 422px;
  background: radial-gradient(circle at 0 40%, #E72264 0%, #E72264 8%, #AA00FF 50%, #00E5FF 92%, #1DE9B6 100%);
  border-radius: 33% 67% 70% 30%/30% 30% 70% 70%;
  animation: blob-landing-transform 20s ease-in-out infinite both alternate, blob-landing-moving-1 40s ease-in-out infinite both;
}
@media (min-width: 768px) {
  body.landingpage section#background div.blob {
    width: 666px;
    height: 554px;
  }
}
body.landingpage section#content {
  padding-top: 85px;
  margin-top: auto;
  margin-bottom: auto;
  transition: filter 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-delay: 600ms;
}
@media (min-width: 768px) {
  body.landingpage section#content {
    padding-top: 140px;
  }
}
body.landingpage section#content.blurred {
  filter: blur(10px);
  pointer-events: none;
}
body.landingpage section#content h1 {
  font-size: 64px;
  line-height: 56px;
  font-weight: 700;
  color: #FFC400;
}
@media (min-width: 768px) {
  body.landingpage section#content h1 {
    font-size: 86px;
    line-height: 80px;
  }
}
@media (min-width: 1024px) {
  body.landingpage section#content h1 {
    line-height: 100px;
  }
}
body.landingpage p.intro {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  body.landingpage p.intro {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 50px;
  }
}
@media (min-width: 1024px) {
  body.landingpage .actions br {
    display: none;
  }
}
body.landingpage .actions a.button {
  width: 100%;
  margin-bottom: 15px;
}
@media (min-width: 375px) {
  body.landingpage .actions a.button {
    width: auto;
  }
}
@media (min-width: 1024px) {
  body.landingpage .actions a.button {
    margin-bottom: 0;
    margin-right: 32px;
  }
}
@media (min-width: 768px) {
  body.landingpage .actions a.button > *:not(:last-child) {
    margin-right: 50px;
  }
}
body.landingpage .actions a.button svg {
  transform-origin: top;
}
body.landingpage .actions a.button:hover svg, body.landingpage .actions a.button:focus svg {
  animation-direction: normal;
  animation-duration: 800ms;
  animation-fill-mode: forwards;
  animation-name: wiggle;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.landingpage .actions a.moreInfo {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  position: relative;
  text-decoration: none;
}
body.landingpage .actions a.moreInfo:before {
  content: "";
  display: inline-block;
  left: 0;
  bottom: -2px;
  position: absolute;
  width: 100%;
  height: 3px;
  background: #fff;
  transform-origin: right;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.landingpage .actions a.moreInfo:after {
  content: "";
  display: inline-block;
  left: 0;
  bottom: -2px;
  position: absolute;
  width: 100%;
  height: 3px;
  background: #FFC400;
  transform-origin: left;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.landingpage .actions a.moreInfo:hover:before, body.landingpage .actions a.moreInfo:focus:before {
  transform: scaleX(0);
}
body.landingpage .actions a.moreInfo:hover:after, body.landingpage .actions a.moreInfo:focus:after {
  transform: scaleX(1);
}
body.landingpage .popup .popupMenu {
  position: relative;
  top: 50px;
  width: 100%;
}
@media (min-width: 768px) {
  body.landingpage .popup .popupMenu {
    position: static;
    top: auto;
    width: auto;
  }
}
body.landingpage section#newsletterPopup {
  position: fixed;
  top: 0;
  left: 0;
  margin-left: -15px;
  margin-right: -15px;
  width: 100%;
  width: calc(100% + 30px);
  z-index: 100;
}
body.landingpage section#newsletterPopup .popup {
  position: absolute;
}
body.landingpage section#newsletterPopup .popup .header {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  body.landingpage section#newsletterPopup .popup .content {
    display: flex;
    align-content: center;
    align-items: center;
  }
  body.landingpage section#newsletterPopup .popup .content[data-simplebar] {
    align-content: center;
    align-items: center;
  }
}
@media screen and (max-height: 700px) {
  body.landingpage section#newsletterPopup .popup .content {
    align-content: flex-start;
    align-items: flex-start;
  }
  body.landingpage section#newsletterPopup .popup .content[data-simplebar] {
    align-content: flex-start;
    align-items: flex-start;
  }
}
body.landingpage section#newsletterPopup .popup .content h2 {
  margin-bottom: 20px;
}
body.landingpage section#newsletterPopup .popup .content .intro {
  color: #232320;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 34px;
  font-weight: 700;
  font-family: "Public Sans", sans-serif;
}
@media (min-width: 768px) {
  body.landingpage section#newsletterPopup .popup .content .intro {
    margin-bottom: 75px;
  }
}
body.landingpage section#newsletterPopup .popup .content form {
  margin-top: 0;
  margin-bottom: 45px;
}
body.landingpage section#newsletterPopup .popup .content form div.input {
  position: relative;
  margin-bottom: 23px;
}
body.landingpage section#newsletterPopup .popup .content form div.input input[type=email], body.landingpage section#newsletterPopup .popup .content form div.input input[type=text] {
  height: 52px;
  border-radius: 52px;
  border: 1px solid #707070;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: #232320;
  padding: 10px 25px 13px;
}
body.landingpage section#newsletterPopup .popup .content form div.input input[type=email]:placeholder, body.landingpage section#newsletterPopup .popup .content form div.input input[type=text]:placeholder {
  color: #83847B;
  font-weight: 300;
}
body.landingpage section#newsletterPopup .popup .content form div.input input[type=email]::placeholder, body.landingpage section#newsletterPopup .popup .content form div.input input[type=text]::placeholder {
  color: #83847B;
  font-weight: 300;
}
body.landingpage section#newsletterPopup .popup .content form div.input input[type=email].error, body.landingpage section#newsletterPopup .popup .content form div.input input[type=text].error {
  border-color: #E72264;
}
body.landingpage section#newsletterPopup .popup .content form a.button, body.landingpage section#newsletterPopup .popup .content form button.button {
  width: 100%;
  cursor: pointer;
}
body.landingpage section#newsletterPopup .popup .content form span.error {
  padding-top: 5px;
  color: #E72264;
  padding-left: 24px;
  font-family: "Public Sans", sans-serif;
  display: none;
}
body.landingpage section#newsletterPopup .popup .content form input.error + span.error {
  display: inline-block;
}
body.landingpage section#newsletterPopup .popup .content sub, body.landingpage section#newsletterPopup .popup .content form.newsletterForm + p {
  display: block;
  text-align: center;
  color: #83847B;
  font-size: 16px;
  line-height: 16px;
}
body.landingpage section#newsletterPopup .popup .content sub a, body.landingpage section#newsletterPopup .popup .content form.newsletterForm + p a {
  color: inherit;
  text-decoration: underline;
}
body.landingpage section#newsletterPopup .popup .content img {
  border-radius: 50%;
  margin-bottom: 30px;
}
body.landingpage section#newsletterPopup .popup .content .container {
  display: none;
}
body.landingpage section#newsletterPopup .popup .content .container.start {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: appearFromLeft;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.landingpage section#newsletterPopup .popup .content .container.success {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: appearFromRight;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.landingpage section#newsletterPopup .popup .content .container.success img {
  border-radius: 50%;
  margin-bottom: 30px;
  width: 200px;
  max-width: 100%;
  transform: scale(0);
  animation-delay: 350ms;
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: popupGif;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.landingpage section#newsletterPopup .popup .content .container.success h2 {
  color: #651FFF;
}
body.landingpage section#newsletterPopup .popup .content .container.loading {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: appearFromRight;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.landingpage section#newsletterPopup .popup .content .container.loading h2 {
  color: #651FFF;
}
body.landingpage section#newsletterPopup .popup .content .container.error {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: appearFromRight;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.landingpage section#newsletterPopup .popup .content .container.error h2 {
  color: #E72264;
}
body.landingpage section#newsletterPopup .popup .content.start .container.start {
  display: block;
}
body.landingpage section#newsletterPopup .popup .content.loading .container.loading {
  display: block;
}
body.landingpage section#newsletterPopup .popup .content.success .container.success {
  display: block;
}
body.landingpage section#newsletterPopup .popup .content.error .container.error {
  display: block;
}
body.landingpage footer {
  background: transparent;
  height: auto;
  min-height: 0;
  margin-bottom: 40px;
  margin-top: 20px;
  border-top: none;
  padding-bottom: 0;
  transition: filter 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-delay: 600ms;
}
@media (min-width: 768px) {
  body.landingpage footer {
    margin-bottom: 80px;
  }
}
body.landingpage footer.blurred {
  pointer-events: none;
  filter: blur(10px);
}
body.landingpage footer ul.socialMediaList {
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: -8px;
  justify-content: center;
}
body.landingpage footer ul.socialMediaList li {
  display: inline-block;
  margin-top: 8px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
}
@media (min-width: 375px) {
  body.landingpage footer ul.socialMediaList li:first-child {
    margin-left: 0;
  }
  body.landingpage footer ul.socialMediaList li:last-child {
    margin-right: 0;
  }
}
body.landingpage footer ul.socialMediaList li a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 56px;
  background: #651FFF;
  transition: background 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.landingpage footer ul.socialMediaList li a svg use {
  transition: fill 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.landingpage footer ul.socialMediaList li a:hover, body.landingpage footer ul.socialMediaList li a:focus {
  background: #FFFFFF;
}
body.landingpage footer ul.socialMediaList li a:hover svg.sprite use, body.landingpage footer ul.socialMediaList li a:focus svg.sprite use {
  fill: #651FFF;
}

body.homepage section#hero {
  padding-top: 80px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  body.homepage section#hero {
    margin-bottom: 70px;
    padding-top: 80px;
    padding-bottom: 120px;
  }
}
@media (min-width: 1024px) {
  body.homepage section#hero {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
body.homepage section#hero .background:before {
  height: 30px;
}
@media (min-width: 768px) {
  body.homepage section#hero .background:before {
    height: 100px;
  }
}
body.homepage section#hero .background:after {
  height: 30px;
}
@media (min-width: 768px) {
  body.homepage section#hero .background:after {
    height: 100px;
  }
}
body.homepage section#hero .background .layer.back {
  background: linear-gradient(to bottom, #651FFF 0, #A620B0 50%, #AA00FF 100%);
}
body.homepage section#hero .background .layer.front {
  opacity: 0;
}
body.homepage section#hero h2 {
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 17px;
  font-size: 48px;
  line-height: 48px;
  font-weight: 600;
}
@media (min-width: 768px) {
  body.homepage section#hero h2 {
    font-size: 62px;
    line-height: 62px;
  }
}
body.homepage section#hero h2 em {
  font-style: normal;
  color: #FFC400;
}
body.homepage section#hero p {
  width: 100%;
  color: #FFFFFF;
  margin-bottom: 32px;
  margin-top: 0;
  font-size: 24px;
  line-height: 32px;
}
@media (min-width: 768px) {
  body.homepage section#hero p {
    margin-bottom: 20px;
  }
}
body.homepage section#hero a.button {
  padding-right: 60px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
body.homepage section#hero svg {
  position: absolute;
}
@media (min-width: 768px) {
  body.homepage section#hero img {
    margin-bottom: 0;
  }
}
.ie body.homepage section#hero img, .edge body.homepage section#hero img {
  border-radius: 31% 69% 56% 44%/57% 58% 42% 43%;
  overflow: hidden;
}
body.homepage section#hero .image {
  position: relative;
  margin-bottom: 10px;
  clip-path: url(#home-mask);
}
body.homepage section#hero .image img {
  position: relative;
  width: 100%;
}
body.homepage section#hero .image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 100;
  mix-blend-mode: soft-light;
  background: linear-gradient(to bottom, #651FFF 0, #A620B0 50%, #AA00FF 100%);
  opacity: 0.5;
}
.ie body.homepage section#hero .image:after, .edge body.homepage section#hero .image:after {
  display: none;
}
body.homepage section.aboutStribe {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  body.homepage section.aboutStribe {
    margin-bottom: 100px;
  }
}
body.homepage section.aboutStribe h2 {
  color: #232320;
  font-size: 48px;
  line-height: 40px;
  margin-bottom: 15px;
}
body.homepage section.aboutStribe p {
  margin-bottom: 30px;
}
body.homepage section.aboutStribe p ~ div {
  text-align: center;
}
@media (min-width: 768px) {
  body.homepage section.aboutStribe p ~ div {
    text-align: left;
  }
}
body.homepage section.aboutStribe a.button {
  padding-right: 60px;
  font-weight: 600;
}
@media (min-width: 768px) {
  body.homepage section.aboutStribe a.button {
    margin-right: 30px;
  }
}
body.homepage section.aboutStribe a.join {
  display: inline-block;
  position: relative;
  color: #E72264;
  text-decoration: none;
  font-weight: 800;
  text-decoration: none;
  position: relative;
  text-decoration: none;
  margin-top: 20px;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.homepage section.aboutStribe a.join:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #E72264;
  height: 2px;
  width: 100%;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.homepage section.aboutStribe a.join:hover:after, body.homepage section.aboutStribe a.join:focus:after {
  transform: scaleX(0);
}
@media (min-width: 768px) {
  body.homepage section.aboutStribe a.join {
    margin-top: 0;
  }
}
body.homepage section.aboutStribe a.join:hover, body.homepage section.aboutStribe a.join:focus {
  color: #AA00FF;
}
body.homepage section.aboutStribe a.join:hover:after, body.homepage section.aboutStribe a.join:focus:after {
  background: #AA00FF;
  transform: scaleX(0);
}
body.homepage section.ebookPromo {
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  body.homepage section.ebookPromo {
    margin-bottom: 150px;
  }
}
body.homepage section.highlightedProducts {
  position: relative;
  width: calc(100% + 35px);
  margin-left: -20px;
  background: #F8F8F4;
  padding-top: 60px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 768px) {
  body.homepage section.highlightedProducts {
    padding-top: 125px;
    padding-bottom: 80px;
    width: calc(100% + 100px);
    margin-left: -50px;
  }
}
body.homepage section.highlightedProducts h2 {
  color: #E72264;
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  body.homepage section.highlightedProducts h2 {
    padding-bottom: 0;
  }
}
body.homepage section.highlightedProducts article > a {
  flex: 0 0 auto;
}
body.homepage section.highlightedProducts ul.highlightedProductsList {
  margin-bottom: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -10px;
  margin-right: -10px;
}
body.homepage section.highlightedProducts ul.highlightedProductsList li {
  flex: 1 1 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: appear;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  body.homepage section.highlightedProducts ul.highlightedProductsList li {
    flex-basis: 25%;
    max-width: 25%;
  }
}
body.homepage section.highlightedProducts ul.highlightedProductsList li.paged, body.homepage section.highlightedProducts ul.highlightedProductsList li.filtered {
  display: none;
}
body.homepage section.highlightedProducts .pager .controlBlock {
  position: relative !important;
}
body.homepage section.highlightedProducts:before {
  display: block;
  z-index: 1;
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  background-size: 100% 100%;
}
body.homepage section.highlightedProducts:before {
  background-image: url("/img/highlighted_curve_1.svg");
  top: 0;
  height: 30px;
  transform: translateY(-100%) translateY(1px);
}
@media (min-width: 768px) {
  body.homepage section.highlightedProducts:before {
    height: 120px;
  }
}
body.homepage section.highlightedProducts .filterInput .info {
  display: none;
}
body.homepage section.highlightedProducts .filterInput .selection {
  display: none;
}
body.homepage section.highlightedProducts .filterInput ul {
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
  opacity: 1;
  transform: none;
  z-index: 1;
  pointer-events: all;
  height: auto;
  overflow-y: auto;
}
body.homepage section.highlightedProducts .filterInput ul li {
  width: 50%;
  padding: 0 10px;
}
@media (min-width: 768px) {
  body.homepage section.highlightedProducts .filterInput ul li {
    margin-right: 40px;
    width: auto;
    padding: 0;
  }
}
body.homepage section.highlightedProducts .filterInput ul li:last-child {
  margin-right: 0;
}
body.homepage section.highlightedProducts .filterInput ul label {
  padding: 10px;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  background: transparent;
  width: 100%;
  height: 50px;
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid #E1E1D2;
}
@media (min-width: 768px) {
  body.homepage section.highlightedProducts .filterInput ul label {
    width: 175px;
    padding: 10px 30px;
    justify-content: flex-start;
  }
}
body.homepage section.highlightedProducts .filterInput ul label:after {
  display: none;
}
body.homepage section.highlightedProducts .filterInput ul label:before, body.homepage section.highlightedProducts .filterInput ul label:after {
  display: block;
  width: 100%;
  height: 100%;
  background: #E1E1D2;
  top: 0;
  left: 0;
  opacity: 0;
  transform: scale(0);
  z-index: -1;
  border-radius: 200px;
}
body.homepage section.highlightedProducts .filterInput ul label:after {
  background: #FFFFFF;
  opacity: 1;
  transform: none;
  z-index: -2;
  border: none;
}
body.homepage section.highlightedProducts .filterInput ul input:checked + label:before {
  transform: none;
  opacity: 1;
}
body.homepage section.stories {
  padding-top: 32px;
}
@media (min-width: 768px) {
  body.homepage section.stories {
    padding-top: 75px;
  }
}
body.homepage section.stories h2 {
  font-size: 36px;
  line-height: 32px;
}
body.homepage section.stories .storiesList {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  body.homepage section.stories .storiesList {
    margin-bottom: 50px;
  }
}
body.homepage section.USPs {
  padding-top: 80px;
}
@media (min-width: 768px) {
  body.homepage section.USPs {
    padding-top: 150px;
  }
}
body.homepage section.USPs h2 {
  color: #651FFF;
}
body.homepage section.USPs .USPFooter span {
  color: #E72264;
}
body.homepage section.newsletter {
  padding-top: 105px;
  padding-bottom: 120px;
}
@media (min-width: 768px) {
  body.homepage section.newsletter {
    padding-top: 164px;
    padding-bottom: 164px;
  }
}
body.homepage section.newsletter h2 {
  color: #12C700;
}
body.homepage section.parallaxBackground {
  margin-bottom: -20px;
}
body.homepage section#content h1 {
  color: #232320;
  font-size: 48px;
  line-height: 40px;
  margin-bottom: 20px;
}
body.homepage section#content h2 {
  margin-top: 0;
}
body.homepage section#content section.highlightedProducts h2 {
  margin-top: 0;
  margin-bottom: 0;
}
body.homepage section#content section.stories h2 {
  margin-top: 0;
  margin-bottom: 75px;
}
body.homepage section#content article.hasImage {
  flex-direction: row;
}
body.homepage section#content article.hasImage .image {
  width: auto;
  margin-right: 20px;
}
@media (min-width: 768px) {
  body.homepage section#content article.hasImage {
    flex-direction: column;
  }
  body.homepage section#content article.hasImage .image {
    width: 100%;
    margin-right: 0;
  }
}

body.striberdetailpage .striberIntro, body.mystriberpreview .striberIntro {
  margin-top: 65px;
  margin-bottom: 32px;
  z-index: 2;
  position: relative;
}
@media (min-width: 768px) {
  body.striberdetailpage .striberIntro, body.mystriberpreview .striberIntro {
    margin-top: 45px;
    margin-bottom: 40px;
  }
}
@media (min-width: 1024px) {
  body.striberdetailpage .striberIntro, body.mystriberpreview .striberIntro {
    margin-bottom: 0;
  }
}
@media (min-width: 1300px) {
  body.striberdetailpage .striberIntro, body.mystriberpreview .striberIntro {
    margin-bottom: -130px;
  }
}
body.striberdetailpage .striberIntro svg.svg, body.mystriberpreview .striberIntro svg.svg {
  position: absolute;
  height: 0;
  width: 0;
}
body.striberdetailpage .striberIntro img, body.mystriberpreview .striberIntro img {
  clip-path: url(#striber-mask);
}
.ie body.striberdetailpage .striberIntro img, .edge body.striberdetailpage .striberIntro img, .ie body.mystriberpreview .striberIntro img, .edge body.mystriberpreview .striberIntro img {
  border-radius: 31% 69% 56% 44%/57% 58% 42% 43%;
  overflow: hidden;
}
body.striberdetailpage .striberIntro h1, body.mystriberpreview .striberIntro h1 {
  color: #E72264;
  margin-top: 0;
  font-size: 48px;
  line-height: 48px;
}
body.striberdetailpage .striberIntro .subtitle, body.mystriberpreview .striberIntro .subtitle {
  display: inline-block;
  color: #83847B;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 28px;
  font-style: normal !important;
}
@media (min-width: 768px) {
  body.striberdetailpage .striberIntro .subtitle, body.mystriberpreview .striberIntro .subtitle {
    font-size: 18px;
    margin-bottom: 26px;
  }
}
body.striberdetailpage .striberIntro .actions li:not(body.striberdetailpage .striberIntro .actions li:last-child, body.mystriberpreview .striberIntro .actions li:last-child), body.mystriberpreview .striberIntro .actions li:not(body.striberdetailpage .striberIntro .actions li:last-child, body.mystriberpreview .striberIntro .actions li:last-child) {
  margin-bottom: 8px;
}
body.striberdetailpage .striberIntro .actions a.button, body.mystriberpreview .striberIntro .actions a.button {
  padding-right: 60px;
}
body.striberdetailpage .striberIntro .actions a:not(.button), body.mystriberpreview .striberIntro .actions a:not(.button) {
  color: #651FFF;
  font-size: 18px;
}
body.striberdetailpage .striberIntro .actions a:not(.button) svg.sprite, body.mystriberpreview .striberIntro .actions a:not(.button) svg.sprite {
  width: 15px;
  margin-right: 8.5px;
  vertical-align: middle;
}
body.striberdetailpage .striberIntro .actions a:not(.button) svg.sprite use, body.mystriberpreview .striberIntro .actions a:not(.button) svg.sprite use {
  fill: #651FFF;
}
body.striberdetailpage section#hero, body.mystriberpreview section#hero {
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  body.striberdetailpage section#hero, body.mystriberpreview section#hero {
    padding-top: 120px;
    padding-bottom: 100px;
  }
}
body.striberdetailpage section#hero .background:before, body.mystriberpreview section#hero .background:before {
  height: 40px;
}
@media (min-width: 768px) {
  body.striberdetailpage section#hero .background:before, body.mystriberpreview section#hero .background:before {
    height: 100px;
  }
}
body.striberdetailpage section#hero .background:after, body.mystriberpreview section#hero .background:after {
  height: 40px;
}
@media (min-width: 768px) {
  body.striberdetailpage section#hero .background:after, body.mystriberpreview section#hero .background:after {
    height: 50px;
  }
}
@media (min-width: 1024px) {
  body.striberdetailpage section#hero .background:after, body.mystriberpreview section#hero .background:after {
    height: 100px;
  }
}
body.striberdetailpage section#hero .background .layer.back, body.mystriberpreview section#hero .background .layer.back {
  background: linear-gradient(to bottom, #FFFFFF 0, #651FFF 1%, #651FFF 50%, #00E5FF 99%, #FFFFFF 100%);
}
body.striberdetailpage section#hero p, body.mystriberpreview section#hero p {
  width: 100%;
  color: #FFFFFF;
  margin-bottom: 30px;
  margin-top: 0;
  font-size: 24px;
  line-height: 32px;
}
@media (min-width: 768px) {
  body.striberdetailpage section#hero p, body.mystriberpreview section#hero p {
    text-align: right;
    margin-bottom: 0;
    padding: 65px 0;
  }
}
body.striberdetailpage section#hero img, body.mystriberpreview section#hero img {
  border-radius: 49% 51% 51% 49%/65% 64% 36% 35%;
}
body.striberdetailpage .umb-grid, body.mystriberpreview .umb-grid {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  body.striberdetailpage .umb-grid, body.mystriberpreview .umb-grid {
    margin-bottom: 70px;
  }
}
@media (min-width: 1024px) {
  body.striberdetailpage .umb-grid, body.mystriberpreview .umb-grid {
    margin-bottom: 65px;
  }
}
body.striberdetailpage section#content section.stories h2, body.mystriberpreview section#content section.stories h2 {
  margin-top: 5px;
  margin-bottom: 5px;
}
body.striberdetailpage section.stories, body.mystriberpreview section.stories {
  padding-top: 65px;
  padding-bottom: 75px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 65px;
}
@media (min-width: 768px) {
  body.striberdetailpage section.stories, body.mystriberpreview section.stories {
    margin-left: -75px;
    margin-right: -75px;
    padding-top: 115px;
    padding-bottom: 95px;
  }
}
@media (min-width: 1024px) {
  body.striberdetailpage section.stories, body.mystriberpreview section.stories {
    margin-left: -50px;
    margin-right: -50px;
    padding-bottom: 156px;
  }
}
@media (min-width: 1024px) {
  body.striberdetailpage section.stories, body.mystriberpreview section.stories {
    padding-bottom: 128px;
    padding-bottom: 175px;
  }
}
body.striberdetailpage section.stories .background, body.mystriberpreview section.stories .background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #F8F8F4;
}
body.striberdetailpage section.stories .background:before, body.striberdetailpage section.stories .background:after, body.mystriberpreview section.stories .background:before, body.mystriberpreview section.stories .background:after {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  background-size: 100% 100%;
  height: 14px;
}
@media (min-width: 768px) {
  body.striberdetailpage section.stories .background:before, body.striberdetailpage section.stories .background:after, body.mystriberpreview section.stories .background:before, body.mystriberpreview section.stories .background:after {
    height: 29px;
  }
}
@media (min-width: 1024px) {
  body.striberdetailpage section.stories .background:before, body.striberdetailpage section.stories .background:after, body.mystriberpreview section.stories .background:before, body.mystriberpreview section.stories .background:after {
    height: 39px;
  }
}
@media (min-width: 1300px) {
  body.striberdetailpage section.stories .background:before, body.striberdetailpage section.stories .background:after, body.mystriberpreview section.stories .background:before, body.mystriberpreview section.stories .background:after {
    height: 55px;
  }
}
body.striberdetailpage section.stories .background:before, body.mystriberpreview section.stories .background:before {
  background-image: url("/img/stories_curve_2.svg");
  top: 0;
}
body.striberdetailpage section.stories .background:after, body.mystriberpreview section.stories .background:after {
  background-image: url("/img/stories_curve_1.svg");
  bottom: 0;
}
body.striberdetailpage section.stories h2, body.mystriberpreview section.stories h2 {
  color: #E72264;
}
body.striberdetailpage section#content section.products h2, body.mystriberpreview section#content section.products h2 {
  margin: 5px auto 20px;
}
body.striberdetailpage section.products, body.mystriberpreview section.products {
  margin-bottom: 130px;
}
@media (min-width: 1024px) {
  body.striberdetailpage section.products, body.mystriberpreview section.products {
    margin-bottom: 160px;
  }
}
body.striberdetailpage section.products h2, body.mystriberpreview section.products h2 {
  color: #E72264;
  font-size: 36px;
  line-height: 42px;
}
body.striberdetailpage section.products > div:nth-of-type(1) > .row, body.mystriberpreview section.products > div:nth-of-type(1) > .row {
  margin-bottom: 35px;
}
body.striberdetailpage section.products > div:nth-of-type(2) > .row, body.mystriberpreview section.products > div:nth-of-type(2) > .row {
  margin-bottom: 35px;
}
body.striberdetailpage section.products > div:nth-of-type(2) > .row > div, body.mystriberpreview section.products > div:nth-of-type(2) > .row > div {
  display: flex;
  align-items: center;
}
body.striberdetailpage section.products a.readmore, body.mystriberpreview section.products a.readmore {
  display: inline-flex;
  align-items: center;
  color: #83847B;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
}
body.striberdetailpage section.products a.readmore svg, body.mystriberpreview section.products a.readmore svg {
  margin-left: 5px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.striberdetailpage section.products a.readmore:hover svg, body.striberdetailpage section.products a.readmore:focus svg, body.mystriberpreview section.products a.readmore:hover svg, body.mystriberpreview section.products a.readmore:focus svg {
  transform: translateX(4px);
}
body.striberdetailpage section.products ul.productsList, body.mystriberpreview section.products ul.productsList {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
body.striberdetailpage section.products ul.productsList li, body.mystriberpreview section.products ul.productsList li {
  margin: 10px;
}
@media (min-width: 768px) {
  body.striberdetailpage section.products ul.productsList li, body.mystriberpreview section.products ul.productsList li {
    max-width: 33%;
    flex-basis: 33%;
  }
}
@media (min-width: 1024px) {
  body.striberdetailpage section.products ul.productsList li, body.mystriberpreview section.products ul.productsList li {
    max-width: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 1300px) {
  body.striberdetailpage section.products ul.productsList li, body.mystriberpreview section.products ul.productsList li {
    margin: 10px 20px;
  }
}

body.journaldetailpage section#content h1 {
  margin-top: 0;
  margin-bottom: 32px;
  color: #232320;
}
body.journaldetailpage section#content h2, body.journaldetailpage section#content h3, body.journaldetailpage section#content h4 {
  margin-bottom: 32px;
}
body.journaldetailpage section#content h5 {
  margin-bottom: 26px;
}
body.journaldetailpage section#content .col > img {
  display: inline-block;
  border-radius: 30px;
  margin-bottom: 40px;
}
@media (max-width: 767.98px) {
  body.journaldetailpage section#content .videoContainer {
    width: calc(100% + 60px);
    margin-left: -30px;
    padding-bottom: 70%;
    border-radius: 0;
  }
  body.journaldetailpage section#content .videoContainer .video .videoPoster img {
    border-radius: 0;
  }
}
body.journaldetailpage [class*=userContent] p {
  font-size: 18px;
  line-height: 32px;
}
body.journaldetailpage [class*=userContent] img {
  border-radius: 32px;
}
body.journaldetailpage [class*=userContent] ul li, body.journaldetailpage [class*=userContent] ol li {
  line-height: 32px;
}
body.journaldetailpage [class*=userContent] ol,
body.journaldetailpage [class*=userContent] ul {
  margin: 20px 0;
}
body.journaldetailpage [class*=userContent] ol li,
body.journaldetailpage [class*=userContent] ul li {
  position: relative;
  margin-left: 25px;
  margin-bottom: 10px;
}
body.journaldetailpage [class*=userContent] ol li:last-child,
body.journaldetailpage [class*=userContent] ul li:last-child {
  margin-bottom: 0;
}
body.journaldetailpage [class*=userContent] *::selection {
  background: #E1E1D2;
}
body.journaldetailpage [class*=userContent] ul {
  padding-left: 20px;
}
body.journaldetailpage [class*=userContent] ul li:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -34px;
  top: 16px;
  width: 24px;
  height: 2px;
  background: #651FFF;
}
body.journaldetailpage [class*=userContent] ol {
  padding-left: 20px;
  counter-set: list;
}
body.journaldetailpage [class*=userContent] ol li {
  counter-increment: list;
}
body.journaldetailpage [class*=userContent] ol li:before {
  position: absolute;
  right: 110%;
  right: calc(100% + 10px);
  width: 30px;
  text-align: right;
  content: counter(list) ".";
  font-weight: 500;
  color: #651FFF;
}
body.journaldetailpage [class*=userContent] ol li ol {
  counter-set: listlist;
}
body.journaldetailpage [class*=userContent] ol li ol li {
  counter-increment: listlist;
}
body.journaldetailpage [class*=userContent] ol li ol li:before {
  content: counter(listlist) ".";
}
body.journaldetailpage [class*=userContent][class*=userContentImages] img {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body.journaldetailpage [class*=userContent][class*=userContentImages] img {
    margin-bottom: 0;
  }
}
body.journaldetailpage .striberQuickView, body.journaldetailpage .striberQuickView + .likes {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body.journaldetailpage .striberQuickView, body.journaldetailpage .striberQuickView + .likes {
    margin-bottom: 0;
  }
}
body.journaldetailpage .striberQuickView + .likes {
  margin-left: auto;
}
@media (min-width: 768px) {
  body.journaldetailpage .striberQuickView + .likes {
    margin-right: 30px;
  }
}
body.journaldetailpage .journalHeaderInfo {
  margin-bottom: 64px;
}
body.journaldetailpage .journalHeaderInfo > div {
  padding-bottom: 10px;
  border-bottom: 1px solid #E1E1D2;
}
body.journaldetailpage .journalFooterInfoOne {
  margin-top: 64px;
  margin-bottom: 75px;
}
@media (min-width: 768px) {
  body.journaldetailpage .journalFooterInfoOne .shareContainer {
    margin-left: auto;
  }
}
body.journaldetailpage .SDGGoalsRow {
  margin-bottom: 50px;
}
body.journaldetailpage .SDGGoalsRow ol.SDG {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: -8px;
}
body.journaldetailpage .SDGGoalsRow ol.SDG li {
  margin-top: 8px;
  margin-right: 8px;
}
body.journaldetailpage .SDGGoalsRow ol.SDG li:last-child {
  margin-right: 0;
}
body.journaldetailpage .SDGGoalsRow ol.SDG li .goal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  height: 48px;
  width: 48px;
  border-radius: 48px;
}
body.journaldetailpage .tagsRow {
  margin-bottom: 100px;
}
body.journaldetailpage address {
  font-size: 18px;
  opacity: 1;
  font-weight: 300;
  color: #83847B;
}
body.journaldetailpage p.intro {
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 48px;
}

body.journalsoverviewpage section#content .highlightedJournal {
  padding-bottom: 85px;
  margin-bottom: 75px;
  border-bottom: 2px solid #E1E1D2;
}
body.journalsoverviewpage section#content .highlightedJournal .imageContainer img {
  border-radius: 32px;
}
body.journalsoverviewpage section#content .highlightedJournal .infoContainer h2 {
  margin-top: 10px;
  margin-bottom: 10px;
}
body.journalsoverviewpage section#content .highlightedJournal .infoContainer .journalInfo {
  display: flex;
  align-items: center;
}
body.journalsoverviewpage section#content .highlightedJournal .infoContainer .journalInfo .likes {
  margin-right: 20px;
}
body.journalsoverviewpage section#content .highlightedJournal .infoContainer .journalInfo .likes .icon {
  display: inline-flex;
  align-items: center;
  margin-right: 0;
}
body.journalsoverviewpage section#content .highlightedJournal .infoContainer .journalInfo .likes .icon svg.sprite {
  width: 19px;
  max-height: 16px;
  margin-right: 3px;
}
body.journalsoverviewpage section#content .highlightedJournal .infoContainer .journalInfo .likes .icon span {
  position: relative;
  color: #232320;
  font-size: 16px;
  top: 0;
}
body.journalsoverviewpage section#content .highlightedJournal .infoContainer .journalInfo .image {
  width: 56px;
  height: 56px;
}
body.journalsoverviewpage section#content .highlightedJournal .infoContainer .journalInfo .image img {
  width: 56px;
  height: 56px;
}
body.journalsoverviewpage section#content .highlightedJournal .infoContainer p {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 9px;
}
body.journalsoverviewpage section#content .highlightedJournal .infoContainer p + a {
  color: #83847B;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  position: relative;
  text-decoration: none;
}
body.journalsoverviewpage section#content .highlightedJournal .infoContainer p + a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #AA00FF;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.journalsoverviewpage section#content .highlightedJournal .infoContainer p + a:hover:after, body.journalsoverviewpage section#content .highlightedJournal .infoContainer p + a:focus:after {
  transform: none;
}
body.journalsoverviewpage section#content .highlightedJournal .infoContainer p + a:hover, body.journalsoverviewpage section#content .highlightedJournal .infoContainer p + a:focus {
  color: #AA00FF;
}
body.journalsoverviewpage section#content h1 {
  margin-top: 0;
  color: #232320;
}
body.journalsoverviewpage .overviewHeaderContainer {
  margin-bottom: 50px;
}
body.journalsoverviewpage .overviewHeaderContainer h1 {
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  body.journalsoverviewpage .overviewHeaderContainer h1 {
    padding-bottom: 0;
  }
}
body.journalsoverviewpage .openFiltersRow {
  margin-bottom: 30px;
}
body.journalsoverviewpage .openFiltersRow a.button.openFilters {
  width: 100%;
  text-align: center;
  display: inline-block;
}
body.journalsoverviewpage .sortOptions {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
body.journalsoverviewpage .sortOptions * {
  white-space: nowrap;
}
@media (min-width: 768px) {
  body.journalsoverviewpage .sortOptions {
    margin-bottom: 0;
  }
}
body.journalsoverviewpage .sortOptions > span {
  margin-right: 8px;
}
body.journalsoverviewpage .sortOptions .sortOption {
  margin-right: 12px;
}
body.journalsoverviewpage .sortOptions .sortOption:last-child {
  margin-right: 0;
}
body.journalsoverviewpage .sortOptions .sortOption input {
  display: none;
}
body.journalsoverviewpage .sortOptions .sortOption label {
  color: #83847B;
  border-bottom: 1px solid transparent;
  padding-bottom: 5px;
  cursor: pointer;
  line-height: 30px;
  transition: border-color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.journalsoverviewpage .sortOptions .sortOption input:checked + label {
  border-bottom-color: #83847B;
  cursor: default;
}
body.journalsoverviewpage .journalsContainer ul.targetsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -40px;
  margin-bottom: 70px;
}
body.journalsoverviewpage .journalsContainer ul.targetsContainer > li {
  flex: 0 0 auto;
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 40px;
  margin-left: 0;
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: appear;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 375px) {
  body.journalsoverviewpage .journalsContainer ul.targetsContainer > li {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  body.journalsoverviewpage .journalsContainer ul.targetsContainer > li {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
}
body.journalsoverviewpage .pagerContainer {
  margin-bottom: 70px;
}

body.stribersoverviewpage section#content .highlightedStriber {
  padding-bottom: 85px;
  margin-bottom: 75px;
  border-bottom: 2px solid #E1E1D2;
}
body.stribersoverviewpage section#content .highlightedStriber .imageContainer svg.svg {
  position: absolute;
  height: 0;
  width: 0;
}
body.stribersoverviewpage section#content .highlightedStriber .imageContainer img {
  clip-path: url(#striber-mask);
}
.ie body.stribersoverviewpage section#content .highlightedStriber .imageContainer img, .edge body.stribersoverviewpage section#content .highlightedStriber .imageContainer img {
  border-radius: 31% 69% 56% 44%/57% 58% 42% 43%;
  overflow: hidden;
}
body.stribersoverviewpage section#content .highlightedStriber .infoContainer h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #E72264;
}
body.stribersoverviewpage section#content .highlightedStriber .infoContainer .journalInfo {
  display: flex;
  align-items: center;
}
body.stribersoverviewpage section#content .highlightedStriber .infoContainer .journalInfo .likes {
  margin-right: 20px;
}
body.stribersoverviewpage section#content .highlightedStriber .infoContainer .journalInfo .likes .icon {
  display: inline-flex;
  align-items: center;
  margin-right: 0;
}
body.stribersoverviewpage section#content .highlightedStriber .infoContainer .journalInfo .likes .icon svg.sprite {
  width: 19px;
  max-height: 16px;
  margin-right: 3px;
}
body.stribersoverviewpage section#content .highlightedStriber .infoContainer .journalInfo .likes .icon span {
  position: relative;
  color: #232320;
  font-size: 16px;
  top: 0;
}
body.stribersoverviewpage section#content .highlightedStriber .infoContainer .journalInfo .image {
  width: 56px;
  height: 56px;
}
body.stribersoverviewpage section#content .highlightedStriber .infoContainer .journalInfo .image img {
  width: 56px;
  height: 56px;
}
body.stribersoverviewpage section#content .highlightedStriber .infoContainer p {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 9px;
}
body.stribersoverviewpage section#content .highlightedStriber .infoContainer p + a {
  color: #83847B;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  position: relative;
  text-decoration: none;
}
body.stribersoverviewpage section#content .highlightedStriber .infoContainer p + a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #AA00FF;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.stribersoverviewpage section#content .highlightedStriber .infoContainer p + a:hover:after, body.stribersoverviewpage section#content .highlightedStriber .infoContainer p + a:focus:after {
  transform: none;
}
body.stribersoverviewpage section#content .highlightedStriber .infoContainer p + a:hover, body.stribersoverviewpage section#content .highlightedStriber .infoContainer p + a:focus {
  color: #AA00FF;
}
body.stribersoverviewpage section#content h1 {
  margin-top: 0;
  color: #232320;
}
body.stribersoverviewpage .overviewHeaderContainer {
  margin-bottom: 50px;
}
body.stribersoverviewpage .overviewHeaderContainer h1 {
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  body.stribersoverviewpage .overviewHeaderContainer h1 {
    padding-bottom: 0;
  }
}
body.stribersoverviewpage .openFiltersRow {
  margin-bottom: 30px;
}
body.stribersoverviewpage .openFiltersRow a.button.openFilters {
  width: 100%;
  text-align: center;
  display: inline-block;
}
body.stribersoverviewpage .sortOptions {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
body.stribersoverviewpage .sortOptions * {
  white-space: nowrap;
}
@media (min-width: 768px) {
  body.stribersoverviewpage .sortOptions {
    margin-bottom: 0;
  }
}
body.stribersoverviewpage .sortOptions > span {
  margin-right: 8px;
}
body.stribersoverviewpage .sortOptions .sortOption {
  margin-right: 12px;
}
body.stribersoverviewpage .sortOptions .sortOption:last-child {
  margin-right: 0;
}
body.stribersoverviewpage .sortOptions .sortOption input {
  display: none;
}
body.stribersoverviewpage .sortOptions .sortOption label {
  color: #83847B;
  border-bottom: 1px solid transparent;
  padding-bottom: 5px;
  cursor: pointer;
  line-height: 30px;
  transition: border-color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.stribersoverviewpage .sortOptions .sortOption input:checked + label {
  border-bottom-color: #83847B;
  cursor: default;
}
body.stribersoverviewpage .stribersContainer ul.targetsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -40px;
  margin-bottom: 70px;
}
body.stribersoverviewpage .stribersContainer ul.targetsContainer > li {
  flex: 0 0 auto;
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 40px;
  margin-left: 0;
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: appear;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 375px) {
  body.stribersoverviewpage .stribersContainer ul.targetsContainer > li {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  body.stribersoverviewpage .stribersContainer ul.targetsContainer > li {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
}
body.stribersoverviewpage .pagerContainer {
  margin-bottom: 70px;
}

body.jointhestribelandingpage section#hero,
body.investlandingpage section#hero {
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 70px;
}
@media (min-width: 768px) {
  body.jointhestribelandingpage section#hero,
  body.investlandingpage section#hero {
    padding-top: 200px;
    padding-bottom: 200px;
    margin-bottom: 70px;
  }
}
body.jointhestribelandingpage section#hero .background:before,
body.investlandingpage section#hero .background:before {
  height: 50px;
  transform: scaleX(-1);
}
@media (min-width: 768px) {
  body.jointhestribelandingpage section#hero .background:before,
  body.investlandingpage section#hero .background:before {
    height: 110px;
  }
}
body.jointhestribelandingpage section#hero .background:after,
body.investlandingpage section#hero .background:after {
  height: 50px;
}
@media (min-width: 768px) {
  body.jointhestribelandingpage section#hero .background:after,
  body.investlandingpage section#hero .background:after {
    height: 100px;
  }
}
body.jointhestribelandingpage section#hero .background .layer.back,
body.investlandingpage section#hero .background .layer.back {
  background: linear-gradient(to bottom, #E72264 0, #E72264 50%, #FFC400 100%);
}
body.jointhestribelandingpage section#hero h1,
body.investlandingpage section#hero h1 {
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 56px;
  line-height: 48px;
  font-weight: 600;
}
@media (min-width: 768px) {
  body.jointhestribelandingpage section#hero h1,
  body.investlandingpage section#hero h1 {
    margin-bottom: 0;
  }
}
body.jointhestribelandingpage section#hero p,
body.investlandingpage section#hero p {
  width: 100%;
  color: #FFFFFF;
  margin-bottom: 28px;
  margin-top: 0;
  font-size: 24px;
  line-height: 32px;
}
@media (min-width: 768px) {
  body.jointhestribelandingpage section#hero p,
  body.investlandingpage section#hero p {
    margin-bottom: 20px;
  }
}
body.jointhestribelandingpage section#hero .c2as,
body.investlandingpage section#hero .c2as {
  margin-bottom: 28px;
  color: #FFFFFF;
  font-size: 32px;
  line-height: 37px;
  font-weight: 900;
  display: block;
}
body.jointhestribelandingpage section#hero .c2as a,
body.investlandingpage section#hero .c2as a {
  color: #FFFFFF;
  text-decoration: none;
  position: relative;
  display: inline;
  color: #FFC400;
}
@media (min-width: 768px) {
  body.jointhestribelandingpage section#hero .c2as a,
  body.investlandingpage section#hero .c2as a {
    color: inherit;
  }
}
@media (min-width: 768px) {
  body.jointhestribelandingpage section#hero .c2as a,
  body.investlandingpage section#hero .c2as a {
    display: inline-block;
  }
}
body.jointhestribelandingpage section#hero .c2as a:after,
body.investlandingpage section#hero .c2as a:after {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: #FFFFFF;
  transition: background 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  body.jointhestribelandingpage section#hero .c2as a:after,
  body.investlandingpage section#hero .c2as a:after {
    content: "";
  }
}
body.jointhestribelandingpage section#hero .c2as a:hover:after, body.jointhestribelandingpage section#hero .c2as a:focus:after,
body.investlandingpage section#hero .c2as a:hover:after,
body.investlandingpage section#hero .c2as a:focus:after {
  background: #FFC400;
}
body.jointhestribelandingpage section#hero .c2as a.button,
body.investlandingpage section#hero .c2as a.button {
  width: 100%;
}
@media (min-width: 768px) {
  body.jointhestribelandingpage section#hero .c2as a.button,
  body.investlandingpage section#hero .c2as a.button {
    width: auto;
  }
}
body.jointhestribelandingpage section#hero .c2as a.button:first-child:not(:last-child),
body.investlandingpage section#hero .c2as a.button:first-child:not(:last-child) {
  margin-bottom: 10px;
}
@media (min-width: 1024px) {
  body.jointhestribelandingpage section#hero .c2as a.button:first-child:not(:last-child),
  body.investlandingpage section#hero .c2as a.button:first-child:not(:last-child) {
    margin-bottom: 0;
    margin-right: 20px;
  }
}
body.jointhestribelandingpage section#hero .c2as a.button:last-child:not(:first-child),
body.investlandingpage section#hero .c2as a.button:last-child:not(:first-child) {
  margin-top: 10px;
}
@media (min-width: 1024px) {
  body.jointhestribelandingpage section#hero .c2as a.button:last-child:not(:first-child),
  body.investlandingpage section#hero .c2as a.button:last-child:not(:first-child) {
    margin-top: 0;
    margin-left: 20px;
  }
}
body.jointhestribelandingpage section#hero .c2as + span,
body.investlandingpage section#hero .c2as + span {
  font-size: 16px;
  line-height: 32px;
  color: #FFFFFF;
}
body.jointhestribelandingpage section#hero .c2as + span a,
body.investlandingpage section#hero .c2as + span a {
  position: relative;
  color: #FFFFFF;
  text-decoration: none;
}
body.jointhestribelandingpage section#hero .c2as + span a:after,
body.investlandingpage section#hero .c2as + span a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: #FFFFFF;
  transition: background 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.jointhestribelandingpage section#hero .c2as + span a:hover:after, body.jointhestribelandingpage section#hero .c2as + span a:focus:after,
body.investlandingpage section#hero .c2as + span a:hover:after,
body.investlandingpage section#hero .c2as + span a:focus:after {
  background: #FFC400;
}
body.jointhestribelandingpage section#hero a.button,
body.investlandingpage section#hero a.button {
  padding-right: 60px;
  font-weight: 600;
}
body.jointhestribelandingpage section#hero a.button.clear,
body.investlandingpage section#hero a.button.clear {
  color: #FFFFFF;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: color, border-color, background;
}
body.jointhestribelandingpage section#hero a.button.clear:hover, body.jointhestribelandingpage section#hero a.button.clear:focus,
body.investlandingpage section#hero a.button.clear:hover,
body.investlandingpage section#hero a.button.clear:focus {
  background: #651FFF;
  border-color: #651FFF;
}
body.jointhestribelandingpage section#hero svg,
body.investlandingpage section#hero svg {
  position: absolute;
}
body.jointhestribelandingpage section#hero img,
body.investlandingpage section#hero img {
  clip-path: url(#home-mask);
}
.ie body.jointhestribelandingpage section#hero img, .edge body.jointhestribelandingpage section#hero img,
.ie body.investlandingpage section#hero img,
.edge body.investlandingpage section#hero img {
  border-radius: 31% 69% 56% 44%/57% 58% 42% 43%;
  overflow: hidden;
}
body.jointhestribelandingpage section.alternating,
body.investlandingpage section.alternating {
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  body.jointhestribelandingpage section.alternating,
  body.investlandingpage section.alternating {
    margin-bottom: 100px;
  }
}
body.jointhestribelandingpage section.quote,
body.investlandingpage section.quote {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body.jointhestribelandingpage section.quote,
  body.investlandingpage section.quote {
    margin-bottom: 65px;
  }
}
body.jointhestribelandingpage section.USPs,
body.investlandingpage section.USPs {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  body.jointhestribelandingpage section.USPs,
  body.investlandingpage section.USPs {
    margin-bottom: 100px;
  }
}
body.jointhestribelandingpage section.USPs h2,
body.investlandingpage section.USPs h2 {
  color: #E72264;
}
body.jointhestribelandingpage section.partners,
body.investlandingpage section.partners {
  margin-bottom: 90px;
}
body.jointhestribelandingpage section.partners h2,
body.investlandingpage section.partners h2 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  color: #83847B;
}
body.jointhestribelandingpage section.partners ~ .container,
body.investlandingpage section.partners ~ .container {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  body.jointhestribelandingpage section.partners ~ .container,
  body.investlandingpage section.partners ~ .container {
    margin-bottom: 100px;
  }
}
body.jointhestribelandingpage section#content h2,
body.investlandingpage section#content h2 {
  margin-top: 0;
}
body.jointhestribelandingpage section#content .umb-grid .row,
body.investlandingpage section#content .umb-grid .row {
  justify-content: center;
}

body.investlandingpage section#hero .background:before {
  height: 40px;
}
@media (min-width: 768px) {
  body.investlandingpage section#hero .background:before {
    height: 80px;
  }
}
body.investlandingpage section#hero .background .layer.back {
  background: linear-gradient(to bottom, #E72264 0, #AA00FF 50%, #651FFF 100%);
}
body.investlandingpage section#hero h1 {
  padding-bottom: 20px;
  font-size: 48px;
  line-height: 40px;
}
@media (min-width: 1300px) {
  body.investlandingpage section#hero h1 {
    padding-bottom: 0;
    font-size: 56px;
    line-height: 48px;
  }
}
body.investlandingpage section.partners ~ .container h3 {
  font-weight: 500;
}
body.investlandingpage section#content section.partners ~ .container h3 {
  margin-top: 0;
}
body.investlandingpage section#content .umb-grid .row {
  justify-content: center;
}
body.investlandingpage section.parallaxBackground {
  overflow: visible;
}
body.investlandingpage section.parallaxBackground .background {
  overflow: hidden;
}

body.signuplandingpage .simpleHero {
  padding-top: 128px;
  margin-bottom: 143px;
  font-size: 24px;
  line-height: 40px;
}
body.signuplandingpage .simpleHero p {
  margin-top: 0;
}
body.signuplandingpage .simpleHero a.button {
  font-size: 22px;
  line-height: 32px;
  padding-right: 60px;
}
body.signuplandingpage h1 {
  color: #651FFF;
  font-size: 48px;
  line-height: 48px;
}
body.signuplandingpage section.howItWorks {
  position: relative;
  background: #F8F8F4;
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 64px;
  padding-right: 15px;
  padding-bottom: 64px;
  padding-left: 15px;
  margin-top: 80px;
  margin-bottom: 144px;
}
@media (min-width: 768px) {
  body.signuplandingpage section.howItWorks {
    margin-left: -75px;
    margin-right: -75px;
    padding-left: 75px;
    padding-right: 75px;
  }
}
body.signuplandingpage section.howItWorks:before, body.signuplandingpage section.howItWorks:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 80px;
  background-image: url("/img/highlighted_curve_1.svg");
  background-size: 100% 100%;
  z-index: 1;
}
body.signuplandingpage section.howItWorks:before {
  transform: translateY(-100%) translateY(1px);
  top: 0;
}
body.signuplandingpage section.howItWorks:after {
  bottom: 0;
  transform: translateY(100%) translateY(-1px) rotate(180deg);
}
body.signuplandingpage section.howItWorks h2 {
  color: #E72264;
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
}
body.signuplandingpage section.howItWorks .container .row .col img, body.signuplandingpage section.howItWorks .container .row [class*=col-] img {
  margin-bottom: 20px;
}
body.signuplandingpage section.howItWorks .container .row .col h3, body.signuplandingpage section.howItWorks .container .row [class*=col-] h3 {
  font-size: 24px;
  line-height: 32px;
}
body.signuplandingpage section.howItWorks .container .row .col p, body.signuplandingpage section.howItWorks .container .row [class*=col-] p {
  font-size: 18px;
  line-height: 32px;
}
body.signuplandingpage .container.video {
  margin-bottom: 94px;
}
body.signuplandingpage .faq {
  margin-bottom: 100px;
}
body.signuplandingpage .faq h2 {
  font-size: 32px;
  line-height: 32px;
  text-align: left;
}
@media (min-width: 768px) {
  body.signuplandingpage .faq h2 {
    text-align: right;
  }
}
body.signuplandingpage section#content h1, body.signuplandingpage section#content h2, body.signuplandingpage section#content h3, body.signuplandingpage section#content h4 {
  margin-top: 0;
}
body.signuplandingpage section#content section.howItWorks h2 {
  margin-bottom: 10px;
}
body.signuplandingpage section#content section.howItWorks .container .row .col h3, body.signuplandingpage section#content section.howItWorks .container .row [class*=col-] h3 {
  margin-bottom: 30px;
}
body.signuplandingpage section#content .umb-grid .row {
  justify-content: center;
}
body.signuplandingpage section.subscriptions h2 {
  color: #a0f;
}

body.projectdetailpage h1 {
  color: #232320;
}
body.projectdetailpage .striberSmall {
  margin-bottom: 32px;
}
body.projectdetailpage .striberSmall img {
  margin-right: 11px;
}
body.projectdetailpage .baseRow {
  margin-bottom: 64px;
}
@media (min-width: 768px) {
  body.projectdetailpage .baseRow {
    margin-bottom: 132px;
  }
}
body.projectdetailpage a.back {
  text-decoration: none;
  color: #83847B;
  display: inline-flex;
  align-items: center;
  min-height: 1px;
  padding: 5px 0;
  margin-bottom: 30px;
  position: relative;
  text-decoration: none;
}
@media (min-width: 768px) {
  body.projectdetailpage a.back {
    margin-bottom: 15px;
  }
}
body.projectdetailpage a.back:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #83847B;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.projectdetailpage a.back:hover:after, body.projectdetailpage a.back:focus:after {
  transform: none;
}
body.projectdetailpage a.back svg.sprite {
  margin-right: 10px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.projectdetailpage a.back:hover svg.sprite, body.projectdetailpage a.back:focus svg.sprite {
  transform: translateX(-5px);
}
body.projectdetailpage .imagesColumn {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  body.projectdetailpage .imagesColumn {
    margin-bottom: 0;
  }
}
body.projectdetailpage .projectImageSliderMain,
body.projectdetailpage .projectImagesSliderSmall {
  position: relative;
}
body.projectdetailpage .projectImageSliderMain .previousContainer,
body.projectdetailpage .projectImageSliderMain .nextContainer,
body.projectdetailpage .projectImagesSliderSmall .previousContainer,
body.projectdetailpage .projectImagesSliderSmall .nextContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  z-index: 1;
}
body.projectdetailpage .projectImageSliderMain .previousContainer a,
body.projectdetailpage .projectImageSliderMain .nextContainer a,
body.projectdetailpage .projectImagesSliderSmall .previousContainer a,
body.projectdetailpage .projectImagesSliderSmall .nextContainer a {
  display: inline-block;
  background: none;
  border: none;
  width: 26px;
  height: 38px;
  padding: 5px;
  cursor: pointer;
  transition: opacity 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  body.projectdetailpage .projectImageSliderMain .previousContainer a,
  body.projectdetailpage .projectImageSliderMain .nextContainer a,
  body.projectdetailpage .projectImagesSliderSmall .previousContainer a,
  body.projectdetailpage .projectImagesSliderSmall .nextContainer a {
    width: 40px;
    height: 56px;
    padding: 10px;
  }
}
body.projectdetailpage .projectImageSliderMain .previousContainer a svg.sprite,
body.projectdetailpage .projectImageSliderMain .nextContainer a svg.sprite,
body.projectdetailpage .projectImagesSliderSmall .previousContainer a svg.sprite,
body.projectdetailpage .projectImagesSliderSmall .nextContainer a svg.sprite {
  display: inline-block;
  width: 16px;
  max-height: 28px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  body.projectdetailpage .projectImageSliderMain .previousContainer a svg.sprite,
  body.projectdetailpage .projectImageSliderMain .nextContainer a svg.sprite,
  body.projectdetailpage .projectImagesSliderSmall .previousContainer a svg.sprite,
  body.projectdetailpage .projectImagesSliderSmall .nextContainer a svg.sprite {
    width: 20px;
    max-height: 36px;
  }
}
body.projectdetailpage .projectImageSliderMain .previousContainer a svg.sprite use,
body.projectdetailpage .projectImageSliderMain .nextContainer a svg.sprite use,
body.projectdetailpage .projectImagesSliderSmall .previousContainer a svg.sprite use,
body.projectdetailpage .projectImagesSliderSmall .nextContainer a svg.sprite use {
  stroke: #E1E1D2;
}
body.projectdetailpage .projectImageSliderMain .previousContainer,
body.projectdetailpage .projectImagesSliderSmall .previousContainer {
  left: 0;
}
@media (min-width: 768px) {
  body.projectdetailpage .projectImageSliderMain .previousContainer,
  body.projectdetailpage .projectImagesSliderSmall .previousContainer {
    left: 5%;
  }
}
body.projectdetailpage .projectImageSliderMain .previousContainer a:hover svg.sprite,
body.projectdetailpage .projectImagesSliderSmall .previousContainer a:hover svg.sprite {
  transform: translateX(-5px);
}
body.projectdetailpage .projectImageSliderMain .nextContainer,
body.projectdetailpage .projectImagesSliderSmall .nextContainer {
  right: 0;
}
@media (min-width: 768px) {
  body.projectdetailpage .projectImageSliderMain .nextContainer,
  body.projectdetailpage .projectImagesSliderSmall .nextContainer {
    right: 5%;
  }
}
body.projectdetailpage .projectImageSliderMain .nextContainer a:hover svg.sprite,
body.projectdetailpage .projectImagesSliderSmall .nextContainer a:hover svg.sprite {
  transform: translateX(5px) scaleX(-1);
}
body.projectdetailpage .projectImageSliderMain {
  margin-bottom: 17px;
}
body.projectdetailpage .projectImageSliderMain .previousContainer a,
body.projectdetailpage .projectImageSliderMain .nextContainer a {
  width: 26px;
  height: 38px;
  padding: 5px;
}
@media (min-width: 768px) {
  body.projectdetailpage .projectImageSliderMain .previousContainer a,
  body.projectdetailpage .projectImageSliderMain .nextContainer a {
    width: 40px;
    height: 56px;
    padding: 10px;
  }
}
body.projectdetailpage .projectImageSliderMain .previousContainer a svg.sprite,
body.projectdetailpage .projectImageSliderMain .nextContainer a svg.sprite {
  width: 16px;
  max-height: 28px;
}
@media (min-width: 768px) {
  body.projectdetailpage .projectImageSliderMain .previousContainer a svg.sprite,
  body.projectdetailpage .projectImageSliderMain .nextContainer a svg.sprite {
    width: 20px;
    max-height: 36px;
  }
}
body.projectdetailpage .projectImageSliderMain li .image {
  position: relative;
  height: 0;
  padding-bottom: 56%;
}
body.projectdetailpage .projectImageSliderMain li .image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 32px;
  object-fit: cover;
}
body.projectdetailpage .projectImagesSliderSmall {
  padding-left: 10%;
  padding-right: 10%;
}
body.projectdetailpage .projectImagesSliderSmall .previousContainer a,
body.projectdetailpage .projectImagesSliderSmall .nextContainer a {
  width: 22px;
  height: 30px;
  padding: 5px;
}
@media (min-width: 768px) {
  body.projectdetailpage .projectImagesSliderSmall .previousContainer a,
  body.projectdetailpage .projectImagesSliderSmall .nextContainer a {
    width: 32px;
    height: 40px;
    padding: 10px;
  }
}
body.projectdetailpage .projectImagesSliderSmall .previousContainer a svg.sprite,
body.projectdetailpage .projectImagesSliderSmall .nextContainer a svg.sprite {
  width: 12px;
  max-height: 20px;
}
@media (min-width: 768px) {
  body.projectdetailpage .projectImagesSliderSmall .previousContainer a svg.sprite,
  body.projectdetailpage .projectImagesSliderSmall .nextContainer a svg.sprite {
    width: 12px;
    max-height: 20px;
  }
}
body.projectdetailpage .projectImagesSliderSmall li {
  padding: 0 15%;
}
body.projectdetailpage .projectImagesSliderSmall li .image {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}
body.projectdetailpage .projectImagesSliderSmall li .image:hover svg {
  opacity: 1;
}
body.projectdetailpage .projectImagesSliderSmall li .image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
body.projectdetailpage .projectImagesSliderSmall li .image svg {
  background-color: #E72264;
  border-radius: 50%;
  opacity: 0.75;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
body.projectdetailpage .projectImagesSliderSmall .slick-current li .image img {
  border: 1px solid #707070;
}
body.projectdetailpage .striberSmall img {
  width: 67px;
  height: 67px;
}
body.projectdetailpage .striberSmall img.circle {
  border-radius: 50%;
}
body.projectdetailpage .striberSmall span {
  font-size: 18px;
  color: #83847B;
}
body.projectdetailpage .tabs ul.tabHeaders {
  display: none;
  justify-content: flex-start;
  margin-bottom: 35px;
  margin-left: -16px;
  margin-right: -16px;
}
@media (min-width: 768px) {
  body.projectdetailpage .tabs ul.tabHeaders {
    display: flex;
    font-size: 20px;
  }
}
body.projectdetailpage .tabs ul.tabHeaders li {
  display: inline-block;
  padding: 0;
  margin: 0 16px;
}
body.projectdetailpage .tabs ul.tabHeaders li button {
  display: inline-block;
  background: transparent;
  border: none;
  padding: 5px 0;
  position: relative;
  text-decoration: none;
  color: #651FFF;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.projectdetailpage .tabs ul.tabHeaders li button:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #651FFF;
  height: 2px;
  width: 100%;
  transform: scaleX(0);
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: transform, background;
}
body.projectdetailpage .tabs ul.tabHeaders li button:hover:after, body.projectdetailpage .tabs ul.tabHeaders li button:focus:after {
  transform: none;
}
body.projectdetailpage .tabs ul.tabHeaders li button[aria-selected=true] {
  color: #232320;
}
body.projectdetailpage .tabs ul.tabHeaders li button[aria-selected=true]:after {
  transform: none;
  background: #232320;
}
body.projectdetailpage .tabs .tabContent {
  margin-bottom: 56px;
  display: block;
}
body.projectdetailpage .tabs .tabContent .mobileTitle {
  display: inline-block;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  body.projectdetailpage .tabs .tabContent .mobileTitle {
    display: none;
  }
}
@media (min-width: 768px) {
  body.projectdetailpage .tabs .tabContent {
    display: none;
    margin-bottom: 0;
  }
  body.projectdetailpage .tabs .tabContent.active {
    display: block;
  }
}
body.projectdetailpage .tabs #info-tab-documents p {
  margin: 16px 0 8px;
}
body.projectdetailpage .tabs #info-tab-documents ul li a {
  text-decoration: none;
  color: #651FFF;
  position: relative;
  padding-left: 34px;
  line-height: 32px;
  position: relative;
  text-decoration: none;
}
body.projectdetailpage .tabs #info-tab-documents ul li a:before {
  content: "";
  width: 24px;
  height: 2px;
  background: #651FFF;
  position: absolute;
  margin-right: 10px;
  left: 0;
  top: 10px;
}
body.projectdetailpage .tabs #info-tab-documents ul li a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #232320;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.projectdetailpage .tabs #info-tab-documents ul li a:hover:after, body.projectdetailpage .tabs #info-tab-documents ul li a:focus:after {
  transform: none;
}
body.projectdetailpage .tabs #info-tab-specifications table {
  display: block;
  width: 100% !important;
}
body.projectdetailpage .tabs #info-tab-specifications table tr,
body.projectdetailpage .tabs #info-tab-specifications table td {
  display: block;
  height: auto !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
body.projectdetailpage .tabs #info-tab-specifications table tbody {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}
body.projectdetailpage .tabs #info-tab-specifications table tr {
  border-top: 1px solid #E1E1D2;
  padding: 10px;
}
body.projectdetailpage .tabs #info-tab-specifications table tr:last-of-type {
  border-bottom: 1px solid #E1E1D2;
}
body.projectdetailpage .tabs #info-tab-specifications table td {
  display: inline-block;
  width: auto !important;
}
body.projectdetailpage .tabs #info-tab-specifications table td:first-child {
  flex: 0 0 100%;
  max-width: 100%;
  color: #83847B;
  font-size: 16px;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  body.projectdetailpage .tabs #info-tab-specifications table td:first-child {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    font-size: 18px;
    padding-bottom: 0;
  }
}
body.projectdetailpage .tabs #info-tab-specifications table td:last-child {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  body.projectdetailpage .tabs #info-tab-specifications table td:last-child {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
}
body.projectdetailpage .aboutSDGGoalsContainer {
  margin-bottom: 70px;
}
body.projectdetailpage .aboutSDGGoalsContainer h2 {
  font-size: 24px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 30px;
}
body.projectdetailpage .aboutSDGGoalsContainer ul {
  margin-bottom: 46px;
}
body.projectdetailpage .aboutSDGGoalsContainer li {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
body.projectdetailpage .aboutSDGGoalsContainer li:last-child {
  margin-bottom: 0;
}
body.projectdetailpage .aboutSDGGoalsContainer li svg.sprite {
  width: 35px;
  margin-right: 16px;
}
body.projectdetailpage .aboutSDGGoalsContainer a {
  color: #651FFF;
  position: relative;
  text-decoration: none;
}
body.projectdetailpage .aboutSDGGoalsContainer a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #651FFF;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.projectdetailpage .aboutSDGGoalsContainer a:hover:after, body.projectdetailpage .aboutSDGGoalsContainer a:focus:after {
  transform: none;
}
body.projectdetailpage section#content h1 {
  margin-top: 0;
  margin-bottom: 28px;
  font-size: 48px;
}
body.projectdetailpage section#content .infoColumn .subtitle {
  display: block;
  font-size: 16px;
  margin-top: 16px;
  line-height: 19px;
  font-style: normal;
  padding-left: 10px;
}
body.projectdetailpage section#content .aboutStriberContainer .striberInfo {
  display: flex;
  align-items: center;
}
body.projectdetailpage section#content .aboutStriberContainer .striberInfo .image {
  flex: 0 0 auto;
  height: 90px;
  width: 90px;
  margin-right: 16px;
}
body.projectdetailpage section#content .aboutStriberContainer .striberInfo .image img {
  border-radius: 50%;
}
body.projectdetailpage section#content .aboutStriberContainer .striberInfo .heading * {
  display: inline-block;
}
body.projectdetailpage section#content .aboutStriberContainer .striberInfo .heading .subtitle {
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  font-weight: normal;
  margin-bottom: 4px;
  font-style: normal;
}
@media (min-width: 768px) {
  body.projectdetailpage section#content .aboutStriberContainer .striberInfo .heading .subtitle {
    line-height: 24px;
    display: block;
    font-size: 16px;
    margin-top: 16px;
  }
}
body.projectdetailpage section#content .aboutStriberContainer .striberInfo .heading .title {
  color: #E72264;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}
body.projectdetailpage section#content .aboutStriberContainer .text a {
  display: inline-block;
  padding: 5px 0;
  color: #707070;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}
body.projectdetailpage section#content .aboutStriberContainer .text a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #707070;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.projectdetailpage section#content .aboutStriberContainer .text a:hover:after, body.projectdetailpage section#content .aboutStriberContainer .text a:focus:after {
  transform: none;
}
body.projectdetailpage section#content .aboutStriberContainer .text a:before {
  content: "▶";
  display: inline-block;
  transform: scale(0.6);
  position: absolute;
  right: -18px;
}
body.projectdetailpage section#content section.products {
  padding-top: 65px;
  padding-bottom: 75px;
  position: relative;
  margin-bottom: -20px;
  margin-top: 20px;
  margin-left: -15px;
  margin-right: -15px;
}
@media (min-width: 768px) {
  body.projectdetailpage section#content section.products {
    margin-left: -75px;
    margin-right: -75px;
    padding-top: 115px;
    padding-bottom: 95px;
    margin-top: 35px;
  }
}
@media (min-width: 1024px) {
  body.projectdetailpage section#content section.products {
    margin-left: -50px;
    margin-right: -50px;
    margin-top: 50px;
  }
}
body.projectdetailpage section#content section.products .background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #F8F8F4;
}
body.projectdetailpage section#content section.products .background:before {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  background-size: 100% 100%;
  height: 14px;
}
@media (min-width: 768px) {
  body.projectdetailpage section#content section.products .background:before {
    height: 29px;
  }
}
@media (min-width: 1024px) {
  body.projectdetailpage section#content section.products .background:before {
    height: 39px;
  }
}
@media (min-width: 1300px) {
  body.projectdetailpage section#content section.products .background:before {
    height: 55px;
  }
}
body.projectdetailpage section#content section.products .background:before {
  background-image: url("/img/stories_curve_2.svg");
  top: 0;
}
body.projectdetailpage section#content section.products h2 {
  color: #E72264;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  body.projectdetailpage section#content section.products h2 {
    margin-bottom: 25px;
  }
}
body.projectdetailpage section#content section.products > div:nth-of-type(1) > .row {
  margin-bottom: 35px;
}
body.projectdetailpage section#content section.products > div:nth-of-type(2) > .row {
  margin-bottom: 35px;
}
body.projectdetailpage section#content section.products > div:nth-of-type(2) > .row > div {
  display: flex;
  align-items: center;
}
body.projectdetailpage section#content section.products a.readmore {
  display: inline-flex;
  align-items: center;
  color: #83847B;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
body.projectdetailpage section#content section.products a.readmore svg {
  margin-left: 5px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.projectdetailpage section#content section.products a.readmore:hover svg, body.projectdetailpage section#content section.products a.readmore:focus svg {
  transform: translateX(4px);
}
body.projectdetailpage section#content section.products ul.productsList {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
body.projectdetailpage section#content section.products ul.productsList li {
  padding: 10px;
  flex-basis: 100%;
}
@media (min-width: 768px) {
  body.projectdetailpage section#content section.products ul.productsList li {
    flex-basis: 50%;
  }
}
@media (min-width: 1300px) {
  body.projectdetailpage section#content section.products ul.productsList li {
    flex-basis: 25%;
    padding: 10px 20px;
  }
}
body.projectdetailpage section#content section.products ul.productsList li article .title {
  font-weight: 400;
}
@media (min-width: 1024px) {
  body.projectdetailpage section#content section.products ul.productsList li article .title {
    font-size: 24px;
  }
}
body.projectdetailpage section#content section.products ul.productsList li article .image {
  justify-content: start;
}
@media (min-width: 1024px) {
  body.projectdetailpage section#content section.products ul.productsList li article .image img {
    width: 175px;
    height: 175px;
  }
}
body.projectdetailpage .socials {
  margin-top: 68px;
}
body.projectdetailpage .socials .socialMediaList {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: 11px;
}
body.projectdetailpage .socials .socialMediaList li {
  padding: 0 8px;
  margin-top: 8px;
}
body.projectdetailpage .socials .socialMediaList li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #12C700;
  transition: background 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.projectdetailpage .socials .socialMediaList li a svg.sprite {
  transform: scale(0.9);
}
body.projectdetailpage .socials .socialMediaList li a svg.sprite use {
  fill: #FFFFFF;
  transition: fill 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.projectdetailpage .socials .socialMediaList li a:hover, body.projectdetailpage .socials .socialMediaList li a:focus {
  background: #E1E1D2;
}
body.projectdetailpage .socials .socialMediaList li a:hover svg.sprite use, body.projectdetailpage .socials .socialMediaList li a:focus svg.sprite use {
  fill: #12C700;
}
body.projectdetailpage .factList {
  margin-top: 25px;
}
body.projectdetailpage .factList li {
  font-size: 18px;
  line-height: 24px;
  padding-left: 20px;
  position: relative;
  margin-bottom: 12px;
}
body.projectdetailpage .factList li:before {
  height: 13px;
  width: 13px;
  display: block;
  position: absolute;
  content: "";
  left: 0;
  top: 7px;
  background: url("/img/icon_check_green.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

body.projectsoverviewpage section#content .highlightedProject {
  padding-bottom: 85px;
  margin-bottom: 75px;
  border-bottom: 2px solid #E1E1D2;
}
body.projectsoverviewpage section#content .highlightedProject .imageContainer img {
  border-radius: 32px;
}
body.projectsoverviewpage section#content .highlightedProject .infoContainer h2 {
  margin-top: 10px;
  margin-bottom: 10px;
}
body.projectsoverviewpage section#content .highlightedProject .infoContainer .projectInfo {
  display: flex;
  align-items: center;
}
body.projectsoverviewpage section#content .highlightedProject .infoContainer .projectInfo .likes {
  margin-right: 20px;
}
body.projectsoverviewpage section#content .highlightedProject .infoContainer .projectInfo .likes .icon {
  display: inline-flex;
  align-items: center;
  margin-right: 0;
}
body.projectsoverviewpage section#content .highlightedProject .infoContainer .projectInfo .likes .icon svg.sprite {
  width: 19px;
  max-height: 16px;
  margin-right: 3px;
}
body.projectsoverviewpage section#content .highlightedProject .infoContainer .projectInfo .likes .icon span {
  position: relative;
  color: #232320;
  font-size: 16px;
  top: 0;
}
body.projectsoverviewpage section#content .highlightedProject .infoContainer .projectInfo .image {
  width: 56px;
  height: 56px;
}
body.projectsoverviewpage section#content .highlightedProject .infoContainer .projectInfo .image img {
  width: 56px;
  height: 56px;
}
body.projectsoverviewpage section#content .highlightedProject .infoContainer p {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 9px;
}
body.projectsoverviewpage section#content .highlightedProject .infoContainer p + a {
  color: #83847B;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  position: relative;
  text-decoration: none;
}
body.projectsoverviewpage section#content .highlightedProject .infoContainer p + a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #AA00FF;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.projectsoverviewpage section#content .highlightedProject .infoContainer p + a:hover:after, body.projectsoverviewpage section#content .highlightedProject .infoContainer p + a:focus:after {
  transform: none;
}
body.projectsoverviewpage section#content .highlightedProject .infoContainer p + a:hover, body.projectsoverviewpage section#content .highlightedProject .infoContainer p + a:focus {
  color: #AA00FF;
}
body.projectsoverviewpage section#content h1 {
  margin-top: 0;
  color: #232320;
}
body.projectsoverviewpage .overviewHeaderContainer {
  margin-bottom: 50px;
}
body.projectsoverviewpage .overviewHeaderContainer h1 {
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  body.projectsoverviewpage .overviewHeaderContainer h1 {
    padding-bottom: 0;
  }
}
body.projectsoverviewpage .openFiltersRow {
  margin-bottom: 30px;
}
body.projectsoverviewpage .openFiltersRow a.button.openFilters {
  width: 100%;
  text-align: center;
  display: inline-block;
}
body.projectsoverviewpage .sortOptions {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
body.projectsoverviewpage .sortOptions * {
  white-space: nowrap;
}
@media (min-width: 768px) {
  body.projectsoverviewpage .sortOptions {
    margin-bottom: 0;
  }
}
body.projectsoverviewpage .sortOptions > span {
  margin-right: 8px;
}
body.projectsoverviewpage .sortOptions .sortOption {
  margin-right: 12px;
}
body.projectsoverviewpage .sortOptions .sortOption:last-child {
  margin-right: 0;
}
body.projectsoverviewpage .sortOptions .sortOption input {
  display: none;
}
body.projectsoverviewpage .sortOptions .sortOption label {
  color: #83847B;
  border-bottom: 1px solid transparent;
  padding-bottom: 5px;
  cursor: pointer;
  line-height: 30px;
  transition: border-color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.projectsoverviewpage .sortOptions .sortOption input:checked + label {
  border-bottom-color: #83847B;
  cursor: default;
}
body.projectsoverviewpage .projectsContainer ul.targetsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -40px;
  margin-bottom: 70px;
}
body.projectsoverviewpage .projectsContainer ul.targetsContainer > li {
  flex: 0 0 auto;
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 40px;
  margin-left: 0;
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: appear;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 375px) {
  body.projectsoverviewpage .projectsContainer ul.targetsContainer > li {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  body.projectsoverviewpage .projectsContainer ul.targetsContainer > li {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
}
body.projectsoverviewpage .pagerContainer {
  margin-bottom: 70px;
}

body.coursedetailpage a.back {
  text-decoration: none;
  color: #83847B;
  display: inline-flex;
  align-items: center;
  min-height: 1px;
  padding: 5px 0;
  margin-bottom: 15px;
  position: relative;
  text-decoration: none;
}
body.coursedetailpage a.back:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #83847B;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.coursedetailpage a.back:hover:after, body.coursedetailpage a.back:focus:after {
  transform: none;
}
body.coursedetailpage a.back svg.sprite {
  margin-right: 10px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.coursedetailpage a.back:hover svg.sprite, body.coursedetailpage a.back:focus svg.sprite {
  transform: translateX(-5px);
}
body.coursedetailpage section#content h1 {
  margin-top: 0;
  color: #232320;
  font-size: 48px;
  line-height: 40px;
  margin-bottom: 32px;
}
body.coursedetailpage .infoColumn .price {
  margin-bottom: 35px;
}
body.coursedetailpage .infoColumn .price .original {
  font-size: 18px;
  color: #E72264;
  text-decoration: line-through;
  display: inline-block;
  margin-bottom: 5px;
  display: block;
  margin-bottom: 4px;
}
body.coursedetailpage .infoColumn .price .current {
  font-size: 32px;
  line-height: 37px;
  font-weight: 700;
}
body.coursedetailpage .addContainer .button {
  min-width: 100%;
  max-width: 100%;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  body.coursedetailpage .addContainer .button {
    min-width: 280px;
  }
}
body.coursedetailpage .addContainer .button[disabled] {
  opacity: 0.5;
}
body.coursedetailpage .addContainer .button + span {
  display: block;
  font-style: normal;
}
body.coursedetailpage .baseRow {
  margin-bottom: 68px;
}
@media (min-width: 768px) {
  body.coursedetailpage .baseRow {
    margin-bottom: 118px;
  }
}
body.coursedetailpage .courseImageSliderMain,
body.coursedetailpage .courseImagesSliderSmall {
  position: relative;
}
body.coursedetailpage .courseImageSliderMain .previousContainer,
body.coursedetailpage .courseImageSliderMain .nextContainer,
body.coursedetailpage .courseImagesSliderSmall .previousContainer,
body.coursedetailpage .courseImagesSliderSmall .nextContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  z-index: 1;
}
body.coursedetailpage .courseImageSliderMain .previousContainer a,
body.coursedetailpage .courseImageSliderMain .nextContainer a,
body.coursedetailpage .courseImagesSliderSmall .previousContainer a,
body.coursedetailpage .courseImagesSliderSmall .nextContainer a {
  display: inline-block;
  background: none;
  border: none;
  width: 26px;
  height: 38px;
  padding: 5px;
  cursor: pointer;
  transition: opacity 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  body.coursedetailpage .courseImageSliderMain .previousContainer a,
  body.coursedetailpage .courseImageSliderMain .nextContainer a,
  body.coursedetailpage .courseImagesSliderSmall .previousContainer a,
  body.coursedetailpage .courseImagesSliderSmall .nextContainer a {
    width: 40px;
    height: 56px;
    padding: 10px;
  }
}
body.coursedetailpage .courseImageSliderMain .previousContainer a svg.sprite,
body.coursedetailpage .courseImageSliderMain .nextContainer a svg.sprite,
body.coursedetailpage .courseImagesSliderSmall .previousContainer a svg.sprite,
body.coursedetailpage .courseImagesSliderSmall .nextContainer a svg.sprite {
  display: inline-block;
  width: 16px;
  max-height: 28px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  body.coursedetailpage .courseImageSliderMain .previousContainer a svg.sprite,
  body.coursedetailpage .courseImageSliderMain .nextContainer a svg.sprite,
  body.coursedetailpage .courseImagesSliderSmall .previousContainer a svg.sprite,
  body.coursedetailpage .courseImagesSliderSmall .nextContainer a svg.sprite {
    width: 20px;
    max-height: 36px;
  }
}
body.coursedetailpage .courseImageSliderMain .previousContainer a svg.sprite use,
body.coursedetailpage .courseImageSliderMain .nextContainer a svg.sprite use,
body.coursedetailpage .courseImagesSliderSmall .previousContainer a svg.sprite use,
body.coursedetailpage .courseImagesSliderSmall .nextContainer a svg.sprite use {
  stroke: #E1E1D2;
}
body.coursedetailpage .courseImageSliderMain .previousContainer,
body.coursedetailpage .courseImagesSliderSmall .previousContainer {
  left: 0;
}
@media (min-width: 768px) {
  body.coursedetailpage .courseImageSliderMain .previousContainer,
  body.coursedetailpage .courseImagesSliderSmall .previousContainer {
    left: 5%;
  }
}
body.coursedetailpage .courseImageSliderMain .previousContainer a:hover svg.sprite,
body.coursedetailpage .courseImagesSliderSmall .previousContainer a:hover svg.sprite {
  transform: translateX(-5px);
}
body.coursedetailpage .courseImageSliderMain .nextContainer,
body.coursedetailpage .courseImagesSliderSmall .nextContainer {
  right: 0;
}
@media (min-width: 768px) {
  body.coursedetailpage .courseImageSliderMain .nextContainer,
  body.coursedetailpage .courseImagesSliderSmall .nextContainer {
    right: 5%;
  }
}
body.coursedetailpage .courseImageSliderMain .nextContainer a:hover svg.sprite,
body.coursedetailpage .courseImagesSliderSmall .nextContainer a:hover svg.sprite {
  transform: translateX(5px) scaleX(-1);
}
body.coursedetailpage .courseImageSliderMain {
  margin-bottom: 17px;
}
body.coursedetailpage .courseImageSliderMain .previousContainer a,
body.coursedetailpage .courseImageSliderMain .nextContainer a {
  width: 26px;
  height: 38px;
  padding: 5px;
}
@media (min-width: 768px) {
  body.coursedetailpage .courseImageSliderMain .previousContainer a,
  body.coursedetailpage .courseImageSliderMain .nextContainer a {
    width: 40px;
    height: 56px;
    padding: 10px;
  }
}
body.coursedetailpage .courseImageSliderMain .previousContainer a svg.sprite,
body.coursedetailpage .courseImageSliderMain .nextContainer a svg.sprite {
  width: 16px;
  max-height: 28px;
}
@media (min-width: 768px) {
  body.coursedetailpage .courseImageSliderMain .previousContainer a svg.sprite,
  body.coursedetailpage .courseImageSliderMain .nextContainer a svg.sprite {
    width: 20px;
    max-height: 36px;
  }
}
body.coursedetailpage .courseImageSliderMain li .image {
  position: relative;
  height: 0;
  padding-bottom: 56%;
}
body.coursedetailpage .courseImageSliderMain li .image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 32px;
  object-fit: cover;
}
body.coursedetailpage .courseImagesSliderSmall {
  padding-left: 10%;
  padding-right: 10%;
}
body.coursedetailpage .courseImagesSliderSmall .previousContainer a,
body.coursedetailpage .courseImagesSliderSmall .nextContainer a {
  width: 22px;
  height: 30px;
  padding: 5px;
}
@media (min-width: 768px) {
  body.coursedetailpage .courseImagesSliderSmall .previousContainer a,
  body.coursedetailpage .courseImagesSliderSmall .nextContainer a {
    width: 32px;
    height: 40px;
    padding: 10px;
  }
}
body.coursedetailpage .courseImagesSliderSmall .previousContainer a svg.sprite,
body.coursedetailpage .courseImagesSliderSmall .nextContainer a svg.sprite {
  width: 12px;
  max-height: 20px;
}
@media (min-width: 768px) {
  body.coursedetailpage .courseImagesSliderSmall .previousContainer a svg.sprite,
  body.coursedetailpage .courseImagesSliderSmall .nextContainer a svg.sprite {
    width: 12px;
    max-height: 20px;
  }
}
body.coursedetailpage .courseImagesSliderSmall li {
  padding: 0 15%;
}
body.coursedetailpage .courseImagesSliderSmall li .image {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  cursor: pointer;
}
body.coursedetailpage .courseImagesSliderSmall li .image:hover svg {
  opacity: 1;
}
body.coursedetailpage .courseImagesSliderSmall li .image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
body.coursedetailpage .courseImagesSliderSmall li .image svg {
  background-color: #E72264;
  border-radius: 50%;
  opacity: 0.75;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
body.coursedetailpage .courseImagesSliderSmall .slick-current li .image img {
  border: 1px solid #707070;
}
body.coursedetailpage .aboutCourseContainer {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body.coursedetailpage .aboutCourseContainer {
    margin-bottom: 104px;
  }
}
body.coursedetailpage .aboutCourseContainer ul li {
  position: relative;
  padding-left: 38px;
  margin-bottom: 14px;
}
body.coursedetailpage .aboutCourseContainer ul li:last-child {
  margin-bottom: 0;
}
body.coursedetailpage .aboutCourseContainer ul li:before {
  content: "";
  position: absolute;
  left: 6px;
  top: 0;
  width: 15px;
  height: 15px;
  background: url("/img/icon_check_green.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
body.coursedetailpage .tabs ul.tabHeaders {
  display: none;
  justify-content: flex-start;
  margin-bottom: 35px;
  margin-left: -16px;
  margin-right: -16px;
}
@media (min-width: 768px) {
  body.coursedetailpage .tabs ul.tabHeaders {
    display: flex;
  }
}
body.coursedetailpage .tabs ul.tabHeaders li {
  display: inline-block;
  padding: 0;
  margin: 0 16px;
}
body.coursedetailpage .tabs ul.tabHeaders li button {
  display: inline-block;
  background: transparent;
  border: none;
  padding: 5px 0;
  position: relative;
  text-decoration: none;
  color: #651FFF;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.coursedetailpage .tabs ul.tabHeaders li button:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #651FFF;
  height: 2px;
  width: 100%;
  transform: scaleX(0);
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: transform, background;
}
body.coursedetailpage .tabs ul.tabHeaders li button:hover:after, body.coursedetailpage .tabs ul.tabHeaders li button:focus:after {
  transform: none;
}
body.coursedetailpage .tabs ul.tabHeaders li button[aria-selected=true] {
  color: #232320;
}
body.coursedetailpage .tabs ul.tabHeaders li button[aria-selected=true]:after {
  transform: none;
  background: #232320;
}
body.coursedetailpage .tabs .tabContent {
  margin-bottom: 56px;
  display: block;
}
body.coursedetailpage .tabs .tabContent .mobileTitle {
  display: inline-block;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  body.coursedetailpage .tabs .tabContent .mobileTitle {
    display: none;
  }
}
@media (min-width: 768px) {
  body.coursedetailpage .tabs .tabContent {
    display: none;
    margin-bottom: 0;
  }
  body.coursedetailpage .tabs .tabContent.active {
    display: block;
  }
}
body.coursedetailpage .tabs #info-tab-description ul, body.coursedetailpage .tabs #info-tab-description ol,
body.coursedetailpage .tabs #info-tab-lessons ul,
body.coursedetailpage .tabs #info-tab-lessons ol,
body.coursedetailpage .tabs #info-tab-targetAudience ul,
body.coursedetailpage .tabs #info-tab-targetAudience ol {
  padding-left: 34px;
}
body.coursedetailpage .tabs #info-tab-description ul li, body.coursedetailpage .tabs #info-tab-description ol li,
body.coursedetailpage .tabs #info-tab-lessons ul li,
body.coursedetailpage .tabs #info-tab-lessons ol li,
body.coursedetailpage .tabs #info-tab-targetAudience ul li,
body.coursedetailpage .tabs #info-tab-targetAudience ol li {
  margin-bottom: 10px;
  position: relative;
}
body.coursedetailpage .tabs #info-tab-description ul li:before, body.coursedetailpage .tabs #info-tab-description ol li:before,
body.coursedetailpage .tabs #info-tab-lessons ul li:before,
body.coursedetailpage .tabs #info-tab-lessons ol li:before,
body.coursedetailpage .tabs #info-tab-targetAudience ul li:before,
body.coursedetailpage .tabs #info-tab-targetAudience ol li:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -34px;
  top: 8px;
  width: 24px;
  height: 2px;
  background: #651FFF;
}
body.coursedetailpage .tabs #info-tab-description ol,
body.coursedetailpage .tabs #info-tab-lessons ol,
body.coursedetailpage .tabs #info-tab-targetAudience ol {
  counter-set: listitem;
}
body.coursedetailpage .tabs #info-tab-description ol li,
body.coursedetailpage .tabs #info-tab-lessons ol li,
body.coursedetailpage .tabs #info-tab-targetAudience ol li {
  counter-increment: listitem;
}
body.coursedetailpage .tabs #info-tab-description ol li:before,
body.coursedetailpage .tabs #info-tab-lessons ol li:before,
body.coursedetailpage .tabs #info-tab-targetAudience ol li:before {
  content: counter(listitem) ".";
  color: #651FFF;
  background: transparent;
  top: 0;
  left: -30px;
  text-align: right;
}
body.coursedetailpage .tabs #info-tab-specifications table {
  display: block;
  width: 100% !important;
}
body.coursedetailpage .tabs #info-tab-specifications table tr,
body.coursedetailpage .tabs #info-tab-specifications table td {
  display: block;
  height: auto !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
body.coursedetailpage .tabs #info-tab-specifications table tbody {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}
body.coursedetailpage .tabs #info-tab-specifications table tr {
  border-top: 1px solid #E1E1D2;
  padding: 10px;
}
body.coursedetailpage .tabs #info-tab-specifications table tr:last-of-type {
  border-bottom: 1px solid #E1E1D2;
}
body.coursedetailpage .tabs #info-tab-specifications table td {
  display: inline-block;
  width: auto !important;
}
body.coursedetailpage .tabs #info-tab-specifications table td:first-child {
  flex: 0 0 100%;
  max-width: 100%;
  color: #83847B;
  font-size: 16px;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  body.coursedetailpage .tabs #info-tab-specifications table td:first-child {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    font-size: 18px;
    padding-bottom: 0;
  }
}
body.coursedetailpage .tabs #info-tab-specifications table td:last-child {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  body.coursedetailpage .tabs #info-tab-specifications table td:last-child {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
}
body.coursedetailpage .specSheet {
  overflow: hidden;
}
body.coursedetailpage .specSheet span {
  font-size: 16px;
  line-height: 24px;
}
body.coursedetailpage .specSheet .row {
  border-top: 2px solid #E1E1D2;
}
body.coursedetailpage .specSheet .row:last-child {
  border-bottom: 2px solid #E1E1D2;
}
body.coursedetailpage .specSheet .row span {
  display: block;
  padding: 8px 0;
}
body.coursedetailpage .specSheet .row > .col:first-child span,
body.coursedetailpage .specSheet .row > [class*=col-]:first-child span {
  color: #83847B;
}
body.coursedetailpage .aboutSDGGoalsContainer {
  margin-bottom: 70px;
}
body.coursedetailpage .aboutSDGGoalsContainer h2 {
  font-size: 24px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 30px;
}
body.coursedetailpage .aboutSDGGoalsContainer ul {
  margin-bottom: 46px;
}
body.coursedetailpage .aboutSDGGoalsContainer li {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
body.coursedetailpage .aboutSDGGoalsContainer li:last-child {
  margin-bottom: 0;
}
body.coursedetailpage .aboutSDGGoalsContainer li svg.sprite {
  width: 35px;
  margin-right: 16px;
}
body.coursedetailpage .aboutSDGGoalsContainer a {
  color: #651FFF;
  position: relative;
  text-decoration: none;
}
body.coursedetailpage .aboutSDGGoalsContainer a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #651FFF;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.coursedetailpage .aboutSDGGoalsContainer a:hover:after, body.coursedetailpage .aboutSDGGoalsContainer a:focus:after {
  transform: none;
}
body.coursedetailpage .aboutSellerContainer {
  margin-bottom: 88px;
}
body.coursedetailpage .aboutSellerContainer .image {
  max-width: 64%;
  margin-bottom: 16px;
}
body.coursedetailpage .aboutSellerContainer .text {
  line-height: 1.4em;
}
body.coursedetailpage section#content .aboutStriberContainer .striberInfo {
  display: flex;
  align-items: center;
}
body.coursedetailpage section#content .aboutStriberContainer .striberInfo .image {
  flex: 0 0 auto;
  height: 90px;
  width: 90px;
  margin-right: 16px;
}
body.coursedetailpage section#content .aboutStriberContainer .striberInfo .image img {
  border-radius: 50%;
}
body.coursedetailpage section#content .aboutStriberContainer .striberInfo .heading * {
  display: inline-block;
}
body.coursedetailpage section#content .aboutStriberContainer .striberInfo .heading .subtitle {
  font-size: 18px;
  line-height: 21px;
  font-weight: normal;
  margin-bottom: 4px;
  font-style: normal;
  display: block;
}
body.coursedetailpage section#content .aboutStriberContainer .striberInfo .heading .title {
  color: #E72264;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}
body.coursedetailpage section#content .aboutStriberContainer .text a {
  display: inline-block;
  padding: 5px 0;
  color: #707070;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}
body.coursedetailpage section#content .aboutStriberContainer .text a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #707070;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.coursedetailpage section#content .aboutStriberContainer .text a:hover:after, body.coursedetailpage section#content .aboutStriberContainer .text a:focus:after {
  transform: none;
}
body.coursedetailpage section#content .aboutStriberContainer .text a:before {
  content: "▶";
  display: inline-block;
  transform: scale(0.6);
  position: absolute;
  right: -18px;
}
body.coursedetailpage .reviewsContainer {
  margin-bottom: 64px;
}
body.coursedetailpage .reviewsContainer .ratingContainer ol {
  display: block;
  margin-bottom: 10px;
}
body.coursedetailpage .reviewsContainer .ratingContainer li {
  display: inline-block;
  width: 29px;
  margin-right: 6px;
}
body.coursedetailpage .reviewsContainer .ratingContainer li svg.sprite {
  width: 35px;
  padding-left: 3px;
  padding-right: 3px;
  max-height: 25px;
}
body.coursedetailpage .reviewsContainer .ratingContainer li svg.sprite use {
  fill: transparent;
  stroke: #707070;
}
body.coursedetailpage .reviewsContainer .ratingContainer li:last-child {
  margin-right: 0;
}
body.coursedetailpage .reviewsContainer .ratingContainer li input {
  display: none;
}
body.coursedetailpage .reviewsContainer .ratingContainer li label {
  cursor: pointer;
}
body.coursedetailpage .reviewsContainer .ratingContainer li label svg.sprite use {
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: fill, stroke;
}
body.coursedetailpage .reviewsContainer .ratingContainer li label:hover svg.sprite use, body.coursedetailpage .reviewsContainer .ratingContainer li label:focus svg.sprite use {
  fill: #12C700;
}
body.coursedetailpage .reviewsContainer .ratingContainer li input:checked + label svg.sprite {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: liked;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.coursedetailpage .reviewsContainer .ratingContainer li input:checked + label svg.sprite use {
  fill: #12C700;
  stroke: #12C700;
}
body.coursedetailpage .reviewsContainer .ratingContainer[data-rating="1"] li:nth-child(-n+1) label svg.sprite {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: liked;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.coursedetailpage .reviewsContainer .ratingContainer[data-rating="1"] li:nth-child(-n+1) label svg.sprite use {
  fill: #12C700;
  stroke: #12C700;
}
body.coursedetailpage .reviewsContainer .ratingContainer[data-rating="2"] li:nth-child(-n+2) label svg.sprite {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: liked;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.coursedetailpage .reviewsContainer .ratingContainer[data-rating="2"] li:nth-child(-n+2) label svg.sprite use {
  fill: #12C700;
  stroke: #12C700;
}
body.coursedetailpage .reviewsContainer .ratingContainer[data-rating="3"] li:nth-child(-n+3) label svg.sprite {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: liked;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.coursedetailpage .reviewsContainer .ratingContainer[data-rating="3"] li:nth-child(-n+3) label svg.sprite use {
  fill: #12C700;
  stroke: #12C700;
}
body.coursedetailpage .reviewsContainer .ratingContainer[data-rating="4"] li:nth-child(-n+4) label svg.sprite {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: liked;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.coursedetailpage .reviewsContainer .ratingContainer[data-rating="4"] li:nth-child(-n+4) label svg.sprite use {
  fill: #12C700;
  stroke: #12C700;
}
body.coursedetailpage .reviewsContainer .ratingContainer[data-rating="5"] li:nth-child(-n+5) label svg.sprite {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: liked;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.coursedetailpage .reviewsContainer .ratingContainer[data-rating="5"] li:nth-child(-n+5) label svg.sprite use {
  fill: #12C700;
  stroke: #12C700;
}
body.coursedetailpage .reviewsContainer .inputRow input {
  padding: 5px 20px;
  line-height: 30px;
}
body.coursedetailpage .reviewsContainer .inputRow label span {
  color: #707070;
}
body.coursedetailpage .reviewsContainer .textarea {
  margin-top: 20px;
  margin-bottom: 32px;
}
body.coursedetailpage .reviewsContainer .textarea label {
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 3px;
}
body.coursedetailpage .reviewsContainer .textarea textarea {
  background: white;
  border-radius: 0;
  border: 1px solid #E1E1D2;
  padding: 5px 10px;
  color: #707070;
  min-height: 128px;
  resize: vertical;
}
body.coursedetailpage .reviewsContainer .textarea textarea:focus {
  border-color: #232320;
}
body.coursedetailpage section#content .reviewsContainer h3 {
  font-weight: 500;
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 32px;
}
body.coursedetailpage ul.reviews > li {
  border-bottom: 1px solid #E1E1D2;
  padding: 24px 0 14px;
}
body.coursedetailpage ul.reviews > li:first-child {
  border-top: 1px solid #E1E1D2;
}
body.coursedetailpage ul.reviews > li .review .rating {
  display: block;
  margin-bottom: 16px;
}
body.coursedetailpage ul.reviews > li .review .rating li {
  display: inline-block;
  width: 20px;
  margin-right: 6px;
}
body.coursedetailpage ul.reviews > li .review .rating li svg.sprite {
  width: 26px;
  padding-left: 2px;
  padding-right: 2px;
  max-height: 19px;
}
body.coursedetailpage ul.reviews > li .review .rating li svg.sprite use {
  fill: #E1E1D2;
  stroke: none;
}
body.coursedetailpage ul.reviews > li .review .rating li:last-child {
  margin-right: 0;
}
body.coursedetailpage ul.reviews > li .review .rating li.active svg.sprite use {
  fill: #12C700;
}
body.coursedetailpage ul.reviews > li .review .info {
  color: #707070;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 7px;
}
body.coursedetailpage ul.reviews > li .review .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 4px;
}
body.coursedetailpage ul.reviews > li .review .content p {
  font-size: 18px;
  line-height: 24px;
}
body.coursedetailpage ul.reviews > li .review .content p:first-child {
  margin-top: 0;
}
body.coursedetailpage ul.reviews > li .review .content p:last-child {
  margin-bottom: 0;
}
body.coursedetailpage .detailsRow {
  margin-bottom: 70px;
}
body.coursedetailpage section.new {
  padding-top: 65px;
  padding-bottom: 75px;
  position: relative;
  margin-bottom: -20px;
  margin-left: -15px;
  margin-right: -15px;
}
@media (min-width: 768px) {
  body.coursedetailpage section.new {
    margin-left: -75px;
    margin-right: -75px;
    padding-top: 115px;
    padding-bottom: 95px;
  }
}
@media (min-width: 1024px) {
  body.coursedetailpage section.new {
    margin-left: -50px;
    margin-right: -50px;
  }
}
body.coursedetailpage section.new .background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #F8F8F4;
}
body.coursedetailpage section.new .background:before {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  background-size: 100% 100%;
  height: 14px;
}
@media (min-width: 768px) {
  body.coursedetailpage section.new .background:before {
    height: 29px;
  }
}
@media (min-width: 1024px) {
  body.coursedetailpage section.new .background:before {
    height: 39px;
  }
}
@media (min-width: 1300px) {
  body.coursedetailpage section.new .background:before {
    height: 55px;
  }
}
body.coursedetailpage section.new .background:before {
  background-image: url("/img/stories_curve_2.svg");
  top: 0;
}
body.coursedetailpage section.new h2 {
  color: #E72264;
  font-size: 36px;
  line-height: 32px;
}
body.coursedetailpage section#content .addContainer .button + span {
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  margin-top: 24px;
}
body.coursedetailpage section#content .aboutCourseContainer h2 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 28px;
}
@media (min-width: 768px) {
  body.coursedetailpage section#content .aboutCourseContainer h2 {
    margin-bottom: 34px;
  }
}
body.coursedetailpage .factList li {
  font-size: 18px;
  line-height: 24px;
  padding-left: 20px;
  position: relative;
  margin-bottom: 12px;
}
body.coursedetailpage .factList li:before {
  height: 13px;
  width: 13px;
  display: block;
  position: absolute;
  content: "";
  left: 0;
  top: 7px;
  background: url("/img/icon_check_green.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

body.coursesoverviewpage section#content .highlightedCourse {
  padding-bottom: 85px;
  margin-bottom: 75px;
  border-bottom: 2px solid #E1E1D2;
}
body.coursesoverviewpage section#content .highlightedCourse .imageContainer img {
  border-radius: 32px;
}
body.coursesoverviewpage section#content .highlightedCourse .infoContainer h2 {
  margin-top: 10px;
  margin-bottom: 10px;
}
body.coursesoverviewpage section#content .highlightedCourse .infoContainer .courseInfo {
  display: flex;
  align-items: center;
}
body.coursesoverviewpage section#content .highlightedCourse .infoContainer .courseInfo .image {
  width: 56px;
  height: 56px;
}
body.coursesoverviewpage section#content .highlightedCourse .infoContainer .courseInfo .image img {
  width: 56px;
  height: 56px;
}
body.coursesoverviewpage section#content .highlightedCourse .infoContainer p {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 9px;
}
body.coursesoverviewpage section#content .highlightedCourse .infoContainer p + a {
  color: #83847B;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  position: relative;
  text-decoration: none;
}
body.coursesoverviewpage section#content .highlightedCourse .infoContainer p + a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #AA00FF;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.coursesoverviewpage section#content .highlightedCourse .infoContainer p + a:hover:after, body.coursesoverviewpage section#content .highlightedCourse .infoContainer p + a:focus:after {
  transform: none;
}
body.coursesoverviewpage section#content .highlightedCourse .infoContainer p + a:hover, body.coursesoverviewpage section#content .highlightedCourse .infoContainer p + a:focus {
  color: #AA00FF;
}
body.coursesoverviewpage section#content h1 {
  margin-top: 0;
  color: #232320;
}
body.coursesoverviewpage .overviewHeaderContainer {
  margin-bottom: 50px;
}
body.coursesoverviewpage .overviewHeaderContainer h1 {
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  body.coursesoverviewpage .overviewHeaderContainer h1 {
    padding-bottom: 0;
  }
}
body.coursesoverviewpage .openFiltersRow {
  margin-bottom: 30px;
}
body.coursesoverviewpage .openFiltersRow a.button.openFilters {
  width: 100%;
  text-align: center;
  display: inline-block;
}
body.coursesoverviewpage .sortOptions {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
body.coursesoverviewpage .sortOptions * {
  white-space: nowrap;
}
@media (min-width: 768px) {
  body.coursesoverviewpage .sortOptions {
    margin-bottom: 0;
  }
}
body.coursesoverviewpage .sortOptions > span {
  margin-right: 8px;
}
body.coursesoverviewpage .sortOptions .sortOption {
  margin-right: 12px;
}
body.coursesoverviewpage .sortOptions .sortOption:last-child {
  margin-right: 0;
}
body.coursesoverviewpage .sortOptions .sortOption input {
  display: none;
}
body.coursesoverviewpage .sortOptions .sortOption label {
  color: #83847B;
  border-bottom: 1px solid transparent;
  padding-bottom: 5px;
  cursor: pointer;
  line-height: 30px;
  transition: border-color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.coursesoverviewpage .sortOptions .sortOption input:checked + label {
  border-bottom-color: #83847B;
  cursor: default;
}
body.coursesoverviewpage .coursesContainer ul.targetsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -40px;
  margin-bottom: 70px;
}
body.coursesoverviewpage .coursesContainer ul.targetsContainer > li {
  flex: 0 0 auto;
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 40px;
  margin-left: 0;
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: appear;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 375px) {
  body.coursesoverviewpage .coursesContainer ul.targetsContainer > li {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  body.coursesoverviewpage .coursesContainer ul.targetsContainer > li {
    flex-basis: 25%;
    max-width: 25%;
  }
}
body.coursesoverviewpage .pagerContainer {
  margin-bottom: 70px;
}

body.courselandingpage section#hero {
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 70px;
}
@media (min-width: 768px) {
  body.courselandingpage section#hero {
    padding-top: 200px;
    padding-bottom: 200px;
    margin-bottom: 70px;
  }
}
body.courselandingpage section#hero .background:before {
  height: 50px;
}
@media (min-width: 768px) {
  body.courselandingpage section#hero .background:before {
    height: 110px;
  }
}
body.courselandingpage section#hero .background:after {
  height: 50px;
}
@media (min-width: 768px) {
  body.courselandingpage section#hero .background:after {
    height: 100px;
  }
}
body.courselandingpage section#hero .background .layer.back {
  background: linear-gradient(to bottom, #651FFF 0, #A620B0 50%, #AA00FF 100%);
  background: linear-gradient(to bottom, #FFFFFF 0, #651FFF 1%, #651FFF 50%, #00E5FF 99%, #FFFFFF 100%);
}
body.courselandingpage section#hero h1 {
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 56px;
  line-height: 48px;
  font-weight: 600;
}
@media (min-width: 768px) {
  body.courselandingpage section#hero h1 {
    margin-bottom: 0;
  }
}
body.courselandingpage section#hero p {
  width: 100%;
  color: #FFFFFF;
  margin-bottom: 28px;
  margin-top: 0;
  font-size: 24px;
  line-height: 32px;
}
@media (min-width: 768px) {
  body.courselandingpage section#hero p {
    margin-bottom: 20px;
  }
}
body.courselandingpage section#hero svg {
  position: absolute;
}
body.courselandingpage section#hero img {
  clip-path: url(#home-mask);
}
.ie body.courselandingpage section#hero img, .edge body.courselandingpage section#hero img {
  border-radius: 31% 69% 56% 44%/57% 58% 42% 43%;
  overflow: hidden;
}
body.courselandingpage .simpleHero {
  margin-bottom: 143px;
  font-size: 24px;
  line-height: 40px;
}
body.courselandingpage .simpleHero p {
  margin-top: 0;
}
body.courselandingpage .simpleHero a.button {
  font-size: 22px;
  line-height: 32px;
  padding-right: 60px;
}
body.courselandingpage section.USPs {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  body.courselandingpage section.USPs {
    margin-bottom: 100px;
  }
}
body.courselandingpage section.USPs h2 {
  color: #E72264;
}
body.courselandingpage section.trainers {
  margin-bottom: 90px;
}
body.courselandingpage section.trainers h2 {
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  color: #E72264;
  text-align: center;
}
body.courselandingpage section.trainers ~ .container {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  body.courselandingpage section.trainers ~ .container {
    margin-bottom: 100px;
  }
}
body.courselandingpage section.trainers div.trainer div.image img {
  border-radius: 50%;
  border: 1px solid #E1E1D2;
  display: block;
  width: 100%;
}
body.courselandingpage section.trainers div.trainer span.name {
  font-size: 24px;
  line-height: 24px;
  color: #83847B;
  text-align: center;
  display: block;
  margin-top: 24px;
}
body.courselandingpage section#content h2 {
  margin-top: 0;
}
body.courselandingpage section#content .umb-grid .row {
  justify-content: center;
}
body.courselandingpage section#content section.highlighted {
  margin-bottom: 60px;
}
body.courselandingpage section#content section.highlighted h2 {
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  color: #E72264;
}
body.courselandingpage section#content section.highlighted article span.title {
  margin-top: 5px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
}
body.courselandingpage section#content section.highlighted article.hasImage > a {
  margin-bottom: 10px;
}
body.courselandingpage section#content article .image {
  justify-content: start;
}
body.courselandingpage section#content article .image img {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  border: 1px solid #E1E1D2;
}
@media (min-width: 768px) {
  body.courselandingpage section#content article .image img {
    width: 214px;
    height: 214px;
  }
}
@media (min-width: 1300px) {
  body.courselandingpage section#content article .image img {
    width: 170px;
    height: 170px;
  }
}

body.shopoverviewpage section#content h1 {
  margin-top: 0;
  font-size: 48px;
  line-height: 40px;
  font-weight: 700;
  color: #232320;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#content h1 {
    font-size: 56px;
    line-height: 48px;
  }
}
body.shopoverviewpage section#hero {
  padding-top: 80px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#hero {
    margin-bottom: 70px;
    padding-top: 80px;
    padding-bottom: 120px;
  }
}
@media (min-width: 1024px) {
  body.shopoverviewpage section#hero {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
body.shopoverviewpage section#hero .slick-slide {
  height: auto;
}
body.shopoverviewpage section#hero .background:before {
  height: 30px;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#hero .background:before {
    height: 100px;
  }
}
body.shopoverviewpage section#hero .background:after {
  height: 30px;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#hero .background:after {
    height: 100px;
  }
}
body.shopoverviewpage section#hero .background .layer.back {
  background: linear-gradient(to bottom, #1DE9B6 0, #00E5FF 100%);
}
body.shopoverviewpage section#hero .background .layer.front {
  opacity: 0;
}
body.shopoverviewpage section#hero h2 {
  color: #232320;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 48px;
  line-height: 40px;
  font-weight: 700;
}
body.shopoverviewpage section#hero h2 em {
  font-style: normal;
  color: #FFC400;
}
body.shopoverviewpage section#hero p {
  width: 100%;
  color: #FFFFFF;
  margin-bottom: 30px;
  margin-top: 0;
  font-size: 24px;
  line-height: 32px;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#hero p {
    margin-bottom: 20px;
  }
}
body.shopoverviewpage section#hero a.button {
  padding-right: 60px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
body.shopoverviewpage section#hero svg {
  position: absolute;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#hero img {
    margin-bottom: 0;
  }
}
.ie body.shopoverviewpage section#hero img, .edge body.shopoverviewpage section#hero img {
  border-radius: 31% 69% 56% 44%/57% 58% 42% 43%;
  overflow: hidden;
}
body.shopoverviewpage section#hero .image {
  position: relative;
  margin-bottom: 10px;
  clip-path: url(#hero-mask);
  margin-left: 50px;
  margin-right: 50px;
}
body.shopoverviewpage section#hero .image img {
  position: relative;
  width: 100%;
  max-height: 480px;
  object-fit: cover;
}
body.shopoverviewpage section#hero .image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 100;
  mix-blend-mode: soft-light;
  background: linear-gradient(to bottom, #651FFF 0, #A620B0 50%, #AA00FF 100%);
  opacity: 0.5;
}
.ie body.shopoverviewpage section#hero .image:after, .edge body.shopoverviewpage section#hero .image:after {
  display: none;
}
body.shopoverviewpage section#hero .productSliderContainer {
  position: relative;
}
body.shopoverviewpage section#hero .productSliderContainer .previousContainer, body.shopoverviewpage section#hero .productSliderContainer .nextContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
}
body.shopoverviewpage section#hero .productSliderContainer .previousContainer a, body.shopoverviewpage section#hero .productSliderContainer .nextContainer a {
  display: inline-block;
  background: none;
  border: none;
  width: 26px;
  height: 38px;
  padding: 5px;
  cursor: pointer;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#hero .productSliderContainer .previousContainer a, body.shopoverviewpage section#hero .productSliderContainer .nextContainer a {
    width: 40px;
    height: 56px;
    padding: 10px;
  }
}
body.shopoverviewpage section#hero .productSliderContainer .previousContainer a svg.sprite, body.shopoverviewpage section#hero .productSliderContainer .nextContainer a svg.sprite {
  display: inline-block;
  width: 16px;
  max-height: 28px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  body.shopoverviewpage section#hero .productSliderContainer .previousContainer a svg.sprite, body.shopoverviewpage section#hero .productSliderContainer .nextContainer a svg.sprite {
    width: 20px;
    max-height: 36px;
  }
}
body.shopoverviewpage section#hero .productSliderContainer .previousContainer a svg.sprite use, body.shopoverviewpage section#hero .productSliderContainer .nextContainer a svg.sprite use {
  stroke: #FFFFFF;
}
body.shopoverviewpage section#hero .productSliderContainer .previousContainer {
  left: 0;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#hero .productSliderContainer .previousContainer {
    left: -10px;
  }
}
body.shopoverviewpage section#hero .productSliderContainer .previousContainer a:hover svg.sprite {
  transform: translateX(-5px);
}
body.shopoverviewpage section#hero .productSliderContainer .nextContainer {
  right: 0;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#hero .productSliderContainer .nextContainer {
    right: -10px;
  }
}
body.shopoverviewpage section#hero .productSliderContainer .nextContainer a:hover svg.sprite {
  transform: translateX(5px) scaleX(-1);
}
body.shopoverviewpage section#hero .productSliderContainer .textColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
body.shopoverviewpage section#hero .productSliderContainer .textColumn h2 {
  margin-bottom: 38px;
  font-size: 48px;
  line-height: 40px;
  font-weight: 700;
}
body.shopoverviewpage section#hero .productSliderContainer .textColumn .priceContainer {
  margin-bottom: 19px;
}
body.shopoverviewpage section#hero .productSliderContainer .textColumn .priceContainer .original {
  display: inline-block;
  color: #E72264;
  font-size: 24px;
  line-height: 32px;
  margin-right: 16px;
  text-decoration: line-through;
}
body.shopoverviewpage section#hero .productSliderContainer .textColumn .priceContainer .current {
  font-size: 40px;
  line-height: 32px;
  font-weight: 600;
  color: #232320;
}
body.shopoverviewpage section#hero .productSliderContainer .textColumn a.button {
  padding-right: 150px;
  margin-bottom: 36px;
}
body.shopoverviewpage section#hero .productSliderContainer .textColumn .sub {
  display: inline-block;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#hero .productSliderContainer .textColumn .sub {
    margin-bottom: 60px;
  }
}
body.shopoverviewpage section#hero .productSliderContainer .dotsContainer {
  text-align: center;
  display: none;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#hero .productSliderContainer .dotsContainer {
    display: block;
  }
}
body.shopoverviewpage section#hero .productSliderContainer .dotsContainer ul {
  display: inline-flex;
}
body.shopoverviewpage section#hero .productSliderContainer .dotsContainer ul li {
  margin: 0 8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #E1E1D2;
  transition: background 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  cursor: pointer;
}
body.shopoverviewpage section#hero .productSliderContainer .dotsContainer ul li button {
  display: none;
}
body.shopoverviewpage section#hero .productSliderContainer .dotsContainer ul li.slick-active {
  background: #83847B;
}
body.shopoverviewpage section#hero + .container {
  margin-bottom: 112px;
}
body.shopoverviewpage p.intro {
  font-size: 24px;
  line-height: 32px;
  margin: 0;
}
body.shopoverviewpage section#content section.categories {
  margin-bottom: 64px;
}
body.shopoverviewpage section#content section.categories h2 {
  text-align: center;
  color: #E72264;
  font-size: 36px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#content section.categories h2 {
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 64px;
  }
}
body.shopoverviewpage section#content section.categories ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -24px;
  margin-left: -10px;
  margin-right: -10px;
}
@media (min-width: 1300px) {
  body.shopoverviewpage section#content section.categories ul {
    margin-left: -20px;
    margin-right: -20px;
  }
}
body.shopoverviewpage section#content section.categories ul li {
  flex: 0 0 auto;
  flex-basis: 50%;
  max-width: 50%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 24px;
}
@media (min-width: 375px) {
  body.shopoverviewpage section#content section.categories ul li {
    flex-basis: 33.33%;
  }
}
@media (min-width: 768px) {
  body.shopoverviewpage section#content section.categories ul li {
    flex-basis: 25%;
  }
}
@media (min-width: 1024px) {
  body.shopoverviewpage section#content section.categories ul li {
    flex-basis: 20%;
  }
}
@media (min-width: 1300px) {
  body.shopoverviewpage section#content section.categories ul li {
    padding-left: 20px;
    padding-right: 20px;
  }
}
body.shopoverviewpage section#content section.categories ul li a {
  display: block;
  color: #83847B;
  text-decoration: none;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopoverviewpage section#content section.categories ul li a:hover, body.shopoverviewpage section#content section.categories ul li a:focus {
  color: #232320;
}
body.shopoverviewpage section#content section.categories ul li .image {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 24px;
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
}
body.shopoverviewpage section#content section.categories ul li .image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
body.shopoverviewpage section#content section.categories ul li span {
  display: block;
  text-align: center;
}
body.shopoverviewpage section#content .new {
  padding-top: 65px;
  padding-bottom: 75px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 128px;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#content .new {
    margin-left: -75px;
    margin-right: -75px;
    padding-top: 115px;
    padding-bottom: 95px;
  }
}
@media (min-width: 1024px) {
  body.shopoverviewpage section#content .new {
    margin-left: -50px;
    margin-right: -50px;
    padding-bottom: 156px;
  }
}
@media (min-width: 1024px) {
  body.shopoverviewpage section#content .new {
    padding-bottom: 128px;
    padding-bottom: 175px;
  }
}
body.shopoverviewpage section#content .new .background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #F8F8F4;
}
body.shopoverviewpage section#content .new .background:before, body.shopoverviewpage section#content .new .background:after {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  background-size: 100% 100%;
  height: 14px;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#content .new .background:before, body.shopoverviewpage section#content .new .background:after {
    height: 29px;
  }
}
@media (min-width: 1024px) {
  body.shopoverviewpage section#content .new .background:before, body.shopoverviewpage section#content .new .background:after {
    height: 39px;
  }
}
@media (min-width: 1300px) {
  body.shopoverviewpage section#content .new .background:before, body.shopoverviewpage section#content .new .background:after {
    height: 55px;
  }
}
body.shopoverviewpage section#content .new .background:before {
  background-image: url("/img/stories_curve_2.svg");
  top: 0;
}
body.shopoverviewpage section#content .new .background:after {
  background-image: url("/img/stories_curve_1.svg");
  bottom: 0;
}
body.shopoverviewpage section#content .new h2 {
  color: #E72264;
  font-size: 36px;
  line-height: 32px;
}
body.shopoverviewpage section#content .new article .image {
  width: 174px;
  max-height: 174px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  body.shopoverviewpage section#content .new article .image {
    margin-left: 0;
    margin-right: 0;
  }
}
body.shopoverviewpage section#content .new article .image img {
  height: 100%;
  width: 100%;
}
body.shopoverviewpage section#content .new article .title {
  height: 40px;
  font-weight: 400;
}
body.shopoverviewpage section#content .new article .price + .subtitle {
  font-size: 16px;
}
body.shopoverviewpage .USPs {
  margin-bottom: 96px;
}
body.shopoverviewpage .USPs h2 {
  color: #E72264;
}
body.shopoverviewpage .landingFooter {
  margin-bottom: 96px;
}
body.shopoverviewpage .landingFooter img {
  padding: 0 50px;
  border-radius: 50%;
}
body.shopoverviewpage .landingFooter p {
  text-align: center;
}
body.shopoverviewpage section.howItWorks {
  position: relative;
}
@media (min-width: 768px) {
  body.shopoverviewpage section.howItWorks {
    margin-bottom: 96px;
  }
}
body.shopoverviewpage section.howItWorks h2 {
  color: #E72264;
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
}
body.shopoverviewpage section.howItWorks .container .row .col, body.shopoverviewpage section.howItWorks .container .row [class*=col-] {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  body.shopoverviewpage section.howItWorks .container .row .col, body.shopoverviewpage section.howItWorks .container .row [class*=col-] {
    margin-bottom: 0;
  }
}
body.shopoverviewpage section.howItWorks .container .row .col h3, body.shopoverviewpage section.howItWorks .container .row [class*=col-] h3 {
  font-size: 24px;
  line-height: 32px;
}
body.shopoverviewpage section.howItWorks .container .row .col img, body.shopoverviewpage section.howItWorks .container .row [class*=col-] img {
  display: block;
  margin: 0 auto 10px;
}
@media (min-width: 768px) {
  body.shopoverviewpage section.howItWorks .container .row .col img, body.shopoverviewpage section.howItWorks .container .row [class*=col-] img {
    margin-bottom: 20px;
  }
}
body.shopoverviewpage section.howItWorks .container .row .col p, body.shopoverviewpage section.howItWorks .container .row [class*=col-] p {
  font-size: 18px;
  line-height: 32px;
  margin: 0;
  text-align: center;
}
body.shopoverviewpage section#content section.howItWorks h2 {
  margin-bottom: 10px;
}
body.shopoverviewpage section#content section.howItWorks .container .row .col h3, body.shopoverviewpage section#content section.howItWorks .container .row [class*=col-] h3 {
  margin-bottom: 30px;
}

body.shopcategorypage .categoryHeaderContainer {
  margin-top: 200px;
  margin-bottom: 76px;
}
@media (min-width: 768px) {
  body.shopcategorypage .categoryHeaderContainer {
    margin-top: 0;
  }
}
body.shopcategorypage .categoryHeaderContainer .categoryHeader {
  background: #F8F8F4;
  border-radius: 16px;
}
body.shopcategorypage .categoryHeaderContainer .categoryHeader .imageColumn {
  position: relative;
  display: flex;
  justify-content: center;
}
body.shopcategorypage .categoryHeaderContainer .categoryHeader .image {
  margin-top: -100px;
}
@media (min-width: 768px) {
  body.shopcategorypage .categoryHeaderContainer .categoryHeader .image {
    position: absolute;
    bottom: 0;
    height: 174px;
    width: 174px;
    transform: translateY(45%);
  }
}
body.shopcategorypage .categoryHeaderContainer .categoryHeader .image img {
  height: 174px;
  width: 174px;
  border-radius: 50%;
}
body.shopcategorypage .categoryHeaderContainer .categoryHeader h1 {
  font-size: 48px;
  line-height: 40px;
  font-weight: 700;
  color: #232320;
  text-align: center;
}
@media (min-width: 768px) {
  body.shopcategorypage .categoryHeaderContainer .categoryHeader h1 {
    text-align: left;
  }
}
body.shopcategorypage section#content h1 {
  margin: 36px 0;
}
body.shopcategorypage .sortOptions {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
body.shopcategorypage .sortOptions * {
  white-space: nowrap;
}
@media (min-width: 768px) {
  body.shopcategorypage .sortOptions {
    margin-bottom: 0;
  }
}
body.shopcategorypage .sortOptions > span {
  margin-right: 8px;
}
body.shopcategorypage .sortOptions .sortOption {
  margin-right: 12px;
}
body.shopcategorypage .sortOptions .sortOption:last-child {
  margin-right: 0;
}
body.shopcategorypage .sortOptions .sortOption input {
  display: none;
}
body.shopcategorypage .sortOptions .sortOption label {
  color: #83847B;
  border-bottom: 1px solid transparent;
  padding-bottom: 5px;
  cursor: pointer;
  line-height: 30px;
  transition: border-color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopcategorypage .sortOptions .sortOption input:checked + label {
  border-bottom-color: #83847B;
  cursor: default;
}
body.shopcategorypage .openFilters {
  display: inline-block;
  width: 100%;
  text-align: center;
}
body.shopcategorypage .productsContainer {
  margin-top: 50px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  body.shopcategorypage .productsContainer {
    margin-bottom: 50px;
  }
}
body.shopcategorypage .productsContainer ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: -24px;
  margin-left: -10px;
  margin-right: -10px;
}
@media (min-width: 1300px) {
  body.shopcategorypage .productsContainer ul {
    margin-left: -20px;
    margin-right: -20px;
  }
}
body.shopcategorypage .productsContainer ul li {
  flex: 0 0 auto;
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 24px;
}
@media (min-width: 375px) {
  body.shopcategorypage .productsContainer ul li {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (min-width: 1024px) {
  body.shopcategorypage .productsContainer ul li {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 1300px) {
  body.shopcategorypage .productsContainer ul li {
    padding-left: 20px;
    padding-right: 20px;
  }
}
body.shopcategorypage .productsContainer article .image {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}
body.shopcategorypage .productsContainer article .image img {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 100%;
}
body.shopcategorypage .productsContainer ~ .pager {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  body.shopcategorypage .productsContainer ~ .pager {
    margin-bottom: 50px;
  }
}
body.shopcategorypage section#content article .image img {
  width: 100%;
  height: 100%;
}
body.shopcategorypage section#content article .contents > a.title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  min-height: 48px;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopcategorypage section#content article .contents > a.title:hover, body.shopcategorypage section#content article .contents > a.title:focus {
  color: #AA00FF;
}

body.shopproductdetailpage a.back {
  text-decoration: none;
  color: #83847B;
  display: inline-flex;
  align-items: center;
  min-height: 1px;
  padding: 5px 0;
  margin-bottom: 15px;
  position: relative;
  text-decoration: none;
}
body.shopproductdetailpage a.back:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #83847B;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopproductdetailpage a.back:hover:after, body.shopproductdetailpage a.back:focus:after {
  transform: none;
}
body.shopproductdetailpage a.back svg.sprite {
  margin-right: 10px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopproductdetailpage a.back:hover svg.sprite, body.shopproductdetailpage a.back:focus svg.sprite {
  transform: translateX(-5px);
}
body.shopproductdetailpage section#content h1 {
  margin-top: 0;
  color: #232320;
  font-size: 48px;
  line-height: 40px;
  margin-bottom: 32px;
}
body.shopproductdetailpage .infoColumn .price {
  margin-bottom: 35px;
}
body.shopproductdetailpage .infoColumn .price .original {
  font-size: 18px;
  color: #E72264;
  text-decoration: line-through;
  display: inline-block;
  margin-bottom: 5px;
}
body.shopproductdetailpage .infoColumn .price .current {
  font-size: 32px;
  line-height: 37px;
  font-weight: 700;
}
body.shopproductdetailpage .options {
  margin-bottom: 16px;
}
body.shopproductdetailpage .options .option {
  margin-bottom: 16px;
}
body.shopproductdetailpage .options .option:last-child {
  margin-bottom: 0;
}
body.shopproductdetailpage .options label {
  position: absolute;
  left: -999vw;
}
body.shopproductdetailpage .options .selectric-wrapper {
  display: inline-block;
  min-width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  body.shopproductdetailpage .options .selectric-wrapper {
    min-width: 280px;
  }
}
body.shopproductdetailpage .options .selectric-wrapper .selectric {
  background: #FFFFFF;
  border-radius: 26px;
  border: 1px solid #E1E1D2;
}
body.shopproductdetailpage .options .selectric-wrapper .selectric .label {
  font-size: 16px;
  font-family: "Public Sans", sans-serif;
  color: #83847B;
  height: 52px;
  line-height: 52px;
  margin: 0 52px 0 30px;
}
body.shopproductdetailpage .options .selectric-wrapper .selectric .button {
  height: 52px;
  width: 52px;
  background-color: #FFFFFF;
}
body.shopproductdetailpage .options .selectric-wrapper .selectric .button:after {
  border-width: 6px;
}
body.shopproductdetailpage .options .selectric-wrapper .selectric-items {
  border-color: #E1E1D2;
  box-shadow: none;
  background: #FFFFFF;
  border-top: none;
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
  overflow: hidden;
}
body.shopproductdetailpage .options .selectric-wrapper .selectric-items li {
  font-size: 16px;
  line-height: 18px;
  padding: 18px 30px;
}
body.shopproductdetailpage .options .selectric-wrapper .selectric-items li.disabled.selected {
  display: none;
}
body.shopproductdetailpage .options .selectric-wrapper .selectric-items li.highlighted, body.shopproductdetailpage .options .selectric-wrapper .selectric-items li:hover {
  background: #f8f8f4;
}
body.shopproductdetailpage .options .selectric-wrapper.selectric-open .selectric {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: transparent;
}
body.shopproductdetailpage .addContainer .button {
  min-width: 100%;
  max-width: 100%;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  body.shopproductdetailpage .addContainer .button {
    min-width: 280px;
  }
}
body.shopproductdetailpage .addContainer .button[disabled] {
  opacity: 0.5;
}
body.shopproductdetailpage .baseRow {
  margin-bottom: 68px;
}
@media (min-width: 768px) {
  body.shopproductdetailpage .baseRow {
    margin-bottom: 118px;
  }
}
body.shopproductdetailpage .productImageSliderMain,
body.shopproductdetailpage .productImagesSliderSmall {
  position: relative;
}
body.shopproductdetailpage .productImageSliderMain .previousContainer,
body.shopproductdetailpage .productImageSliderMain .nextContainer,
body.shopproductdetailpage .productImagesSliderSmall .previousContainer,
body.shopproductdetailpage .productImagesSliderSmall .nextContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  z-index: 1;
}
body.shopproductdetailpage .productImageSliderMain .previousContainer a,
body.shopproductdetailpage .productImageSliderMain .nextContainer a,
body.shopproductdetailpage .productImagesSliderSmall .previousContainer a,
body.shopproductdetailpage .productImagesSliderSmall .nextContainer a {
  display: inline-block;
  background: none;
  border: none;
  width: 26px;
  height: 38px;
  padding: 5px;
  cursor: pointer;
  transition: opacity 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  body.shopproductdetailpage .productImageSliderMain .previousContainer a,
  body.shopproductdetailpage .productImageSliderMain .nextContainer a,
  body.shopproductdetailpage .productImagesSliderSmall .previousContainer a,
  body.shopproductdetailpage .productImagesSliderSmall .nextContainer a {
    width: 40px;
    height: 56px;
    padding: 10px;
  }
}
body.shopproductdetailpage .productImageSliderMain .previousContainer a svg.sprite,
body.shopproductdetailpage .productImageSliderMain .nextContainer a svg.sprite,
body.shopproductdetailpage .productImagesSliderSmall .previousContainer a svg.sprite,
body.shopproductdetailpage .productImagesSliderSmall .nextContainer a svg.sprite {
  display: inline-block;
  width: 16px;
  max-height: 28px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
@media (min-width: 768px) {
  body.shopproductdetailpage .productImageSliderMain .previousContainer a svg.sprite,
  body.shopproductdetailpage .productImageSliderMain .nextContainer a svg.sprite,
  body.shopproductdetailpage .productImagesSliderSmall .previousContainer a svg.sprite,
  body.shopproductdetailpage .productImagesSliderSmall .nextContainer a svg.sprite {
    width: 20px;
    max-height: 36px;
  }
}
body.shopproductdetailpage .productImageSliderMain .previousContainer a svg.sprite use,
body.shopproductdetailpage .productImageSliderMain .nextContainer a svg.sprite use,
body.shopproductdetailpage .productImagesSliderSmall .previousContainer a svg.sprite use,
body.shopproductdetailpage .productImagesSliderSmall .nextContainer a svg.sprite use {
  stroke: #E1E1D2;
}
body.shopproductdetailpage .productImageSliderMain .previousContainer,
body.shopproductdetailpage .productImagesSliderSmall .previousContainer {
  left: 0;
}
@media (min-width: 768px) {
  body.shopproductdetailpage .productImageSliderMain .previousContainer,
  body.shopproductdetailpage .productImagesSliderSmall .previousContainer {
    left: 5%;
  }
}
body.shopproductdetailpage .productImageSliderMain .previousContainer a:hover svg.sprite,
body.shopproductdetailpage .productImagesSliderSmall .previousContainer a:hover svg.sprite {
  transform: translateX(-5px);
}
body.shopproductdetailpage .productImageSliderMain .nextContainer,
body.shopproductdetailpage .productImagesSliderSmall .nextContainer {
  right: 0;
}
@media (min-width: 768px) {
  body.shopproductdetailpage .productImageSliderMain .nextContainer,
  body.shopproductdetailpage .productImagesSliderSmall .nextContainer {
    right: 5%;
  }
}
body.shopproductdetailpage .productImageSliderMain .nextContainer a:hover svg.sprite,
body.shopproductdetailpage .productImagesSliderSmall .nextContainer a:hover svg.sprite {
  transform: translateX(5px) scaleX(-1);
}
body.shopproductdetailpage .productImageSliderMain {
  margin-bottom: 17px;
}
body.shopproductdetailpage .productImageSliderMain .previousContainer a,
body.shopproductdetailpage .productImageSliderMain .nextContainer a {
  width: 26px;
  height: 38px;
  padding: 5px;
}
@media (min-width: 768px) {
  body.shopproductdetailpage .productImageSliderMain .previousContainer a,
  body.shopproductdetailpage .productImageSliderMain .nextContainer a {
    width: 40px;
    height: 56px;
    padding: 10px;
  }
}
body.shopproductdetailpage .productImageSliderMain .previousContainer a svg.sprite,
body.shopproductdetailpage .productImageSliderMain .nextContainer a svg.sprite {
  width: 16px;
  max-height: 28px;
}
@media (min-width: 768px) {
  body.shopproductdetailpage .productImageSliderMain .previousContainer a svg.sprite,
  body.shopproductdetailpage .productImageSliderMain .nextContainer a svg.sprite {
    width: 20px;
    max-height: 36px;
  }
}
body.shopproductdetailpage .productImageSliderMain li {
  padding: 0 15%;
}
body.shopproductdetailpage .productImageSliderMain li .image {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}
body.shopproductdetailpage .productImageSliderMain li .image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
body.shopproductdetailpage .productImagesSliderSmall {
  padding-left: 10%;
  padding-right: 10%;
}
body.shopproductdetailpage .productImagesSliderSmall .previousContainer a,
body.shopproductdetailpage .productImagesSliderSmall .nextContainer a {
  width: 22px;
  height: 30px;
  padding: 5px;
}
@media (min-width: 768px) {
  body.shopproductdetailpage .productImagesSliderSmall .previousContainer a,
  body.shopproductdetailpage .productImagesSliderSmall .nextContainer a {
    width: 32px;
    height: 40px;
    padding: 10px;
  }
}
body.shopproductdetailpage .productImagesSliderSmall .previousContainer a svg.sprite,
body.shopproductdetailpage .productImagesSliderSmall .nextContainer a svg.sprite {
  width: 12px;
  max-height: 20px;
}
@media (min-width: 768px) {
  body.shopproductdetailpage .productImagesSliderSmall .previousContainer a svg.sprite,
  body.shopproductdetailpage .productImagesSliderSmall .nextContainer a svg.sprite {
    width: 12px;
    max-height: 20px;
  }
}
body.shopproductdetailpage .productImagesSliderSmall li {
  padding: 0 15%;
}
body.shopproductdetailpage .productImagesSliderSmall li .image {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}
body.shopproductdetailpage .productImagesSliderSmall li .image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
body.shopproductdetailpage .productImagesSliderSmall .slick-current li .image img {
  border: 1px solid #707070;
}
body.shopproductdetailpage .tabs ul.tabHeaders {
  display: none;
  justify-content: flex-start;
  margin-bottom: 35px;
  margin-left: -16px;
  margin-right: -16px;
}
@media (min-width: 768px) {
  body.shopproductdetailpage .tabs ul.tabHeaders {
    display: flex;
  }
}
body.shopproductdetailpage .tabs ul.tabHeaders li {
  display: inline-block;
  padding: 0;
  margin: 0 16px;
}
body.shopproductdetailpage .tabs ul.tabHeaders li button {
  display: inline-block;
  background: transparent;
  border: none;
  padding: 5px 0;
  position: relative;
  text-decoration: none;
  color: #651FFF;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopproductdetailpage .tabs ul.tabHeaders li button:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #651FFF;
  height: 2px;
  width: 100%;
  transform: scaleX(0);
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: transform, background;
}
body.shopproductdetailpage .tabs ul.tabHeaders li button:hover:after, body.shopproductdetailpage .tabs ul.tabHeaders li button:focus:after {
  transform: none;
}
body.shopproductdetailpage .tabs ul.tabHeaders li button[aria-selected=true] {
  color: #232320;
}
body.shopproductdetailpage .tabs ul.tabHeaders li button[aria-selected=true]:after {
  transform: none;
  background: #232320;
}
body.shopproductdetailpage .tabs .tabContent {
  margin-bottom: 56px;
  display: block;
}
body.shopproductdetailpage .tabs .tabContent .mobileTitle {
  display: inline-block;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  body.shopproductdetailpage .tabs .tabContent .mobileTitle {
    display: none;
  }
}
@media (min-width: 768px) {
  body.shopproductdetailpage .tabs .tabContent {
    display: none;
    margin-bottom: 0;
  }
  body.shopproductdetailpage .tabs .tabContent.active {
    display: block;
  }
}
body.shopproductdetailpage .tabs #info-tab-description ul, body.shopproductdetailpage .tabs #info-tab-description ol,
body.shopproductdetailpage .tabs #info-tab-specifications ul,
body.shopproductdetailpage .tabs #info-tab-specifications ol,
body.shopproductdetailpage .tabs #info-tab-shipping ul,
body.shopproductdetailpage .tabs #info-tab-shipping ol {
  padding-left: 34px;
}
body.shopproductdetailpage .tabs #info-tab-description ul li, body.shopproductdetailpage .tabs #info-tab-description ol li,
body.shopproductdetailpage .tabs #info-tab-specifications ul li,
body.shopproductdetailpage .tabs #info-tab-specifications ol li,
body.shopproductdetailpage .tabs #info-tab-shipping ul li,
body.shopproductdetailpage .tabs #info-tab-shipping ol li {
  margin-bottom: 10px;
  position: relative;
}
body.shopproductdetailpage .tabs #info-tab-description ul li:before, body.shopproductdetailpage .tabs #info-tab-description ol li:before,
body.shopproductdetailpage .tabs #info-tab-specifications ul li:before,
body.shopproductdetailpage .tabs #info-tab-specifications ol li:before,
body.shopproductdetailpage .tabs #info-tab-shipping ul li:before,
body.shopproductdetailpage .tabs #info-tab-shipping ol li:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -34px;
  top: 8px;
  width: 24px;
  height: 2px;
  background: #651FFF;
}
body.shopproductdetailpage .tabs #info-tab-description ol,
body.shopproductdetailpage .tabs #info-tab-specifications ol,
body.shopproductdetailpage .tabs #info-tab-shipping ol {
  counter-set: listitem;
}
body.shopproductdetailpage .tabs #info-tab-description ol li,
body.shopproductdetailpage .tabs #info-tab-specifications ol li,
body.shopproductdetailpage .tabs #info-tab-shipping ol li {
  counter-increment: listitem;
}
body.shopproductdetailpage .tabs #info-tab-description ol li:before,
body.shopproductdetailpage .tabs #info-tab-specifications ol li:before,
body.shopproductdetailpage .tabs #info-tab-shipping ol li:before {
  content: counter(listitem) ".";
  color: #651FFF;
  background: transparent;
  top: 0;
  left: -30px;
  text-align: right;
}
body.shopproductdetailpage .tabs #info-tab-specifications table {
  display: block;
  width: 100% !important;
  font-size: inherit !important;
  font-family: inherit !important;
}
body.shopproductdetailpage .tabs #info-tab-specifications table tr,
body.shopproductdetailpage .tabs #info-tab-specifications table td {
  display: block;
  height: auto !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
body.shopproductdetailpage .tabs #info-tab-specifications table tbody {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}
body.shopproductdetailpage .tabs #info-tab-specifications table tr {
  border-top: 1px solid #E1E1D2;
  height: auto !important;
  padding: 10px;
}
body.shopproductdetailpage .tabs #info-tab-specifications table tr:last-of-type {
  border-bottom: 1px solid #E1E1D2;
}
body.shopproductdetailpage .tabs #info-tab-specifications table td {
  display: inline-block;
  width: auto !important;
  border: none !important;
  overflow: visible !important;
  padding: 0 !important;
  vertical-align: middle !important;
}
body.shopproductdetailpage .tabs #info-tab-specifications table td:first-child {
  flex: 0 0 100%;
  max-width: 100%;
  color: #83847B;
  font-size: 16px;
  padding-bottom: 5px !important;
}
@media (min-width: 768px) {
  body.shopproductdetailpage .tabs #info-tab-specifications table td:first-child {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    font-size: 18px;
    padding-bottom: 0 !important;
  }
}
body.shopproductdetailpage .tabs #info-tab-specifications table td:last-child {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  body.shopproductdetailpage .tabs #info-tab-specifications table td:last-child {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
}
body.shopproductdetailpage .specSheet {
  overflow: hidden;
}
body.shopproductdetailpage .specSheet span {
  font-size: 16px;
  line-height: 24px;
}
body.shopproductdetailpage .specSheet .row {
  border-top: 2px solid #E1E1D2;
}
body.shopproductdetailpage .specSheet .row:last-child {
  border-bottom: 2px solid #E1E1D2;
}
body.shopproductdetailpage .specSheet .row span {
  display: block;
  padding: 8px 0;
}
body.shopproductdetailpage .specSheet .row > .col:first-child span,
body.shopproductdetailpage .specSheet .row > [class*=col-]:first-child span {
  color: #83847B;
}
body.shopproductdetailpage .aboutSDGGoalsContainer {
  margin-bottom: 70px;
}
body.shopproductdetailpage .aboutSDGGoalsContainer h2 {
  font-size: 24px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 30px;
}
body.shopproductdetailpage .aboutSDGGoalsContainer ul {
  margin-bottom: 46px;
}
body.shopproductdetailpage .aboutSDGGoalsContainer li {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
body.shopproductdetailpage .aboutSDGGoalsContainer li:last-child {
  margin-bottom: 0;
}
body.shopproductdetailpage .aboutSDGGoalsContainer li svg.sprite {
  width: 35px;
  margin-right: 16px;
}
body.shopproductdetailpage .aboutSDGGoalsContainer a {
  color: #651FFF;
  position: relative;
  text-decoration: none;
}
body.shopproductdetailpage .aboutSDGGoalsContainer a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #651FFF;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopproductdetailpage .aboutSDGGoalsContainer a:hover:after, body.shopproductdetailpage .aboutSDGGoalsContainer a:focus:after {
  transform: none;
}
body.shopproductdetailpage .aboutSellerContainer {
  margin-bottom: 88px;
}
body.shopproductdetailpage .aboutSellerContainer .image {
  max-width: 64%;
  margin-bottom: 16px;
}
body.shopproductdetailpage .aboutSellerContainer .text {
  line-height: 1.4em;
}
body.shopproductdetailpage section#content .aboutStriberContainer .striberInfo {
  display: flex;
  align-items: center;
}
body.shopproductdetailpage section#content .aboutStriberContainer .striberInfo .image {
  flex: 0 0 auto;
  height: 90px;
  width: 90px;
  margin-right: 16px;
}
body.shopproductdetailpage section#content .aboutStriberContainer .striberInfo .image img {
  border-radius: 50%;
}
body.shopproductdetailpage section#content .aboutStriberContainer .striberInfo .heading * {
  display: inline-block;
}
body.shopproductdetailpage section#content .aboutStriberContainer .striberInfo .heading .subtitle {
  font-size: 18px;
  line-height: 21px;
  font-weight: normal;
  margin-bottom: 4px;
  font-style: normal;
}
body.shopproductdetailpage section#content .aboutStriberContainer .striberInfo .heading .title {
  color: #E72264;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}
body.shopproductdetailpage section#content .aboutStriberContainer .text a {
  display: inline-block;
  padding: 5px 0;
  color: #707070;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}
body.shopproductdetailpage section#content .aboutStriberContainer .text a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #707070;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopproductdetailpage section#content .aboutStriberContainer .text a:hover:after, body.shopproductdetailpage section#content .aboutStriberContainer .text a:focus:after {
  transform: none;
}
body.shopproductdetailpage section#content .aboutStriberContainer .text a:before {
  content: "▶";
  display: inline-block;
  transform: scale(0.6);
  position: absolute;
  right: -18px;
}
body.shopproductdetailpage .reviewsContainer {
  margin-bottom: 64px;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer ol {
  display: block;
  margin-bottom: 10px;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer li {
  display: inline-block;
  width: 29px;
  margin-right: 6px;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer li svg.sprite {
  width: 35px;
  padding-left: 3px;
  padding-right: 3px;
  max-height: 25px;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer li svg.sprite use {
  fill: transparent;
  stroke: #707070;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer li:last-child {
  margin-right: 0;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer li input {
  display: none;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer li label {
  cursor: pointer;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer li label svg.sprite use {
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: fill, stroke;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer li label:hover svg.sprite use, body.shopproductdetailpage .reviewsContainer .ratingContainer li label:focus svg.sprite use {
  fill: #12C700;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer li input:checked + label svg.sprite {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: liked;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopproductdetailpage .reviewsContainer .ratingContainer li input:checked + label svg.sprite use {
  fill: #12C700;
  stroke: #12C700;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer[data-rating="1"] li:nth-child(-n+1) label svg.sprite {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: liked;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopproductdetailpage .reviewsContainer .ratingContainer[data-rating="1"] li:nth-child(-n+1) label svg.sprite use {
  fill: #12C700;
  stroke: #12C700;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer[data-rating="2"] li:nth-child(-n+2) label svg.sprite {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: liked;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopproductdetailpage .reviewsContainer .ratingContainer[data-rating="2"] li:nth-child(-n+2) label svg.sprite use {
  fill: #12C700;
  stroke: #12C700;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer[data-rating="3"] li:nth-child(-n+3) label svg.sprite {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: liked;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopproductdetailpage .reviewsContainer .ratingContainer[data-rating="3"] li:nth-child(-n+3) label svg.sprite use {
  fill: #12C700;
  stroke: #12C700;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer[data-rating="4"] li:nth-child(-n+4) label svg.sprite {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: liked;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopproductdetailpage .reviewsContainer .ratingContainer[data-rating="4"] li:nth-child(-n+4) label svg.sprite use {
  fill: #12C700;
  stroke: #12C700;
}
body.shopproductdetailpage .reviewsContainer .ratingContainer[data-rating="5"] li:nth-child(-n+5) label svg.sprite {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: liked;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopproductdetailpage .reviewsContainer .ratingContainer[data-rating="5"] li:nth-child(-n+5) label svg.sprite use {
  fill: #12C700;
  stroke: #12C700;
}
body.shopproductdetailpage .reviewsContainer .inputRow input {
  padding: 5px 20px;
  line-height: 30px;
}
body.shopproductdetailpage .reviewsContainer .inputRow label span {
  color: #707070;
}
body.shopproductdetailpage .reviewsContainer .textarea {
  margin-top: 20px;
  margin-bottom: 32px;
}
body.shopproductdetailpage .reviewsContainer .textarea label {
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 3px;
}
body.shopproductdetailpage .reviewsContainer .textarea textarea {
  background: white;
  border-radius: 0;
  border: 1px solid #E1E1D2;
  padding: 5px 10px;
  color: #707070;
  min-height: 128px;
  resize: vertical;
}
body.shopproductdetailpage .reviewsContainer .textarea textarea:focus {
  border-color: #232320;
}
body.shopproductdetailpage section#content .reviewsContainer h3 {
  font-weight: 500;
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 32px;
}
body.shopproductdetailpage section#content section.related {
  padding-top: 65px;
  padding-bottom: 75px;
  position: relative;
  margin-bottom: -20px;
  margin-top: 20px;
  margin-left: -15px;
  margin-right: -15px;
}
@media (min-width: 768px) {
  body.shopproductdetailpage section#content section.related {
    margin-left: -75px;
    margin-right: -75px;
    padding-top: 115px;
    padding-bottom: 95px;
    margin-top: 35px;
  }
}
@media (min-width: 1024px) {
  body.shopproductdetailpage section#content section.related {
    margin-left: -50px;
    margin-right: -50px;
    margin-top: 50px;
  }
}
body.shopproductdetailpage section#content section.related .background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #F8F8F4;
}
body.shopproductdetailpage section#content section.related .background:before {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  background-size: 100% 100%;
  height: 14px;
}
@media (min-width: 768px) {
  body.shopproductdetailpage section#content section.related .background:before {
    height: 29px;
  }
}
@media (min-width: 1024px) {
  body.shopproductdetailpage section#content section.related .background:before {
    height: 39px;
  }
}
@media (min-width: 1300px) {
  body.shopproductdetailpage section#content section.related .background:before {
    height: 55px;
  }
}
body.shopproductdetailpage section#content section.related .background:before {
  background-image: url("/img/stories_curve_2.svg");
  top: 0;
}
body.shopproductdetailpage section#content section.related h2 {
  color: #E72264;
  margin-bottom: 10px;
}
body.shopproductdetailpage section#content section.related > div:nth-of-type(1) > .row {
  margin-bottom: 35px;
}
body.shopproductdetailpage section#content section.related > div:nth-of-type(2) > .row {
  margin-bottom: 35px;
}
body.shopproductdetailpage section#content section.related > div:nth-of-type(2) > .row > div {
  display: flex;
  align-items: center;
}
body.shopproductdetailpage section#content section.related a.readmore {
  display: inline-flex;
  align-items: center;
  color: #83847B;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
body.shopproductdetailpage section#content section.related a.readmore svg {
  margin-left: 5px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopproductdetailpage section#content section.related a.readmore:hover svg, body.shopproductdetailpage section#content section.related a.readmore:focus svg {
  transform: translateX(4px);
}
body.shopproductdetailpage section#content section.related ul.productsList {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
body.shopproductdetailpage section#content section.related ul.productsList li {
  padding: 10px;
  flex-basis: 100%;
}
@media (min-width: 768px) {
  body.shopproductdetailpage section#content section.related ul.productsList li {
    flex-basis: 50%;
  }
}
@media (min-width: 1300px) {
  body.shopproductdetailpage section#content section.related ul.productsList li {
    flex-basis: 25%;
    padding: 10px 20px;
  }
}
body.shopproductdetailpage section#content section.related ul.productsList li article .title {
  font-weight: 400;
}
@media (min-width: 1024px) {
  body.shopproductdetailpage section#content section.related ul.productsList li article .title {
    font-size: 24px;
  }
}
body.shopproductdetailpage section#content section.related ul.productsList li article .image {
  justify-content: start;
}
@media (min-width: 1024px) {
  body.shopproductdetailpage section#content section.related ul.productsList li article .image img {
    width: 175px;
    height: 175px;
  }
}
body.shopproductdetailpage ul.reviews > li {
  border-bottom: 1px solid #E1E1D2;
  padding: 24px 0 14px;
}
body.shopproductdetailpage ul.reviews > li:first-child {
  border-top: 1px solid #E1E1D2;
}
body.shopproductdetailpage ul.reviews > li .review .rating {
  display: block;
  margin-bottom: 16px;
}
body.shopproductdetailpage ul.reviews > li .review .rating li {
  display: inline-block;
  width: 20px;
  margin-right: 6px;
}
body.shopproductdetailpage ul.reviews > li .review .rating li svg.sprite {
  width: 26px;
  padding-left: 2px;
  padding-right: 2px;
  max-height: 19px;
}
body.shopproductdetailpage ul.reviews > li .review .rating li svg.sprite use {
  fill: #E1E1D2;
  stroke: none;
}
body.shopproductdetailpage ul.reviews > li .review .rating li:last-child {
  margin-right: 0;
}
body.shopproductdetailpage ul.reviews > li .review .rating li.active svg.sprite use {
  fill: #12C700;
}
body.shopproductdetailpage ul.reviews > li .review .info {
  color: #707070;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 7px;
}
body.shopproductdetailpage ul.reviews > li .review .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 4px;
}
body.shopproductdetailpage ul.reviews > li .review .content p {
  font-size: 18px;
  line-height: 24px;
}
body.shopproductdetailpage ul.reviews > li .review .content p:first-child {
  margin-top: 0;
}
body.shopproductdetailpage ul.reviews > li .review .content p:last-child {
  margin-bottom: 0;
}
body.shopproductdetailpage .factList li {
  font-size: 18px;
  line-height: 24px;
  padding-left: 20px;
  position: relative;
  margin-bottom: 12px;
}
body.shopproductdetailpage .factList li:last-child {
  margin-bottom: 0;
}
body.shopproductdetailpage .factList li:before {
  height: 13px;
  width: 13px;
  display: block;
  position: absolute;
  content: "";
  left: 0;
  top: 7px;
  background: url("/img/icon_check_green.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

body.shopbasketpage section#content {
  padding-top: 50px;
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  body.shopbasketpage section#content {
    padding-top: 50px;
    margin-bottom: 128px;
  }
}
body.shopbasketpage a.continue.disabled, body.shopbasketpage button.continue.disabled {
  opacity: 0.3;
  pointer-events: none;
  user-select: none;
  cursor: default;
}
body.shopbasketpage a.contintueshopping span.link {
  font-size: 18px;
}
body.shopbasketpage .headerRow {
  margin-bottom: 32px;
}
body.shopbasketpage section#content h1 {
  color: #232320;
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: bolder;
}
@media (min-width: 768px) {
  body.shopbasketpage section#content h1 {
    margin-bottom: 0;
  }
}
body.shopbasketpage form {
  margin: 0;
  margin-bottom: 72px;
}
body.shopbasketpage form img.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
body.shopbasketpage form.loading {
  opacity: 0.6;
  pointer-events: none;
}
body.shopbasketpage form.loading img.loading {
  display: inline-block;
}
body.shopbasketpage ul.basketProducts li {
  padding: 24px 0;
  border-bottom: 1px solid #E1E1D2;
}
body.shopbasketpage ul.basketProducts li:first-of-type {
  border-top: 1px solid #E1E1D2;
}
body.shopbasketpage ul.basketProducts li .row {
  align-items: center;
}
body.shopbasketpage .imageColumn .image {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  border-radius: 50%;
  overflow: hidden;
}
body.shopbasketpage .imageColumn .image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
body.shopbasketpage .infoColumn a.title {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 7px;
  color: #232320;
  text-decoration: none;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopbasketpage .infoColumn a.title:hover, body.shopbasketpage .infoColumn a.title:focus {
  color: #651FFF;
}
body.shopbasketpage .infoColumn .variant {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 26px;
}
body.shopbasketpage .infoColumn .variant span {
  padding-left: 16px;
}
body.shopbasketpage .infoColumn .variant span:first-child {
  padding-left: 0;
}
body.shopbasketpage .infoColumn .price {
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #83847B;
}
@media (min-width: 768px) {
  body.shopbasketpage .infoColumn .price {
    display: inline-block;
  }
}
body.shopbasketpage .infoColumn .price .original {
  padding-left: 16px;
  color: #E72264;
  text-decoration: line-through;
}
body.shopbasketpage .infoColumn .seller {
  font-size: 16px;
  line-height: 18px;
  color: #83847B;
}
@media (min-width: 768px) {
  body.shopbasketpage .infoColumn .seller {
    margin-left: 17px;
  }
}
body.shopbasketpage .infoColumn .seller:before {
  display: none;
  margin-right: 17px;
  content: "";
  top: -3px;
  height: 1px;
  width: 48px;
  background: #83847B;
  position: relative;
}
@media (min-width: 768px) {
  body.shopbasketpage .infoColumn .seller:before {
    display: inline-block;
  }
}
body.shopbasketpage .quantityColumn input {
  text-align: center;
  border: none;
  background: transparent;
  border-radius: 0;
  border-bottom: 1px solid #E1E1D2;
}
body.shopbasketpage .quantityColumn input:hover, body.shopbasketpage .quantityColumn input:focus {
  border-bottom-color: #232320;
}
body.shopbasketpage .priceColumn {
  margin-top: 32px;
}
@media (min-width: 768px) {
  body.shopbasketpage .priceColumn {
    margin-top: 0;
  }
}
body.shopbasketpage .priceColumn .totalPrice {
  text-align: right;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 17px;
}
body.shopbasketpage .priceColumn .VAT {
  text-align: right;
  font-size: 16px;
  line-height: 24px;
  color: #83847B;
}
body.shopbasketpage .deleteColumn {
  text-align: right;
  margin-top: 32px;
}
@media (min-width: 768px) {
  body.shopbasketpage .deleteColumn {
    margin-top: 0;
  }
}
body.shopbasketpage .deleteColumn button {
  background: transparent;
}
body.shopbasketpage .deleteColumn button svg.sprite use {
  transition: fill 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopbasketpage .deleteColumn button:hover svg.sprite use, body.shopbasketpage .deleteColumn button:focus svg.sprite use {
  fill: #E72264;
}
body.shopbasketpage .voucherColumn form {
  margin-bottom: 0;
}
body.shopbasketpage .voucherColumn .button {
  color: #707070;
  transition-property: border-color, color;
}
body.shopbasketpage .voucherColumn .button:hover, body.shopbasketpage .voucherColumn .button:focus {
  border-color: #232320;
  color: #232320;
}
body.shopbasketpage .voucherColumn, body.shopbasketpage .totalsColumn {
  margin-bottom: 70px;
}
body.shopbasketpage .enterVoucherContainer {
  width: 100%;
  border: 1px solid #E1E1D2;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  body.shopbasketpage .enterVoucherContainer {
    flex-direction: row;
  }
}
body.shopbasketpage .enterVoucherContainer .inputRow {
  margin-bottom: 0;
  position: relative;
}
body.shopbasketpage .enterVoucherContainer label {
  position: absolute;
  top: -15px;
  transition-property: color, transform;
}
body.shopbasketpage .enterVoucherContainer button {
  flex: 0 0 auto;
  margin-top: 24px;
  text-align: center;
  display: inline-block;
}
@media (min-width: 1024px) {
  body.shopbasketpage .enterVoucherContainer button {
    margin-top: 0;
    margin-left: 24px;
  }
}
body.shopbasketpage .enterVoucherContainer input {
  border-bottom-color: #707070;
}
body.shopbasketpage .totalsColumn table {
  margin-left: auto;
}
@media (max-width: 374.98px) {
  body.shopbasketpage .totalsColumn table {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 374.98px) {
  body.shopbasketpage .totalsColumn table tr {
    display: flex;
    flex-direction: column;
  }
}
body.shopbasketpage .totalsColumn table td {
  font-size: 18px;
  line-height: 24px;
  color: #83847B;
  text-align: right;
}
@media (max-width: 374.98px) {
  body.shopbasketpage .totalsColumn table td {
    display: block;
    text-align: left;
  }
}
body.shopbasketpage .totalsColumn table td:first-child {
  font-size: 16px;
}
@media (max-width: 374.98px) {
  body.shopbasketpage .totalsColumn table td:first-child {
    color: #232320;
  }
}
body.shopbasketpage .totalsColumn table td:last-child {
  padding-left: 67px;
  white-space: nowrap;
}
@media (max-width: 374.98px) {
  body.shopbasketpage .totalsColumn table td:last-child {
    padding-left: 0;
    margin-bottom: 16px;
  }
}
body.shopbasketpage .totalsColumn table .subtotal td:last-child {
  font-size: 24px;
}
body.shopbasketpage .totalsColumn table .shipping td:first-child {
  position: relative;
}
body.shopbasketpage .totalsColumn table .shipping i.info {
  position: absolute;
  right: -24px;
  top: 4px;
}
@media (max-width: 374.98px) {
  body.shopbasketpage .totalsColumn table .shipping i.info {
    right: -16px;
  }
}
body.shopbasketpage .totalsColumn table .total td {
  padding-top: 37px;
  color: #232320;
}
@media (max-width: 374.98px) {
  body.shopbasketpage .totalsColumn table .total td {
    padding-top: 0;
  }
}
body.shopbasketpage .totalsColumn table .total td:last-child {
  font-size: 24px;
}

body.shopcheckoutaddresspage section#content h1 {
  color: #232320;
  margin-top: 0;
  margin-bottom: 43px;
  font-size: 32px;
  line-height: 32px;
}
body.shopcheckoutaddresspage .progressRow {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body.shopcheckoutaddresspage .progressRow {
    margin-bottom: 62px;
  }
}
body.shopcheckoutaddresspage .progressRow .progressStep {
  position: relative;
  display: block;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  body.shopcheckoutaddresspage .progressRow .progressStep {
    margin-bottom: 0;
  }
}
body.shopcheckoutaddresspage .progressRow .progressStep span {
  display: inline-block;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 16px;
  color: #707070;
}
body.shopcheckoutaddresspage .progressRow .progressStep:before, body.shopcheckoutaddresspage .progressRow .progressStep:after {
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  content: "";
  display: block;
  transform-origin: left;
}
body.shopcheckoutaddresspage .progressRow .progressStep:before {
  background: #E1E1D2;
}
body.shopcheckoutaddresspage .progressRow .progressStep:after {
  background: #FFC400;
  transform: scaleX(0);
}
body.shopcheckoutaddresspage .progressRow .progressStep.active span {
  color: #232320;
  font-weight: 700;
}
body.shopcheckoutaddresspage .progressRow .progressStep.active:after {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: activeProgressStep;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopcheckoutaddresspage .progressRow .progressStep.finished span {
  color: #232320;
}
body.shopcheckoutaddresspage .progressRow .progressStep.finished:after {
  transform: none;
}
body.shopcheckoutaddresspage form {
  margin: 0;
}
body.shopcheckoutaddresspage form legend {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: 38px;
}
body.shopcheckoutaddresspage form .input {
  align-items: center;
}
body.shopcheckoutaddresspage form .input label {
  color: #707070;
  cursor: pointer;
}
body.shopcheckoutaddresspage form .input input {
  padding: 5px 20px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #E1E1D2;
  padding-left: 10px;
  font-size: 18px;
  background: transparent;
  color: #232320;
  line-height: 30px;
}
body.shopcheckoutaddresspage form .input input:focus {
  border-bottom-color: #707070;
}
body.shopcheckoutaddresspage form .field-validation-error {
  color: #E72264;
  line-height: 21px;
  display: inline-block;
  margin-top: 5px;
}
body.shopcheckoutaddresspage form .simpleRow {
  margin-bottom: 32px;
}
body.shopcheckoutaddresspage form .simpleRow:last-child {
  margin-bottom: 0;
}
body.shopcheckoutaddresspage form .emailRow {
  margin-bottom: 67px;
}
body.shopcheckoutaddresspage .billingColumn, body.shopcheckoutaddresspage .shippingColumn {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  body.shopcheckoutaddresspage .billingColumn, body.shopcheckoutaddresspage .shippingColumn {
    margin-bottom: 67px;
  }
}
body.shopcheckoutaddresspage .shippingColumn .simpleRow.check {
  position: absolute;
  top: 38px;
}
body.shopcheckoutaddresspage .shippingColumn .simpleRow:not(.check) {
  display: flex;
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: appear;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopcheckoutaddresspage .shippingColumn .simpleRow.check.checked ~ .simpleRow {
  display: none;
}
body.shopcheckoutaddresspage section#content .accountRow span.subtitle {
  display: inline-block;
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 32px;
}
body.shopcheckoutaddresspage .accountRow {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  body.shopcheckoutaddresspage .accountRow {
    margin-bottom: 100px;
  }
}
body.shopcheckoutaddresspage .accountRow legend {
  margin-bottom: 14px;
}
body.shopcheckoutaddresspage .accountRow .simpleRow:not(.check) {
  display: none;
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: appear;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopcheckoutaddresspage .accountRow .simpleRow.check.checked ~ .simpleRow {
  display: flex;
}
body.shopcheckoutaddresspage .stepsRow {
  padding-top: 32px;
  border-top: 1px solid #E1E1D2;
  align-items: center;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body.shopcheckoutaddresspage .stepsRow {
    margin-bottom: 100px;
  }
}
body.shopcheckoutaddresspage a.back {
  text-decoration: none;
  color: #83847B;
  display: inline-flex;
  align-items: center;
  min-height: 1px;
  padding: 5px 0;
  position: relative;
  text-decoration: none;
}
body.shopcheckoutaddresspage a.back:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #83847B;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopcheckoutaddresspage a.back:hover:after, body.shopcheckoutaddresspage a.back:focus:after {
  transform: none;
}
body.shopcheckoutaddresspage a.back svg.sprite {
  margin-right: 10px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopcheckoutaddresspage a.back:hover svg.sprite, body.shopcheckoutaddresspage a.back:focus svg.sprite {
  transform: translateX(-5px);
}
body.shopcheckoutaddresspage .stepForwardColumn .button {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body.shopcheckoutaddresspage .stepForwardColumn .button {
    margin-bottom: 0;
  }
}

body.shopcheckoutoverviewpage section#content h1 {
  color: #232320;
  margin-top: 0;
  margin-bottom: 43px;
  font-size: 32px;
  line-height: 32px;
}
body.shopcheckoutoverviewpage section#content h2 {
  font-size: 24px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 38px;
}
body.shopcheckoutoverviewpage .progressRow {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body.shopcheckoutoverviewpage .progressRow {
    margin-bottom: 62px;
  }
}
body.shopcheckoutoverviewpage .progressRow .progressStep {
  position: relative;
  display: block;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  body.shopcheckoutoverviewpage .progressRow .progressStep {
    margin-bottom: 0;
  }
}
body.shopcheckoutoverviewpage .progressRow .progressStep span {
  display: inline-block;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 16px;
  color: #707070;
}
body.shopcheckoutoverviewpage .progressRow .progressStep:before, body.shopcheckoutoverviewpage .progressRow .progressStep:after {
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  content: "";
  display: block;
  transform-origin: left;
}
body.shopcheckoutoverviewpage .progressRow .progressStep:before {
  background: #E1E1D2;
}
body.shopcheckoutoverviewpage .progressRow .progressStep:after {
  background: #FFC400;
  transform: scaleX(0);
}
body.shopcheckoutoverviewpage .progressRow .progressStep.active span {
  color: #232320;
  font-weight: 700;
}
body.shopcheckoutoverviewpage .progressRow .progressStep.active:after {
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: activeProgressStep;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopcheckoutoverviewpage .progressRow .progressStep.finished span {
  color: #232320;
}
body.shopcheckoutoverviewpage .overviewRow {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body.shopcheckoutoverviewpage .overviewRow {
    margin-bottom: 64px;
  }
}
body.shopcheckoutoverviewpage .overview li {
  border-top: 1px solid #E1E1D2;
  padding-top: 24px;
  padding-bottom: 24px;
}
body.shopcheckoutoverviewpage .overview li:last-of-type {
  border-bottom: 1px solid #E1E1D2;
}
body.shopcheckoutoverviewpage .overview .quantityColumn {
  text-align: center;
}
body.shopcheckoutoverviewpage .overview .imageColumn .image {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  border-radius: 300px;
  overflow: hidden;
}
body.shopcheckoutoverviewpage .overview .imageColumn .image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
body.shopcheckoutoverviewpage .overview .infoColumn .name {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 7px;
}
body.shopcheckoutoverviewpage .overview .infoColumn .variant {
  margin-bottom: 4px;
}
body.shopcheckoutoverviewpage .overview .infoColumn .variant span {
  font-size: 16px;
  line-height: 18px;
}
body.shopcheckoutoverviewpage .overview .infoColumn .seller span {
  font-size: 16px;
  line-height: 18px;
  color: #707070;
}
body.shopcheckoutoverviewpage .overview .priceColumn {
  text-align: right;
  font-size: 24px;
  line-height: 24px;
}
body.shopcheckoutoverviewpage .totalsRow {
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  body.shopcheckoutoverviewpage .totalsRow {
    margin-bottom: 96px;
  }
}
body.shopcheckoutoverviewpage .totalsRow table {
  margin-left: auto;
}
@media (max-width: 374.98px) {
  body.shopcheckoutoverviewpage .totalsRow table {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 374.98px) {
  body.shopcheckoutoverviewpage .totalsRow table tr {
    display: flex;
    flex-direction: column;
  }
}
body.shopcheckoutoverviewpage .totalsRow table td {
  font-size: 18px;
  line-height: 24px;
  color: #83847B;
  text-align: right;
}
@media (max-width: 374.98px) {
  body.shopcheckoutoverviewpage .totalsRow table td {
    display: block;
    text-align: left;
  }
}
body.shopcheckoutoverviewpage .totalsRow table td:first-child {
  font-size: 16px;
}
@media (max-width: 374.98px) {
  body.shopcheckoutoverviewpage .totalsRow table td:first-child {
    color: #232320;
  }
}
body.shopcheckoutoverviewpage .totalsRow table td:last-child {
  padding-left: 67px;
  white-space: nowrap;
}
@media (max-width: 374.98px) {
  body.shopcheckoutoverviewpage .totalsRow table td:last-child {
    padding-left: 0;
    margin-bottom: 16px;
  }
}
body.shopcheckoutoverviewpage .totalsRow table .subtotal td:last-child {
  font-size: 24px;
}
body.shopcheckoutoverviewpage .totalsRow table .shipping td:first-child {
  position: relative;
}
body.shopcheckoutoverviewpage .totalsRow table .shipping i.info {
  position: absolute;
  right: -24px;
  top: 4px;
}
@media (max-width: 374.98px) {
  body.shopcheckoutoverviewpage .totalsRow table .shipping i.info {
    right: -16px;
  }
}
body.shopcheckoutoverviewpage .totalsRow table .total td {
  padding-top: 37px;
  color: #232320;
}
@media (max-width: 374.98px) {
  body.shopcheckoutoverviewpage .totalsRow table .total td {
    padding-top: 0;
  }
}
body.shopcheckoutoverviewpage .totalsRow table .total td:first-child {
  font-weight: 700;
}
body.shopcheckoutoverviewpage .totalsRow table .total td:last-child {
  color: #12C700;
  font-size: 24px;
}
body.shopcheckoutoverviewpage .addressRow {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body.shopcheckoutoverviewpage .addressRow {
    margin-bottom: 64px;
  }
}
body.shopcheckoutoverviewpage .addressRow span {
  display: block;
  font-size: 18px;
  line-height: 32px;
}
body.shopcheckoutoverviewpage .addressRow .billingColumn {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body.shopcheckoutoverviewpage .addressRow .billingColumn {
    margin-bottom: 0;
  }
}
body.shopcheckoutoverviewpage .stepsRow {
  padding-top: 32px;
  border-top: 1px solid #E1E1D2;
  align-items: center;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body.shopcheckoutoverviewpage .stepsRow {
    margin-bottom: 64px;
  }
}
body.shopcheckoutoverviewpage .stepsRow form {
  margin: 0;
}
body.shopcheckoutoverviewpage a.back {
  text-decoration: none;
  color: #83847B;
  display: inline-flex;
  align-items: center;
  min-height: 1px;
  padding: 5px 0;
  position: relative;
  text-decoration: none;
}
body.shopcheckoutoverviewpage a.back:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #83847B;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopcheckoutoverviewpage a.back:hover:after, body.shopcheckoutoverviewpage a.back:focus:after {
  transform: none;
}
body.shopcheckoutoverviewpage a.back svg.sprite {
  margin-right: 10px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopcheckoutoverviewpage a.back:hover svg.sprite, body.shopcheckoutoverviewpage a.back:focus svg.sprite {
  transform: translateX(-5px);
}
body.shopcheckoutoverviewpage .stepForwardColumn .button {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-bottom: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
}
@media (min-width: 768px) {
  body.shopcheckoutoverviewpage .stepForwardColumn .button {
    margin-bottom: 0;
  }
}
body.shopcheckoutoverviewpage .disclaimerRow span {
  display: inline-block;
  padding-left: 26px;
  font-size: 16px;
  line-height: 18px;
  color: #707070;
  margin-bottom: 62px;
}
@media (min-width: 768px) {
  body.shopcheckoutoverviewpage .disclaimerRow span {
    margin-bottom: 94px;
  }
}
body.shopcheckoutoverviewpage .disclaimerRow span a {
  text-decoration: none;
  transition: background 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  color: #651FFF;
}
body.shopcheckoutoverviewpage .disclaimerRow span a:hover, body.shopcheckoutoverviewpage .disclaimerRow span a:focus {
  background: rgba(101, 31, 255, 0.2);
}
body.shopcheckoutoverviewpage .disclaimerRow i.info {
  position: absolute;
  top: 1px;
  left: 16px;
}
body.shopcheckoutoverviewpage .disclaimerRow i.info span {
  margin: 0;
  padding: 0;
  color: #FFFFFF;
  font-size: 14px;
}
body.shopcheckoutoverviewpage .disclaimerRow p {
  margin: 0;
  display: inline;
}

body.paymentsuccesspage section#content h1,
body.paymentcancelledpage section#content h1 {
  font-size: 38px;
  line-height: 40px;
  font-weight: 800;
  color: #651FFF;
  margin-bottom: 10px;
  margin-top: 25px;
  text-align: center;
  font-family: "Public Sans", sans-serif;
}
@media (min-width: 768px) {
  body.paymentsuccesspage section#content h1,
  body.paymentcancelledpage section#content h1 {
    font-size: 56px;
    line-height: 48px;
  }
}
body.paymentsuccesspage section#content img.circle,
body.paymentcancelledpage section#content img.circle {
  border-radius: 200px;
  width: 175px;
  height: 175px;
  display: block;
  margin: 32px auto 32px;
  animation-direction: normal;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: popupGif;
  animation-timing-function: cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.paymentsuccesspage section#content div.subtitle,
body.paymentcancelledpage section#content div.subtitle {
  line-height: 1.4em;
  font-size: 18px;
  font-style: normal;
  display: block;
  text-align: center;
  margin-bottom: 32px;
  color: #83847B;
}
body.paymentsuccesspage section#content div.subtitle a,
body.paymentcancelledpage section#content div.subtitle a {
  color: #83847B;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  position: relative;
  text-decoration: none;
}
body.paymentsuccesspage section#content div.subtitle a:after,
body.paymentcancelledpage section#content div.subtitle a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #83847B;
  height: 1px;
  width: 100%;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.paymentsuccesspage section#content div.subtitle a:hover:after, body.paymentsuccesspage section#content div.subtitle a:focus:after,
body.paymentcancelledpage section#content div.subtitle a:hover:after,
body.paymentcancelledpage section#content div.subtitle a:focus:after {
  transform: scaleX(0);
}
body.paymentsuccesspage section#content div.subtitle a:hover, body.paymentsuccesspage section#content div.subtitle a:focus,
body.paymentcancelledpage section#content div.subtitle a:hover,
body.paymentcancelledpage section#content div.subtitle a:focus {
  color: #651FFF;
}

body.shopstriberoverviewpage .productsContainer {
  margin-top: 50px;
}
body.shopstriberoverviewpage .productsContainer ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: -24px;
  margin-left: -10px;
  margin-right: -10px;
}
@media (min-width: 1300px) {
  body.shopstriberoverviewpage .productsContainer ul {
    margin-left: -20px;
    margin-right: -20px;
  }
}
body.shopstriberoverviewpage .productsContainer ul li {
  flex: 0 0 auto;
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 24px;
}
@media (min-width: 375px) {
  body.shopstriberoverviewpage .productsContainer ul li {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 1024px) {
  body.shopstriberoverviewpage .productsContainer ul li {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media (min-width: 1300px) {
  body.shopstriberoverviewpage .productsContainer ul li {
    padding-left: 20px;
    padding-right: 20px;
  }
}
body.shopstriberoverviewpage .productsContainer article .image {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}
body.shopstriberoverviewpage .productsContainer article .image img {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 100%;
}
body.shopstriberoverviewpage section#content article .image img {
  width: 100%;
  height: 100%;
}
body.shopstriberoverviewpage section#content article .contents > a.title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body.shopstriberoverviewpage section#content article .contents > a.title:hover, body.shopstriberoverviewpage section#content article .contents > a.title:focus {
  color: #AA00FF;
}

@media (min-width: 768px) {
  body[class*=mystribe] aside {
    position: sticky;
    top: 160px;
    bottom: 30px;
    margin-top: 135px;
    transition: top 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  }
}
body[class*=mystribe] header.hidden + section#content aside {
  top: 30px;
}
body[class*=mystribe] form .imageUpload .input {
  flex: 0 0 auto;
}
body[class*=mystribe] form .imageUpload .input input[type=file],
body[class*=mystribe] form .imageUpload .input input[type=checkbox] {
  display: none;
}
body[class*=mystribe] form .imageUpload .input input[type=checkbox]:checked ~ label {
  display: none;
}
body[class*=mystribe] form .imageUpload .input label {
  padding: 13px 40px;
  border: 1px solid #83847B;
  border-radius: 50px;
  color: #83847B;
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
  display: inline-block;
}
body[class*=mystribe] form .imageUpload .input label.auto {
  width: auto;
}
body[class*=mystribe] form .imageUpload .input label:hover, body[class*=mystribe] form .imageUpload .input label:focus {
  background: #f8f8f4;
}
body[class*=mystribe] form .imageUpload .input label.remove {
  border: none;
}
body[class*=mystribe] form .imageUpload .input label.remove:hover, body[class*=mystribe] form .imageUpload .input label.remove:focus {
  background: none;
}
body[class*=mystribe] form .imageUpload .imageUploadContainer {
  display: flex;
  min-height: 175px;
  align-items: center;
}
body[class*=mystribe] form .imageUpload .imageUploadContainer.dragging {
  outline: 3px dashed #83847B;
}
body[class*=mystribe] form .imageUpload .imageUploadContainer .imagePreview {
  position: relative;
  width: 210px;
  height: 175px;
  margin-right: 24px;
  cursor: pointer;
  flex: 0 0 auto;
}
body[class*=mystribe] form .imageUpload .imageUploadContainer .imagePreview svg.svg {
  position: absolute;
  height: 0;
  width: 0;
}
body[class*=mystribe] form .imageUpload .imageUploadContainer .imagePreview .mask {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  clip-path: url(#striber-mask);
  height: 100%;
  background: #F8F8F4;
}
body[class*=mystribe] form .imageUpload .imageUploadContainer .imagePreview .mask .image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
body[class*=mystribe] form .imageUpload.wide .imageUploadContainer {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
body[class*=mystribe] form .imageUpload.wide .imageUploadContainer .imagePreview {
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  margin-right: 0;
  margin-bottom: 24px;
  margin-top: 40px;
  border-radius: 24px;
  overflow: hidden;
}
body[class*=mystribe] form .imageUpload.wide .imageUploadContainer .imagePreview .mask {
  height: 100%;
  clip-path: none;
  position: absolute;
  width: 100%;
}
body[class*=mystribe] form .imageUpload.wide .imageUploadContainer .imagePreview .mask .image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
body[class*=mystribe] form .imageUpload.wide .input {
  margin-top: 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
body[class*=mystribe] form .imageUpload.centered .input {
  display: flex;
  width: 100%;
}
body[class*=mystribe] form .imageUpload.videosupport .selection {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .options {
  margin-right: 24px;
  padding: 10px 0;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div {
  height: 50%;
  display: flex;
  align-items: center;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input {
  display: none;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label,
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: block;
  line-height: 24px;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label:before, body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label:after,
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label:before,
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border-radius: 20px;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label:before,
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label:before {
  opacity: 0;
  background-image: url("/img/icon_check_green.svg");
  background-position: center 30%;
  background-repeat: no-repeat;
  transform: scale(0.4);
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: opacity, transform;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label:after,
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label:after {
  border: 1px solid #83847B;
  transition: border-color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label:hover:after, body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label:focus:after,
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label:hover:after,
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input + label:focus:after {
  border-color: #232320;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input:checked + label {
  color: #12C700;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input:checked + label:before {
  opacity: 1;
  transform: none;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div input:checked + label:after {
  border-color: #12C700;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .options > div:last-child {
  transform: translateY(24px);
}
body[class*=mystribe] form .imageUpload.videosupport .selection .extra {
  display: flex;
  flex-direction: column;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .extra > div {
  height: 50%;
  display: flex;
  align-items: center;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .extra input {
  display: inline-block;
  border: none;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .extra label {
  display: inline-block;
  border: none;
  padding: 0;
  white-space: nowrap;
  margin-right: 12px;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .extra label:hover, body[class*=mystribe] form .imageUpload.videosupport .selection .extra label:focus {
  background: transparent;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .extra .input {
  display: flex;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .extra > div:first-child {
  transform: translateY(5px);
}
body[class*=mystribe] form .imageUpload.videosupport .selection .extra > div:first-child input {
  display: none;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .extra > div:first-child label {
  display: block;
  padding: 12px 30px;
  border: 1px solid #83847B;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .extra > div:first-child label:hover, body[class*=mystribe] form .imageUpload.videosupport .selection .extra > div:first-child label:focus {
  background: #f8f8f4;
}
body[class*=mystribe] form .imageUpload.videosupport .selection .extra > div:last-child {
  transform: translateY(22px);
}
body[class*=mystribe] form .imageUpload.videosupport .selection .extra > div:last-child input {
  border-bottom: 1px solid #E1E1D2;
}
body[class*=mystribe] form .imageUpload.circle .imagePreview {
  width: 200px;
  height: 200px;
  max-height: 200px;
}
body[class*=mystribe] .editorsContainer .header nav .tabHeaders {
  margin-bottom: -1px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
body[class*=mystribe] .editorsContainer .header nav .tabHeaders li:not(:last-child) {
  margin-right: -1px;
}
body[class*=mystribe] .editorsContainer .header nav .tabHeaders li button {
  padding: 10px 25px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #E1E1D2;
  background: #FFFFFF;
  font-size: 14px;
  line-height: 16px;
  color: #83847B;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body[class*=mystribe] .editorsContainer .header nav .tabHeaders li button[aria-selected=true] {
  background: #F8F8F4;
  color: #232320;
  border-bottom-color: #F8F8F4;
}
body[class*=mystribe] .editorsContainer .header nav .tabHeaders li button[aria-selected=false]:hover, body[class*=mystribe] .editorsContainer .header nav .tabHeaders li button[aria-selected=false]:focus {
  background: rgba(248, 248, 244, 0.5);
}
body[class*=mystribe] .editorsContainer .tabContents {
  background: #F8F8F4;
  padding: 8px;
  border: 1px solid #E1E1D2;
}
body[class*=mystribe] .editorsContainer .tabContents .tabContent {
  animation: none;
}
body[class*=mystribe] .editorsContainer .tabContents .tabContent > div {
  animation: none;
}
body[class*=mystribe] .editorsContainer .tabContents .tabContent .ql-toolbar,
body[class*=mystribe] .editorsContainer .tabContents .tabContent .ql-container {
  background: #FFFFFF;
}
body[class*=mystribe] .editorsContainer .tabContents .tabContent .ql-toolbar {
  position: relative;
  z-index: 1;
}
body[class*=mystribe] .editorsContainer .tabContents .tabContent .ql-editor {
  min-height: 230px;
}
body[class*=mystribe] .editorsContainer .tabContents .tabContent input, body[class*=mystribe] .editorsContainer .tabContents .tabContent textarea {
  background: #FFFFFF;
}
body[class*=mystribe] section#form form {
  margin-top: 0;
}
body[class*=mystribe] form .simpleRow.input {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  body[class*=mystribe] form .simpleRow.input {
    align-items: center;
    flex-direction: row;
  }
}
body[class*=mystribe] form .simpleRow.input label {
  width: 100%;
  color: #707070;
  padding-left: 10px;
}
@media (min-width: 768px) {
  body[class*=mystribe] form .simpleRow.input label {
    padding-left: 0;
    width: 22%;
    margin-right: 34px;
  }
}
body[class*=mystribe] form .simpleRow.input input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #E1E1D2;
  padding-left: 10px;
  font-size: 18px;
  background: transparent;
  color: #232320;
}
@media (min-width: 768px) {
  body[class*=mystribe] form .simpleRow.input input {
    width: 60%;
  }
}
body[class*=mystribe] form .simpleRow.input .field-validation-error {
  display: inline-block;
  color: #E72264;
  padding-left: 10px;
}
@media (min-width: 768px) {
  body[class*=mystribe] form .simpleRow.input .field-validation-error {
    margin-left: 22%;
    padding-left: 34px;
  }
}
body[class*=mystribe][class*=mypage] section#content span.subtitle, body[class*=mystribe][class*=myjournal] section#content span.subtitle {
  font-size: 16px;
  line-height: 18px;
  display: inline-block;
  margin-bottom: 16px;
  font-style: normal;
}
body[class*=mystribe][class*=myprofile] p.intro, body[class*=mystribe][class*=newsletters] p.intro {
  font-size: 16px;
  line-height: 18px;
  color: #83847B;
}
body[class*=mystribe][class*=myprofile] section#content h2, body[class*=mystribe][class*=newsletters] section#content h2 {
  font-size: 32px;
  line-height: 32px;
}
body[class*=mystribe][class*=myprofile] form, body[class*=mystribe][class*=newsletters] form {
  transform-origin: left;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body[class*=mystribe][class*=myprofile] form.submitting, body[class*=mystribe][class*=newsletters] form.submitting {
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
body[class*=mystribe][class*=myprofile] form .imageUploadContainer, body[class*=mystribe][class*=newsletters] form .imageUploadContainer {
  min-height: 0;
}
body[class*=mystribe][class*=myprofile] form .imageUploadContainer .imagePreview, body[class*=mystribe][class*=newsletters] form .imageUploadContainer .imagePreview {
  width: 67px;
  height: 67px;
}
body[class*=mystribe][class*=myprofile] form .imageUploadContainer .imagePreview .mask, body[class*=mystribe][class*=newsletters] form .imageUploadContainer .imagePreview .mask {
  border-radius: 50%;
  width: 67px;
  height: 67px;
  min-height: 0;
}
body[class*=mystribe][class*=myprofile] form .imageUploadContainer .imagePreview .mask .image, body[class*=mystribe][class*=newsletters] form .imageUploadContainer .imagePreview .mask .image {
  width: 67px;
  height: 67px;
  border-radius: 50%;
}
body[class*=mystribe][class*=myprofile] form .imageUploadContainer .input label, body[class*=mystribe][class*=newsletters] form .imageUploadContainer .input label {
  padding-left: 40px;
}
body[class*=mystribe][class*=myprofile] form [type=submit], body[class*=mystribe][class*=newsletters] form [type=submit] {
  font-weight: bold;
  color: #FFFFFF;
  background: #12C700;
}
body[class*=mystribe][class*=myprofile] form [type=submit]:hover, body[class*=mystribe][class*=myprofile] form [type=submit]:focus, body[class*=mystribe][class*=newsletters] form [type=submit]:hover, body[class*=mystribe][class*=newsletters] form [type=submit]:focus {
  background: #E1E1D2;
  color: #12C700;
}
body[class*=mystribe][class*=myprofile] form [type=submit][disabled], body[class*=mystribe][class*=newsletters] form [type=submit][disabled] {
  background: #E1E1D2;
}
body[class*=mystribe][class*=myprofile] form [type=submit][disabled]:hover, body[class*=mystribe][class*=myprofile] form [type=submit][disabled]:focus, body[class*=mystribe][class*=newsletters] form [type=submit][disabled]:hover, body[class*=mystribe][class*=newsletters] form [type=submit][disabled]:focus {
  color: #FFFFFF;
}
body[class*=mystribe][class*=myprofile] form [type=submit].button-google, body[class*=mystribe][class*=newsletters] form [type=submit].button-google {
  border-radius: 4px;
  background-color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 3px #E1E1D2;
  width: 240px;
  transition: box-shadow 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body[class*=mystribe][class*=myprofile] form [type=submit].button-google:hover, body[class*=mystribe][class*=myprofile] form [type=submit].button-google:focus, body[class*=mystribe][class*=newsletters] form [type=submit].button-google:hover, body[class*=mystribe][class*=newsletters] form [type=submit].button-google:focus {
  box-shadow: 1px 1px 4px #83847B;
}
body[class*=mystribe][class*=myprofile] form [type=submit].button-google svg, body[class*=mystribe][class*=newsletters] form [type=submit].button-google svg {
  width: 46px;
  height: 46px;
}
body[class*=mystribe][class*=myprofile] form [type=submit].button-google span, body[class*=mystribe][class*=newsletters] form [type=submit].button-google span {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
body[class*=mystribe][class*=myjournals] h1 + a.button {
  position: relative;
  margin-bottom: 20px;
  right: 0;
  padding: 10px 25px;
}
@media (min-width: 768px) {
  body[class*=mystribe][class*=myjournals] h1 + a.button {
    position: absolute;
    top: 40px;
    margin-bottom: 0;
    padding: 12px 30px;
  }
}
body[class*=mystribe][class*=myjournals] h1 + a.button span {
  transform: translateX(-5px);
}
body[class*=mystribe][class*=myjournals] .sortOptions {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  body[class*=mystribe][class*=myjournals] .sortOptions {
    justify-content: flex-end;
    text-align: right;
  }
}
body[class*=mystribe][class*=myjournals] .sortOptions * {
  white-space: nowrap;
}
body[class*=mystribe][class*=myjournals] .sortOptions > span {
  margin-right: 8px;
}
body[class*=mystribe][class*=myjournals] .sortOptions .sortOption {
  margin-right: 12px;
}
body[class*=mystribe][class*=myjournals] .sortOptions .sortOption:last-child {
  margin-right: 0;
}
body[class*=mystribe][class*=myjournals] .sortOptions .sortOption input {
  display: none;
}
body[class*=mystribe][class*=myjournals] .sortOptions .sortOption label {
  color: #83847B;
  border-bottom: 1px solid transparent;
  padding-bottom: 5px;
  cursor: pointer;
  line-height: 30px;
  transition: border-color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body[class*=mystribe][class*=myjournals] .sortOptions .sortOption input:checked + label {
  border-bottom-color: #83847B;
  cursor: default;
}
body[class*=mystribe][class*=myjournals] ul.pagedContainer {
  border-top: 1px solid #E1E1D2;
  margin-bottom: 56px;
}
body[class*=mystribe][class*=myjournals] ul.pagedContainer li {
  border-bottom: 1px solid #E1E1D2;
}
body[class*=mystribe][class*=myjournals] ul.pagedContainer li .journal {
  display: flex;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
}
body[class*=mystribe][class*=myjournals] ul.pagedContainer li .journal .image {
  width: 67px;
  max-width: 67px;
  height: 67px;
  margin-bottom: 0;
  margin-right: 15px;
}
body[class*=mystribe][class*=myjournals] ul.pagedContainer li .journal .image a {
  display: inline-block;
  width: 100%;
}
body[class*=mystribe][class*=myjournals] ul.pagedContainer li .journal .image img {
  width: 100%;
  height: 100%;
}
body[class*=mystribe][class*=myjournals] ul.pagedContainer li .journal .image .icon {
  display: none;
}
body[class*=mystribe][class*=myjournals] ul.pagedContainer li .journal .contents .title {
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}
body[class*=mystribe][class*=myjournals] ul.pagedContainer li .journal .contents .info {
  margin-bottom: 0;
}
body[class*=mystribe][class*=myjournals] ul.pagedContainer li .journal .contents address {
  margin-right: 8px;
}
body[class*=mystribe][class*=myjournals] ul.pagedContainer li .journal .contents .journalLikes {
  margin-right: 0;
}
body[class*=mystribe][class*=myjournals] ul.pagedContainer + .pagerContainer {
  margin-bottom: 56px;
}
body[class*=mystribe][class*=myorders] ol.orderList > li {
  padding: 30px 0;
  border-bottom: 1px solid #E1E1D2;
}
body[class*=mystribe][class*=myorders] ol.orderList > li .orderDate {
  font-size: 18px;
  color: #12C700;
  margin-bottom: 5px;
}
body[class*=mystribe][class*=myorders] ol.orderList > li .orderStatus {
  font-size: 14px;
  color: #707070;
}
body[class*=mystribe][class*=myorders] ol.orderList > li .orderProducts ul li {
  margin-bottom: 10px;
  font-size: 14px;
  color: #707070;
  line-height: 18px;
}
body[class*=mystribe][class*=myorders] ol.orderList > li .lastCol {
  text-align: right;
}
body[class*=mystribe][class*=myorders] ol.orderList > li .lastCol .orderTotal {
  font-size: 18px;
  margin-bottom: 5px;
}
body[class*=mystribe][class*=myorders] ol.orderList > li .lastCol .orderVatTotal {
  font-size: 14px;
  color: #707070;
}
body[class*=mystribe][class*=myorders] ol.orderList > li .lastCol a.orderDetailLink {
  display: block;
  margin-top: 20px;
  font-size: 14px;
}
body[class*=mystribe][class*=myorderdetail] a.back {
  text-decoration: none;
  color: #83847B;
  display: inline-flex;
  align-items: center;
  min-height: 1px;
  padding: 5px 0;
  margin-bottom: 15px;
  position: relative;
  text-decoration: none;
}
body[class*=mystribe][class*=myorderdetail] a.back:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #83847B;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body[class*=mystribe][class*=myorderdetail] a.back:hover:after, body[class*=mystribe][class*=myorderdetail] a.back:focus:after {
  transform: none;
}
body[class*=mystribe][class*=myorderdetail] a.back svg.sprite {
  margin-right: 10px;
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body[class*=mystribe][class*=myorderdetail] a.back:hover svg.sprite, body[class*=mystribe][class*=myorderdetail] a.back:focus svg.sprite {
  transform: translateX(-5px);
}
body[class*=mystribe][class*=myorderdetail] ul.basketProducts li {
  padding: 24px 0;
  border-bottom: 1px solid #E1E1D2;
}
body[class*=mystribe][class*=myorderdetail] ul.basketProducts li:first-of-type {
  border-top: 1px solid #E1E1D2;
}
body[class*=mystribe][class*=myorderdetail] ul.basketProducts li .row {
  align-items: center;
}
body[class*=mystribe][class*=myorderdetail] .imageColumn .image {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  border-radius: 50%;
  overflow: hidden;
}
body[class*=mystribe][class*=myorderdetail] .imageColumn .image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
body[class*=mystribe][class*=myorderdetail] .infoColumn a.title {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 7px;
  color: #232320;
  text-decoration: none;
  transition: color 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body[class*=mystribe][class*=myorderdetail] .infoColumn a.title:hover, body[class*=mystribe][class*=myorderdetail] .infoColumn a.title:focus {
  color: #651FFF;
}
body[class*=mystribe][class*=myorderdetail] .infoColumn .variant {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 26px;
}
body[class*=mystribe][class*=myorderdetail] .infoColumn .variant span {
  padding-left: 16px;
}
body[class*=mystribe][class*=myorderdetail] .infoColumn .variant span:first-child {
  padding-left: 0;
}
body[class*=mystribe][class*=myorderdetail] .infoColumn .price {
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #83847B;
}
@media (min-width: 768px) {
  body[class*=mystribe][class*=myorderdetail] .infoColumn .price {
    display: inline-block;
  }
}
body[class*=mystribe][class*=myorderdetail] .infoColumn .price .original {
  padding-left: 16px;
  color: #E72264;
  text-decoration: line-through;
}
body[class*=mystribe][class*=myorderdetail] .infoColumn .seller {
  font-size: 16px;
  line-height: 18px;
  color: #83847B;
}
@media (min-width: 768px) {
  body[class*=mystribe][class*=myorderdetail] .infoColumn .seller {
    margin-left: 17px;
  }
}
body[class*=mystribe][class*=myorderdetail] .infoColumn .seller:before {
  display: none;
  margin-right: 17px;
  content: "";
  top: -3px;
  height: 1px;
  width: 48px;
  background: #83847B;
  position: relative;
}
@media (min-width: 768px) {
  body[class*=mystribe][class*=myorderdetail] .infoColumn .seller:before {
    display: inline-block;
  }
}
body[class*=mystribe][class*=myorderdetail] .quantityColumn input {
  text-align: center;
  border: none;
  background: transparent;
  border-radius: 0;
  border-bottom: 1px solid #E1E1D2;
}
body[class*=mystribe][class*=myorderdetail] .quantityColumn input:hover, body[class*=mystribe][class*=myorderdetail] .quantityColumn input:focus {
  border-bottom-color: #232320;
}
body[class*=mystribe][class*=myorderdetail] .priceColumn {
  margin-top: 32px;
}
@media (min-width: 768px) {
  body[class*=mystribe][class*=myorderdetail] .priceColumn {
    margin-top: 0;
  }
}
body[class*=mystribe][class*=myorderdetail] .priceColumn .totalPrice {
  text-align: right;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 17px;
}
body[class*=mystribe][class*=myorderdetail] .priceColumn .VAT {
  text-align: right;
  font-size: 16px;
  line-height: 24px;
  color: #83847B;
}
body[class*=mystribe][class*=myorderdetail] .totalsRow {
  margin-bottom: 20px;
  margin-top: 20px;
}
@media (min-width: 768px) {
  body[class*=mystribe][class*=myorderdetail] .totalsRow {
    margin-bottom: 96px;
  }
}
body[class*=mystribe][class*=myorderdetail] .totalsRow table {
  margin-left: auto;
}
@media (max-width: 374.98px) {
  body[class*=mystribe][class*=myorderdetail] .totalsRow table {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 374.98px) {
  body[class*=mystribe][class*=myorderdetail] .totalsRow table tr {
    display: flex;
    flex-direction: column;
  }
}
body[class*=mystribe][class*=myorderdetail] .totalsRow table td {
  font-size: 18px;
  line-height: 24px;
  color: #83847B;
  text-align: right;
}
@media (max-width: 374.98px) {
  body[class*=mystribe][class*=myorderdetail] .totalsRow table td {
    display: block;
    text-align: left;
  }
}
body[class*=mystribe][class*=myorderdetail] .totalsRow table td:first-child {
  font-size: 16px;
}
@media (max-width: 374.98px) {
  body[class*=mystribe][class*=myorderdetail] .totalsRow table td:first-child {
    color: #232320;
  }
}
body[class*=mystribe][class*=myorderdetail] .totalsRow table td:last-child {
  padding-left: 67px;
  white-space: nowrap;
}
@media (max-width: 374.98px) {
  body[class*=mystribe][class*=myorderdetail] .totalsRow table td:last-child {
    padding-left: 0;
    margin-bottom: 16px;
  }
}
body[class*=mystribe][class*=myorderdetail] .totalsRow table .subtotal td:last-child {
  font-size: 24px;
}
body[class*=mystribe][class*=myorderdetail] .totalsRow table .shipping td:first-child {
  position: relative;
}
body[class*=mystribe][class*=myorderdetail] .totalsRow table .shipping i.info {
  position: absolute;
  right: -24px;
  top: 4px;
}
@media (max-width: 374.98px) {
  body[class*=mystribe][class*=myorderdetail] .totalsRow table .shipping i.info {
    right: -16px;
  }
}
body[class*=mystribe][class*=myorderdetail] .totalsRow table .total td {
  padding-top: 37px;
  color: #232320;
}
@media (max-width: 374.98px) {
  body[class*=mystribe][class*=myorderdetail] .totalsRow table .total td {
    padding-top: 0;
  }
}
body[class*=mystribe][class*=myorderdetail] .totalsRow table .total td:first-child {
  font-weight: 700;
}
body[class*=mystribe][class*=myorderdetail] .totalsRow table .total td:last-child {
  color: #12C700;
  font-size: 24px;
}
body[class*=mystribe][class*=myorderdetail] .addressRow {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body[class*=mystribe][class*=myorderdetail] .addressRow {
    margin-bottom: 64px;
  }
}
body[class*=mystribe][class*=myorderdetail] .addressRow span {
  display: block;
  font-size: 18px;
  line-height: 32px;
}
body[class*=mystribe][class*=myorderdetail] .addressRow .billingColumn {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  body[class*=mystribe][class*=myorderdetail] .addressRow .billingColumn {
    margin-bottom: 0;
  }
}
body[class*=mystribe] section#content h1 {
  margin-top: 20px;
  margin-bottom: 14px;
}
@media (min-width: 768px) {
  body[class*=mystribe] section#content h1 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
body[class*=mystribe] section#content h2 {
  margin-top: 20px;
}
@media (min-width: 768px) {
  body[class*=mystribe] section#content h2 {
    margin-top: 40px;
  }
}
body[class*=mystribe] section#content p.intro {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  body[class*=mystribe] section#content p.intro {
    margin-bottom: 64px;
  }
}
body[class*=mystribe] section#content h2 {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 11px;
}
body[class*=mystribe] ul.accordeon,
body[class*=mystribe] ol.accordeon {
  border-top: none;
}
body[class*=mystribe] ul.accordeon li,
body[class*=mystribe] ol.accordeon li {
  border-bottom-width: 1px;
}
body[class*=mystribe] ul.accordeon li:last-child,
body[class*=mystribe] ol.accordeon li:last-child {
  border-bottom: none;
}
body[class*=mystribe] ul.accordeon li .header,
body[class*=mystribe] ol.accordeon li .header {
  padding-top: 10px;
  padding-bottom: 10px;
}
body[class*=mystribe] ul.accordeon li .header .headerContent h3,
body[class*=mystribe] ol.accordeon li .header .headerContent h3 {
  display: inline-block;
}
body[class*=mystribe] ul.accordeon li .header .headerContent h3:after,
body[class*=mystribe] ol.accordeon li .header .headerContent h3:after {
  content: ">";
  content: "▼";
  display: inline-block;
  position: relative;
  color: #707070;
  transform: scale(0.5) translateY(2px);
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: transform, opacity;
}
body[class*=mystribe] ul.accordeon li .content,
body[class*=mystribe] ol.accordeon li .content {
  overflow-x: hidden;
}
body[class*=mystribe] ul.accordeon li .content form,
body[class*=mystribe] ol.accordeon li .content form {
  margin-top: 10px;
}
body[class*=mystribe] ul.accordeon li.active .header .headerContent h3,
body[class*=mystribe] ol.accordeon li.active .header .headerContent h3 {
  color: #232320;
}
body[class*=mystribe] ul.accordeon li.active .header .headerContent h3:after,
body[class*=mystribe] ol.accordeon li.active .header .headerContent h3:after {
  transform: scale(0.5) translateY(2px) scaleY(0);
  opacity: 0;
}
body[class*=mystribe] fieldset.login button.button,
body[class*=mystribe] fieldset.reset button.button,
body[class*=mystribe] fieldset.signup button.button {
  background: #E1E1D2;
  color: #FFFFFF;
  font-weight: bold;
  width: 240px;
  padding-top: 15px;
  padding-bottom: 15px;
  max-width: 100%;
  text-align: center;
  display: inline-block;
}
body[class*=mystribe] fieldset.login button.button:hover, body[class*=mystribe] fieldset.login button.button:focus,
body[class*=mystribe] fieldset.reset button.button:hover,
body[class*=mystribe] fieldset.reset button.button:focus,
body[class*=mystribe] fieldset.signup button.button:hover,
body[class*=mystribe] fieldset.signup button.button:focus {
  color: #232320;
}
body[class*=mystribe] fieldset.login button.button.green,
body[class*=mystribe] fieldset.reset button.button.green,
body[class*=mystribe] fieldset.signup button.button.green {
  background: #12C700;
}
body[class*=mystribe] fieldset.login button.button.green:hover, body[class*=mystribe] fieldset.login button.button.green:focus,
body[class*=mystribe] fieldset.reset button.button.green:hover,
body[class*=mystribe] fieldset.reset button.button.green:focus,
body[class*=mystribe] fieldset.signup button.button.green:hover,
body[class*=mystribe] fieldset.signup button.button.green:focus {
  background: #E1E1D2;
  color: #12C700;
}
body[class*=mystribe] fieldset.login .simpleRow input[type=checkbox] ~ label,
body[class*=mystribe] fieldset.reset .simpleRow input[type=checkbox] ~ label,
body[class*=mystribe] fieldset.signup .simpleRow input[type=checkbox] ~ label {
  color: #707070;
  font-size: 16px;
}
body[class*=mystribe] fieldset.login .simpleRow input[type=checkbox] ~ label:before,
body[class*=mystribe] fieldset.reset .simpleRow input[type=checkbox] ~ label:before,
body[class*=mystribe] fieldset.signup .simpleRow input[type=checkbox] ~ label:before {
  filter: grayscale(1);
}
body[class*=mystribe] fieldset.login .simpleRow input[type=checkbox]:checked ~ label:after,
body[class*=mystribe] fieldset.reset .simpleRow input[type=checkbox]:checked ~ label:after,
body[class*=mystribe] fieldset.signup .simpleRow input[type=checkbox]:checked ~ label:after {
  border-color: #83847B !important;
}
body[class*=mystribe] fieldset.reset button.button {
  margin-top: 15px;
}
body[class*=mystribe] .actionsRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 48px;
  padding-top: 24px;
  border-top: 1px solid #E1E1D2;
  margin-top: -10px;
}
@media (min-width: 768px) {
  body[class*=mystribe] .actionsRow {
    flex-wrap: nowrap;
  }
}
body[class*=mystribe] .actionsRow button,
body[class*=mystribe] .actionsRow a {
  margin-top: 10px;
}
body[class*=mystribe] .actionsRow button.saveButton,
body[class*=mystribe] .actionsRow a.saveButton {
  margin-right: 15px;
  font-weight: bold;
}
body[class*=mystribe] .actionsRow button.previewButton,
body[class*=mystribe] .actionsRow a.previewButton {
  margin-right: 15px;
  color: #232320;
  padding-left: 56px;
  padding-right: 56px;
  color: #83847B;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: color, border-color;
}
body[class*=mystribe] .actionsRow button.previewButton:hover, body[class*=mystribe] .actionsRow button.previewButton:focus,
body[class*=mystribe] .actionsRow a.previewButton:hover,
body[class*=mystribe] .actionsRow a.previewButton:focus {
  color: #232320;
  border-color: #707070;
}
body[class*=mystribe] .actionsRow button.cancelButton,
body[class*=mystribe] .actionsRow a.cancelButton {
  border: none;
  background: #FFFFFF;
  padding-left: 0;
  padding-right: 0;
  margin-right: 15px;
  color: #83847B;
  position: relative;
  text-decoration: none;
}
body[class*=mystribe] .actionsRow button.cancelButton:after,
body[class*=mystribe] .actionsRow a.cancelButton:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #83847B;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
body[class*=mystribe] .actionsRow button.cancelButton:hover:after, body[class*=mystribe] .actionsRow button.cancelButton:focus:after,
body[class*=mystribe] .actionsRow a.cancelButton:hover:after,
body[class*=mystribe] .actionsRow a.cancelButton:focus:after {
  transform: none;
}
body[class*=mystribe] .actionsRow button.publishButton,
body[class*=mystribe] .actionsRow a.publishButton {
  font-weight: bold;
  margin-left: 0;
}
@media (min-width: 768px) {
  body[class*=mystribe] .actionsRow button.publishButton,
  body[class*=mystribe] .actionsRow a.publishButton {
    margin-right: 0;
    margin-left: auto;
  }
}

.container.login .separator {
  margin-top: 90px;
  margin-bottom: 16px;
  width: 100%;
  display: inline-block;
  pointer-events: none;
  height: 1px;
  background: #E1E1D2;
}
.container.login button.button {
  margin-bottom: 32px;
}
.container.login .forgot {
  display: inline-block;
  color: #651FFF;
  font-size: 14px;
  position: relative;
  text-decoration: none;
}
.container.login .forgot:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #651FFF;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.container.login .forgot:hover:after, .container.login .forgot:focus:after {
  transform: none;
}

.loginFooter {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  .loginFooter {
    margin-bottom: 90px;
  }
}
.loginFooter a {
  color: #651FFF;
  position: relative;
  text-decoration: none;
}
.loginFooter a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #651FFF;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.loginFooter a:hover:after, .loginFooter a:focus:after {
  transform: none;
}
.loginFooter .col,
.loginFooter [class*=col-] {
  margin-bottom: 32px;
}
.loginFooter .col:last-child,
.loginFooter [class*=col-]:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .loginFooter .col,
  .loginFooter [class*=col-] {
    margin-bottom: 0;
  }
}

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

.toFade {
  opacity: 0;
}
.toFade.fadingIn {
  opacity: 1;
  transform: none;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
  transition-property: opacity, transform;
}

body {
  font-size: 18px;
  font-family: "Public Sans", sans-serif;
  color: #232320;
}
body.preventAnimations *:not(.blob) {
  animation: none !important;
  transition: none !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Public Sans", sans-serif;
}
h1 + p,
h1 + a,
h2 + p,
h2 + a,
h3 + p,
h3 + a,
h4 + p,
h4 + a,
h5 + p,
h5 + a {
  margin-top: 10px;
}

h1 {
  font-size: 38px;
  font-weight: 700;
  line-height: 38px;
  color: #651FFF;
}
@media (min-width: 1024px) {
  h1 {
    font-size: 54px;
    line-height: 46px;
  }
}

h2 {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
}
@media (min-width: 768px) {
  h2 {
    font-size: 40px;
  }
}
@media (min-width: 1024px) {
  h2 {
    font-size: 48px;
    line-height: 40px;
  }
}

h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}
@media (min-width: 768px) {
  h3 {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (min-width: 1024px) {
  h3 {
    font-size: 40px;
  }
}

h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}
@media (min-width: 768px) {
  h4 {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (min-width: 1024px) {
  h4 {
    font-size: 32px;
  }
}

h5 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
@media (min-width: 768px) {
  h5 {
    line-height: 32px;
  }
}
@media (min-width: 1024px) {
  h5 {
    font-size: 24px;
  }
}

p {
  line-height: 1.4em;
  margin: 25px 0;
}

img {
  max-width: 100%;
}

.positiveIndex {
  position: relative;
  z-index: 1;
}

header,
section,
footer {
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  header,
  section,
  footer {
    padding-left: 75px;
    padding-right: 75px;
  }
}
@media (min-width: 1024px) {
  header,
  section,
  footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (min-width: 1300px) {
  header,
  section,
  footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
header section,
section section,
footer section {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  header section,
  section section,
  footer section {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  header section,
  section section,
  footer section {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1300px) {
  header section,
  section section,
  footer section {
    padding-left: 0;
    padding-right: 0;
  }
}

section#content {
  margin-top: 97px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  section#content {
    margin-top: 160px;
  }
}
section#content .titleColumn {
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  section#content .titleColumn {
    margin-bottom: 45px;
  }
}
@media (min-width: 1024px) {
  section#content .titleColumn {
    margin-bottom: 40px;
  }
}
@media (min-width: 1300px) {
  section#content .titleColumn {
    margin-bottom: 45px;
  }
}
section#content h1 {
  margin-top: 35px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  section#content h1 {
    margin-top: 95px;
    margin-bottom: 15px;
  }
}
section#content h2 {
  margin-top: 48px;
  margin-bottom: 42px;
}
section#content h3 {
  margin-top: 55px;
  margin-bottom: 32px;
}
section#content h4,
section#content h5 {
  margin-top: 48px;
  margin-bottom: 32px;
}
section#content h2:first-child, section#content h3:first-child, section#content h4:first-child, section#content h5:first-child {
  margin-top: 0;
}
section#content .subtitle {
  font-size: 18px;
  line-height: 24px;
  color: #83847B;
  font-style: italic;
  font-weight: 300;
}
@media (min-width: 768px) {
  section#content .subtitle {
    line-height: 32px;
  }
}
@media (min-width: 1024px) {
  section#content .subtitle {
    font-size: 24px;
  }
}
@media (min-width: 1300px) {
  section#content .subtitle {
    line-height: 24px;
  }
}
section#content .umb-grid ol,
section#content .umb-grid ul {
  margin: 20px 0;
}
section#content .umb-grid ol li,
section#content .umb-grid ul li {
  position: relative;
  margin-left: 25px;
  margin-bottom: 10px;
}
section#content .umb-grid ol li:last-child,
section#content .umb-grid ul li:last-child {
  margin-bottom: 0;
}
section#content .umb-grid *::selection {
  background: #E1E1D2;
}
section#content .umb-grid ul {
  padding-left: 20px;
}
section#content .umb-grid ul li:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -34px;
  top: 10px;
  width: 24px;
  height: 2px;
  background: #651FFF;
}
section#content .umb-grid ol {
  padding-left: 20px;
  counter-set: list;
}
section#content .umb-grid ol li {
  counter-increment: list;
}
section#content .umb-grid ol li:before {
  position: absolute;
  right: 110%;
  right: calc(100% + 10px);
  width: 30px;
  text-align: right;
  content: counter(list) ".";
  font-weight: 500;
  color: #651FFF;
}
section#content .umb-grid ol li ol {
  counter-set: listlist;
}
section#content .umb-grid ol li ol li {
  counter-increment: listlist;
}
section#content .umb-grid ol li ol li:before {
  content: counter(listlist) ".";
}
section#content .umb-grid h2 + ol,
section#content .umb-grid h2 + ul,
section#content .umb-grid h3 + ol,
section#content .umb-grid h3 + ul,
section#content .umb-grid h4 + ol,
section#content .umb-grid h4 + ul,
section#content .umb-grid h5 + ol,
section#content .umb-grid h5 + ul {
  margin-top: 10px;
}
section#content .umb-grid a {
  color: #AA00FF;
  text-decoration: none;
  border-bottom: 2px solid #AA00FF;
  padding-left: 2px;
  padding-right: 2px;
  transition: background 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
section#content .umb-grid a:hover, section#content .umb-grid a:focus {
  background: rgba(170, 0, 255, 0.1);
}
section#content .umb-grid img {
  display: block;
  margin-bottom: 20px;
  border-radius: 32px;
  margin-top: 48px;
  margin-bottom: 48px;
}
section#content .umb-grid img[height][width] {
  max-width: 100%;
  height: auto;
}
section#content .umb-grid img:not([width][height]) {
  width: 100%;
}
section#content .umb-grid blockquote {
  margin-top: 45px;
  margin-bottom: 45px;
  width: 100%;
}
section#content .umb-grid p.intro {
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;
}
section#content .umb-grid p.intro:first-child {
  margin-top: 0;
}
@media (min-width: 768px) {
  section#content .umb-grid p.intro {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 50px;
  }
}
section#content .umb-grid p {
  font-weight: 300;
}
section#content .umb-grid hr {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  height: 2px;
  background: #E1E1D2;
  border: none;
  border-radius: 5px;
}
@media (min-width: 768px) {
  section#content .umb-grid hr {
    width: 50%;
  }
}
section#content div.videoContainer {
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 56.25%;
  margin-bottom: 45px;
  border-radius: 32px;
}
section#content div.videoContainer div.video {
  position: absolute;
  width: 100%;
  height: 100%;
}
section#content div.videoContainer div.video div.videoPoster {
  position: absolute;
  width: 100%;
  height: 100%;
}
section#content div.videoContainer div.video div.videoPoster img {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 32px;
  object-fit: cover;
}
section#content div.videoContainer div.video div.videoPoster a {
  position: absolute;
  width: 105px;
  height: 105px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: #E72264;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  border-bottom: none;
  opacity: 0.75;
  transition: all 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
section#content div.videoContainer div.video div.videoPoster a svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 56px;
  max-height: 56px;
  transform: scale(1.5);
}
section#content div.videoContainer div.video div.videoPoster a:hover {
  opacity: 1;
}
section#content div.videoContainer div.video iframe {
  border-radius: 32px;
}
section#content div.videoContainer.notAccepted {
  border: 1px solid #e0e0e0;
  background: #e0e0e0;
}
section#content div.videoContainer.notAccepted .content {
  border-radius: 4px;
  text-align: center;
  padding: 20px;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: auto;
}
section#content div.videoContainer.notAccepted .content .title {
  font-weight: 300;
  margin-top: 0;
  font-size: 1.2em;
  font-family: "Public Sans", sans-serif;
  color: #651FFF;
}
@media (min-width: 768px) {
  section#content div.videoContainer.notAccepted .content .title {
    font-size: 2em;
  }
}

ul.tags {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-left: -8px;
}
ul.tags li {
  margin-top: 16px;
  margin-left: 8px;
}
ul.tags li a {
  font-size: 16px;
  text-decoration: none;
  line-height: 18px;
  color: #83847B;
  background: #F8F8F4;
  padding: 5px 10px;
  border-radius: 8px;
  transition: background 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
ul.tags li a:hover, ul.tags li a:focus {
  background: #e4e4d5;
}

ul.partners {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -20px;
  margin-left: -10px;
  margin-right: -10px;
  justify-content: center;
  align-items: center;
}
ul.partners li {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
ul.partners li a img {
  height: 130px;
  min-width: 1px;
}

address {
  font-weight: bold;
  opacity: 0.4;
}

blockquote {
  text-align: center;
  font-size: 40px;
  line-break: 48px;
  font-weight: 700;
  color: #651FFF;
  font-family: "Public Sans", sans-serif;
}
blockquote:before, blockquote:after {
  content: '"';
}
@media (min-width: 768px) {
  blockquote {
    font-size: 48px;
  }
}

section.parallaxBackground {
  position: relative;
  overflow: visible;
  width: calc(100% + 30px);
  margin-left: -15px;
}
@media (min-width: 768px) {
  section.parallaxBackground {
    width: calc(100% + 100px);
    margin-left: -50px;
  }
}
section.parallaxBackground > section {
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  section.parallaxBackground > section {
    padding-left: 50px;
    padding-right: 50px;
  }
}
section.parallaxBackground .background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
section.parallaxBackground .background .layer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url("/img/bg_hero_shapes_yellow.svg");
  background-size: 200%;
  opacity: 0;
  top: -10%;
  left: -10%;
  height: 120%;
  width: 120%;
}
@media (min-width: 768px) {
  section.parallaxBackground .background .layer {
    background-size: 150%;
  }
}
@media (min-width: 1024px) {
  section.parallaxBackground .background .layer {
    background-size: 100%;
  }
}

i.info {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: #83847B;
  color: #E1E1D2;
  font-size: 12px;
  border-radius: 8px;
  font-family: "Georgia", serif;
  user-select: none;
  cursor: help;
}

.text-align-center {
  text-align: center;
}

.partlyHidden {
  overflow-y: hidden;
  position: relative;
}
.partlyHidden:after {
  position: absolute;
  bottom: 0;
  height: 25px;
  width: 100%;
  left: 0;
  content: "";
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #FFFFFF);
}

.showMoreLink {
  display: inline-block;
  padding: 5px 0;
  color: #707070;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}
.showMoreLink:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #707070;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transition: transform 400ms cubic-bezier(0.05, 0.93, 0.53, 0.9);
}
.showMoreLink:hover:after, .showMoreLink:focus:after {
  transform: none;
}
.showMoreLink:before {
  content: "▼";
  display: inline-block;
  transform: scale(0.6);
  position: absolute;
  right: -18px;
}