// Colors
$colorGrey: #707070;
$colorWhite: #FFFFFF;
$colorPurple: #AA00FF;
$colorPurple2: #A620B0;
$colorBlue: #651FFF;
$colorCyan: #00E5FF;
$colorJava: #1DE9B6;
$colorGreen: #12C700;
$colorYellow: #FFC400;
$colorRed: #E72264;
$colorBlack: #232320;
$colorGreyLighter: #83847B;
$colorGreyLight: #E1E1D2;
$colorGreyLighter2: #F8F8F4;

// Sizes
$sizeMenuHeight: 160px;
$sizeMenuHeightMobile: 97px;

// Fonts
$fontDefault: 'Public Sans', sans-serif;
$fontTitle: $fontDefault;

// Others
$animationFunction: cubic-bezier(.05, .93, .53, .9);
$animationDuration: 400ms;

:root {
    --fullHeight: 100vh;
}