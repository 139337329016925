form {
    margin: 50px 0; // Is temporary

    *::placeholder {
        color: rgba(0, 0, 0, .7);
    }

    *:placeholder {
        color: rgba(0, 0, 0, .7);
    }

    input {
        width: 100%;

        &[type="button"],
        &[type="checkbox"],
        &[type="image"],
        &[type="radio"],
        &[type="reset"],
        &[type="submit"] {
            width: auto;
        }
    }

    textarea,
    select {
        width: 100%;
    }

    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="file"],
    textarea {
        border: 1px solid #9b9b9b;
        background: #f7f7f7;
        border-radius: 4px;
        padding: 5px 20px;

        font-size: 16px;
        line-height: 30px;
        font-family: $fontDefault;

        @include transition(border-color);

        &:focus {
            border-color: #000;
        }
    }

    button,
    input[type="submit"] {
        border: none;
        background: #bbbbbb;
    }


    &.searchForm {
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: stretch;

        label {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            background: #bbbbbb;
        }

        input {
            flex-grow: 1;
        }

        button[type="submit"] {}
    }

    // Input rows have JS hooked up to make the label float above the input when needed
    .inputRow {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        label {
            display: inline-block;
            text-align: left;
            margin-bottom: 0;
            padding-left: 10px;
            font-size: 18px;
            margin-bottom: -5px;
            transform: translateY(31px);
            cursor: text;

            transform-origin: left;
            @include transition(color transform);

            &.entered,
            &.active {
                transform: scale(.9);
                color: $colorGreen;
                cursor: default;
            }
        }

        input {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $colorGreyLight;
            padding-left: 10px;
            font-size: 18px;
            background: transparent;
            color: $colorGrey;
        }

        .field-validation-valid {
            display: none;
        }

        .field-validation-error {
            display: block;
            padding: 10px;
            color: $colorRed;
            font-size: .9em;
        }
    }

    .validation-summary-errors {
        ul {
            li {
                color: $colorRed;
                padding: 10px;
                font-size: .9em;
            }
        }
    };

    .simpleRow {
        margin-bottom: 20px;
        position: relative;

        input[type="checkbox"],
        input[type="radio"] {
            display: none;

            +label,
            +input[type="hidden"]+label {
                position: relative;
                padding-left: 35px;
                cursor: pointer;
                display: block;
                line-height: 24px;
                @include transition(color);

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 18px;
                    height: 18px;
                    border-radius: 5px;
                }

                &:before {
                    opacity: 0;
                    background-image: url('/img/icon_check_green.svg');
                    background-position: center 30%;
                    background-repeat: no-repeat;
                    transform: scale(.4);
                    @include transition(opacity transform);
                }

                &:after {
                    border: 1px solid $colorGreyLighter;
                    @include transition(border-color);
                }

                @include active {
                    &:after {
                        border-color: $colorBlack;
                    }
                }
            }

            &:checked {

                +label,
                +input[type="hidden"]+label {
                    color: $colorGreen;

                    &:before {
                        opacity: 1;
                        transform: none;
                    }

                    &:after {
                        border-color: $colorGreen;
                    }
                }
            }
        }

        input[type="radio"] {

            +label,
            +input[type="hidden"]+label {

                &:before,
                &:after {
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.orColumn {
    display: flex;
    align-items: center;
    position: relative;

    &:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        background: $colorGreyLight;
        top: 50%;
        width: 100%;
        left: 0;
        height: 1px;

        @include media-breakpoint-up(md) {
            left: 50%;
            height: 100%;
            width: 1px;
            top: 0;
        }
    }

    span {
        display: inline-block;
        text-align: center;
        background: $colorWhite;
        padding: 5px 8px 7px 8px;
        margin: 0 auto;
    }
}

.select2 {
    &.select2-container {
        height: 40px;
        width: 100% !important;

        .select2-selection--single {
            height: 100%;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $colorGreyLight;

            span {
                height: 100%;
                line-height: 40px;
            }
        }

        .select2-selection--multiple {
            border: 1px solid $colorGreyLight;

            .select2-selection__choice {
                height: 32px;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                background-color: $colorGreyLighter2;
                border: 1px solid $colorGreyLight;
                font-size: 16px;
                margin-bottom: 3px;

                span {
                    margin-left: 5px;
                }
            }
        }
    }

    &.select2-container--focus,
    &.select2-container--open,
    &:active {
        .select2-selection--single {
            border-bottom-color: $colorGrey;
        }
    }
}

.select2-dropdown {
    border-radius: 16px;
    overflow: hidden;

}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: none;
    border-bottom: 1px solid $colorGreyLight;
    line-height: 30px;
}

.select2-results__option {
    line-height: 30px;
    padding: 5px 6px;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: $colorGreyLight;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    color: $colorWhite;
    background-color: $colorBlue;
}