.striber {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @include media-breakpoint-up(sm) {
        display: block;
    }

    .image {
        margin-bottom: 10px;
        margin-right: 15px;

        flex: 0 0 86px;
        max-width: 86px;

        @include media-breakpoint-up(sm) {
            // height: 175px;
            max-width: 175px;
            margin-right: 0;
        }

        a {
            text-decoration: none;

            display: block;
            position: relative;
            height: 0;
            padding-bottom: 100%;
        }

        img {
            border-radius: 50%;
            // height: 86px;
            // width: 86px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            
            @include media-breakpoint-up(sm) {
                // height: 175px;
                // width: 175px;
            }
        }

        .icon {
            height: 33px;
            width: 33px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: (86px / 2);
            left: (86px / 2);
            background-color: $colorRed;
            border-radius: 50%;
            opacity: .75;

            transform: translate(-50%, -50%);
            
            svg.sprite {
                width: 25px;
                max-height: 25px;
            }

            @include media-breakpoint-up(sm) {
                height: 67px;
                width: 67px;
                top: (175px / 2);
                left: (175px / 2);

                svg.sprite {
                    width: 50px;
                    max-height: 50px;
                }
            }
        }
    }

    .contents {
        .author {
            font-size: 16px;
            color: $colorGreyLighter;
            display: block;
            margin-bottom: 5px;
        }

        .title, > a .title {
            font-size: 24px;
            line-height: 24px;
            font-weight: 800;

            display: block;
            margin-bottom: 10px;
        }

        > a {
            text-decoration: none;

            .title {
                color: $colorBlack;

                @include transition(color);
            }

            @include active {
                .title {
                    color: $colorPurple;
                }
            }
        }

        .info {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 15px;

            .journalLikes {
                display: inline-flex;
                align-items: center;
                margin-right: 16px;

                svg.sprite {
                    width: 19px;
                    max-height: 16px;
                    margin-right: 8px;

                    use {
                        stroke: $colorGreen;
                        fill: $colorGreen;
                    }
                }

                span {
                    font-size: 16px;
                }
            }

            address {
                font-size: 16px;
                opacity: 1;
                color: $colorGreyLighter;
                font-weight: 400;
            }
        }
    }
}