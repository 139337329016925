.pagedContainer {
    li {
        @include animation(appear);

        &.paged {
            display: none;
        }
    }
}

.pager {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: $fontDefault;
    font-size: 18px;
    font-weight: 400;
    color: $colorGreyLighter;

    > a.next {
        display: none;
        color: $colorBlack;
        padding: 12px 100px;

        @include active {
            color: $colorBlue;
        }

        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }

    .controlBlock {
        display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
            position: absolute;
            right: 0;
        }
    }

    .controls {
        display: inline-flex;
        align-items: center;

        .previous, .next, .current {
            height: 48px;
            width: 48px;

            display: inline-flex;
            align-items: center;
            justify-content: center;

            svg.sprite {
                use {
                    @include transition(stroke);
                }
            }
        }

        .previous, .next {
            background: transparent;
            border: none;

            @include transition(opacity);

            @include active {
                svg.sprite {
                    use {
                        stroke: $colorBlue;
                    }
                }
            }
        }

        .current {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: $colorWhite;

            border: 1px solid $colorGreyLight;
            border-radius: 50%;
        }
        
        ul.pages {
            display: inline-flex;
            align-items: center;

            li {
                padding: 0 8px;

                .page {
                    height: 48px;
                    width: 48px;

                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background: $colorWhite;
                    border: 1px solid $colorGreyLight;
                    border-radius: 50%;
                    cursor: pointer;
                }

                &.active {
                    .page {
                        color: $colorRed;
                    }
                }
            }
        }
    }

    [disabled] {
        opacity: .4;
        pointer-events: none;
        user-select: none;
    }
}