section.stories {

    h2 {
        color: $colorRed;
    }

    > div:nth-of-type(2) > .row {
        margin-bottom: 35px;

        > div {
            display: flex;
            align-items: center;

        }
    }
    

    a.readmore {
        display: inline-flex;
        align-items: center;
        color: $colorGreyLighter;
        text-decoration: none;
        margin:0 auto;
        
        svg {
            margin-left: 5px;
            @include transition(transform);
        }

        @include active {
            svg {
                transform: translateX(4px);
            }
        }
    }

    .storiesList {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: -32px;

        @include media-breakpoint-up(lg) {
            margin-left: -10px;
            margin-right: -10px;
        }

        li {
            flex-basis: 100%;
            width: 100%;
            max-width: 100%;

            @include media-breakpoint-up(lg) {
                flex-basis: calc(50% - 20px);
                width: calc(50% - 20px);
                max-width: calc(50% - 20px);
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        li:not(:last-child) {
            margin-top: 32px;
            margin-bottom: 32px;

            @include media-breakpoint-up(md) {
                margin-top: 32px;
                margin-bottom: 0;
            }
        }

        li:last-child {
            @include media-breakpoint-up(md) {
                margin-top: 32px;
            }
        }

        .embeddedStory {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }

            a.image {
                width: 132px;
                height: 132px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                margin-bottom: 10px;
                flex-shrink: 0;

                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                    margin-right: 40px;

                    width: 136px;
                    height: 136px;
                }

                @include media-breakpoint-up(xl) {
                    width: 174px;
                    height: 174px;
                }

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                }

                svg {
                    display: none;
                }
            }

            > div {
                // display: flex;
                display: inline-block;
                flex-direction: column;
                align-items: center;

                @include media-breakpoint-up(md) {
                    align-items: flex-start;
                }
            }

            a.text {
                display: inline-block;
                text-decoration: none;
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 10px;
                color: $colorBlack;

                @include media-breakpoint-up(md) {
                    font-size: 24px;
                    line-height: 24px;
                }
            }

            div.like {
                display: flex;
                justify-content: center;
                align-items: center;

                @include media-breakpoint-up(md) {
                    display: inline-flex;
                    justify-content: flex-start;
                }

                svg {
                    margin-right: 5px;

                    use {
                        fill: $colorGreen;
                    }
                }
            }
        }
    }
}