svg.sprite {
    &.logo {
        width: 120px;
        max-height: 50px;

        use {
            fill: $colorBlack;
        }
        
        &.white {
            use {
                fill: $colorWhite;
            }
        }
    }

    &[class*="share_"] {
        width: 48px;
        max-height: 48px;

        use {
            fill: $colorWhite;
            stroke: $colorGreyLighter;
        }
    }

    &.icon_avatar{
        width:50px;
        height:50px;
    }

    &.icon_facebook {
        width: 14px;
        max-height: 25px;

        use {
            fill: $colorWhite;
        }
    }

    &.icon_instagram {
        width: 32px;
        max-height: 32px;

        use {
            fill: $colorWhite;
        }
    }

    &.icon_linkedin {
        width: 24px;
        max-height: 24px;

        use {
            fill: $colorWhite;
        }
    }

    &.icon_twitter {
        width: 28px;
        max-height: 23px;

        use {
            fill: $colorWhite;
        }
    }

    &.icon_play {
        width: 43px;
        max-height: 43px;

        use {
            fill: $colorWhite;
            transform: translateX(3px);
        }
    }

    &.icon_bell {
        width: 27px;
        max-height: 34px;

        use {
            fill: $colorWhite;
        }
    }

    &.icon_send {
        width: 32px;
        max-height: 32px;

        use {
            fill: $colorWhite;
        }
    }

    &.icon_close {
        width: 56px;
        max-height: 56px;
		display:block;

        use {
            fill: $colorGreyLighter;
        }
    }

    &.icon_search {
        width: 24px;
        max-height: 24px;

        use {
            fill: $colorBlack;
        }
    }

    &.icon_shop {
        width: 26px;
        max-height: 32px;

        use {
            fill: $colorBlack;
        }
    }

    &.icon_shop_filled {
        width: 28px;
        max-height: 36px;

        use {
            fill: $colorBlack;
        }
    }

    &.icon_shop_small {
        width: 15px;
        max-height: 18px;

        use {
            fill: $colorBlack;
        }
    }

    &.icon_heart_small {
        width: 19px;
        max-height: 16px;

        use {
            fill: $colorBlack;
        }
    }

    &.icon_stories_small {
        width: 16px;
        max-height: 18px;

        use {
            fill: $colorBlack;
        }
    }

    &.icon_project_small {
        width: 12px;
        max-height: 18px;

        use {
            fill: $colorBlack;
        }
    }

    &.icon_chevron_right {
        width: 9px;
        max-height: 15px;

        use {
            stroke: $colorGreyLighter;
        }
    }

    &.icon_chevron_left {
        width: 11px;
        max-height: 19px;

        use {
            stroke: $colorGreyLighter;
        }

        &.right {
            transform: scaleX(-1);
        }
    }

    &.icon_like {
        width: 36px;
        max-height: 31px;

        use {
            fill: $colorWhite;
            stroke: $colorGreyLighter;
        }
    }

    &.icon_arrow_left {
        width: 32px;
        max-height: 10px;

        use {
            fill: $colorGreyLighter;
            stroke: $colorGreyLighter;
        }
    }

    &.icon_edit {
        width: 12px;
        max-height: 12px;

        use {
            fill: $colorGreyLighter;
        }
    }

    &.icon_stop {
        width: 12px;
        max-height: 12px;
        
        use {
            fill: $colorGreyLighter;
        }
    }

    &.icon_error, &.icon_success {
        width: 24px;
        max-height: 24px;
    }

    &.icon_delete {
        width: 28px;
        max-height: 32px;

        use {
            fill: $colorGreyLight;
        }
    }

    &.icon_rating {
        width: 29px;
        max-height: 25px;
    }

    &.icon_angle_down {
        width: 14px;
        max-height: 10.5px;
    }


    // SDG Goals

    &[class*="SDG_"] {
        max-height: 30px;

        use {
            fill: $colorBlack;
        }
    }

    &.SDG_1 {
        width: 36px;
    }
    
    &.SDG_2 {
        width: 35px;
    }

    &.SDG_3 {
        width: 42px;
    }

    &.SDG_4 {
        width: 37px;
    }

    &.SDG_5 {
        width: 22px;
    }

    &.SDG_6 {
        width: 22px;
    }

    &.SDG_7 {
        width: 30px;
    }

    &.SDG_8 {
        width: 29px;
    }

    &.SDG_9 {
        width: 30px;
    }

    &.SDG_10 {
        width: 29px;
    }

    &.SDG_11 {
        width: 40px;
    }

    &.SDG_12 {
        width: 32px;
    }

    &.SDG_13 {
        width: 47px;
    }

    &.SDG_14 {
        width: 35px;
    }

    &.SDG_15 {
        width: 31px;
    }

    &.SDG_16 {
        width: 30px;
    }

    &.SDG_17 {
        width: 31px;
    }
}