body.journaldetailpage {
    section#content {
        h1 {
            margin-top: 0;
            margin-bottom: 32px;
            color: $colorBlack;
        }

        h2, h3, h4 {
            margin-bottom: 32px;
        }

        h5 {
            margin-bottom: 26px;
        }
        
        .col > img {
            display: inline-block;
            border-radius: 30px;
            margin-bottom: 40px;
        }

        .videoContainer {
            @include media-breakpoint-down(sm) {
                width: calc(100% + 60px);
                margin-left: -30px;
                padding-bottom: 70%;
                border-radius: 0;

                .video {
                    .videoPoster {
                        img {
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }

    [class*="userContent"] {
        p {
            font-size: 18px;
            line-height: 32px;
        }

        img {
            border-radius: 32px;
        }

        ul, ol {
            li {
                line-height: 32px;
            }
        }

        ol,
        ul {
            margin: 20px 0;

            li {
                position: relative;
                margin-left: 25px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        *::selection {
            background: $colorGreyLight;
        }

        ul {
            padding-left: 20px;

            li {
                &:before {
                    content: '';
                    display: inline-block;
                    position: absolute;

                    left: -34px;
                    top: 16px;
                    width: 24px;
                    height: 2px;
                    background: $colorBlue;
                }
            }
        }

        ol {
            padding-left: 20px;
            counter-set: list;

            li {
                counter-increment: list;

                &:before {
                    position: absolute;
                    right: 110%;
                    right: calc(100% + 10px);
                    width: 30px;
                    text-align: right;
                    content: counter(list) '.';
                    font-weight: 500;
                    color: $colorBlue;
                }

                ol {
                    counter-set: listlist;

                    li {
                        counter-increment: listlist;

                        &:before {
                            content: counter(listlist) '.';
                        }
                    }
                }
            }
        }

        &[class*="userContentImages"] {
            img {
                margin-bottom: 32px;

                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .striberQuickView, .striberQuickView + .likes {
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    .striberQuickView + .likes {
        margin-left: auto;
        
        @include media-breakpoint-up(md) {
            margin-right: 30px;
        }
    }

    .journalHeaderInfo {
        margin-bottom: 64px;

        > div {
            padding-bottom: 10px;
            border-bottom: 1px solid $colorGreyLight;
        }
    }

    .journalFooterInfoOne {
        margin-top: 64px;
        margin-bottom: 75px;

        .shareContainer {
            @include media-breakpoint-up(md) {
                margin-left: auto;
            }
        }
    }

    .SDGGoalsRow {
        margin-bottom: 50px;

        ol.SDG {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            margin-top: -8px;

            li {
                margin-top: 8px;
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }

                .goal {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 12px;

                    height: 48px;
                    width: 48px;
                    border-radius: 48px;
                }
            }
        }
    }

    .tagsRow {
        margin-bottom: 100px;
    }

    address {
        font-size: 18px;
        opacity: 1;
        font-weight: 300;
        color: $colorGreyLighter;
    }

    p.intro {
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 48px;
    }
}