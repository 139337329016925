.striberQuickView {
    display: inline-flex;
    
    .image {
        margin-right: 8px;
        height: 67px;
        width: 67px;
        
        @include media-breakpoint-up(md) {
            margin-right: 16px;
        }

        img {
            border-radius: 100px;
        }
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $colorGreyLighter;
        
        @include media-breakpoint-up(md) {
            justify-content: flex-start;
            align-items: flex-start;

            &.center {
                justify-content: center;
            }
        }

        .name {
            display: block;
            margin-top: 5px;
            margin-bottom: 6px;
            font-size: 16px;
            line-height: 18px;

            @include media-breakpoint-up(md) {
                display: flex;
            }
        }

        ul.activity {
            display: none;
            
            @include media-breakpoint-up(md) {
                display: flex;
            }

            li {
                margin-right: 16px;
                display: inline-flex;
                align-items: center;

                &:last-child {
                    margin-right: 0;
                }

                svg.sprite {
                    margin-right: 6px;

                    use {
                        fill: $colorGreyLighter;
                    }
                }

                span {

                }
            }
        }
    }
}