.likes[data-like] {
    display: inline-flex;
    align-self: center;
    align-items: center;
    align-content: center;
    justify-content: center;

    .icon {
        position: relative;
        cursor: pointer;
        margin-right: 10px;

        display: inline-flex;
        align-items: center;
        font-size: 14px;


        @include media-breakpoint-up(md) {
            display: block;
        }
        
        svg.sprite {
            flex: 0 0 auto;
            width: 21px;
            max-height: 18px;

            margin-right: 4px;

            @include media-breakpoint-up(md) {
                margin-right: 0;
                width: 51px;
                max-height: 43px;
            }

            use {
                @include transition(fill stroke);
            }
        }

        span {
            position: relative;
            left: 0;
            top: -2px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: $colorBlack;

            @include media-breakpoint-up(md) {
                position: absolute;
                color: $colorGreyLighter;
            }
            
            @include transition(opacity);
        }
    }

    > span {
        font-size: .9em;
        line-height: 24px;
        margin-right: 10px;

        display: none;

        @include media-breakpoint-up(md) {
            display: inline;
        }
    }

    &:not(.liked) {
        .icon {
            
            @include active {
                svg.sprite use {
                    stroke: $colorGreen;
                }

                span {
                    color: $colorGreen;
                }
            }
        }
    }

    &.liked {
        .icon {
            pointer-events: none;
            cursor: default;

            svg.sprite {
                @include animation(liked, 600ms, ease-out);

                use {
                    fill: $colorGreen;
                    stroke: $colorWhite;
                }
            }

            span {
                @include media-breakpoint-up(md) {
                    color: $colorWhite;
                    opacity: .8;
                }
            }
        }
    }

    &.alreadyLiked {
        * {
            animation: none !important;
            transition: none !important;
        }
    }
}