ul, ol {
    &.accordeon {
        border-top: 2px solid $colorGreyLight;

        li {
            border-bottom: 2px solid $colorGreyLight;
            padding-top: 3px;
            padding-bottom: 3px;
        }

        .header {
            display: flex;
            align-items: center;
            cursor: pointer;

            .headerContent {
                flex: 1 1 auto;

                h3 {
                    font-size: 24px;
                    line-height: 32px;
                    @include transition;
                }
            }

            .headerCloseButton {
                flex: 0 0 auto;

                .close {
                    position: relative;
                    height: 45px;
                    width: 45px;
                    transform: translateX(10px);

                    // + / x button
                    &:before, &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;

                        width: 2px;
                        height: 17px;
                        background: $colorBlack;

                        transform: translateX(-50%) translateY(-50%);
                        @include transition(transform);
                    }

                    &:after {
                        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
                        transition-delay: 80ms;
                    }
                }
            }
        }

        li.active .header {
            .headerContent {
                h3 {
                    color: $colorRed;
                }
            }
            
            .headerCloseButton {
                .close {
                    &:before {
                        transform: translateX(-50%) translateY(-50%) scaleY(0);
                    }

                    &:after {
                        transform: translateX(-50%) translateY(-50%) rotate(-90deg) scaleY(.8);
                    }
                }
            }
        }

        .content {
            overflow-y: hidden;
            font-size: 18px;
            line-height: 32px;
            
            p {
                &:first-child {
                    margin-top: 10px;
                }

                &:last-child {
                    margin-bottom: 10px;
                }
            }

            @include transition(height);

            .actions {
                > a, > div, > span {
                    display: flex;
                    align-items: center;
                    color: $colorPurple;
                    text-decoration: none;

                    svg.sprite {
                        flex: 0 0 auto;
                        width: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }

    }
}

section#content {
    ul, ol {
        &.accordeon {
            
            .header {
                .headerContent {
                    h3 {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .umb-grid
    {
        ul, ol {
            &.accordeon{
                padding-left:0;
                margin:0;
                li{
                    margin:0;
                    &:before{
                        content:none;
                    }
                }
            }
        }
    }
}