section.subscriptions {
    margin-bottom: 32px;

    @include media-breakpoint-up(md) {
        margin-bottom: 64px;
    }

    h2 {
        color: $colorRed;
        text-align: center;
    }

    p.intro {
        width: 100%;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
            margin-bottom: 64px;
        }
    }

    .subscriptionsRow {
        > .col, > [class*="col-"] {
            margin-bottom: 32px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }
    }

    .subscription {
        display: flex;
        flex-direction: column;

        padding: 32px;
        padding-bottom: 45px;

        border: 1px solid $colorGrey;
        border-radius: 32px;

        @include media-breakpoint-up(md) {
            height: 100%;
        }

        .title {
            width: 100%;
            text-align: center;

            font-size: 32px;
            line-height: 37px;
            margin-bottom: 32px;
            font-weight: 900;
        }

        .USPs {
            margin-bottom: 18px;

            li {
                font-size: 18px;
                line-height: 24px;
                padding-left: 37px;
                position: relative;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }

                &:before {
                    height: 17px;
                    width: 17px;
                    display: block;
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 0;
                    background: url('/img/icon_check_green.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }
            }
        }

        .price {
            width: 100%;
            text-align: center;
            margin-top: auto;
            margin-bottom: 24px;

            span {
                display: block;
                font-size: 36px;
                line-height: 32px;
            }

            span.VAT {
                color: $colorGrey;
                font-size: 18px;
            }
        }

        .actions {
            text-align: center;
            padding: 0 37px;
            
            .button {
                color: $colorWhite;
                display: inline-block;
                width: 100%;
                text-align: center;
                background: $colorGreyLighter;
                font-size: 22px;
                font-weight: 400;
            }
        }

        &.free {
            .title {
                color: $colorGreen;
            }

            .actions {
                .button {
                    background: $colorGreen;

                    @include active {
                        background: $colorGreyLight;
                        color: $colorGreen;
                    }
                }
            }
        }

        &.community {
            .title {
                color: $colorRed;
            }

            .actions {
                .button {
                    background: $colorRed;

                    @include active {
                        background: $colorGreyLight;
                        color: $colorRed;
                    }
                }
            }
        }

        &.academy {
            .title {
                color: $colorBlue;
            }

            .actions {
                .button {
                    background: $colorBlue;

                    @include active {
                        background: $colorGreyLight;
                        color: $colorBlue;
                    }
                }
            }
        }
    }
}

section#content section.subscriptions {
    h2 {
        margin-bottom: 20px;
    }
}