footer {
    background: $colorWhite;
    min-height: 200px;
    border-top: 1px solid $colorGreyLight;
    padding-bottom: 40px;

    .row.footerLinks {
        margin-top: 45px;
        margin-bottom: 45px;

        > div:not(:last-child) {
            margin-bottom: 30px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }

        nav {
            h2, a {
                font-size: 18px;
                line-height: 20px;
                font-family: $fontDefault;
                font-weight: 500;
            }

            h2 {
                color: $colorRed;
                font-weight: 800;
            }
            
            a {
                color: $colorGreyLighter;
                text-decoration: none;
                font-weight: 300;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: $colorRed;
                    transform: scaleX(0);
                    opacity: 0;

                    @include transition(transform opacity);
                }

                @include active {
                    color: $colorRed;
                    
                    &:after {
                        opacity: 1;
                        transform: none;
                    }
                }
            }

            ul {
                li {
                    margin-bottom: 16px;
                }
            }
        }
    }

    ul.socialMediaList {
        display: inline-flex;
        flex-wrap: wrap;
        margin-top: -8px;
        justify-content: center;

        li {
            display: inline-block;
            margin-top: 8px;
            margin-left: 6px;
            margin-right: 6px;
            margin-bottom: 8px;

            @include media-breakpoint-up(md) {
                margin-left: 8px;
                margin-right: 8px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            a {
                display: inline-flex;
                justify-content: center;
                align-items: center;

                width: 50px;
                height: 50px;
                border-radius: 56px;
                background: $colorBlue;

                @include transition(background);

                svg use {
                    @include transition(fill);
                }

                @include active {
                    background: $colorWhite;

                    &:after {
                        opacity: 1;
                        transform: none;
                    }

                    svg.sprite {
                        use {
                            fill: $colorBlue;
                        }
                    }
                }

                @include media-breakpoint-up(md) {
                    width: 56px;
                    height: 56px;
                }
            }
        }
    }
}

section#prefooter {
    display: none;

    background: rgba(0, 0, 0, .1);

    /* EXPERIMENTAL */
    /* margin-top: auto; */
}