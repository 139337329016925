body.paymentsuccesspage,
body.paymentcancelledpage {
    section#content {
        h1 {
            font-size: 38px;
            line-height: 40px;
            font-weight: 800;
            color: $colorBlue;
            margin-bottom: 10px;
            margin-top: 25px;
            text-align: center;
            font-family: $fontTitle;

            @include media-breakpoint-up(md) {
                font-size: 56px;
                line-height: 48px;
            }
        }

        img.circle {
            border-radius: 200px;
            width: 175px;
            height: 175px;
            display: block;
            margin: 32px auto 32px;
            @include animation(popupGif);
        }

        div.subtitle {
            line-height: 1.4em;
            font-size: 18px;
            font-style: normal;

            display: block;
            text-align: center;
            margin-bottom: 32px;
            color: $colorGreyLighter;

            a {
                color: $colorGreyLighter;
                @include transition(color);
                @include underline($colorGreyLighter, 1px);

                @include active {
                    color: $colorBlue;
                }
            }
        }
    }
}