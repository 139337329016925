.parallaxImageSet {
    position: relative;
    padding-bottom: 70%;
    
    .layer {
        position: absolute;
        background-size: contain;

        &.back {

        }

        &.middle {

        }

        &.front {

        }
    }

    &.Een {
        .layer {
            &.back {
                // background-image: url('/img/sets/een/back.png');
            }

            &.middle {
                // background-image: url('/img/sets/een/middle.png');
            }

            &.front {
                // background-image: url('/img/sets/een/front.png');
            }
        }
    }

    &.Twee {
        
    }
}