body.shopbasketpage {

    section#content {
        padding-top: 50px;
        margin-bottom: 60px;

        @include media-breakpoint-up(md) {
            padding-top: 50px;
            margin-bottom: 128px;
        }
    }

    a.continue, button.continue {
        &.disabled {
            opacity: .3;
            pointer-events: none;
            user-select: none;
            cursor: default;
        }
    }

    a.contintueshopping span.link {
        font-size: 18px;
    }

    .headerRow {
        margin-bottom: 32px;
    }

    section#content {
        h1 {
            color: $colorBlack;
            margin-top: 0;
            margin-bottom: 32px;
            font-size: 32px;
            font-weight: bolder;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
    }

    form {
        margin: 0;
        margin-bottom: 72px;

        img.loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: none;
        }

        &.loading {
            opacity: .6;
            pointer-events: none;

            img.loading {
                display: inline-block;
            }
        }
    }

    ul.basketProducts {
        li {
            padding: 24px 0;
            border-bottom: 1px solid $colorGreyLight;

            &:first-of-type {
                border-top: 1px solid $colorGreyLight;
            }

            .row {
                align-items: center;
            }
        }
    }

    .imageColumn {
        .image {
            position: relative;
            height: 0;
            padding-bottom: 100%;
            border-radius: 50%;
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .infoColumn {
        a.title {
            display: inline-block;
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 7px;
            color: $colorBlack;
            text-decoration: none;

            @include transition(color);

            @include active {
                color: $colorBlue;
            }
        }

        .variant {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 26px;

            span {
                padding-left: 16px;

                &:first-child {
                    padding-left: 0;
                }
            }
        }

        .price {
            display: block;
            font-size: 18px;
            line-height: 24px;
            color: $colorGreyLighter;

            @include media-breakpoint-up(md) {
                display: inline-block;
            }

            .original {
                padding-left: 16px;
                color: $colorRed;
                text-decoration: line-through;
            }
        }

        .seller {
            font-size: 16px;
            line-height: 18px;
            color: $colorGreyLighter;

            @include media-breakpoint-up(md) {
                margin-left: 17px;
            }

            &:before {
                display: none;
                margin-right: 17px;
                content: '';
                top: -3px;
                height: 1px;
                width: 48px;
                background: $colorGreyLighter;
                position: relative;


                @include media-breakpoint-up(md) {
                    display: inline-block;
                }
            }
        }
    }

    .quantityColumn {
        input {
            text-align: center;
            border: none;
            background: transparent;
            border-radius: 0;
            border-bottom: 1px solid $colorGreyLight;

            @include active {
                border-bottom-color: $colorBlack;
            }
        }
    }

    .priceColumn {
        margin-top: 32px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }

        .totalPrice {
            text-align: right;
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 17px;
        }

        .VAT {
            text-align: right;
            font-size: 16px;
            line-height: 24px;
            color: $colorGreyLighter;
        }
    }

    .deleteColumn {
        text-align: right;
        margin-top: 32px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }

        button {
            background: transparent;

            svg.sprite use {
                @include transition(fill);
            }

            @include active {
                svg.sprite {
                    use {
                        fill: $colorRed;
                    }
                }
            }
        }
    }

    .voucherColumn {
        form {
            margin-bottom: 0;
        }

        .button {
            color: $colorGrey;
            transition-property: border-color, color;

            @include active {
                border-color: $colorBlack;
                color: $colorBlack;
            }
        }
    }

    .voucherColumn, .totalsColumn {
        margin-bottom: 70px;
    }

    .enterVoucherContainer {
        width: 100%;
        border: 1px solid $colorGreyLight;
        border-radius: 16px;
        padding: 32px;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }

        .inputRow {
            margin-bottom: 0;
            position: relative;
        }

        label {
            position: absolute;
            top: -15px;
            transition-property: color, transform;
        }

        button {
            flex: 0 0 auto;
            margin-top: 24px;
            text-align: center;
            display: inline-block;

            @include media-breakpoint-up(lg) {
                margin-top: 0;
                margin-left: 24px;
            }
        }

        input {
            border-bottom-color: $colorGrey;
        }
    }

    .totalsColumn {
        table {
            margin-left: auto;

            @include media-breakpoint-down(xs) {
                display: flex;
                flex-direction: column;
            }

            tr {
                @include media-breakpoint-down(xs) {
                    display: flex;
                    flex-direction: column;
                }
            }

            td {
                font-size: 18px;
                line-height: 24px;
                color: $colorGreyLighter;
                text-align: right;

                @include media-breakpoint-down(xs) {
                    display: block;
                    text-align: left;
                }

                &:first-child {
                    font-size: 16px;

                    @include media-breakpoint-down(xs) {
                        color: $colorBlack;
                    }
                }

                &:last-child {
                    padding-left: 67px;
                    white-space: nowrap;

                    @include media-breakpoint-down(xs) {
                        padding-left: 0;
                        margin-bottom: 16px;
                    }
                }
            }

            .subtotal td:last-child {
                font-size: 24px;
            }

            .shipping {
                td:first-child {
                    position: relative;
                }

                i.info {
                    position: absolute;
                    right: -24px;
                    top: 4px;

                    @include media-breakpoint-down(xs) {
                        right: -16px;
                    }
                }
            }

            .total td {
                padding-top: 37px;
                color: $colorBlack;

                @include media-breakpoint-down(xs) {
                    padding-top: 0;
                }

                &:last-child {
                    font-size: 24px;
                }
            }
        }
    }
}
