section#hero {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    overflow: hidden;

    // With the hero being in the content section, we need to break out of it to go fullwidth
    margin-left: -15px;
    margin-right: -15px;

    > .container {
        padding-left: 10px + 15px;
        padding-right: 10px + 15px;
    }

    @include media-breakpoint-up(md) {
        margin-left: -75px;
        margin-right: -75px;

        padding-top: 75px;
        padding-bottom: 90px;
    }

    @include media-breakpoint-up(lg) {
        margin-left: -50px;
        margin-right: -50px;

        padding-bottom: 75px;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 180px;
        padding-bottom: 40px;
    }

    .background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        .layer {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            &.back {
                background: $colorGreyLighter;
            }

            &.front {
                background: url('/img/bg_hero_shapes.svg');
                background-attachment: fixed;
                background-size: 200%;
                opacity: 0;

                top: -10%;
                left: -10%;
                height: 120%;
                width: 120%;

                @include media-breakpoint-up(md) {
                    background-size: 150%;
                }

                @include media-breakpoint-up(lg) {
                    background-size: 100%;
                }

                .ie & {
                    background-attachment: scroll;
                }
            }
        }

        &:before, &:after {
            display: block;
            z-index: 1;
            position: absolute;
            content: '';
            width: 100%;
            left: 0;
            background-size: 100% 100%;
        }

        &:before {
            background-image: url('/img/hero_curve_1.svg');
            top: -1.5px;
            height: 143px;
        }

        &:after {
            background-image: url('/img/hero_curve_2.svg');
            bottom: -1.5px;
            height: 100px;
            z-index: 0;
        }

        &.reverse {
            &:before {
                background-image: url('/img/hero_curve_2.svg');
                top: 0;
                height: 100px;
                transform: rotate(180deg) scaleX(-1);
            }
    
            &:after {
                background-image: url('/img/hero_curve_1.svg');
                bottom: 0;
                height: 134px;
                transform: rotate(180deg) scaleX(-1);
            }
        }

        &.reverseX {
            &:before {
                transform: scaleX(-1);
            }

            &:after {
                transform: scaleX(-1);
            }
        }
    }

    // img {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;

    //     height: 100%;
    //     width: 100%;
    //     object-fit: cover;
    // }
}   