body.signuplandingpage {
    .simpleHero {
        padding-top: 128px;
        margin-bottom: 64px + 64px + 15px;
        font-size: 24px;
        line-height: 40px;

        p {
            margin-top: 0;
        }

        a.button {
            font-size: 22px;
            line-height: 32px;
            padding-right: 60px;
        }
    }

    h1 {
        color: $colorBlue;
        font-size: 48px;
        line-height: 48px;
    }

    section.howItWorks {
        position: relative;
        background: #F8F8F4;
        margin-left: -15px;
        margin-right: -15px;
        padding-top: 64px;
        padding-right: 15px;
        padding-bottom: 64px;
        padding-left: 15px;
        margin-top: 80px;
        margin-bottom: 80px + 64px;

        @include media-breakpoint-up(md) {
            margin-left: -75px;
            margin-right: -75px;
            padding-left: 75px;
            padding-right: 75px;
        }

        &:before, &:after {
            content: '';
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: 80px;
            background-image: url('/img/highlighted_curve_1.svg');
            background-size: 100% 100%;
            z-index: 1;
        }

        &:before {
            transform: translateY(-100%) translateY(1px);
            top: 0;
        }

        &:after {
            bottom: 0;
            transform: translateY(100%) translateY(-1px) rotate(180deg);
        }

        h2 {
            color: $colorRed;
            font-size: 32px;
            line-height: 32px;
            font-weight: 500;
        }

        .container .row {
            .col, [class*="col-"] {
                img {
                    margin-bottom: 20px;
                }

                h3 {
                    font-size: 24px;
                    line-height: 32px;
                }

                p {
                    font-size: 18px;
                    line-height: 32px;
                }
            }
        }
    }

    .container.video {
        margin-bottom: 94px;
    }

    .faq {
        margin-bottom: 100px;

        h2 {
            font-size: 32px;
            line-height: 32px;
            text-align: left;

            @include media-breakpoint-up(md) {
                text-align: right;
            }
        }
    }

    section#content {
        h1, h2, h3, h4 {
            margin-top: 0;
        }

        section.howItWorks {
            h2 {
                margin-bottom: 10px;
            }
        }

        section.howItWorks {
            .container .row {
                .col, [class*="col-"] {

                    h3 {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .umb-grid {
            .row {
                justify-content: center;
            }
        }
    }

    section.subscriptions {
        h2 {
            color: #a0f;
        }
    }
}
