body.landingpage {
    $blurStrength: 10px;
    $blurDelay: 600ms;

    background: #871FD5;
    background: linear-gradient(to bottom,
            $colorBlue 0,
            #A620B0 50%,
            $colorPurple 100%);
    background-repeat: no-repeat;
    background-position: fixed;
    background-size: contain;
    min-height: 100vh;
    min-height: var(--fullHeight);
    height: 200px; // Yay IE hacks

    max-height: 100vh;
    max-height: var(--fullHeight);
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;



    header {
        // Some hack to make sure the layout's the same as when it's absolute positioned
        position: relative;
        top: 34px;
        height: 0;
        overflow-y: visible;

        // position: absolute;
        // top: 0;
        width: 100%;
        // margin-top: 34px;

        @include media-breakpoint-up(md) {
            // margin-top: 67px;
            top: 67px;
        }

        @include transition(filter);
        transition-delay: $blurDelay;

        &.blurred {
            pointer-events: none;
            filter: blur($blurStrength);
        }

        a.headerLogo {
            img {
                width: 117px;

                @include media-breakpoint-up(md) {
                    width: 175px;
                }
            }
        }

        .headerNavigation {
            align-items: center;
            display: flex;
            justify-content: flex-end;

            nav {

                ul,
                li {
                    display: inline-block;
                }

                li {
                    margin-right: 32px;

                    @include media-breakpoint-up(md) {
                        margin-right: 52px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                a {
                    text-decoration: none;
                    color: $colorWhite;
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 500;
                    position: relative;
                    padding: 10px 0;

                    @include media-breakpoint-up(md) {
                        padding: 10px 5px;
                    }

                    &:after {
                        position: absolute;
                        width: 100%;
                        height: 5px;
                        content: '';
                        display: inline-block;

                        background: $colorYellow;
                        bottom: 0;
                        left: 0;
                        transform: scaleX(0);
                        transform-origin: left;
                        border-radius: 500px;
                        opacity: 0;
                        @include transition(transform opacity);
                    }

                    @include active {
                        &:after {
                            transform: none;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    h1 {
        color: $colorYellow;
    }

    section#background {
        display: flex;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100vh;
        height: var(--fullHeight);
        overflow: hidden;
        z-index: -1;
        pointer-events: none;

        @include transition(filter);
        transition-delay: $blurDelay;

        &.blurred {
            filter: blur($blurStrength);
        }

        &.noanimate{
            div.row.parallaxSceneMouse div[class*="col-"]{
                @include transition(transform 200ms);
                transform: none !important;
            }
            div.blob {
                animation-play-state: paused;
               
            }
        }

        div.blob {
            width: 507px;
            height: 422px;
            background: radial-gradient(circle at 0 40%,
                    #E72264 0%,
                    #E72264 8%,
                    $colorPurple 50%,
                    #00E5FF 92%,
                    #1DE9B6 100%);
            border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%;
            animation:
                blob-landing-transform 20s ease-in-out infinite both alternate,
                blob-landing-moving-1 40s ease-in-out infinite both;

            @include media-breakpoint-up(md) {
                width: 666px;
                height: 554px;
            }
        }
    }

    section#content {
        padding-top: 85px;
        margin-top: auto;
        margin-bottom: auto;

        @include media-breakpoint-up(md) {
            padding-top: 140px;
        }

        @include transition(filter);
        transition-delay: $blurDelay;

        &.blurred {
            filter: blur($blurStrength);
            pointer-events: none;
        }

        h1 {
            font-size: 64px;
            line-height: 56px;
            font-weight: 700;
            color: $colorYellow;
            // white-space: nowrap;

            @include media-breakpoint-up(md) {
                font-size: 86px;
                line-height: 80px;
            }

            @include media-breakpoint-up(lg) {
                line-height: 100px;
            }
        }
    }

    p.intro {
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
        color: $colorWhite;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            font-size: 28px;
            line-height: 30px;
            margin-bottom: 50px;
        }
    }

    .actions {
        br {
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        a.button {
            width: 100%;
            margin-bottom: 15px;

            @include media-breakpoint-up(sm) {
                width: auto;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
                margin-right: 32px;
            }

            >*:not(:last-child) {

                @include media-breakpoint-up(md) {
                    margin-right: 50px;
                }
            }

            svg {
                transform-origin: top;
            }

            @include active {
                svg {
                    @include animation(wiggle, 800ms);
                }
            }
        }

        a.moreInfo {
            color: $colorWhite;
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            position: relative;
            text-decoration: none;

            &:before {
                content: '';
                display: inline-block;
                left: 0;
                bottom: -2px;
                position: absolute;
                width: 100%;
                height: 3px;
                background: $white;
                transform-origin: right;

                @include transition(transform);
            }

            &:after {
                content: '';
                display: inline-block;
                left: 0;
                bottom: -2px;
                position: absolute;
                width: 100%;
                height: 3px;
                background: $colorYellow;
                transform-origin: left;
                transform: scaleX(0);

                @include transition(transform);
            }

            @include active {
                &:before {
                    transform: scaleX(0);
                }

                &:after {
                    transform: scaleX(1);
                }
            }
        }
    }

    .popup {
        .popupMenu {
            position: relative;
            top: 50px;
            width: 100%;

            @include media-breakpoint-up(md) {
                position: static;
                top: auto;
                width: auto;
            }
        }
    }

    section#newsletterPopup {
        position: fixed;
        top: 0;
        left: 0;
        margin-left: -15px;
        margin-right: -15px;
        width: 100%;
        width: calc(100% + 30px);
        z-index: 100;

        .popup {
            position: absolute;

            .header {
                margin-bottom: 15px;
            }

            .content {
                @include media-breakpoint-up(md) {
                    display: flex;

                    align-content: center;
                    align-items: center;

                    &[data-simplebar] {
                        align-content: center;
                        align-items: center;
                    }
                }

                @media screen and (max-height: 700px) {
                    align-content: flex-start;
                    align-items: flex-start;

                    &[data-simplebar] {
                        align-content: flex-start;
                        align-items: flex-start;
                    }

                }

                h2 {
                    margin-bottom: 20px;
                }

                .intro {
                    color: $colorBlack;
                    font-size: 22px;
                    line-height: 24px;
                    margin-bottom: 34px;
                    font-weight: 700;
                    font-family: $fontTitle;

                    @include media-breakpoint-up(md) {
                        margin-bottom: 75px;
                    }
                }

                form {
                    margin-top: 0;
                    margin-bottom: 45px;

                    div.input {
                        position: relative;
                        margin-bottom: 23px;

                        input[type="email"], input[type="text"] {
                            height: 52px;
                            border-radius: 52px;
                            border: 1px solid $colorGrey;
                            font-family: $fontDefault;
                            font-size: 24px;
                            line-height: 32px;
                            color: $colorBlack;
                            padding: 10px 25px 13px;

                            // + label {
                            //     position: absolute;
                            //     top: 8px;
                            //     left: 25px;
                            //     font-family: $fontDefault;
                            //     font-size: 24px;
                            //     line-height: 32px;
                            //     color: $colorGreyLighter;
                            //     @include transition(transform color);
                            // }

                            // &:focus {
                            //     + label {
                            //         transform: translateY(-50px);
                            //         color: $colorBlack;
                            //     }
                            // }

                            &:placeholder {
                                color: $colorGreyLighter;
                                font-weight: 300;
                            }

                            &::placeholder {
                                color: $colorGreyLighter;
                                font-weight: 300;
                            }

                            &.error {
                                border-color: $colorRed;
                            }
                        }
                    }

                    a.button, button.button {
                        width: 100%;
                        cursor: pointer;
                    }

                    span.error {
                        padding-top: 5px;
                        color: $colorRed;
                        padding-left: 24px;
                        font-family: $fontDefault;
                        display: none;
                    }

                    input.error + span.error {
                        display: inline-block;
                    }
                }

                sub, form.newsletterForm + p {
                    display: block;
                    text-align: center;
                    color: $colorGreyLighter;
                    font-size: 16px;
                    line-height: 16px;

                    a {
                        color: inherit;
                        text-decoration: underline;
                    }
                }

                img {
                    border-radius: 50%;
                    margin-bottom: 30px;
                }

                .container {
                    display: none;

                    &.start {
                        @include animation(appearFromLeft);
                    }

                    &.success {
                        @include animation(appearFromRight);

                        img {
                            border-radius: 50%;
                            margin-bottom: 30px;
                            width: 200px;
                            max-width: 100%;

                            transform: scale(0);
                            animation-delay: 350ms;
                            @include animation(popupGif);
                        }

                        h2 {
                            color: $colorBlue;
                        }
                    }

                    &.loading {
                        @include animation(appearFromRight);

                        h2 {
                            color: $colorBlue;
                        }
                    }

                    &.error {
                        @include animation(appearFromRight);
                        
                        h2 {
                            color: $colorRed;
                        }
                    }
                }

                &.start {
                    .container.start {
                        display: block;
                    }
                }

                &.loading {
                    .container.loading {
                        display: block;
                    }
                }

                &.success {
                    .container.success {
                        display: block;
                    }
                }

                &.error {
                    .container.error {
                        display: block;
                    }
                }
            }
        }
    }

    footer {
        background: transparent;
        height: auto;
        min-height: 0;
        margin-bottom: 40px;
        margin-top: 20px;
        border-top: none;
        padding-bottom: 0;

        @include media-breakpoint-up(md) {
            margin-bottom: 80px;
        }

        @include transition(filter);
        transition-delay: $blurDelay;

        &.blurred {
            pointer-events: none;
            filter: blur($blurStrength);
        }

        ul.socialMediaList {
            display: inline-flex;
            flex-wrap: wrap;
            margin-top: -8px;
            justify-content: center;

            li {
                display: inline-block;
                margin-top: 8px;
                margin-left: 5px;
                margin-right: 5px;
                margin-bottom: 8px;

                @include media-breakpoint-up(sm) {
                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                a {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    width: 56px;
                    height: 56px;
                    border-radius: 56px;
                    background: $colorBlue;

                    @include transition(background);

                    svg use {
                        @include transition(fill);
                    }

                    @include active {
                        background: $colorWhite;

                        svg.sprite {
                            use {
                                fill: $colorBlue;
                            }
                        }
                    }
                }
            }
        }
    }
}