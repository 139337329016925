section.USPs {
    h2 {
        font-size: 36px;
        line-height: 36px;
        font-weight: 500;

        @include media-breakpoint-up(md) {
            text-align: center;
            font-size: 48px;
            line-height: 48px;
            font-weight: 800;
        }
    }

    .USPRow {
        .usp {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }

            h3 {
                font-size: 32px;
                line-height: 32px;

                @include media-breakpoint-up(md) {
                    text-align: right;
                }
            }
        }
    }
}

section#content section.USPs {
    h2 {
        margin-top: 0;
        margin-bottom: 40px;
        font-weight: 800;

        @include media-breakpoint-up(md) {
            margin-bottom: 45px;
        }
    }

    .USPRow {
        margin-bottom: 70px;

        .usp {
            h3 {
                margin-top: 0;
                margin-bottom: 10px;

                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                }
            }

            p {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    .USPFooter {
        span {
            display: block;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 24px;
            font-weight: 800;

            @include media-breakpoint-up(md) {
                text-align: right;
                margin-bottom: 0;
            }
        }

        a.button {
            font-size: 22px;
            font-weight: 600;
            padding-right: 60px;
        }
    }
}