body[class*="mystribe"] {
	aside {
		@include media-breakpoint-up(md) {
			position: sticky;
			top: $sizeMenuHeight;
			bottom: 30px;
			margin-top: 135px;

			@include transition(top);
		}
	}

	header.hidden + section#content aside {
		top: 30px;
	}

	form {
		.imageUpload {
			.input {
				flex: 0 0 auto;

				input[type="file"],
				input[type="checkbox"] {
					display: none;
				}

				input[type="checkbox"]:checked {
					~ label {
						display: none;
					}
				}


				label {
					padding: 13px 40px;
					border: 1px solid $colorGreyLighter;
					border-radius: 50px;
					color: $colorGreyLighter;
					font-size: 18px;
					line-height: 20px;
					cursor: pointer;
					display: inline-block;

					&.auto {
						width: auto;
					}

					@include active {
						background: #f8f8f4;
					}

					&.remove {
						border: none;

						@include active {
							background: none;
						}
					}
				}
			}

			.imageUploadContainer {
				display: flex;
				min-height: 175px;
				align-items: center;

				&.dragging {
					outline: 3px dashed $colorGreyLighter;
				}

				.imagePreview {
					position: relative;
					width: 210px;
					height: 175px;
					margin-right: 24px;
					cursor: pointer;
					flex: 0 0 auto;

					svg.svg {
						position: absolute;
						height: 0;
						width: 0;
					}

					.mask {
						display: flex;
						justify-content: center;
						align-items: center;
						min-height: 100px;
						clip-path: url(#striber-mask);
						height: 100%;
						background: #F8F8F4;

						span {
						}

						.image {
							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							width: 100%;
							background-size: cover;
							background-repeat: no-repeat;
							background-position: center;
						}
					}
				}
			}

			&.wide {
				.imageUploadContainer {
					flex-direction: column;
					align-items: flex-start;
					width: 100%;

					.imagePreview {
						width: 100%;
						height: 0;
						padding-bottom: 60%;
						margin-right: 0;
						margin-bottom: 24px;
						margin-top: 40px;
						border-radius: 24px;
						overflow: hidden;

						.mask {
							height: 100%;
							clip-path: none;
							position: absolute;
							width: 100%;

							.image {
								position: absolute;
								top: 0;
								left: 0;
								height: 100%;
								width: 100%;
								background-size: cover;
								background-repeat: no-repeat;
								background-position: center;
							}
						}
					}
				}

				.input {
					margin-top: 20px;
					display: inline-flex;
					flex-direction: column;
					align-items: center;
				}
			}

			&.centered {
				.input {
					display: flex;
					width: 100%;
				}
			}

			&.videosupport {
				.selection {
					display: flex;
					align-items: stretch;
					justify-content: flex-start;

					.options {
						margin-right: 24px;
						padding: 10px 0;

						> div {
							height: 50%;
							display: flex;
							align-items: center;

							input {
								display: none;

								+ label,
								+ label {
									position: relative;
									padding-left: 35px;
									cursor: pointer;
									display: block;
									line-height: 24px;
									@include transition(color);

									&:before,
									&:after {
										content: '';
										position: absolute;
										left: 0;
										top: 3px;
										width: 18px;
										height: 18px;
										border-radius: 20px;
									}

									&:before {
										opacity: 0;
										background-image: url('/img/icon_check_green.svg');
										background-position: center 30%;
										background-repeat: no-repeat;
										transform: scale(.4);
										@include transition(opacity transform);
									}

									&:after {
										border: 1px solid $colorGreyLighter;
										@include transition(border-color);
									}

									@include active {
										&:after {
											border-color: $colorBlack;
										}
									}
								}

								&:checked {

									+ label {
										color: $colorGreen;

										&:before {
											opacity: 1;
											transform: none;
										}

										&:after {
											border-color: $colorGreen;
										}
									}
								}
							}

							&:last-child {
								transform: translateY(24px);
							}
						}
					}

					.extra {
						display: flex;
						flex-direction: column;

						> div {
							height: 50%;
							display: flex;
							align-items: center;
						}

						input {
							display: inline-block;
							border: none;
						}

						label {
							display: inline-block;
							border: none;
							padding: 0;
							white-space: nowrap;
							margin-right: 12px;

							@include active {
								background: transparent;
							}
						}

						.input {
							display: flex;
						}

						> div:first-child {
							// hacky
							transform: translateY(5px);

							input {
								display: none;
							}

							label {
								display: block;
								padding: 12px 30px;
								border: 1px solid $colorGreyLighter;

								@include active {
									background: #f8f8f4;
								}
							}
						}

						> div:last-child {
							transform: translateY(22px);

							input {
								border-bottom: 1px solid $colorGreyLight;
							}
						}
					}
				}
			}

			&.circle {
				.imagePreview {
					width: 200px;
					height: 200px;
					max-height: 200px;
				}
			}
		}
	}

	.editorsContainer {
		.header {
			nav {
				.tabHeaders {
					margin-bottom: -1px;
					position: relative;
					z-index: 1;
					display: flex;
					justify-content: flex-end;

					li {
						&:not(:last-child) {
							margin-right: -1px;
						}

						button {
							padding: 10px 25px;
							border-top-left-radius: 8px;
							border-top-right-radius: 8px;
							border: 1px solid $colorGreyLight;
							// border-bottom: none;
							background: $colorWhite;
							font-size: 14px;
							line-height: 16px;
							color: $colorGreyLighter;

							@include transition(color);

							&[aria-selected="true"] {
								background: #F8F8F4;
								color: $colorBlack;
								border-bottom-color: #F8F8F4;
							}

							&[aria-selected="false"] {
								@include active {
									background: transparentize(#F8F8F4, .5);
								}
							}

							span {
								&.status {
								}
							}
						}
					}
				}
			}
		}

		.tabContents {
			background: #F8F8F4;
			padding: 8px;
			border: 1px solid $colorGreyLight;

			.tabContent {
				animation: none;

				> div {
					animation: none;
				}

				.ql-toolbar,
				.ql-container {
					background: $colorWhite;
				}

				.ql-toolbar {
					position: relative;
					z-index: 1;
				}

				.ql-editor {
					min-height: 230px;
				}

				input, textarea {
					background: $colorWhite;
				}
			}
		}
	}

	section#form {
		form {
			margin-top: 0;
		}
	}

	form {
		.simpleRow {
			&.input {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;

				@include media-breakpoint-up(md) {
					align-items: center;
					flex-direction: row;
				}

				label {
					width: 100%;
					color: $colorGrey;
					padding-left: 10px;

					@include media-breakpoint-up(md) {
						padding-left: 0;
						width: 22%;
						margin-right: 34px;
					}
				}

				input {
					border: none;
					border-radius: 0;
					border-bottom: 1px solid $colorGreyLight;
					padding-left: 10px;
					font-size: 18px;
					background: transparent;
					color: $colorBlack;

					@include media-breakpoint-up(md) {
						width: 60%;
					}
				}

				.field-validation-error {
					display: inline-block;
					color: $colorRed;
					padding-left: 10px;

					@include media-breakpoint-up(md) {
						margin-left: 22%;
						padding-left: 34px;
					}
				}
			}
		}
	}

	&[class*="mypage"],
	&[class*="myjournal"] {

		section#content {

			span.subtitle {
				font-size: 16px;
				line-height: 18px;
				display: inline-block;
				margin-bottom: 16px;
				font-style: normal;
			}
		}
	}

	&[class*="myprofile"],
	&[class*="newsletters"] {
		p.intro {
			font-size: 16px;
			line-height: 18px;
			color: $colorGreyLighter;
		}

		section#content {
			h2 {
				font-size: 32px;
				line-height: 32px;
			}
		}

		form {
			transform-origin: left;
			@include transition(transform);

			&.submitting {
				pointer-events: none;
				user-select: none;
				opacity: .5;
			}

			.imageUploadContainer {
				min-height: 0;

				.imagePreview {
					width: 67px;
					height: 67px;

					.mask {
						border-radius: 50%;
						width: 67px;
						height: 67px;
						min-height: 0;

						.image {
							width: 67px;
							height: 67px;
							border-radius: 50%;
						}
					}
				}

				.input {
					label {
						padding-left: 40px;
					}
				}
			}

			[type="submit"] {
				font-weight: bold;
				color: $colorWhite;
				background: $colorGreen;

				@include active {
					background: $colorGreyLight;
					color: $colorGreen;
				}

				&[disabled] {
					background: $colorGreyLight;

					@include active {
						color: $colorWhite;
					}
				}

				&.button-google {
					border-radius: 4px;
					background-color: white;
					text-decoration: none;
					display: flex;
					align-items: center;
					box-shadow: 1px 1px 3px $colorGreyLight;
					width:240px;
					@include transition(box-shadow);
				
					@include active() {
						box-shadow: 1px 1px 4px $colorGreyLighter;
					}
				
					svg {
						width: 46px;
						height: 46px;
					}
				
					span {
						font-size: 14px;
						color: rgba(0, 0, 0, 0.54);
						font-weight: 500;
						font-family: 'Roboto', sans-serif;
					}
				}
			}
		}
	}

	&[class*="myjournals"] {
		h1 + a.button {
			position: relative;
			margin-bottom: 20px;
			right: 0;
			padding: 10px 25px;

			@include media-breakpoint-up(md) {
				position: absolute;
				top: 40px;
				margin-bottom: 0;
				padding: 12px 30px;
			}

			span {
				transform: translateX(-5px);
			}
		}

		.sortOptions {
			display: flex;
			align-items: center;
			text-align: left;
			justify-content: flex-start;
			flex-wrap: wrap;
			margin-bottom: 20px;

			@include media-breakpoint-up(md) {
				justify-content: flex-end;
				text-align: right;
			}

			* {
				white-space: nowrap;
			}

			> span {
				margin-right: 8px;
			}

			.sortOption {
				margin-right: 12px;


				&:last-child {
					margin-right: 0;
				}

				input {
					display: none;
				}

				label {
					color: $colorGreyLighter;
					border-bottom: 1px solid transparent;
					padding-bottom: 5px;
					cursor: pointer;
					line-height: 30px;

					@include transition(border-color);
				}

				input:checked + label {
					border-bottom-color: $colorGreyLighter;
					cursor: default;
				}
			}
		}

		ul.pagedContainer {
			border-top: 1px solid $colorGreyLight;
			margin-bottom: 56px;

			li {
				border-bottom: 1px solid $colorGreyLight;

				.journal {
					display: flex;
					align-items: center;
					padding-top: 18px;
					padding-bottom: 18px;

					.image {
						width: 67px;
						max-width: 67px;
						height: 67px;
						margin-bottom: 0;
						margin-right: 15px;

						a {
							display: inline-block;
							width: 100%;
						}

						img {
							width: 100%;
							height: 100%;
						}

						.icon {
							display: none; // for now
						}
					}

					.contents {
						.title {
							font-weight: normal;
							font-size: 18px;
							line-height: 24px;
						}

						.info {
							margin-bottom: 0;
						}

						address {
							margin-right: 8px;
						}

						.journalLikes {
							margin-right: 0;
						}
					}
				}
			}

			+ .pagerContainer {
				margin-bottom: 56px;
			}
		}
	}

	&[class*="myorders"] {
		ol.orderList {
			> li {
				padding: 30px 0;
				border-bottom: 1px solid $colorGreyLight;

				.orderDate {
					font-size: 18px;
					color: $colorGreen;
					margin-bottom: 5px;
				}

				.orderStatus {
					font-size: 14px;
					color: $colorGrey;
				}

				.orderProducts {
					ul {
						li {
							margin-bottom: 10px;
							font-size: 14px;
							color: $colorGrey;
							line-height: 18px;
						}
					}
				}

				.lastCol {
					text-align: right;

					.orderTotal {
						font-size: 18px;
						margin-bottom: 5px;
					}

					.orderVatTotal {
						font-size: 14px;
						color: $colorGrey;
					}

					a.orderDetailLink {
						display: block;
						margin-top: 20px;
						font-size: 14px;
					}
				}
			}
		}
	}

	&[class*="myorderdetail"] {
		a.back {
			text-decoration: none;
			color: $colorGreyLighter;
			display: inline-flex;
			align-items: center;
			min-height: 1px;
			padding: 5px 0;
			margin-bottom: 15px;

			@include underline_closed($colorGreyLighter, 1px);

			svg.sprite {
				margin-right: 10px;
				@include transition(transform);
			}

			@include active {
				svg.sprite {
					transform: translateX(-5px);
				}
			}
		}

		ul.basketProducts {
			li {
				padding: 24px 0;
				border-bottom: 1px solid $colorGreyLight;

				&:first-of-type {
					border-top: 1px solid $colorGreyLight;
				}

				.row {
					align-items: center;
				}
			}
		}

		.imageColumn {
			.image {
				position: relative;
				height: 0;
				padding-bottom: 100%;
				border-radius: 50%;
				overflow: hidden;

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}

		.infoColumn {
			a.title {
				display: inline-block;
				font-size: 24px;
				line-height: 24px;
				margin-bottom: 7px;
				color: $colorBlack;
				text-decoration: none;

				@include transition(color);

				@include active {
					color: $colorBlue;
				}
			}

			.variant {
				font-size: 16px;
				line-height: 18px;
				margin-bottom: 26px;

				span {
					padding-left: 16px;

					&:first-child {
						padding-left: 0;
					}
				}
			}

			.price {
				display: block;
				font-size: 18px;
				line-height: 24px;
				color: $colorGreyLighter;

				@include media-breakpoint-up(md) {
					display: inline-block;
				}

				.original {
					padding-left: 16px;
					color: $colorRed;
					text-decoration: line-through;
				}
			}

			.seller {
				font-size: 16px;
				line-height: 18px;
				color: $colorGreyLighter;

				@include media-breakpoint-up(md) {
					margin-left: 17px;
				}

				&:before {
					display: none;
					margin-right: 17px;
					content: '';
					top: -3px;
					height: 1px;
					width: 48px;
					background: $colorGreyLighter;
					position: relative;


					@include media-breakpoint-up(md) {
						display: inline-block;
					}
				}
			}
		}

		.quantityColumn {
			input {
				text-align: center;
				border: none;
				background: transparent;
				border-radius: 0;
				border-bottom: 1px solid $colorGreyLight;

				@include active {
					border-bottom-color: $colorBlack;
				}
			}
		}

		.priceColumn {
			margin-top: 32px;

			@include media-breakpoint-up(md) {
				margin-top: 0;
			}

			.totalPrice {
				text-align: right;
				font-size: 24px;
				line-height: 24px;
				margin-bottom: 17px;
			}

			.VAT {
				text-align: right;
				font-size: 16px;
				line-height: 24px;
				color: $colorGreyLighter;
			}
		}

		.totalsRow {
			margin-bottom: 20px;
			margin-top:20px;

			@include media-breakpoint-up(md) {
				margin-bottom: 96px;
			}

			table {
				margin-left: auto;

				@include media-breakpoint-down(xs) {
					display: flex;
					flex-direction: column;
				}

				tr {
					@include media-breakpoint-down(xs) {
						display: flex;
						flex-direction: column;
					}
				}

				td {
					font-size: 18px;
					line-height: 24px;
					color: $colorGreyLighter;
					text-align: right;

					@include media-breakpoint-down(xs) {
						display: block;
						text-align: left;
					}

					&:first-child {
						font-size: 16px;

						@include media-breakpoint-down(xs) {
							color: $colorBlack;
						}
					}

					&:last-child {
						padding-left: 67px;
						white-space: nowrap;

						@include media-breakpoint-down(xs) {
							padding-left: 0;
							margin-bottom: 16px;
						}
					}
				}

				.subtotal td:last-child {
					font-size: 24px;
				}

				.shipping {
					td:first-child {
						position: relative;
					}

					i.info {
						position: absolute;
						right: -24px;
						top: 4px;

						@include media-breakpoint-down(xs) {
							right: -16px;
						}
					}
				}

				.total td {
					padding-top: 37px;
					color: $colorBlack;

					@include media-breakpoint-down(xs) {
						padding-top: 0;
					}

					&:first-child {
						font-weight: 700;
					}

					&:last-child {
						color: $colorGreen;
						font-size: 24px;
					}
				}
			}
		}

		.addressRow {
			margin-bottom: 32px;

			@include media-breakpoint-up(md) {
				margin-bottom: 64px;
			}

			span {
				display: block;
				font-size: 18px;
				line-height: 32px;
			}

			.billingColumn {
				margin-bottom: 32px;

				@include media-breakpoint-up(md) {
					margin-bottom: 0;
				}
			}
		}
	}

	section#content {
		h1 {
			margin-top: 20px;
			margin-bottom: 14px;

			@include media-breakpoint-up(md) {
				margin-top: 40px;
				margin-bottom: 40px;
			}
		}

		h2 {
			margin-top: 20px;

			@include media-breakpoint-up(md) {
				margin-top: 40px;
			}
		}

		p.intro {
			font-size: 18px;
			line-height: 32px;
			margin-bottom: 12px;

			@include media-breakpoint-up(md) {
				margin-bottom: 64px;
			}
		}

		h2 {
			font-size: 24px;
			line-height: 28px;
			margin-bottom: 11px;
		}
	}

	ul.accordeon,
	ol.accordeon {
		border-top: none;

		li {
			border-bottom-width: 1px;

			&:last-child {
				border-bottom: none;
			}

			.header {
				padding-top: 10px;
				padding-bottom: 10px;

				.headerContent {
					h3 {
						display: inline-block;

						&:after {
							content: '>';
							content: '\25bc';
							display: inline-block;
							position: relative;
							color: $colorGrey;
							transform: scale(.5) translateY(2px);
							@include transition(transform opacity);
						}
					}
				}
			}

			.content {
				overflow-x: hidden;

				form {
					margin-top: 10px;
				}
			}

			&.active {
				.header {
					.headerContent {
						h3 {
							color: $colorBlack;

							&:after {
								transform: scale(0.5) translateY(2px) scaleY(0);
								opacity: 0;
							}
						}
					}
				}
			}
		}
	}

	fieldset.login,
	fieldset.reset,
	fieldset.signup {
		button.button {
			background: $colorGreyLight;
			color: $colorWhite;
			font-weight: bold;
			width: 240px;
			padding-top: 15px;
			padding-bottom: 15px;
			max-width: 100%;
			text-align: center;
			display: inline-block;

			@include active {
				color: $colorBlack;
			}

			&.green {
				background: $colorGreen;

				@include active {
					background: $colorGreyLight;
					color: $colorGreen
				}
			}
		}

		.simpleRow {
			input[type="checkbox"] {
				& ~ label {
					color: $colorGrey;
					font-size: 16px;

					&:before {
						filter: grayscale(1);
					}
				}

				&:checked {
					& ~ label {
						&:after {
							border-color: $colorGreyLighter !important;
						}
					}
				}
			}
		}
	}

	fieldset.reset {
		button.button {
			margin-top: 15px;
		}
	}

	.actionsRow {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: 48px;
		padding-top: 24px;
		border-top: 1px solid $colorGreyLight;
		margin-top: -10px;

		@include media-breakpoint-up(md) {
			flex-wrap: nowrap;
		}

		button,
		a {
			margin-top: 10px;

			&.saveButton {
				margin-right: 15px;
				font-weight: bold;
			}

			&.previewButton {
				margin-right: 15px;
				color: $colorBlack;
				padding-left: 56px;
				padding-right: 56px;
				color: $colorGreyLighter;
				@include transition(color border-color);

				@include active {
					color: $colorBlack;
					border-color: $colorGrey;
				}
			}

			&.cancelButton {
				border: none;
				background: $colorWhite;
				padding-left: 0;
				padding-right: 0;
				margin-right: 15px;
				color: $colorGreyLighter;

				@include underline_closed($colorGreyLighter);
			}

			&.publishButton {
				font-weight: bold;
				margin-left: 0;

				@include media-breakpoint-up(md) {
					margin-right: 0;
					margin-left: auto;
				}
			}
		}
	}
}

.container.login {
	.separator {
		margin-top: 90px;
		margin-bottom: 16px;
		width: 100%;
		display: inline-block;
		pointer-events: none;
		height: 1px;
		background: $colorGreyLight;
	}

	button.button {
		margin-bottom: 32px;
	}

	.forgot {
		display: inline-block;
		color: $colorBlue;
		font-size: 14px;

		@include underline_closed($colorBlue);
	}
}

.loginFooter {
	font-size: 18px;
	line-height: 32px;
	margin-bottom: 45px;

	@include media-breakpoint-up(md) {
		margin-bottom: 90px;
	}

	a {
		color: $colorBlue;
		@include underline_closed($colorBlue);
	}

	.col,
	[class*=col-] {
		margin-bottom: 32px;

		&:last-child {
			margin-bottom: 0;
		}

		@include media-breakpoint-up(md) {
			margin-bottom: 0;
		}
	}
}
