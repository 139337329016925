body.shopcheckoutoverviewpage {
    section#content {
        h1 {
            color: $colorBlack;
            margin-top: 0;
            margin-bottom: 43px;
            font-size: 32px;
            line-height: 32px;
        }

        h2 {
            font-size: 24px;
            line-height: 32px;
            margin-top: 0;
            margin-bottom: 38px;
        }
    }

    .progressRow {
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
            margin-bottom: 62px;
        }

        .progressStep {
            position: relative;
            display: block;
            margin-bottom: 10px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }

            span {
                display: inline-block;
                font-size: 18px;
                line-height: 21px;
                margin-bottom: 16px;
                color: $colorGrey;
            }

            &:before, &:after {
                position: absolute;
                height: 1px;
                width: 100%;
                bottom: 0;
                content: '';
                display: block;
                transform-origin: left;
            }

            &:before {
                background: $colorGreyLight;
            }

            &:after {
                background: $colorYellow;
                transform: scaleX(0);
            }

            &.active {
                span {
                    color: $colorBlack;
                    font-weight: 700;
                }

                &:after {
                    @include animation(activeProgressStep);
                }
            }

            &.finished {
                span {
                    color: $colorBlack;
                }
            }
        }
    }

    .overviewRow {
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
            margin-bottom: 64px;
        }
    }

    .overview {
        li {
            border-top: 1px solid $colorGreyLight;
            padding-top: 24px;
            padding-bottom: 24px;

            &:last-of-type {
                border-bottom: 1px solid $colorGreyLight;
            }
        }

        .quantityColumn {
            text-align: center;
        }

        .imageColumn {
            .image {
                position: relative;
                height: 0;
                padding-bottom: 100%;
                border-radius: 300px;
                overflow: hidden;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .infoColumn {
            .name {
                display: inline-block;
                font-size: 24px;
                line-height: 24px;
                margin-bottom: 7px;
            }

            .variant {
                margin-bottom: 4px;
                
                span {
                    font-size: 16px;
                    line-height: 18px;
                }
            }

            .seller {
                span {
                    font-size: 16px;
                    line-height: 18px;
                    color: $colorGrey;
                }
            }
        }

        .priceColumn {
            text-align: right;
            font-size: 24px;
            line-height: 24px;
        }
    }
    
    .totalsRow {
        margin-bottom: 60px;

        @include media-breakpoint-up(md) {
            margin-bottom: 96px;
        }

        table {
            margin-left: auto;

            @include media-breakpoint-down(xs) {
                display: flex;
                flex-direction: column;
            }

            tr {
                @include media-breakpoint-down(xs) {
                    display: flex;
                    flex-direction: column;
                }
            }

            td {
                font-size: 18px;
                line-height: 24px;
                color: $colorGreyLighter;
                text-align: right;

                @include media-breakpoint-down(xs) {
                    display: block;
                    text-align: left;
                }

                &:first-child {
                    font-size: 16px;

                    @include media-breakpoint-down(xs) {
                        color: $colorBlack;
                    }
                }

                &:last-child {
                    padding-left: 67px;
                    white-space: nowrap;

                    @include media-breakpoint-down(xs) {
                        padding-left: 0;
                        margin-bottom: 16px;
                    }
                }
            }

            .subtotal td:last-child {
                font-size: 24px;
            }

            .shipping {
                td:first-child {
                    position: relative;
                }

                i.info {
                    position: absolute;
                    right: -24px;
                    top: 4px;

                    @include media-breakpoint-down(xs) {
                        right: -16px;
                    }
                }
            }

            .total td {
                padding-top: 37px;
                color: $colorBlack;

                @include media-breakpoint-down(xs) {
                    padding-top: 0;
                }

                &:first-child {
                    font-weight: 700;
                }

                &:last-child {
                    color: $colorGreen;
                    font-size: 24px;
                }
            }
        }
    }

    .addressRow {
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
            margin-bottom: 64px;
        }

        span {
            display: block;
            font-size: 18px;
            line-height: 32px;
        }

        .billingColumn {
            margin-bottom: 32px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
    }

    .stepsRow {
        padding-top: 32px;
        border-top: 1px solid $colorGreyLight;
        align-items: center;
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
            margin-bottom: 64px;
        }

        form {
            margin: 0;
        }
    }

    a.back {
        text-decoration: none;
        color: $colorGreyLighter;
        display: inline-flex;
        align-items: center;
        min-height: 1px;
        padding: 5px 0;

        @include underline_closed($colorGreyLighter, 1px);

        svg.sprite {
            margin-right: 10px;
            @include transition(transform);
        }

        @include active {
            svg.sprite {
                transform: translateX(-5px);
            }
        }
    }

    .stepForwardColumn {
        .button {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-weight: 600;

            margin-bottom: 32px;
            padding-top: 12px;
            padding-bottom: 12px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
    }

    .disclaimerRow {
        span {
            display: inline-block;
            padding-left: 26px;
            font-size: 16px;
            line-height: 18px;
            color: $colorGrey;
            margin-bottom: 62px;

            @include media-breakpoint-up(md) {
                margin-bottom: 94px;
            }

            a {
                text-decoration: none;
                @include transition(background);
                color: $colorBlue;
            
                @include active {
                    background: transparentize($colorBlue, .8);
                }
            }
        }

        i.info {
            position: absolute;
            top: 1px;
            left: 16px;

            span {
                margin: 0;
                padding: 0;
                color: $colorWhite;
                font-size: 14px;
            }
        }

        p {
            margin: 0;
            display: inline;
        }
    }
}