section.alternating {
    .block {
        margin-bottom: 70px;

        @include media-breakpoint-up(md) {
            margin-bottom: 90px;
        }
        
        &:last-child {
            margin-bottom: 0;
        }

        > .col, > [class*="col-"] {
            &:first-child:not(:last-child) {
                margin-bottom: 30px;

                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                }
            }
        }
    }

    h2 {
        font-size: 48px;
        line-height: 40px;
    }

    p {
        margin-top: 0;
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 32px;

        a {
            color: $colorBlue;

            @include underline_closed($colorBlue);
        }
    }

    p + a {
        color: $colorRed;
        font-size: 22px;
        line-height: 32px;
        font-weight: 600;
        @include underline($colorRed);
    }
}

section#content section.alternatingBlocks {
    h2 {
        margin-top: 0;
        margin-bottom: 24px;
    }
}