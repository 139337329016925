@import 'reset';
@import 'vendors';
@import 'settings';
@import 'components';
@import 'pages';

* {
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

// Intersection fading
.toFade {
    opacity: 0;
    // transform: translate3d(0, 0, 0);
    // transform: scale(.8);

    &.fadingIn {
        opacity: 1;
        transform: none;
        @include transition(opacity transform, $animationDuration);
    }
}

body {
    font-size: 18px;
    font-family: $fontDefault;
    color: $colorBlack;

    // margin-top: $sizeMenuHeightMobile;

    @include media-breakpoint-up(md) {
        // margin-top: $sizeMenuHeight;
    }

    &.preventAnimations {
        *:not(.blob) {
            animation: none !important;
            transition: none !important;
        }
    }
}

h1,
h2,
h3,
h4,
h5 {
    font-family: $fontTitle;
    // text-transform: lowercase;

    +p,
    +a {
        margin-top: 10px;
    }
}

h1 {
    font-size: 38px;
    font-weight: 700;
    line-height: 38px;
    color: $colorBlue;

    @include media-breakpoint-up(lg) {
        font-size: 54px;
        line-height: 46px;
    }
}

h2 {
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;

    @include media-breakpoint-up(md) {
        font-size: 40px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 48px;
        line-height: 40px;
    }
}

h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;

    @include media-breakpoint-up(md) {
        font-size: 32px;
        line-height: 40px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 40px;
    }
}

h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;

    @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 32px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 32px;
    }
}

h5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;

    @include media-breakpoint-up(md) {
        line-height: 32px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 24px;
    }
}

p {
    line-height: 1.4em;
    margin: 25px 0;
}

img {
    max-width: 100%;
}

.positiveIndex{
    position: relative;
    z-index: 1;
}

header,
section,
footer {
    padding-left: 15px;
    padding-right: 15px;

    @include media-breakpoint-up(md) {
        padding-left: 75px;
        padding-right: 75px;
    }

    @include media-breakpoint-up(lg) {
        padding-left: 50px;
        padding-right: 50px;
    }

    @include media-breakpoint-up(xl) {
        padding-left: 50px;
        padding-right: 50px;
    }

    section {
        padding-left: 0;
        padding-right: 0;

        @include media-breakpoint-up(md) {
            padding-left: 0;
            	padding-right: 0;
        }

        @include media-breakpoint-up(lg) {
            padding-left: 0;
            padding-right: 0;
        }

        @include media-breakpoint-up(xl) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

section#content {
    margin-top: $sizeMenuHeightMobile;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
        margin-top: $sizeMenuHeight;
    }

    .titleColumn {
        margin-bottom: 45px;

        @include media-breakpoint-up(md) {
            margin-bottom: 45px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 40px;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 45px;
        }
    }

    h1 {
        margin-top: 35px;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            margin-top: 95px;
            margin-bottom: 15px;
        }
    }

    h2 {
        margin-top: 48px;
        margin-bottom: 42px;
    }

    h3 {
        margin-top: 55px;
        margin-bottom: 32px;
    }

    h4,
    h5 {
        margin-top: 48px;
        margin-bottom: 32px;
    }

    h2, h3, h4, h5 {
        &:first-child {
            margin-top: 0;
        }
    }

    .subtitle {
        font-size: 18px;
        line-height: 24px;
        color: $colorGreyLighter;
        font-style: italic;
        font-weight: 300;

        @include media-breakpoint-up(md) {
            line-height: 32px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 24px;
        }

        @include media-breakpoint-up(xl) {
            line-height: 24px;
        }
    }

    .umb-grid {

        ol,
        ul {
            margin: 20px 0;

            li {
                position: relative;
                margin-left: 25px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        *::selection {
            background: $colorGreyLight;
        }

        ul {
            padding-left: 20px;

            li {
                &:before {
                    content: '';
                    display: inline-block;
                    position: absolute;

                    left: -34px;
                    top: 10px;
                    width: 24px;
                    height: 2px;
                    background: $colorBlue;
                }
            }
        }

        ol {
            padding-left: 20px;
            counter-set: list;

            li {
                counter-increment: list;

                &:before {
                    position: absolute;
                    right: 110%;
                    right: calc(100% + 10px);
                    width: 30px;
                    text-align: right;
                    content: counter(list) '.';
                    font-weight: 500;
                    color: $colorBlue;
                }

                ol {
                    counter-set: listlist;

                    li {
                        counter-increment: listlist;

                        &:before {
                            content: counter(listlist) '.';
                        }
                    }
                }
            }
        }

        h2,
        h3,
        h4,
        h5 {

            +ol,
            +ul {
                margin-top: 10px;
            }
        }

        a {
            color: $colorPurple;
            text-decoration: none;
            border-bottom: 2px solid $colorPurple;
            padding-left: 2px;
            padding-right: 2px;

            @include transition(background);

            @include active {
                background: transparentize($colorPurple, .9);
            }
        }

        img {
            display: block;
            margin-bottom: 20px;
            border-radius: 32px;
            margin-top: 48px;
            margin-bottom: 48px;
            

            &[height][width] {
                // If there's a set width and height, don't force-scale
                max-width:100%;
                height:auto;
            }
            &:not([width][height]){
                width: 100%;
            }
        }

        blockquote {
            margin-top: 45px;
            margin-bottom: 45px;

            width: 100%;

            // @include media-breakpoint-up(md) {
            //     margin-left: 10%;
            //     width: 80%;
            // }

            // @include media-breakpoint-up(lg) {
            //     margin-left: 15%;
            //     width: 70%;
            // }
        }

        p.intro {
            font-size: 18px;
            line-height: 32px;
            font-weight: 300;

            &:first-child {
                margin-top: 0;
            }

            @include media-breakpoint-up(md) {
                font-size: 24px;
                line-height: 40px;
                margin-bottom: 50px;
            }
        }

        p {
            font-weight: 300;
        }

        hr {
            width: 100%;
            margin-top: 50px;
            margin-bottom: 50px;
            height: 2px;
            background: $colorGreyLight;
            border: none;
            border-radius: 5px;

            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }
    }

    div.videoContainer {
        position: relative;
        height: 0;
        width: 100%;
        padding-bottom: 56.25%;
        margin-bottom: 45px;
        border-radius: 32px;

        div.video {
            position: absolute;
            width: 100%;
            height: 100%;

            div.videoPoster {
                position: absolute;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    border-radius: 32px;
                    object-fit: cover;
                }

                a {
                    position: absolute;
                    width: 105px;
                    height: 105px;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    background-color: $colorRed;
                    border-radius: 50%;
                    cursor: pointer;
                    display: block;
                    border-bottom: none;
                    opacity: 0.75;
                    @include transition();

                    svg {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        width: 56px;
                        max-height: 56px;
                        transform: scale(1.5);
                    }

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            iframe {
                border-radius: 32px;
            }
        }

        &.notAccepted {
            border: 1px solid #e0e0e0;
            background: #e0e0e0;

            .content {

                border-radius: 4px;
                text-align: center;
                padding: 20px;
                position: absolute;
                height: 100%;
                width: 100%;
                overflow: auto;

                .title {
                    font-weight: 300;
                    margin-top: 0;
                    font-size: 1.2em;
                    font-family: $fontTitle;
                    color: $colorBlue;

                    @include media-breakpoint-up(md) {
                        font-size: 2em;
                    }
                }
            }
        }
    }
}

ul.tags {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -8px;
    margin-left: -8px;

    li {
        margin-top: 8px + 8px;
        margin-left: 8px;

        a {
            font-size: 16px;
            text-decoration: none;
            line-height: 18px;
            color: $colorGreyLighter;
            background: #F8F8F4;
            padding: 5px 10px;
            border-radius: 8px;

            @include transition(background);

            @include active {
                background: darken(#F8F8F4, 10);
            }
        }
    }
}

ul.partners {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -20px;
    margin-left: -10px;
    margin-right: -10px;
    // margin-bottom: -20px;
    justify-content: center;
    align-items: center;

    li {
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;

        a {
            img {
                height: 130px;
                min-width: 1px;
            }
        }
    }
}

address {
    font-weight: bold;
    opacity: .4;
}

blockquote {
    text-align: center;
    font-size: 40px;
    line-break: 48px;
    font-weight: 700;

    color: $colorBlue;
    font-family: $fontTitle;

    &:before,
    &:after {
        content: '"';
    }

    @include media-breakpoint-up(md) {
        font-size: 48px;
    }
}

section.parallaxBackground {
    position: relative;
    overflow: visible;

    width: calc(100% + 30px);
    margin-left: -15px;

    @include media-breakpoint-up(md) {
        width: calc(100% + 100px);
        margin-left: -50px;
    }

    > section {
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-up(md) {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    .background  {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;

        .layer {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: url('/img/bg_hero_shapes_yellow.svg');
            // background-attachment: fixed;
            background-size: 200%;
            opacity: 0;
            top: -10%;
            left: -10%;
            height: 120%;
            // height: 100%;
            width: 120%;
            // width: 100%;
            @include media-breakpoint-up(md) {
                background-size: 150%;
            }

            @include media-breakpoint-up(lg) {
                background-size: 100%;
            }

    
            // .ie & {
            //     // background-attachment: scroll;
            // }
        }
    }
}

i.info {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background: $colorGreyLighter;
    color: $colorGreyLight;
    font-size: 12px;
    border-radius: 8px;
    font-family: 'Georgia', serif;
    user-select: none;
    cursor: help;
}

.text-align-center {
    text-align: center;
}

.partlyHidden {
    overflow-y: hidden;
    position: relative;

    &:after {
        position: absolute;
        bottom: 0;
        height: 25px;
        width: 100%;
        left: 0;
        content: '';

        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), $colorWhite);
    }
}

.showMoreLink {
    display: inline-block;
    padding: 5px 0;
    color: $colorGrey;
    cursor: pointer;

    @include underline_closed($colorGrey, 1px);

    &:before {
        content: '\25bc';
        display: inline-block;
        transform: scale(.6);
        position: absolute;
        right: -18px;
    }
}