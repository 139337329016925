.toast {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 60px;
    z-index: 1000;

    background: #c1c1bd;
    
    @include animation(toastAppear);
    @include transition(transform);
    animation-fill-mode: none;

    &.disappearing {
        transform: translateY(-100%);
    }

    &.success {
        color: #0E9201;
        background: #D0F2CD;

        a {
            color: #0d6605;
        }
    }

    &.error {
        color: #8E113B;
        background: #FCCDDD;

        svg.sprite {
            use {
                fill: #8e113b;
            }
        }

        a {
            color: #640b28;
        }
    }

    &.warning {
        color: #513e00;
        background: $colorYellow;

        svg.sprite {
            use {
                fill: #513e00;
            }
        }

        a {
            color: #42350a;
        }
    }

    .container {
        height: 100%;

        .row {
            height: 100%;
            align-items: center;

            .content {
                padding: 10px 0;
                display: inline-flex;
                align-items: center;
                min-height: 1px;

                svg.sprite {
                    flex: 0 0 auto;
                    margin-right: 10px;
                }

                span {
                    font-size: 18px;
                    line-height: 21px;
                }
            }
        }
    }
}