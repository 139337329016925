body.shopcategorypage {

    .categoryHeaderContainer {
        margin-top: 200px;
        margin-bottom: 76px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }

        .categoryHeader {
            background: #F8F8F4;
            border-radius: 16px;


            .imageColumn {
                position: relative;
                display: flex;
                justify-content: center;
            }

            .image {
                margin-top: -100px;

                @include media-breakpoint-up(md) {
                    position: absolute;
                    bottom: 0;
                    height: 174px;
                    width: 174px;
                    transform: translateY(45%);
                }

                img {
                    height: 174px;
                    width: 174px;
                    border-radius: 50%;
                }
            }

            h1 {
                font-size: 48px;
                line-height: 40px;
                font-weight: 700;
                color: $colorBlack;
                text-align: center;

                @include media-breakpoint-up(md) {
                    text-align: left;
                }
            }
        }
    }

    section#content h1 {
        margin: 36px 0;
    }

    .sortOptions {
        display: flex;
        align-items: center;
        text-align: right;
        justify-content: flex-end;
        flex-wrap: wrap;

        margin-bottom: 20px;

        * {
            white-space: nowrap;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }

        >span {
            margin-right: 8px;
        }

        .sortOption {
            margin-right: 12px;


            &:last-child {
                margin-right: 0;
            }

            input {
                display: none;
            }

            label {
                color: $colorGreyLighter;
                border-bottom: 1px solid transparent;
                padding-bottom: 5px;
                cursor: pointer;
                line-height: 30px;

                @include transition(border-color);
            }

            input:checked+label {
                border-bottom-color: $colorGreyLighter;
                cursor: default;
            }
        }
    }

    .openFilters {
        display: inline-block;
        width: 100%;
        text-align: center;
    }

    .productsContainer {
        margin-top: 50px;
        margin-bottom: 50px;

        @include media-breakpoint-up(md) {
            margin-bottom: 50px;
        }

        ul {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;

            margin-top: -24px;
            margin-left: -10px; // Half of gutter
            margin-right: -10px;

            @include media-breakpoint-up(xl) {
                margin-left: -20px;
                margin-right: -20px;
            }

            li {
                flex: 0 0 auto;
                flex-basis: 100%;
                max-width: 100%;
                padding-left: 10px;
                padding-right: 10px;
                margin-top: 24px;

                @include media-breakpoint-up(sm) {
                    flex-basis: 50%;
                    max-width: 50%;
                }

                @include media-breakpoint-up(lg) {
                    flex-basis: 33.33%;
                    max-width: 33.33%;
                }

                @include media-breakpoint-up(xl) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }

        article {
            .image {
                position: relative;
                height: 0;
                padding-bottom: 100%;

                img {
                    top: 0;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    max-height: 100%;
                }
            }
        }

        ~.pager {
            margin-bottom: 20px;

            @include media-breakpoint-up(md) {
                margin-bottom: 50px;
            }
        }
    }

    section#content {
        article {
            .image {
                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .contents {
                >a.title {
                    font-size: 24px;
                    line-height: 24px;
                    font-weight: 500;

                    min-height: 24px * 2;

                    @include transition(color);

                    @include active {
                        color: $colorPurple;
                    }
                }
            }
        }
    }
}