body.projectdetailpage {
    h1 {
        color: $colorBlack;
    }

    .striberSmall {
        margin-bottom: 32px;

        img {
            margin-right: 11px;
        }
    }

    .baseRow {
        margin-bottom: 64px;

        @include media-breakpoint-up(md) {
            margin-bottom: 132px;
        }
    }


    a.back {
        text-decoration: none;
        color: $colorGreyLighter;
        display: inline-flex;
        align-items: center;
        min-height: 1px;
        padding: 5px 0;
        margin-bottom: 30px;

        @include media-breakpoint-up(md) {
            margin-bottom: 15px;
        }

        @include underline_closed($colorGreyLighter, 1px);

        svg.sprite {
            margin-right: 10px;
            @include transition(transform);
        }

        @include active {
            svg.sprite {
                transform: translateX(-5px);
            }
        }
    }

    .imagesColumn {
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    .projectImageSliderMain,
    .projectImagesSliderSmall {
        position: relative;

        .previousContainer,
        .nextContainer {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            z-index: 1;

            a {
                display: inline-block;
                background: none;
                border: none;
                width: 16px + 10px;
                height: 28px + 10px;
                padding: 5px;
                cursor: pointer;
                @include transition(opacity);

                @include media-breakpoint-up(md) {
                    width: 20px + 20px;
                    height: 36px + 20px;
                    padding: 10px;
                }

                svg.sprite {
                    display: inline-block;
                    width: 16px;
                    max-height: 28px;
                    @include transition(transform);

                    @include media-breakpoint-up(md) {
                        width: 20px;
                        max-height: 36px;
                    }

                    use {
                        stroke: $colorGreyLight;
                    }
                }
            }
        }

        .previousContainer {
            left: 0;

            @include media-breakpoint-up(md) {
                left: 5%;
            }

            a {
                &:hover {
                    svg.sprite {
                        transform: translateX(-5px);
                    }
                }
            }
        }

        .nextContainer {
            right: 0;

            @include media-breakpoint-up(md) {
                right: 5%;
            }

            a {
                &:hover {
                    svg.sprite {
                        transform: translateX(5px) scaleX(-1);
                    }
                }
            }
        }
    }

    .projectImageSliderMain {
        margin-bottom: 17px;

        .previousContainer,
        .nextContainer {
            a {
                width: 16px + 10px;
                height: 28px + 10px;
                padding: 5px;

                @include media-breakpoint-up(md) {
                    width: 20px + 20px;
                    height: 36px + 20px;
                    padding: 10px;
                }

                svg.sprite {
                    width: 16px;
                    max-height: 28px;

                    @include media-breakpoint-up(md) {
                        width: 20px;
                        max-height: 36px;
                    }
                }
            }
        }

        li {
            .image {
                position: relative;
                height: 0;
                padding-bottom: 56%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 32px;
                    object-fit: cover;
                }
            }
        }
    }

    .projectImagesSliderSmall {
        padding-left: 10%;
        padding-right: 10%;

        .previousContainer,
        .nextContainer {
            a {
                width: 12px + 10px;
                height: 20px + 10px;
                padding: 5px;

                @include media-breakpoint-up(md) {
                    width: 12px + 20px;
                    height: 20px + 20px;
                    padding: 10px;
                }

                svg.sprite {
                    width: 12px;
                    max-height: 20px;

                    @include media-breakpoint-up(md) {
                        width: 12px;
                        max-height: 20px;
                    }
                }
            }
        }

        li {
            padding: 0 15%;

            .image {
                position: relative;
                height: 0;
                padding-bottom: 100%;

                &:hover {
                    svg {
                        opacity: 1;
                    }
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }

                svg {
                    background-color: $colorRed;
                    border-radius: 50%;
                    opacity: 0.75;
                    @include transition();
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                }
            }
        }

        .slick-current {
            li {
                .image {
                    img {
                        border: 1px solid $colorGrey;
                    }
                }
            }
        }
    }

    .striberSmall {
        img {
            width: 67px;
            height: 67px;

            &.circle {
                border-radius: 50%;
            }
        }

        span {
            font-size: 18px;
            color: $colorGreyLighter;
        }
    }

    .tabs {
        ul.tabHeaders {
            display: none;
            justify-content: flex-start;
            margin-bottom: 35px;
            margin-left: -16px;
            margin-right: -16px;

            @include media-breakpoint-up(md) {
                display: flex;
                font-size: 20px;
            }

            li {
                display: inline-block;
                padding: 0;
                margin: 0 16px;

                button {
                    display: inline-block;
                    background: transparent;
                    border: none;
                    padding: 5px 0;
                    position: relative;
                    text-decoration: none;
                    color: $colorBlue;
                    @include transition(color);

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background: $colorBlue;
                        height: 2px;
                        width: 100%;
                        transform: scaleX(0);

                        @include transition(transform background);
                    }

                    @include active {
                        &:after {
                            transform: none;
                        }
                    }

                    &[aria-selected="true"] {
                        color: $colorBlack;

                        &:after {
                            transform: none;
                            background: $colorBlack;
                        }
                    }
                }
            }
        }

        .tabContent {
            margin-bottom: 56px;

            .mobileTitle {
                display: inline-block;
                margin-bottom: 30px;

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }

            display: block;

            @include media-breakpoint-up(md) {
                display: none;
                margin-bottom: 0;

                &.active {
                    display: block;
                }
            }
        }

        #info-tab-documents {
            p {
                margin: 16px 0 8px;
            }

            ul {

                li {
                    a {
                        text-decoration: none;
                        color: $colorBlue;
                        position: relative;
                        padding-left: 34px;
                        line-height: 32px;

                        &:before {
                            content: '';
                            width: 24px;
                            height: 2px;
                            background: $colorBlue;
                            position: absolute;
                            margin-right: 10px;
                            left: 0;
                            top: 10px;
                        }

                        @include underline_closed($colorBlack, 1px);
                    }
                }
            }
        }

        #info-tab-specifications {
            table {
                display: block;
                width: 100% !important; // Overriding copied attributes

                tr,
                td {
                    display: block;
                    height: auto !important; // Overriding copied attributes

                    @include make-row();
                }

                tbody {
                    @include make-container();
                    display: block;
                }

                tr {
                    border-top: 1px solid $colorGreyLight;

                    &:last-of-type {
                        border-bottom: 1px solid $colorGreyLight;
                    }

                    padding: 10px;
                }

                td {
                    display: inline-block;
                    width: auto !important;

                    &:first-child {
                        @include make-col(12);
                        color: $colorGreyLighter;
                        font-size: 16px;
                        padding-bottom: 5px;

                        @include media-breakpoint-up(md) {
                            @include make-col(4);
                            font-size: 18px;
                            padding-bottom: 0;
                        }
                    }

                    &:last-child {
                        @include make-col(12);

                        @include media-breakpoint-up(md) {
                            @include make-col(8);
                        }
                    }
                }
            }
        }
    }

    .aboutSDGGoalsContainer {
        margin-bottom: 70px;

        h2 {
            font-size: 24px;
            line-height: 32px;
            margin-top: 0;
            margin-bottom: 30px;
        }

        ul {
            margin-bottom: 46px;
        }

        li {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            svg.sprite {
                width: 35px;
                margin-right: 16px;
            }
        }

        a {
            color: $colorBlue;

            @include underline_closed($colorBlue, 1px);
        }
    }

    section#content {
        h1 {
            margin-top: 0;
            margin-bottom: 28px;
            font-size: 48px;
        }

        .infoColumn {
            .subtitle {
                display: block;
                font-size: 16px;
                margin-top: 16px;
                line-height: 19px;
                font-style: normal;
                padding-left: 10px;
            }
        }

        .aboutStriberContainer {
            .striberInfo {
                display: flex;
                align-items: center;

                .image {
                    flex: 0 0 auto;
                    height: 90px;
                    width: 90px;
                    margin-right: 16px;

                    img {
                        border-radius: 50%;
                    }
                }

                .heading {
                    * {
                        display: inline-block;
                    }

                    .subtitle {
                        font-style: normal;
                        font-size: 18px;
                        line-height: 21px;
                        font-weight: normal;
                        margin-bottom: 4px;
                        font-style: normal;

                        @include media-breakpoint-up(md) {
                            line-height: 24px;
                            display: block;
                            font-size: 16px;
                            margin-top: 16px;
                        }
                    }

                    .title {
                        color: $colorRed;
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: 700;
                    }
                }
            }

            .text {
                a {
                    display: inline-block;
                    padding: 5px 0;
                    color: $colorGrey;
                    cursor: pointer;

                    @include underline_closed($colorGrey, 1px);

                    &:before {
                        content: '\25b6';
                        display: inline-block;
                        transform: scale(.6);
                        position: absolute;
                        right: -18px;
                    }
                }
            }
        }

        section.products {
            padding-top: 65px;
            padding-bottom: 75px;
            position: relative;
            margin-bottom: -20px;
            margin-top: 20px;
            margin-left: -15px;
            margin-right: -15px;

            @include media-breakpoint-up(md) {
                margin-left: -75px;
                margin-right: -75px;
                padding-top: 115px;
                padding-bottom: 95px;
                margin-top: 35px;
            }

            @include media-breakpoint-up(lg) {
                margin-left: -50px;
                margin-right: -50px;
                margin-top: 50px;
            }

            .background {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: #F8F8F4;

                &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    left: 0;
                    background-size: 100% 100%;
                    height: 14px;

                    @include media-breakpoint-up(md) {
                        height: 29px;
                    }

                    @include media-breakpoint-up(lg) {
                        height: 39px;
                    }

                    @include media-breakpoint-up(xl) {
                        height: 55px;
                    }
                }

                &:before {
                    background-image: url('/img/stories_curve_2.svg');
                    top: 0;
                }
            }

            h2 {
                color: $colorRed;
                margin-bottom: 16px;
                margin-left: auto;
                margin-right: auto;

                @include media-breakpoint-up(md) {
                    margin-bottom: 25px;
                }
            }

            > div:nth-of-type(1) > .row {
                margin-bottom: 35px;
            }

            > div:nth-of-type(2) > .row {
                margin-bottom: 35px;

                > div {
                    display: flex;
                    align-items: center;
                }
            }


            a.readmore {
                display: inline-flex;
                align-items: center;
                color: $colorGreyLighter;
                text-decoration: none;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;

                svg {
                    margin-left: 5px;
                    @include transition(transform);
                }

                @include active {
                    svg {
                        transform: translateX(4px);
                    }
                }
            }

            ul.productsList {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;

                li {
                    padding: 10px;
                    flex-basis: 100%;

                    @include media-breakpoint-up(md) {
                        flex-basis: 50%;
                    }

                    @include media-breakpoint-up(xl) {
                        flex-basis: 25%;
                        padding: 10px 20px;
                    }

                    article {
                        .title {
                            font-weight: 400;

                            @include media-breakpoint-up(lg) {
                                font-size: 24px;
                            }
                        }



                        .image {
                            justify-content: start;

                            img {
                                @include media-breakpoint-up(lg) {
                                    width: 175px;
                                    height: 175px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }



    .socials {
        margin-top: 68px;

        .socialMediaList {
            display: flex;
            flex-wrap: wrap;

            margin: {
                left: -8px;
                right: -8px;
                top: 11px;
            }

            li {
                padding: 0 8px;
                margin-top: 8px;

                a {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    background: $colorGreen;
                    @include transition(background);

                    svg.sprite {
                        transform: scale(.9);

                        use {
                            fill: $colorWhite;
                            @include transition(fill);
                        }
                    }

                    @include active {
                        background: $colorGreyLight;

                        svg.sprite {
                            use {
                                fill: $colorGreen;
                            }
                        }
                    }
                }
            }
        }
    }

    .factList {
        margin-top: 25px;
        li {
            font-size: 18px;
            line-height: 24px;
            padding-left: 20px;
            position: relative;
            margin-bottom: 12px;

            &:before {
                height: 13px;
                width: 13px;
                display: block;
                position: absolute;
                content: '';
                left: 0;
                top: 7px;
                background: url('/img/icon_check_green.svg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
    }
}
