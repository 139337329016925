body.shopproductdetailpage {

    a.back {
        text-decoration: none;
        color: $colorGreyLighter;
        display: inline-flex;
        align-items: center;
        min-height: 1px;
        padding: 5px 0;
        margin-bottom: 15px;

        @include underline_closed($colorGreyLighter, 1px);

        svg.sprite {
            margin-right: 10px;
            @include transition(transform);
        }

        @include active {
            svg.sprite {
                transform: translateX(-5px);
            }
        }
    }

    section#content h1 {
        margin-top: 0;
        color: $colorBlack;
        font-size: 48px;
        line-height: 40px;
        margin-bottom: 32px;
    }

    .infoColumn {
        .price {
            margin-bottom: 35px;

            .original {
                font-size: 18px;
                color: $colorRed;
                text-decoration: line-through;
                display: inline-block;
                margin-bottom: 5px;
            }

            .current {
                font-size: 32px;
                line-height: 37px;
                font-weight: 700;
            }
        }
    }

    .options {
        margin-bottom: 16px;

        .option {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        label {
            position: absolute;
            left: -999vw;
        }

        .selectric-wrapper {
            display: inline-block;
            min-width: 100%;
            max-width: 100%;

            @include media-breakpoint-up(md) {
                min-width: 280px;
            }

            .selectric {
                background: $colorWhite;
                border-radius: (52px / 2);
                border: 1px solid $colorGreyLight;

                .label {
                    font-size: 16px;
                    font-family: $fontDefault;
                    color: $colorGreyLighter;
                    height: 52px;
                    line-height: 52px;
                    margin: 0 52px 0 30px;
                }

                .button {
                    height: 52px;
                    width: 52px;
                    background-color: $colorWhite;

                    &:after {
                        border-width: 6px;
                    }
                }
            }

            .selectric-items {
                border-color: $colorGreyLight;
                box-shadow: none;
                background: $colorWhite;
                border-top: none;
                border-bottom-left-radius: (52px / 2);
                border-bottom-right-radius: (52px / 2);
                overflow: hidden;

                li {
                    font-size: 16px;
                    line-height: 18px;
                    padding: (52px / 2 - 16px / 2) 30px;

                    &.disabled.selected {
                        display: none;
                    }

                    &.highlighted,
                    &:hover {
                        background: #f8f8f4;
                    }
                }
            }

            &.selectric-open {
                .selectric {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    border-bottom-color: transparent;
                }
            }
        }
    }

    .addContainer {
        .button {
            min-width: 100%;
            max-width: 100%;
            justify-content: flex-start;

            @include media-breakpoint-up(md) {
                min-width: 280px;
            }

            &[disabled] {
                opacity: .5;
            }
        }
    }

    .baseRow {
        margin-bottom: 68px;

        @include media-breakpoint-up(md) {
            margin-bottom: 118px;
        }
    }

    .productImageSliderMain,
    .productImagesSliderSmall {
        position: relative;

        .previousContainer,
        .nextContainer {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            z-index: 1;

            a {
                display: inline-block;
                background: none;
                border: none;
                width: 16px + 10px;
                height: 28px + 10px;
                padding: 5px;
                cursor: pointer;
                @include transition(opacity);

                @include media-breakpoint-up(md) {
                    width: 20px + 20px;
                    height: 36px + 20px;
                    padding: 10px;
                }

                svg.sprite {
                    display: inline-block;
                    width: 16px;
                    max-height: 28px;
                    @include transition(transform);

                    @include media-breakpoint-up(md) {
                        width: 20px;
                        max-height: 36px;
                    }

                    use {
                        stroke: $colorGreyLight;
                    }
                }
            }
        }

        .previousContainer {
            left: 0;

            @include media-breakpoint-up(md) {
                left: 5%;
            }

            a {
                &:hover {
                    svg.sprite {
                        transform: translateX(-5px);
                    }
                }
            }
        }

        .nextContainer {
            right: 0;

            @include media-breakpoint-up(md) {
                right: 5%;
            }

            a {
                &:hover {
                    svg.sprite {
                        transform: translateX(5px) scaleX(-1);
                    }
                }
            }
        }
    }

    .productImageSliderMain {
        margin-bottom: 17px;

        .previousContainer,
        .nextContainer {
            a {
                width: 16px + 10px;
                height: 28px + 10px;
                padding: 5px;

                @include media-breakpoint-up(md) {
                    width: 20px + 20px;
                    height: 36px + 20px;
                    padding: 10px;
                }

                svg.sprite {
                    width: 16px;
                    max-height: 28px;

                    @include media-breakpoint-up(md) {
                        width: 20px;
                        max-height: 36px;
                    }
                }
            }
        }

        li {
            padding: 0 15%;

            .image {
                position: relative;
                height: 0;
                padding-bottom: 100%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
        }
    }

    .productImagesSliderSmall {
        padding-left: 10%;
        padding-right: 10%;

        .previousContainer,
        .nextContainer {
            a {
                width: 12px + 10px;
                height: 20px + 10px;
                padding: 5px;

                @include media-breakpoint-up(md) {
                    width: 12px + 20px;
                    height: 20px + 20px;
                    padding: 10px;
                }

                svg.sprite {
                    width: 12px;
                    max-height: 20px;

                    @include media-breakpoint-up(md) {
                        width: 12px;
                        max-height: 20px;
                    }
                }
            }
        }

        li {
            padding: 0 15%;

            .image {
                position: relative;
                height: 0;
                padding-bottom: 100%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
        }

        .slick-current {
            li {
                .image {
                    img {
                        border: 1px solid $colorGrey;
                    }
                }
            }
        }
    }


    .tabs {
        ul.tabHeaders {
            display: none;
            justify-content: flex-start;
            margin-bottom: 35px;
            margin-left: -16px;
            margin-right: -16px;

            @include media-breakpoint-up(md) {
                display: flex;
            }

            li {
                display: inline-block;
                padding: 0;
                margin: 0 16px;

                button {
                    display: inline-block;
                    background: transparent;
                    border: none;
                    padding: 5px 0;
                    position: relative;
                    text-decoration: none;
                    color: $colorBlue;
                    @include transition(color);

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background: $colorBlue;
                        height: 2px;
                        width: 100%;
                        transform: scaleX(0);

                        @include transition(transform background);
                    }

                    @include active {
                        &:after {
                            transform: none;
                        }
                    }

                    &[aria-selected="true"] {
                        color: $colorBlack;

                        &:after {
                            transform: none;
                            background: $colorBlack;
                        }
                    }
                }
            }
        }

        .tabContent {
            margin-bottom: 56px;

            .mobileTitle {
                display: inline-block;
                margin-bottom: 30px;

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }

            display: block;

            @include media-breakpoint-up(md) {
                display: none;
                margin-bottom: 0;

                &.active {
                    display: block;
                }
            }
        }

        #info-tab-description,
        #info-tab-specifications,
        #info-tab-shipping {
            ul, ol {
                padding-left: 34px;

                li {
                    // padding-left: 25px;
                    margin-bottom: 10px;
                    position: relative;

                    &:before {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        left: -34px;
                        top: 8px;
                        width: 24px;
                        height: 2px;
                        background: $colorBlue;
                    }
                }
            }

            ol {
                counter-set: listitem;

                li {
                    counter-increment: listitem;

                    &:before {
                        content: counter(listitem)'.';
                        color: $colorBlue;
                        background: transparent;
                        top: 0;
                        left: -30px;
                        text-align: right;
                    }
                }
            }
        }

        #info-tab-specifications {
            table {
                display: block;
                // Overrides
                width: 100% !important;
                font-size: inherit !important;
                font-family: inherit !important;

                tr,
                td {
                    display: block;
                    height: auto !important; // Overriding copied attributes

                    @include make-row();
                }

                tbody {
                    @include make-container();
                    display: block;
                }

                tr {
                    border-top: 1px solid $colorGreyLight;
                    // Overrides
                    height: auto !important;

                    &:last-of-type {
                        border-bottom: 1px solid $colorGreyLight;
                    }

                    padding: 10px;
                }

                td {
                    display: inline-block;
                    width: auto !important;
                    // Overrides
                    border: none !important;
                    overflow: visible !important;
                    padding: 0 !important;
                    vertical-align: middle !important;

                    &:first-child {
                        @include make-col(12);
                        color: $colorGreyLighter;
                        font-size: 16px;
                        padding-bottom: 5px !important;

                        @include media-breakpoint-up(md) {
                            @include make-col(4);
                            font-size: 18px;
                            padding-bottom: 0 !important;
                        }
                    }

                    &:last-child {
                        @include make-col(12);

                        @include media-breakpoint-up(md) {
                            @include make-col(8);
                        }
                    }
                }
            }
        }
    }

    .specSheet {
        overflow: hidden;

        span {
            font-size: 16px;
            line-height: 24px;
        }

        .row {
            border-top: 2px solid $colorGreyLight;

            &:last-child {
                border-bottom: 2px solid $colorGreyLight;
            }

            span {
                display: block;
                padding: 8px 0;
            }

            > .col,
            > [class*="col-"] {
                &:first-child {
                    span {
                        color: $colorGreyLighter;
                    }
                }
            }
        }
    }

    .extraColumns {
        // background: $colorGreen;
    }

    .aboutSDGGoalsContainer {
        margin-bottom: 70px;

        h2 {
            font-size: 24px;
            line-height: 32px;
            margin-top: 0;
            margin-bottom: 30px;
        }

        ul {
            margin-bottom: 46px;
        }

        li {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            svg.sprite {
                width: 35px;
                margin-right: 16px;
            }
        }

        a {
            color: $colorBlue;

            @include underline_closed($colorBlue, 1px);
        }
    }

    .aboutSellerContainer {
        margin-bottom: 88px;

        .image {
            max-width: 64%;
            margin-bottom: 16px;
        }

        .text {
            line-height: 1.4em;
        }
    }

    section#content .aboutStriberContainer {
        .striberInfo {
            display: flex;
            align-items: center;

            .image {
                flex: 0 0 auto;
                height: 90px;
                width: 90px;
                margin-right: 16px;

                img {
                    border-radius: 50%;
                }
            }

            .heading {
                * {
                    display: inline-block;
                }

                .subtitle {
                    font-size: 18px;
                    line-height: 21px;
                    font-weight: normal;
                    margin-bottom: 4px;
                    font-style: normal;
                }

                .title {
                    color: $colorRed;
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 700;
                }
            }
        }

        .text {
            a {
                display: inline-block;
                padding: 5px 0;
                color: $colorGrey;
                cursor: pointer;

                @include underline_closed($colorGrey, 1px);

                &:before {
                    content: '\25b6';
                    display: inline-block;
                    transform: scale(.6);
                    position: absolute;
                    right: -18px;
                }
            }
        }
    }

    .reviewsContainer {
        margin-bottom: 64px;

        .ratingContainer {
            ol {
                display: block;
                margin-bottom: 10px;
            }

            li {
                display: inline-block;
                width: 29px;
                margin-right: 6px;

                svg.sprite {
                    width: 35px;
                    padding-left: 3px;
                    padding-right: 3px;
                    max-height: 25px;

                    use {
                        fill: transparent;
                        stroke: $colorGrey;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                input {
                    display: none;
                }

                label {
                    cursor: pointer;

                    svg.sprite use {
                        @include transition(fill stroke);
                    }

                    @include active {
                        svg.sprite use {
                            fill: $colorGreen;
                        }
                    }
                }

                input:checked + label {
                    svg.sprite {
                        @include animation(liked);

                        use {
                            fill: $colorGreen;
                            stroke: $colorGreen;
                        }
                    }
                }
            }

            &[data-rating="1"] {
                li:nth-child(-n+1) {
                    label svg.sprite {
                        @include animation(liked);

                        use {
                            fill: $colorGreen;
                            stroke: $colorGreen;
                        }
                    }
                }
            }

            &[data-rating="2"] {
                li:nth-child(-n+2) {
                    label svg.sprite {
                        @include animation(liked);

                        use {
                            fill: $colorGreen;
                            stroke: $colorGreen;
                        }
                    }
                }
            }

            &[data-rating="3"] {
                li:nth-child(-n+3) {
                    label svg.sprite {
                        @include animation(liked);

                        use {
                            fill: $colorGreen;
                            stroke: $colorGreen;
                        }
                    }
                }
            }

            &[data-rating="4"] {
                li:nth-child(-n+4) {
                    label svg.sprite {
                        @include animation(liked);

                        use {
                            fill: $colorGreen;
                            stroke: $colorGreen;
                        }
                    }
                }
            }

            &[data-rating="5"] {
                li:nth-child(-n+5) {
                    label svg.sprite {
                        @include animation(liked);

                        use {
                            fill: $colorGreen;
                            stroke: $colorGreen;
                        }
                    }
                }
            }
        }

        .inputRow {
            input {
                padding: 5px 20px;
                line-height: 30px;
            }

            label span {
                color: $colorGrey;
            }
        }

        .textarea {
            margin-top: 20px;
            margin-bottom: 32px;

            label {
                display: inline-block;
                padding: 5px 10px;
                margin-bottom: 3px;
            }

            textarea {
                background: white;
                border-radius: 0;
                border: 1px solid $colorGreyLight;
                padding: 5px 10px;
                color: $colorGrey;
                min-height: 128px;
                resize: vertical;

                &:focus {
                    border-color: $colorBlack;
                }
            }
        }
    }

    section#content {
        .reviewsContainer h3 {
            font-weight: 500;
            margin-bottom: 25px;
            font-size: 24px;
            line-height: 32px;
        }

        section.related {
            padding-top: 65px;
            padding-bottom: 75px;
            position: relative;
            margin-bottom: -20px;
            margin-top: 20px;
            margin-left: -15px;
            margin-right: -15px;

            @include media-breakpoint-up(md) {
                margin-left: -75px;
                margin-right: -75px;
                padding-top: 115px;
                padding-bottom: 95px;
                margin-top: 35px;
            }

            @include media-breakpoint-up(lg) {
                margin-left: -50px;
                margin-right: -50px;
                margin-top: 50px;
            }

            .background {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: #F8F8F4;

                &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    left: 0;
                    background-size: 100% 100%;
                    height: 14px;

                    @include media-breakpoint-up(md) {
                        height: 29px;
                    }

                    @include media-breakpoint-up(lg) {
                        height: 39px;
                    }

                    @include media-breakpoint-up(xl) {
                        height: 55px;
                    }
                }

                &:before {
                    background-image: url('/img/stories_curve_2.svg');
                    top: 0;
                }
            }

            h2 {
                color: $colorRed;
                margin-bottom: 10px;
            }

            > div:nth-of-type(1) > .row {
                margin-bottom: 35px;
            }

            > div:nth-of-type(2) > .row {
                margin-bottom: 35px;

                > div {
                    display: flex;
                    align-items: center;
                }
            }


            a.readmore {
                display: inline-flex;
                align-items: center;
                color: $colorGreyLighter;
                text-decoration: none;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;

                svg {
                    margin-left: 5px;
                    @include transition(transform);
                }

                @include active {
                    svg {
                        transform: translateX(4px);
                    }
                }
            }

            ul.productsList {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;

                li {
                    padding: 10px;
                    flex-basis: 100%;

                    @include media-breakpoint-up(md) {
                        flex-basis: 50%;
                    }

                    @include media-breakpoint-up(xl) {
                        flex-basis: 25%;
                        padding: 10px 20px;
                    }

                    article {
                        .title {
                            font-weight: 400;

                            @include media-breakpoint-up(lg) {
                                font-size: 24px;
                            }
                        }



                        .image {
                            justify-content: start;

                            img {
                                @include media-breakpoint-up(lg) {
                                    width: 175px;
                                    height: 175px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    ul.reviews {
        > li {
            border-bottom: 1px solid $colorGreyLight;
            padding: 24px 0 14px;

            &:first-child {
                border-top: 1px solid $colorGreyLight;
            }

            .review {
                .rating {
                    display: block;
                    margin-bottom: 16px;

                    li {
                        display: inline-block;
                        width: 20px;
                        margin-right: 6px;

                        svg.sprite {
                            width: 26px;
                            padding-left: 2px;
                            padding-right: 2px;
                            max-height: 19px;

                            use {
                                fill: $colorGreyLight;
                                stroke: none;
                            }
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        &.active {
                            svg.sprite use {
                                fill: $colorGreen;
                            }
                        }
                    }
                }

                .info {
                    color: $colorGrey;
                    font-size: 16px;
                    line-height: 21px;
                    margin-bottom: 7px;

                    .author {
                    }

                    .date {
                    }
                }

                .title {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 4px;
                }

                .content {
                    p {
                        font-size: 18px;
                        line-height: 24px;

                        &:first-child {
                            margin-top: 0;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .factList {
        li {
            font-size: 18px;
            line-height: 24px;
            padding-left: 20px;
            position: relative;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                height: 13px;
                width: 13px;
                display: block;
                position: absolute;
                content: '';
                left: 0;
                top: 7px;
                background: url('/img/icon_check_green.svg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
    }
}
