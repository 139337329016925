.ebookPromo {
    .promo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: stretch;

        border: 1px solid $colorGreyLight;

        border-radius: 32px;
        padding: 32px 40px;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        .content {
            flex: 1 1 auto;
            order: 2;
            margin-top: 10px;

            @include media-breakpoint-up(md) {
                margin-top: 0;
                order: 1;
            }

            h2, .title {
                font-size: 36px;
                line-height: 42px;
                font-weight: 900;

                em {
                    font-style: normal;
                    color: $colorRed;
                }
            }

            p {
                margin-top: 0;
                margin-bottom: 30px;

                font-size: 18px;
                line-height: 30px;
            }

            .actions {
                font-size: 22px;
                font-weight: 900;
                padding-top: 12px;
                padding-bottom: 12px;

                display: flex;
                flex-direction: column;
                align-items: center;

                @include media-breakpoint-up(md) {
                    flex-direction: row;
                    justify-content: flex-start;
                }

                .button:not(.secondary) {
                    padding-right: 57px;
                }

                .secondary {
                    margin-top: 20px;
                    color: $colorRed;
                    font-weight: 900;

                    @include media-breakpoint-up(md) {
                        margin-top: 0;
                        margin-left: 20px;
                    }
                }
            }
        }

        .image {
            order: 1;
            flex: 1 1 auto;
            min-width: 200px;

            @include media-breakpoint-up(md) {
                order: 2;
                margin-left: 40px;
            }
        }
    }
}

section#content .ebookPromo {
    .promo {
        .content {
            h2, .title {
                margin-top: 0;
                margin-bottom: 23px;
            }
        }
    }
}