body.shopstriberoverviewpage {
    .productsContainer {
        margin-top: 50px;

        ul {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;

            margin-top: -24px;
            margin-left: -10px; // Half of gutter
            margin-right: -10px;

            @include media-breakpoint-up(xl) {
                margin-left: -20px;
                margin-right: -20px;
            }

            li {
                flex: 0 0 auto;
                flex-basis: 100%;
                max-width: 100%;
                padding-left: 10px;
                padding-right: 10px;
                margin-top: 24px;

                @include media-breakpoint-up(sm) {
                    flex-basis: 33.33%;
                    max-width: 33.33%;
                }

                @include media-breakpoint-up(lg) {
                    flex-basis: 25%;
                    max-width: 25%;
                }

                @include media-breakpoint-up(xl) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }

        article {
            .image {
                position: relative;
                height: 0;
                padding-bottom: 100%;

                img {
                    top: 0;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    section#content {
        article {
            .image {
                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .contents {
                >a.title {
                    font-size: 24px;
                    line-height: 24px;
                    font-weight: 500;

                    @include transition(color);

                    @include active {
                        color: $colorPurple;
                    }
                }
            }
        }
    }
}