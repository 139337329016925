ul.slider {
    
}

.sliderControls {
    display: flex;
    justify-content: center;

    .prev, .next {
        button {
            background: none;
            border: none;
            height: 48px;
            width: 48px;
        }
    }

    ul.slick-dots {
        display: inline-flex;
        align-items: center;

        li {
            margin: 0 8px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            button {
                color: $colorBlack;
                border: 1px solid $colorGreyLight;
                background: $colorWhite;
                height: 48px;
                width: 48px;

                border-radius: 50px;
                @include transition(color border-color);

                @include active {
                    border-color: $colorGreyLighter;
                }
            }

            &.slick-active {
                button {
                    color: $colorRed;
                    border-color: $colorGreyLighter;
                }
            }
        }
    }
}