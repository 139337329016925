#cookieBar {
	$colorBackground: transparentize($colorBlack, .2);
    $colorText: $colorWhite;
    $colorButton: $colorCyan;
    $colorButtonText: $colorBlack;


	position: fixed;
	background: $colorBackground;
	right: 0;
	bottom: 0;
	left: 0;
	color: $colorText;
	padding: 20px 0 30px 0;
	@include transition(transform, 800ms);
	transform: translateY(100%);
	width: 100%;
	z-index: 9999;

	&.open {
		transform: translateY(0);
	}

	.inner {
		display: flex;
		flex-wrap: wrap;

		@include media-breakpoint-down(md) {
			flex-direction: column;
		}

		span.title {
			display: block;
			font-size: 20px;
			font-weight: 700;
			line-height: 22px;
			flex-basis: 100%;
            color: $colorText;
            margin-bottom: 10px;
		}

		.text {
			text-align: left;
			color: $colorText;
			margin-bottom: 0;
			display: none;
			flex-direction: column;
			justify-content: center;
			flex: 1;
			margin-right: 50px;
			font-size: 16px;
			line-height: 22px;

			@include media-breakpoint-up(md) {
				display: flex;
			}

			&.mobile {
				display: flex;
				margin-right: 0;

				@include media-breakpoint-up(md) {
					display: none;
				}
			}

			p {
				margin: 0;
			}

			a {
				color: #ffffff;
				text-decoration: none;

				&:hover {
					text-decoration: none;
				}
			}
		}

		.buttons {
			margin-top: 20px;

			@include media-breakpoint-up(md) {
				margin-top: 0;
			}

			a.button, button {
				cursor: pointer;
				width: 100%;
				max-width: 150px;
				text-align: center;
				font-weight: 600;
				padding: 7px 15px;
				margin: 5px 5px 0 0;
				font-size: 0.9em;
				border-radius:30px;
				justify-content: center;
                color: $colorButtonText;
                text-decoration: none;

				@include transition();

				@include media-breakpoint-up(md) {
					margin: 5px 0;
					max-width: none;
					display: block;
				}

				@include media-breakpoint-up(lg) {
					font-size: 0.9em;
				}

				&.agreeCookies {
					background-color: $colorButton;

					@include active() {
						background-color: $colorButtonText;
						color: $colorButton;
					}
				}

				&.goConfigure {
					background-color: $colorButton;

					@include active() {
						background-color: $colorButtonText;
						color: $colorButton;
					}
				}
			}
		}
	}
}

.videoCookieMessage {
	background: #F5F5F5;
	text-align: center;
	border: 1px solid #CECECE;
	display: table;
	height: 100%;
	margin: -1px;
	width: 100%;

	.contentHolder {
		display: table-cell;
		vertical-align: middle;

		.inner {
			margin-left: auto;
			margin-right: auto;
			padding: 10px 0;
			width: 100%;

			@include media-breakpoint-up(md) {
				padding: 20px;
			}
		}
	}

	svg {
		display: none;
		height: 60px;
		margin-bottom: 13px;
		vertical-align: top;
		width: 60px;

		@include media-breakpoint-up(md) {
			display: inline-block;
		}
	}

	span.title {
		color: #4A4A4A;
		display: block;
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 8px;
		padding: 0 20px;
	}

	p {
		color: #4A4A4A;
		font-size: 13px;
		line-height: 20px;
		margin: 0 0 14px;
		padding: 0 20px;

		a {
			border-bottom: 0;
			color: #3388C2;
			padding-bottom: 0;
			text-decoration: none;

			&:hover, &:active, &:focus {
				border-bottom: 0;
				text-decoration: none;
			}
		}
	}
}

#cookieConfigure {
    $colorBackground: rgba(74, 74, 74, 0.95);
    $colorText: #ffffff;
    $colorButton: #19ffe0;
    $colorButtonText: #333333;

	.checkboxButton {
		margin-top: 30px;
		margin-bottom: 15px;
		margin-left: 30px;
		display: inline-block;
		position: relative;

		@include media-breakpoint-down(md) {
			margin-left: 0px;
		}


		label {
			display: block;
			margin-bottom: 10px;

			&:before, &:after {
				content: '';
				height: 20px;
				width: 20px;
				border-radius: 4px;
				display: inline-block;
				position: absolute;
				left: -35px;
				top: -3px;
			}

			&:before {
				border: 3px solid white;
				border-radius: 6px;
				background: #ff1990;
				transform: scale(0);

				@include transition(transform);
			}

			&:after {
				border: 2px solid #333333;
			}

			span {
				display: inline-block;
				vertical-align: middle;
				margin-bottom: 13px;

				a {
					color: #4a8dc0;
					font-weight: 700;
				}
			}
		}

		input {
			display: none;

			&:checked {
				background-color: red;

				~ label {
					&:before {
						transform: none;
					}
				}
			}

			&:disabled {
				~ label {
					&:before, &:after {
						opacity: .5;
					}
				}
				
			}
		}


		&.invalid {
			
		}
	}

	button.configure {
		background-color: green;
		border-radius:25px;
		padding:0 25px;
		border:none;
		height:50px;

		span{
			color:white;
		}
	}

	.onSaveComplete {
		@include transition(opacity, 200ms);
		margin-left: 20px;
		display: inline-block;
		opacity: 0;
		pointer-events: none;

		@include media-breakpoint-down(md) {
			margin-left: 0px;
		}

		&.success {
			opacity: 1;
		}
	}
}
