header:not(.landing) {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    height: $sizeMenuHeightMobile;
    // background: linear-gradient(
    //     to bottom,
    //     $colorWhite 0,
    //     $colorWhite 55%,
    //     transparentize($colorWhite, 1));
    background: $colorWhite;

    @include transition(transform);

    @include media-breakpoint-up(md) {
        height: $sizeMenuHeight;
    }

    &.hidden {
        transform: translateY(-100%);

        &.mobileMenuActive {
            transform: none;

            @include media-breakpoint-up(md) {
                transform: translateY(-100%);
            }
        }
    }

    .container {
        height: $sizeMenuHeightMobile;
        height: 100%;

        @include media-breakpoint-up(md) {
            height: $sizeMenuHeight;
            height: 100%;
        }

        .row {
            height: 100%;

            @include media-breakpoint-up(md) {
                height: 100%;
            }

            .col,
            [class*="col-"] {
                max-height: 100%;
            }
        }
    }

    .logoColumn {
        padding-top: 10px;
        padding-bottom: 10px;

        display: flex;
        justify-content: flex-start;

        .headerLogo {
            align-self: center;

            width: 120px;

            @include media-breakpoint-up(md) {
                width: 100px;
            }

            @include media-breakpoint-up(lg) {
                width: 120px;
            }

            @include media-breakpoint-up(xl) {
                width: 100%;
            }

            svg {
                width: 100%;
                max-height: $sizeMenuHeightMobile;

                use {
                    @include transition(fill);
                    transition-delay: 200ms;
                }
            }
        }

        a {
            display: inline-block;
        }

        img {
            max-height: $sizeMenuHeightMobile - 20px;

            @include media-breakpoint-up(md) {
                max-height: $sizeMenuHeight - 20px;
            }
        }
    }
}

header:not(.landing).mobileMenuActive {
    .logoColumn {
        .headerLogo {
            svg {
                use {
                    fill: $colorWhite;

                    @include media-breakpoint-up(md) {
                        fill: $colorBlack;
                    }
                }
            }
        }
    }
}